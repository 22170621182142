import MyConfig from '../../config/MyConfig';
import {
    SET_USER_FEEDBACK_LIST,
    SET_MULTIPLE_USER_FEEDBACK_LIST,
    RESET_USER_FEEDBACK_LIST
} from '../constants/actionTypes';

const initialState = {
    addFeedbackModal: false,
    editId: "",
    successMsg: false,
    viewMode: "upload",
    document_Json: [],
    deviceWidth: window.innerWidth,
    loader: false,
    documentFiles: [],
    title: "",
    description: "",
    deviceOs: "",
    deviceOsVersion: "",
    deviceModel: "",
    deviceOtherOsVersion: "",
    deviceOsVersionOption: [],
    issueFrom: "Web",
    submitLoader: false,
    imagePreview: {},
    modalVisible: false,
    errors: {
        title: "",
        deviceModel: "",
        deviceOsVersion: "",
        deviceOtherOsVersion: "",
    },
    pageLimit: 10,
    noOfPage: 1,
    pagination: [],
    issueList: [],
    viewModal: false,
    viewType: "",
    viewDetails: {},
    previewModal: false,
    previewIndex: "",
    previewTotalLength: 0,
    feedbackListFrom: "own",
    next: false,
    prev: false
};

const userOrgFeedbackListReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_FEEDBACK_LIST: {
            return {
                ...state,
                [action.key]: action.value,
            };
        }
        case SET_MULTIPLE_USER_FEEDBACK_LIST: {
            return {
                ...state,
                ...action.data,
            };
        }
        case RESET_USER_FEEDBACK_LIST: {
            return {
                ...state,
                addFeedbackModal: false,
                editId: "",
                successMsg: false,
                viewMode: "upload",
                document_Json: [],
                deviceWidth: window.innerWidth,
                loader: false,
                documentFiles: [],
                title: "",
                description: "",
                deviceOs: "",
                deviceOsVersion: "",
                deviceModel: "",
                deviceOtherOsVersion: "",
                deviceOsVersionOption: [],
                issueFrom: "Web",
                submitLoader: false,
                imagePreview: {},
                modalVisible: false,
                errors: {
                    title: "",
                    deviceModel: "",
                    deviceOsVersion: "",
                    deviceOtherOsVersion: "",
                },
                pageLimit: 10,
                noOfPage: 1,
                pagination: [],
                issueList: {},
                viewModal: false,
                viewType: "",
                viewDetails: {},
                previewModal: false,
                previewIndex: "",
                previewTotalLength: 0,
                feedbackListFrom: "own",
                next: false,
                prev: false
            };
        }
        default: {
            return state;
        }
    }
};

export default userOrgFeedbackListReducer;
