import React from 'react';
import { getEngPageText, getGrpcClient, getPageText, getUuidv4, getValidLabels } from '../../config/Common';
import MyConfig from '../../config/MyConfig';
import { ZiFetchRequest } from '../../protobuf/generated/ZiFetchService_grpc_web_pb';
import GlobalDomainCons from '../../config/GlobalDomainCons';
import GlobalEventCons from '../../config/GlobalEventCons';
import GlobalMsgCons from '../../config/GlobalMsgCons';
import { connect } from 'react-redux';
import { setMultipleAuthData } from "../../redux/actions";
import { checkLangFileExistAndDownload } from '../../config/MyLangManager';
import MyConstant from '../../config/MyConstant';

class SwitchLanguage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageText: {},
            engPageText: {},
            languageCloneList: [],
            languageList: [],
            languageListSelected: "",
            isBetaLanguage: false
        }
    }

    componentDidMount() {
        this.init()
        this.getLanguageList()
    }

    async init() {
        let pageText = await getPageText('language');
        let engPageText = await getEngPageText('language');

        this.setState({
            pageText, 
            engPageText
        })
    }

    getLanguageList = () => {
        let { languagePreference } = this.props
        let besidesBeta = GlobalMsgCons.besidesBeta
        let postJson = { reqId: getUuidv4(), };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.uiSetDomain);
        request.setEvent(GlobalEventCons.languagesDataSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                let languageOptions = []
                let selectedVal = "";
                let isBetaLanguage = false;
                if (json && json.uiMap && Object.keys(json.uiMap).length !== 0) {
                    Object.keys(json.uiMap).map((key) => {
                        let lan = json.uiMap[key]
                        var obj = JSON.parse(lan)
                        if (key === languagePreference) {
                            selectedVal = obj["name"]
                            if (!besidesBeta.includes(key)) {
                                isBetaLanguage = true
                            }
                        }
                        languageOptions.push({ label: obj["name"], value: key, beta: !besidesBeta.includes(key), releasedStatus: obj["releasedStatus"], releasedDate: obj["releasedDate"] })
                    })
                }
                this.setState({ 
                    languageList: languageOptions, 
                    languageCloneList: languageOptions, 
                    languageListSelected: selectedVal, 
                    isBetaLanguage,
                })
            }
        });
    }



    renderItem = (item, index) => {
        let { pageText, engPageText } = this.state
        let { label, value, releasedStatus, releasedDate } = item
        var printLabel = label;
        let langCode = localStorage.getItem(MyConfig.project_LocalStorage + "languagePreference");
        langCode = langCode ? langCode : "eng"
        if (getValidLabels(pageText, value)) {
            if (langCode !== "eng") {
                printLabel = getValidLabels(pageText, value) + " (" + getValidLabels(engPageText, value) + ")"

            } else {
                printLabel = getValidLabels(pageText, value)
            }
        }
            return releasedStatus === "Yes" && <div key={index} onClick={async () => {
                await checkLangFileExistAndDownload(value, releasedDate);
                this.updateLanguage(value)
            }} style={{ display: "flex", flexDirection: "row", padding: 10, marginBottom: 5, borderRadius: 5, border: "1px solid #ddd" }}>
                <div style={{ display: "flex", flex: 1, alignItems: "flex-start", justifyContent: "center", paddingLeft: 10, }}>
                    <div style={{ display:"flex", flex: 1, flexDirection: "row", }}>
                        <div style={{ fontSize: 16, }}>{printLabel}</div>
                        {
                            item.beta && <div style={{ backgroundColor: "#FB6F92", padding: "1px 10px", borderRadius: 5, margin: "0 10px"}}><div style={{ fontSize: 14, color: "#fff" }}>Beta</div></div>
                        }

                    </div>
                </div>
                <div style={{ width: 50, alignItems: "center", justifyContent: "center" }}>
                    <div style={{ width: 20, height: 20, borderRadius: 10, borderWidth: 1, alignItems: "center", justifyContent: "center", display: "flex", border: '1px solid #ddd' }}>
                        {
                            langCode === item.value && <div style={{ width: 14, height: 14, borderRadius: 7, backgroundColor: "#1e325c" }}></div> 
                        }
                    </div>
                </div>
            </div>
    }

    updateLanguage(languageSelected) {
        let { userId, path } = this.props;
        let { languageList } = this.state;
        let postJson = { reqId: getUuidv4(), userId, languagePreference: languageSelected };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.userSetDomain);
        request.setEvent(GlobalEventCons.updateLanguageSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                if (responseData.response === GlobalMsgCons.success) {
                    let findLang = languageList.filter((e) => { return e.value === languageSelected })
                    if (findLang.length > 0) {
                        findLang = findLang[0].label
                    }
                    localStorage.setItem(MyConfig.project_LocalStorage + "languagePreference", languageSelected);

                    this.props.setMultipleAuthData({
                        languagePreference: languageSelected
                    })

                    if(path) {
                        this.props.history.push(`${path}/home`);
                    } else{
                        this.props.history.goBack()
                    }
                }
            }
        });
    }

    setSearchValue(searchValue) {
        let { languageCloneList } = this.state;
        let languageList = languageCloneList.filter(option => option.label.toLowerCase().includes(searchValue.trim().toLowerCase()))
        
        this.setState({
            languageList
        })
    }
    render() {
        let { pageText, languageList } = this.state;
        return <div className="mobile-container">
            <div className="header">
                <div style={{ display: "flex", flex: 1, padding: 5, flexDirection: "row", fontSize: 18, alignItems: "center" }}>
                    <div style={{ padding: "0 10px" }} onClick={() => {
                        this.props.history.goBack()
                    }}>
                        <i className='fas fa-chevron-left' />
                    </div>
                    <div style={{ flex: 1 }}>
                        <div>{getValidLabels(pageText, "headerTxt")}</div>
                    </div>
                </div>
            </div>
            <div className="body">
                <div className='d-flex' style={{ flexDirection: "column", height: 'calc(100vh - 50px)'}}>
                    <div className="p-2">
                        <input placeholder="Search" className="form-control" onChange={(e) => {
                            this.setSearchValue(e.target.value)
                        }}/>
                    </div>
                    <div className='p-2 search-language-menubar-list'>
                        {
                            languageList.map((item, index) => {
                                return this.renderItem(item, index)
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    }

}

const mapStateToProps = (state) => {
    return {
        ...state,
        userId: state.authReducer.loginId,
        companyId: state.authReducer.activeCompany,
        firstName: state.authReducer.firstName,
        languagePreference: state.authReducer.languagePreference,
    }
}

const mapDispatchToProps = {
    setMultipleAuthData
};

export default connect(mapStateToProps, mapDispatchToProps)(SwitchLanguage);