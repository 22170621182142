import {
  SET_USER_LEAVE_REQUEST,
  SET_MULTIPLE_USER_LEAVE_REQUEST,
  RESET_USER_LEAVE_REQUEST,
} from '../constants/actionTypes';

let time = new Date()

const initialState = {
  userId: "",
  companyId: "",
  events: [],
  meetingModal: false,
  meetingViewModal: false,
  viewDetails: {},
  title: "",
  description: "",
  startTime: time,
  endTime: time,
  members: [],
  timezone: "",
  errors: {
    title: ""
  },
  editId: "",
  usersOptions: [],
  usersAllOptions: {},
  membersOptions: [],
  membersAllOptions: {},
  timezoneOptions: [
    { label: "IST", value: "IST" },
    { label: "EST", value: "EST" }
  ],
  fromDate: "",
  endDate: "",
  pageText: null,
  editTextChanges: false,
  editResponseData: {},
  membersTypes: [],
  loaderCalendar: false,
  existData: {}
};

const userleaverequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_LEAVE_REQUEST: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_USER_LEAVE_REQUEST: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_USER_LEAVE_REQUEST: {
      return {
        ...state,
        userId: "",
        companyId: "",
        events: [],
        meetingModal: false,
        meetingViewModal: false,
        viewDetails: {},
        title: "",
        description: "",
        startTime: "",
        endTime: "",
        members: [],
        timezone: "",
        errors: {
          title: ""
        },
        editId: "",
        membersOptions: [],
        membersAllOptions: {},
        timezoneOptions: [
          { label: "IST", value: "IST" },
          { label: "EST", value: "EST" }
        ],
        fromDate: "",
        endDate: "",
        pageText: null,
        editTextChanges: false,
        editResponseData: {},
        loaderCalendar: false,
        existData: {},
      };
    }
    default: {
      return state;
    }
  }
};

export default userleaverequestReducer;
