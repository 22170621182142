import { SET_MULTIPLE_CALENDLY_EVENT, RESET_CALENDLY_EVENT } from '../constants/actionTypes';

const initialState = {
  eventModal: false,
  eventSubmitLoader: false,
  eventName: "",
  description: "",
  dates: [],
  slots: [],
  guests: [],
  limit: 0,
  eventType: "timeRange",
  timezoneInfo: Intl.DateTimeFormat().resolvedOptions().timeZone
};

const calendlyEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MULTIPLE_CALENDLY_EVENT: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_CALENDLY_EVENT: {
      return {
        ...state,
        eventModal: false,
        eventSubmitLoader: false,
        eventName: "",
        description: "",
        dates: [],
        slots: [],
        guests: [],
        limit: 0,
        eventType: "timeRange",
        timezoneInfo: Intl.DateTimeFormat().resolvedOptions().timeZone
      };
    }
    default: {
      return state;
    }
  }
};

export default calendlyEventReducer;
