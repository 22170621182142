import { REMOVE_USER_IP_HISTORY, RESET_USER_IP_HISTORY, SET_USER_IP_HISTORY, SET_MULTIPLE_USER_IP_HISTORY } from '../constants/actionTypes';

let date = new Date();
var getMonth = (date.getMonth() + 1).toString().padStart(2, "0");
var currentDate = date.getFullYear() + "-" + getMonth + "-" + date.getDate().toString().padStart(2, "0");

const initialState = {
    currentDate,
    data: {},
    resultArr:[]
};

const userIpHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_IP_HISTORY: {
            return {
                ...state,
                [action.key]: action.value,
            };
        }
        case SET_MULTIPLE_USER_IP_HISTORY: {
            if (action.date) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        [action.date]: {
                            ...state.data[action.date],
                            ...action.data
                        }
                    }
                }
            }
        }
        case REMOVE_USER_IP_HISTORY: {
            if (action.date) {
                const next = { ...state }
                delete next.data[action.date]
                return next
            }
        }
        case RESET_USER_IP_HISTORY: {
            return {
                ...state,
                currentDate,
                data: {},
                resultArr:[]
            };
        }
        default: {
            return state;
        }
    }
};

export default userIpHistoryReducer;
