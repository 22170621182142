import { useEffect, useState } from "react";
import { ZiFetchRequest } from "../protobuf/generated/ZiFetchService_grpc_web_pb";
import GlobalDomainCons from "../config/GlobalDomainCons";
import GlobalEventCons from "../config/GlobalEventCons";
import { getGrpcClient, getUuidv4, getValidTooltip } from "../config/Common";
import { useSelector, useDispatch } from "react-redux";
import GlobalMsgCons from "../config/GlobalMsgCons";
import MyConfig from "../config/MyConfig";
import { setMultipleAuthData, setMultipleLangugeData } from "../redux/actions";
import { checkLangFileExistAndDownload } from "../config/MyLangManager";

const besidesBeta = ["eng", "tam"];
const besidesPartial = ["som", "sna", "ibo", "orm", "hau","mah"];

export default function LanguageMenu(props) {
    let { setLanguagePreference, toggleUserLanguage, setToggleUserLanguage, accumulation, pageNameAcc, getPageEventName, setToggleUserStatus, setToggleUserMenu, pageText } = props
    let [languageList, setLanguageList] = useState([]);
    let [languageListSelected, setLanguageListSelected] = useState("");
    let [isBetaLanguage, setIsBetaLanguage] = useState(false);
    let [searchValue, setSearchValue] = useState("");
    let [isPartialLanguage, setIsPartialLanguage] = useState(false);

    const auth = useSelector(state => state.authReducer);
    const languageReducer = useSelector(state => state.languageReducer);

    const dispatch = useDispatch();
    let { loginId } = auth;
    let { languageOptions } = languageReducer;

    useEffect(() => {
        getLanguageList();
    }, [])

    useEffect(() => {
        let searchList = languageOptions.filter(option => option.label.toLowerCase().includes(searchValue.trim().toLowerCase()))
        setLanguageList(searchList)
    }, [searchValue])

    function updateLanguage(languageSelected) {
        let postJson = { reqId: getUuidv4(), userId: loginId, languagePreference: languageSelected };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.userSetDomain);
        request.setEvent(GlobalEventCons.updateLanguageSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                if (responseData.response === GlobalMsgCons.success) {
                    let findLang = languageList.filter((e) => { return e.value === languageSelected })
                    if (findLang.length > 0) {
                        findLang = findLang[0].label
                    }
                    localStorage.setItem(MyConfig.project_LocalStorage + "languagePreference", languageSelected);
                    setLanguagePreference(languageSelected)
                    setLanguageListSelected(findLang)
                    setIsBetaLanguage(!besidesBeta.includes(languageSelected) && !besidesPartial.includes(languageSelected))
                    setIsPartialLanguage(besidesPartial.includes(languageSelected))

                    dispatch(setMultipleAuthData({
                        languagePreference: languageSelected
                    }))

                }
            }
        });
    }

    function getLanguageList() {
        let postJson = { reqId: getUuidv4(), };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.uiSetDomain);
        request.setEvent(GlobalEventCons.languagesDataSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                let languageOptions = []
                let selectedVal = "";
                let isBetaLanguage = false;
                let isPartialLanguage = false;
                if (json && json.uiMap && Object.keys(json.uiMap).length !== 0) {
                    Object.keys(json.uiMap).map((key) => {
                        let lan = json.uiMap[key]
                        var obj = JSON.parse(lan)
                        let { translationMethod, translationLangCode } = obj;
                        if (key === auth.languagePreference) {
                            selectedVal = obj["name"]
                            if (!besidesBeta.includes(key) && !besidesPartial.includes(key)) {
                                isBetaLanguage = true
                            }
                            if (besidesPartial.includes(key) && !besidesBeta.includes(key)) {
                                isPartialLanguage = true
                            }
                        }

                        var releasedDate = ""
                        var releasedStatus = obj.releasedStatus
                        if (releasedStatus == "Yes") {
                            releasedDate = obj.releasedDate
                        }

                        languageOptions.push({ label: obj["name"], value: key, beta: (!besidesBeta.includes(key) && !besidesPartial.includes(key)), releasedDate, releasedStatus, partial: besidesPartial.includes(key), translationMethod, translationLangCode })
                    })
                }
                languageOptions.sort((a, b) => {
                    return (a.label > b.label ? 1 : (a.label === b.label ? 0 : -1))
                })

                setLanguageList(languageOptions)
                setLanguageListSelected(selectedVal)
                setIsBetaLanguage(isBetaLanguage)
                setIsPartialLanguage(isPartialLanguage)
                dispatch(setMultipleLangugeData({
                    languageOptions: languageOptions
                }))
            }
        });
    }

    return <div className={`language-details-icon ${toggleUserLanguage ? "active" : ""}`}>
        <div className='icon user-icon-menu' onClick={() => {
            if(accumulation) {
                accumulation(pageNameAcc, getPageEventName("workspaceTopMenu", "languageChanged"), auth.activeCompany, auth.loginId, props.grpcClient)
            }
            setToggleUserLanguage(!toggleUserLanguage);
            setToggleUserMenu(false);
            setToggleUserStatus(false)
        }} data-tip={getValidTooltip(pageText, 'languageIconTooltipTxt')}>
            <i className='fa fa-language user-icon-menu' />
            <div className='user-icon-menu lang-font'>{
                languageListSelected
            }</div>
            {
                isBetaLanguage && <div className='beta selected'>Beta</div>
            }
            {
                isPartialLanguage && <div className='beta partial selected'>Partial</div>
            }
        </div>
        <div className={`options ${toggleUserLanguage ? "d-flex" : ""}`} style={{ flexDirection: "column"}}>
            <div className="p-2">
                <input placeholder="Search" className="form-control search-language-menubar" onChange={(e) => {
                    setSearchValue(e.target.value)
                }}/>
            </div>
            <div className="search-language-menubar-list">
                {
                    languageList.map((item, index) => {
                        let { value, releasedDate, releasedStatus } = item;
                        return releasedStatus === "Yes" && <div className='option-list' key={index} onClick={async () => {
                            await checkLangFileExistAndDownload(value, releasedDate);
                            updateLanguage(value)
                        }}>
                            <div className='link d-flex'>
                                {item.label}
                                {
                                    item.beta && <span className='beta'>Beta</span>
                                }
                                {
                                    item.partial && <span className='beta partial'>Partial</span>
                                }
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    </div>;
}
