import {
  SET_TIMEZONE,
  SET_MULTIPLE_TIMEZONE,
  RESET_TIMEZONE
} from '../constants/actionTypes';

let timeZoneOptions = []
let timeZoneList = Intl.supportedValuesOf('timeZone');

timeZoneList.map((name, index) => {
  timeZoneOptions.push({ label: name, value: index })
})

const initialState = {
  timeZoneOptions,
  timeZoneSelect: "",
  timeZoneName: "",
  userId: "",
  timeZoneType: "manual"
};

const timezoneReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TIMEZONE: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_TIMEZONE: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_TIMEZONE: {
      return {
        ...state,
        timeZoneOptions,
        timeZoneSelect: "",
        timeZoneName: "",
        userId: "",
        timeZoneType: "manual"
      };
    }
    default: {
      return state;
    }
  }
};

export default timezoneReducer;
