/**
 * @fileoverview gRPC-Web generated client stub for simulation
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.14.0
// source: ZiFetchService.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

// const proto = {};
// proto.simulation = require('./ZiFetchService_pb.js');

// source: ZiFetchService.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.simulation.ZiFetchReply', null, global);
goog.exportSymbol('proto.simulation.ZiFetchRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.simulation.ZiFetchRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.simulation.ZiFetchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.simulation.ZiFetchRequest.displayName = 'proto.simulation.ZiFetchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.simulation.ZiFetchReply = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.simulation.ZiFetchReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.simulation.ZiFetchReply.displayName = 'proto.simulation.ZiFetchReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.simulation.ZiFetchRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.simulation.ZiFetchRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.simulation.ZiFetchRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.simulation.ZiFetchRequest.toObject = function (includeInstance, msg) {
    var f, obj = {
      domain: jspb.Message.getFieldWithDefault(msg, 1, ""),
      event: jspb.Message.getFieldWithDefault(msg, 2, ""),
      message: jspb.Message.getFieldWithDefault(msg, 3, ""),
      datetime: jspb.Message.getFieldWithDefault(msg, 4, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.simulation.ZiFetchRequest}
 */
proto.simulation.ZiFetchRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.simulation.ZiFetchRequest;
  return proto.simulation.ZiFetchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.simulation.ZiFetchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.simulation.ZiFetchRequest}
 */
proto.simulation.ZiFetchRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setDomain(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setEvent(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setMessage(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setDatetime(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.simulation.ZiFetchRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.simulation.ZiFetchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.simulation.ZiFetchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.simulation.ZiFetchRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEvent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDatetime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string domain = 1;
 * @return {string}
 */
proto.simulation.ZiFetchRequest.prototype.getDomain = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.simulation.ZiFetchRequest} returns this
 */
proto.simulation.ZiFetchRequest.prototype.setDomain = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string event = 2;
 * @return {string}
 */
proto.simulation.ZiFetchRequest.prototype.getEvent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.simulation.ZiFetchRequest} returns this
 */
proto.simulation.ZiFetchRequest.prototype.setEvent = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.simulation.ZiFetchRequest.prototype.getMessage = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.simulation.ZiFetchRequest} returns this
 */
proto.simulation.ZiFetchRequest.prototype.setMessage = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string dateTime = 4;
 * @return {string}
 */
proto.simulation.ZiFetchRequest.prototype.getDatetime = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.simulation.ZiFetchRequest} returns this
 */
proto.simulation.ZiFetchRequest.prototype.setDatetime = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.simulation.ZiFetchReply.prototype.toObject = function (opt_includeInstance) {
    return proto.simulation.ZiFetchReply.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.simulation.ZiFetchReply} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.simulation.ZiFetchReply.toObject = function (includeInstance, msg) {
    var f, obj = {
      message: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.simulation.ZiFetchReply}
 */
proto.simulation.ZiFetchReply.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.simulation.ZiFetchReply;
  return proto.simulation.ZiFetchReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.simulation.ZiFetchReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.simulation.ZiFetchReply}
 */
proto.simulation.ZiFetchReply.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setMessage(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.simulation.ZiFetchReply.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.simulation.ZiFetchReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.simulation.ZiFetchReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.simulation.ZiFetchReply.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.simulation.ZiFetchReply.prototype.getMessage = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.simulation.ZiFetchReply} returns this
 */
proto.simulation.ZiFetchReply.prototype.setMessage = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.simulation);


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.simulation.ZiFetchServiceClient =
  function (hostname, credentials, options) {
    if (!options) options = {};
    options.format = 'text';

    /**
     * @private @const {!grpc.web.GrpcWebClientBase} The client
     */
    this.client_ = new grpc.web.GrpcWebClientBase(options);

    /**
     * @private @const {string} The hostname
     */
    this.hostname_ = hostname.replace(/\/+$/, '');

  };


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.simulation.ZiFetchServicePromiseClient =
  function (hostname, credentials, options) {
    if (!options) options = {};
    options.format = 'text';

    /**
     * @private @const {!grpc.web.GrpcWebClientBase} The client
     */
    this.client_ = new grpc.web.GrpcWebClientBase(options);

    /**
     * @private @const {string} The hostname
     */
    this.hostname_ = hostname.replace(/\/+$/, '');

  };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.simulation.ZiFetchRequest,
 *   !proto.simulation.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService_SayZiFetch = new grpc.web.MethodDescriptor(
  '/simulation.ZiFetchService/SayZiFetch',
  grpc.web.MethodType.UNARY,
  proto.simulation.ZiFetchRequest,
  proto.simulation.ZiFetchReply,
  /**
   * @param {!proto.simulation.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  },
  proto.simulation.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.simulation.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.simulation.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.simulation.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.simulation.ZiFetchServiceClient.prototype.sayZiFetch =
  function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ +
      '/simulation.ZiFetchService/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService_SayZiFetch,
      callback);
  };


/**
 * @param {!proto.simulation.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.simulation.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.simulation.ZiFetchServicePromiseClient.prototype.sayZiFetch =
  function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ +
      '/simulation.ZiFetchService/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService_SayZiFetch);
  };


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.simulation.ZiFetchRequest,
 *   !proto.simulation.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService_ItKeepsReplying = new grpc.web.MethodDescriptor(
  '/simulation.ZiFetchService/ItKeepsReplying',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.simulation.ZiFetchRequest,
  proto.simulation.ZiFetchReply,
  /**
   * @param {!proto.simulation.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  },
  proto.simulation.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.simulation.ZiFetchRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.simulation.ZiFetchReply>}
 *     The XHR Node Readable Stream
 */
proto.simulation.ZiFetchServiceClient.prototype.itKeepsReplying =
  function (request, metadata) {
    return this.client_.serverStreaming(this.hostname_ +
      '/simulation.ZiFetchService/ItKeepsReplying',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService_ItKeepsReplying);
  };


/**
 * @param {!proto.simulation.ZiFetchRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.simulation.ZiFetchReply>}
 *     The XHR Node Readable Stream
 */
proto.simulation.ZiFetchServicePromiseClient.prototype.itKeepsReplying =
  function (request, metadata) {
    return this.client_.serverStreaming(this.hostname_ +
      '/simulation.ZiFetchService/ItKeepsReplying',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService_ItKeepsReplying);
  };


module.exports = proto.simulation;

