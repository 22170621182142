import {
    SET_EDUCATION_REPORT,
    SET_MULTIPLE_EDUCATION_REPORT,
    RESET_EDUCATION_REPORT
  } from '../constants/actionTypes';
  
  
  var d = new Date();
  d.setDate(d.getDate() - 7);
  let ds = new Date()
  let yesterday = d;
  
  const initialState = {
    userId: "",
    companyId: "",
    isLoggedIn: '0',
    sDate: yesterday,
    eDate: ds,
    activeTab: "classroom",
    reportData: {
      classReport: {},
      subjectReport: {},
      taskReport: {},
      teamReport: {},
    },
    commonReportData: [],
    loginLoader: false

  };
  
  const educationReportsReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_EDUCATION_REPORT: {
        return {
          ...state,
          [action.key]: action.value,
        };
      }
      case SET_MULTIPLE_EDUCATION_REPORT: {
        return {
          ...state,
          ...action.data,
        };
      }
      case RESET_EDUCATION_REPORT: {
        return {
          ...state,
          userId: "",
          companyId: "",
          isLoggedIn: '0',
          sDate: new Date(),
          eDate: new Date(),
          activeTab: "classroom",
          reportData: {
            classReport: {},
            subjectReport: {},
            taskReport: {},
            teamReport: {},
          },
          commonReportData: [],
          loginLoader: false
        };
      }
      default: {
        return state;
      }
    }
  };
  
  export default educationReportsReducer;
  