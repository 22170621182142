import React, { useEffect, useState } from "react";
import MyConstant from "../config/MyConstant";
import GlobalMsgCons from "../config/GlobalMsgCons";
import ReactTooltip from 'react-tooltip';
import { getValidTooltip } from '../config/Common';


function WorkspaceMenu(props) {
    let { companyList, activeCompanyId, setActiveCompanyList, activeUserType, systemLevelRole, unreadMsgList } = props;
    const [workspaceList, setWorkspaceList] = useState([]);
    const [workspaceUnreadCount, setWorkspaceUnreadCount] = useState({});
    const [activeWorkspaceType, setActiveWorkspaceType] = useState("");
    const [eventPosition, setEventPosition] = useState("")

    const groupList = [
        { label: "TeqWork", value: "company", icon: "building", color: "#6bae67" },
        { label: "TeqEd", value: "education", icon: "school", color: "#6c92e3" },
        { label: "TeqCommunity", value: "communityService", icon: "university", color: "#bf62cf" },
        { label: "TeqHealth", value: GlobalMsgCons.orgTypeHealthcareService, icon: "hospital-symbol", color: "#82a7e6" },
        { label: "TeqSupport", value: GlobalMsgCons.orgTypeCustomerSupport, icon: "headset", color: "#688391" },
        { label: "TeqPlay", value: GlobalMsgCons.orgTypeMultiplayerGaming, icon: "gamepad", color: "#3f51b6" },
    ]
    useEffect(() => {
        if (activeWorkspaceType) {
            ReactTooltip.rebuild();
        }
        window.addEventListener("click", handleClickOutside);
        return () => {
            //componentWillUnmount
            window.removeEventListener("click", handleClickOutside);

        }
    }, [activeWorkspaceType])

    useEffect(() => {
        let unreadCount = {};
        let workspaceListBuild = {}
        for (let i = 0; i < companyList.length; i++) {
            let { organizationType, organizationId } = companyList[i];
            if (unreadMsgList[organizationId]) {
                if (unreadCount[organizationType] === undefined) {
                    unreadCount[organizationType] = 0
                }

                if (activeCompanyId !== organizationId) {
                    unreadCount[organizationType] += unreadMsgList[organizationId]
                }
            }

            if (workspaceListBuild[organizationType] === undefined) {
                workspaceListBuild[organizationType] = []
            }
            workspaceListBuild[organizationType].push(companyList[i]);
        }
        setWorkspaceUnreadCount(unreadCount)
        setWorkspaceList(workspaceListBuild)
    }, [companyList, unreadMsgList])

    function roleLetter(details) {
        let { organizationType } = details
        let str = organizationType === GlobalMsgCons.orgTypeEducation ? "S" : "M";
        if (activeUserType === "Admin") {
            str = "O";
        } else if (systemLevelRole === GlobalMsgCons.systemLevelRoleAdmin) {
            str = "A";
        }

        return str;
    }

    function roleClass() {
        let str = "member";
        if (activeUserType === "Admin") {
            str = "owner";
        } else if (systemLevelRole === GlobalMsgCons.systemLevelRoleAdmin) {
            str = "admin";
        }

        return str;
    }
    function handleClickOutside(e) {
        if (e.target.className !== undefined && typeof e.target.className === "string" && !e.target.className.includes("modal-workspace-list")) {
            e.stopPropagation();
            let result = groupList.map(a => a.value);
            if (e.target.id !== undefined && e.target.id && result.includes(e.target.id)) {
                setActiveWorkspaceType(e.target.id === activeWorkspaceType ? "" : e.target.id)
            } else {
                setActiveWorkspaceType("")
            }
        }
    }

    return <div className='company-list'>
        <React.Fragment>
            {
                groupList.map((group, index) => {
                    let { value, icon, color, label } = group;
                    return <div className={`company-icon ${value}`} id={value} key={index} onClick={(e) => {
                        let rect = e.currentTarget.getBoundingClientRect();
                        setEventPosition(rect.top)
                    }}>
                        <div className='box-width' id={value} style={{ backgroundColor: color, color: "#fff" }} data-tip={label}>
                            {
                                activeWorkspaceType === value && <div className="arrow" />
                            }
                            <i id={value} className={`fas fa-${icon}`}></i>
                            {
                                workspaceUnreadCount[value] > 0 && <div className={`message-count-org-box`}>{workspaceUnreadCount[value] > 99 ? 99 : workspaceUnreadCount[value]}</div>
                            }
                        </div>
                    </div>
                })
            }
            {
                activeWorkspaceType && <div className={`modal-workspace-box d-flex custom-scrollbar`} style={{ top: eventPosition }}>
                    {/* <div className="modal-workspace-close" onClick={(e) => {
                        e.stopPropagation();
                        setActiveWorkspaceType("")
                    }}>
                        <i className="fa fa-times" />
                    </div> */}
                    <div className="modal-workspace-list" >
                        {
                            workspaceList[activeWorkspaceType] && workspaceList[activeWorkspaceType].sort((a, b) => a.organizationName.localeCompare(b.organizationName)).map((details) => {
                                let { logo, organizationId } = details
                                let orgName = details.organizationName
                                let imagePath = ""
                                if (logo && typeof logo !== undefined && logo !== null) {
                                    let split = logo.split(".")
                                    let processType = "companyLogo", fileType = split[1], docsId = split[0]
                                    imagePath = MyConstant.keyList.apiURL + "vp?action=" + processType + "&key=" + docsId + "." + fileType + "&id=logos"
                                }


                                return <div className={`company-icon`} key={organizationId} onClick={(e) => {
                                    e.stopPropagation();
                                    setActiveWorkspaceType("")
                                    if (organizationId !== activeCompanyId) {
                                        setActiveCompanyList(details)
                                    }
                                }}>

                                    {
                                        imagePath ? <div className={`box-width ${organizationId === activeCompanyId ? "active" : ""}`} style={{
                                            backgroundImage: `url(${imagePath})`,
                                            backgroundPosition: 'center',
                                            backgroundSize: 'contain',
                                            backgroundRepeat: 'no-repeat'
                                        }} data-tip={orgName}>
                                            {
                                                organizationId === activeCompanyId ? <div className={`roles-box ${roleClass()}`}>{roleLetter(details)}</div> : <React.Fragment>
                                                    {
                                                        unreadMsgList[organizationId] > 0 && <div className={`message-count-org-box`}>{unreadMsgList[organizationId] > 99 ? 99 : unreadMsgList[organizationId]}</div>
                                                    }
                                                </React.Fragment>
                                            }
                                        </div> : <div className='box-width' data-tip={orgName}>
                                            {orgName.substring(0, 3).toUpperCase()}
                                            {
                                                organizationId === activeCompanyId ? <div className={`roles-box ${roleClass()}`}>{roleLetter(details)}</div> : <React.Fragment>
                                                    {
                                                        unreadMsgList[organizationId] > 0 && <div className={`message-count-org-box`}>{unreadMsgList[organizationId] > 99 ? 99 : unreadMsgList[organizationId]}</div>
                                                    }
                                                </React.Fragment>
                                            }
                                        </div>
                                    }

                                </div>
                            })
                        }
                    </div>
                </div>
            }

        </React.Fragment>
    </div>;
}

export default WorkspaceMenu;