import GlobalMsgCons from '../../config/GlobalMsgCons';
import MyConfig from '../../config/MyConfig';
import { RESET_SWITCH_ACCOUNT, SET_SWITCH_ACCOUNT, SET_MULTIPLE_SWITCH_ACCOUNT } from '../constants/actionTypes';

const initialState = {
    companyList: [],
    activeCompany: "",
    companyModal: false,
    workspaceColors: ["#1e325c", "#2f3136", "#eeeeee", "#9c27b0", "#2196f3", "#4caf50", "#ff9800", "#607d8b"],
    companyBgColor: "#1e325c",
    companyName: "",
    weekStartOn: { value: "Monday", label: "Monday" },
    weekStartOptions: MyConfig.weekStart,
    dateFormat: { value: "MM/DD/YYYY", label: "MM/DD/YYYY (e.g. 01/01/2022)" },
    dateFormatOptions: MyConfig.dateFormat,
    currencyFormat: { value: "USD", label: "United States Dollar - USD" },
    currencyFormatOptiosn: MyConfig.currencyFormat,
    companyTypeOptions: MyConfig.companyType,
    companyType: GlobalMsgCons.orgTypeCompany,
    numberFormat: "",
    perWeekMax: "40:00",
    perWeekMin: "40:00",
    perDayMax: "08:00",
    perDayMin: "08:00",
    editId: "",
    errors: {
        companyName: "",
        country: "",
        pinCode: ""
    },
    documentFiles: [],
    document_Json: [],
    countryOptions: [],
    countrySelected: "",
    address: "",
    pinCode: "",
    loadingOrganization: false,
    sortBy: "createdAt",
    sortType: "asc",
};

const switchAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SWITCH_ACCOUNT: {
            return {
                ...state,
                [action.key]: action.value,
            };
        }
        case SET_MULTIPLE_SWITCH_ACCOUNT: {
            return {
                ...state,
                ...action.data,
            };
        }
        case RESET_SWITCH_ACCOUNT: {
            return {
                ...state,
                companyList: [],
                activeCompany: "",
                companyModal: false,
                workspaceColors: ["#1e325c", "#2f3136", "#eeeeee", "#9c27b0", "#2196f3", "#4caf50", "#ff9800", "#607d8b"],
                companyBgColor: "#1e325c",
                companyName: "",
                weekStartOn: { value: "Monday", label: "Monday" },
                weekStartOptions: MyConfig.weekStart,
                dateFormat: { value: "MM/DD/YYYY", label: "MM/DD/YYYY (e.g. 01/01/2022)" },
                dateFormatOptions: MyConfig.dateFormat,
                currencyFormat: { value: "USD", label: "United States Dollar - USD" },
                currencyFormatOptiosn: MyConfig.currencyFormat,
                companyTypeOptions: MyConfig.companyType,
                companyType: GlobalMsgCons.orgTypeCompany,
                numberFormat: "",
                perWeekMax: "40:00",
                perWeekMin: "40:00",
                perDayMax: "08:00",
                perDayMin: "08:00",
                editId: "",
                errors: {
                    companyName: "",
                    country: "",
                    pinCode: ""
                },
                documentFiles: [],
                document_Json: [],
                countryOptions: [],
                countrySelected: "",
                address: "",
                pinCode: "",
                loadingOrganization: false,
                sortBy: "createdAt",
                sortType: "asc",
            };
        }
        default: {
            return state;
        }
    }
};

export default switchAccountReducer;
