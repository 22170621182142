import MyConfig from './MyConfig';
import MyConstant from './MyConstant';

import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import GlobalMsgCons from './GlobalMsgCons';
import { v4 as uuidv4 } from 'uuid';
import GlobalDomainCons from './GlobalDomainCons';
import GlobalEventCons from './GlobalEventCons';
import Autolinker from 'autolinker';
import { toast } from 'react-toastify';
import GlobalAccumulation from '../config/globalFrontendAccumulation.json';
import { readBinary } from './MyFileSystem';
import TeqitTv from '../pages/common/teqittv/TeqitTv';
import Graph from '../pages/common/simulation/Graph';
import AppsBuilder from '../pages/common/AppsBuilder';
import DocsWrite from '../pages/common/DocsWrite';
import MigrantList from '../pages/common/migrant/MigrantList';
import FeedbackEdit from '../pages/common/feedback/FeedbackEdit';
import TeqDrawWrite from '../pages/common/teqdraw/TeqDrawWrite';
import SheetsWrite from '../pages/common/sheets/SheetsWrite';
var CryptoJS = require("crypto-js");




const {
    ZiFetchService2Client, ZiFetchService3Client,
    ZiFetchService4Client,
} = require('../protobuf/generated/ZiFetchService_grpc_web_pb');
let grpcClient2 = new ZiFetchService2Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient3 = new ZiFetchService3Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient4 = new ZiFetchService4Client(MyConstant.keyList.grpcURL, {}, {});
const grpcClientArr = new Array(grpcClient2, grpcClient3, grpcClient4);
const grpcClient = grpcClientArr[Math.floor(Math.random() * grpcClientArr.length)];

export function pageNumbers(totalPages, page, maxLength) {
    if (maxLength < 5) throw "maxLength must be at least 5";
    function range(start, end) {
        return Array.from(Array(end - start + 1), (_, i) => i + start);
    }
    let pagination = [];
    var sideWidth = maxLength < 9 ? 1 : 2;
    var leftWidth = (maxLength - sideWidth * 2 - 3) >> 1;
    var rightWidth = (maxLength - sideWidth * 2 - 2) >> 1;
    if (totalPages <= maxLength) {
        // no breaks in list
        pagination = range(1, totalPages);
    } else if (page <= maxLength - sideWidth - 1 - rightWidth) {
        // no break on left of page
        pagination = range(1, maxLength - sideWidth - 1)
            .concat(0, range(totalPages - sideWidth + 1, totalPages));
    } else if (page >= totalPages - sideWidth - 1 - rightWidth) {
        // no break on right of page
        pagination = range(1, sideWidth)
            .concat(0, range(totalPages - sideWidth - 1 - rightWidth - leftWidth, totalPages));
    } else {
        // Breaks on both sides
        pagination = range(1, sideWidth)
            .concat(0, range(page - leftWidth, page + rightWidth),
                0, range(totalPages - sideWidth + 1, totalPages));
    }

    return pagination;
}
export function getGuestFeedbackId(id) {
    return "GT" + String(id).padStart(5, '0');
}

export function getIssueId(id) {

    return "WI" + String(id).padStart(5, '0');
}

export function getCaseId(id) {
    return "CR" + String(id).padStart(5, '0');
}

export function getReportName(id, serviceType, date) {
    const st = serviceType.split(" ").map((char) => char[0]).join('')

    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    return id + st + year + ("0" + month).slice(-2) + ("0" + day).slice(-2);
}

export function humanDateFormat(time) {
    return new Date(time).toLocaleString()
}
export function getAmount(amount) {
    return Number(amount).toFixed(2)
}
export function getCurrencyFormat(activeCompanyDetails) {
    let returnStr = "&#36";

    if (activeCompanyDetails) {
        activeCompanyDetails = JSON.parse(activeCompanyDetails);
        let currencyCode = activeCompanyDetails.currencyCode.code;
        let currencyCodeHtml = MyConfig.currencyCodeHtml;
        if (currencyCodeHtml[currencyCode] !== undefined) {
            returnStr = currencyCodeHtml[currencyCode]
        }
    }

    return returnStr;
}
export function getDateWithFormat(date, activeCompanyDetails, formate) {
    date = parseInt(date)
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hours = d.getHours(),
        minutes = d.getMinutes(),
        ampm = hours >= 12 ? 'PM' : 'AM';

    let returnStr = new Date(date).toLocaleString();

    if (activeCompanyDetails) {
        activeCompanyDetails = JSON.parse(activeCompanyDetails);

        let dateFormat = activeCompanyDetails.dateFormat;
        let separator = "";
        if (dateFormat === "MM/DD/YYYY") {
            separator = "/";
            returnStr = ("0" + month).slice(-2) + separator + ("0" + day).slice(-2) + separator + year;
        } else if (dateFormat === "DD/MM/YYYY") {
            separator = "/";
            returnStr = ("0" + day).slice(-2) + separator + ("0" + month).slice(-2) + separator + year;
        } else if (dateFormat === "YYYY-MM-DD") {
            separator = "-";
            returnStr = year + separator + ("0" + month).slice(-2) + separator + ("0" + day).slice(-2);
        } else if (dateFormat === "DD.MM.YYYY") {
            separator = ".";
            returnStr = ("0" + day).slice(-2) + separator + ("0" + month).slice(-2) + separator + year;
        } else if (dateFormat === "YYYY.MM.DD") {
            separator = ".";
            returnStr = year + separator + ("0" + month).slice(-2) + separator + ("0" + day).slice(-2);
        } else if (dateFormat === "YYYY/MM/DD") {
            separator = "/";
            returnStr = year + separator + ("0" + month).slice(-2) + separator + ("0" + day).slice(-2);
        }
        if (hours === 0 && !formate) {
            hours = 12;
        }
        let convertHoursFormate = ("0" + hours).slice(-2)
        if (formate && formate === 12) {
            if (convertHoursFormate.toString() > "12") {
                convertHoursFormate = (parseInt(convertHoursFormate) - 12).toString();
                convertHoursFormate = ("0" + convertHoursFormate).slice(-2)
            }
        }
        returnStr += " " + convertHoursFormate + ":" + ("0" + minutes).slice(-2) + " " + ampm;
    }

    return returnStr;
}

export function getWeekDetails(date, activeCompanyDetails) {
    let current = new Date(date);
    let weekStartOn = "Sunday";
    if (activeCompanyDetails) {
        activeCompanyDetails = JSON.parse(activeCompanyDetails);
        weekStartOn = activeCompanyDetails.weekStartOn;
    }

    if (weekStartOn === "Sunday") {
        current.setDate((current.getDate() - current.getDay()));

    } else if (weekStartOn === "Saturday") {
        if (current.getDay() !== 6) {
            current.setDate((current.getDate() - current.getDay() - 1));
        }
    } else {
        // Starting Monday not Sunday
        if (current.getDay() === 0) {
            current.setDate((current.getDate() - current.getDay() - 6));
        } else {
            current.setDate((current.getDate() - current.getDay() + 1));
        }
    }
    var getMonth = (current.getMonth() + 1).toString().padStart(2, "0");
    let startDate = current.getFullYear() + "-" + getMonth + "-" + current.getDate().toString().padStart(2, "0")

    current.setDate(current.getDate() + 7);
    var getMonth = (current.getMonth() + 1).toString().padStart(2, "0");
    let endDate = current.getFullYear() + "-" + getMonth + "-" + current.getDate().toString().padStart(2, "0")


    return { startDate, endDate }
}

export function getValidLabels(pageText, labelName) {

    if (pageText != null && Object.keys(pageText).length > 0) {
        if (labelName in pageText.label) {
            return pageText.label[labelName]
        }
    }
    return "";
}

export function getDefaultValidLabels(pageText, labelName, defaultValue) {
    if (pageText != null && Object.keys(pageText).length > 0) {
        if (pageText.label) {
            if (labelName in pageText.label) {
                return pageText.label[labelName]
            }
        }
    }
    return defaultValue;
}

export function getChatPlatformTxt(appPlatform, pageTextGlobal) {
    switch (appPlatform) {
        case "web":
            return getDefaultValidLabels(pageTextGlobal, "webTxt", "Web");
            break
        case "ios":
            return getDefaultValidLabels(pageTextGlobal, "iosTxt", "iOS");
            break
        case "android":
            return getDefaultValidLabels(pageTextGlobal, "androidTxt", "Android");
            break
        case "mobile web":
            return getDefaultValidLabels(pageTextGlobal, "mobileWebTxt", "Mobile Web");
            break
        case "sms":
            return getDefaultValidLabels(pageTextGlobal, "smsTxt", "SMS");
            break
        case "ussd":
            return getDefaultValidLabels(pageTextGlobal, "ussdTxt", "USSD");
            break
    }
    return appPlatform;
}

export function getPlatfotmTxt(appPlatform, pageTextGlobal) {
    switch (appPlatform) {
        case "web":
            return getDefaultValidLabels(pageTextGlobal, "browserTxt", "Browser");
            break
        case "ios":
            return getDefaultValidLabels(pageTextGlobal, "iosTxt", "iOS");
            break
        case "android":
            return getDefaultValidLabels(pageTextGlobal, "androidTxt", "Android");
            break
        case "mobile web":
            return getDefaultValidLabels(pageTextGlobal, "mobileWebTxt", "Mobile Web");
            break
    }
    return appPlatform;
}

export function getValidTooltip(pageText, toolTipName) {

    if (pageText != null && Object.keys(pageText).length > 0) {
        if (toolTipName in pageText.tooltip) {
            return pageText.tooltip[toolTipName]
        }
    }
    return "";
}

export function getGrpcClient(grpcClient) {
    return grpcClient[Math.floor(Math.random() * grpcClient.length)];
}

export function getGrpcPollingClient(grpcPollingClient) {
    return grpcPollingClient[Math.floor(Math.random() * grpcPollingClient.length)];
}

export async function getPageText(pageName) {
    let langCode = localStorage.getItem(MyConfig.project_LocalStorage + "languagePreference");
    let data = await readBinary({ key: MyConfig.languageSetup + "_" + langCode })
    let index;
    if (data) {
        index = data.findIndex(function (data) {
            return data.filename === langCode + "/" + pageName + ".json";
        });
    }

    return index >= 0 ? JSON.parse(data[index].json) : {}
}

export function getInboxIcon(subject) {
    let icon = <i className={`fa fa-bell`}></i>;
    let color = "#047CC4"
    if (subject.includes("Monitoring team members auto scheduled")) {
        icon = <i className="fas fa-user-clock"></i>
        color = "#03A9F4"
    } else if (subject.includes("Your Daily Report Not Submit")) {
        icon = <i className="fa fa-newspaper"></i>
        color = "#40E0D0"
    } else if (subject.includes("You have been invited to the following event")) {
        icon = <i className="far fa-calendar-alt"></i>
        color = "#CE857A"
    } else if (subject.includes("Teqit App issue fixed")) {
        icon = <i className="fas fa-bug"></i>
        color = "#FF0000"
    } else if (subject.includes("apply Leave Request")) {
        icon = <i className="fas fa-user-edit"></i>
        color = "#ff980e"
    } else if (subject.includes("Sheet shared with you")) {
        icon = <i className="fas fa-table"></i>
        color = "#4B7B4E"
    } else if (subject.includes("Document shared with you")) {
        icon = <i className="fas fa-file-alt"></i>
        color = "#304EA9"
    }

    return { icon, color }
}

export async function getEngPageText(pageName) {
    let langCode = "eng";
    let data = await readBinary({ key: MyConfig.languageSetup + "_" + langCode })
    let index;
    if (data) {
        index = data.findIndex(function (data) {
            return data.filename === langCode + "/" + pageName + ".json";
        });
    }

    return index >= 0 ? JSON.parse(data[index].json) : []
}

export function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export function uniqDateFormat(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    let separator = "-";
    return year + separator + ("0" + month).slice(-2) + separator + ("0" + day).slice(-2);
}

export function uniqDateTimeFormat(date) {
    var now = new Date(date);
    var d = new Date(now.getTime() + now.getTimezoneOffset() * 60000),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    let separator = "-";
    return year + separator + ("0" + month).slice(-2) + separator + ("0" + day).slice(-2) + separator + ("0" + d.getHours()).slice(-2) + separator + ("0" + d.getMinutes()).slice(-2) + separator + ("0" + d.getSeconds()).slice(-2);
}

export function convertTime12to24(time12h) {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');

    if (hours === '12') {
        hours = '00';
    }

    if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
}

export function changeDateTimezone(date, timeZone) {
    return timeZone ? new Date(date.toLocaleString("en-US", { timeZone })) : new Date(date);
}

export function getWeeklyCalendarDate(dateStr) {
    return new Date(dateStr.replace(/-/g, '\/'));
}

export function isValidHttpUrl(string) {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

export function getOnlyDateWithFormat(date, activeCompanyDetails) {
    if (typeof date === "string") {
        date = date.replace(/-/g, '\/')
    }
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    let returnStr = new Date(date).toLocaleString();

    if (activeCompanyDetails) {
        activeCompanyDetails = JSON.parse(activeCompanyDetails);
        let dateFormat = activeCompanyDetails.dateFormat;
        let separator = "";
        if (dateFormat === "MM/DD/YYYY") {
            separator = "/";
            returnStr = ("0" + month).slice(-2) + separator + ("0" + day).slice(-2) + separator + year;
        } else if (dateFormat === "DD/MM/YYYY") {
            separator = "/";
            returnStr = ("0" + day).slice(-2) + separator + ("0" + month).slice(-2) + separator + year;
        } else if (dateFormat === "YYYY-MM-DD") {
            separator = "-";
            returnStr = year + separator + ("0" + month).slice(-2) + separator + ("0" + day).slice(-2);
        } else if (dateFormat === "DD.MM.YYYY") {
            separator = ".";
            returnStr = ("0" + day).slice(-2) + separator + ("0" + month).slice(-2) + separator + year;
        } else if (dateFormat === "YYYY.MM.DD") {
            separator = ".";
            returnStr = year + separator + ("0" + month).slice(-2) + separator + ("0" + day).slice(-2);
        } else if (dateFormat === "YYYY/MM/DD") {
            separator = "/";
            returnStr = year + separator + ("0" + month).slice(-2) + separator + ("0" + day).slice(-2);
        }
    } else {
        let separator = "/";
        returnStr = ("0" + month).slice(-2) + separator + ("0" + day).slice(-2) + separator + year;
    }
    return returnStr;
}

export function readSessionData() {
    var manage_session = ""
    var manage_mUserId = ""
    let sessionId = localStorage.getItem(MyConfig.project_LocalStorage + "manage_session")
    let mUserId = localStorage.getItem(MyConfig.project_LocalStorage + "manage_mUserId")

    if (sessionId) {
        manage_session = sessionId
    }
    if (mUserId) {
        manage_mUserId = mUserId
    }
    let obj = { manage_session, manage_mUserId }
    return obj
}
export async function resetLocalData(navigation) {
    setTimeout(() => {
        localStorage.removeItem(MyConfig.project_LocalStorage + "manage_session");
        localStorage.removeItem(MyConfig.project_LocalStorage + "manage_mUserId");
        localStorage.removeItem(MyConfig.project_LocalStorage + "manageLogin");
        navigation.history.push(MyConstant.keyList.projectUrl + "/manage")
        window.location = MyConstant.keyList.projectUrl + "/manage"
    }, 3000);
}

export function dateConverter(ts) {
    var date_ob = new Date(parseInt(ts));
    var year = date_ob.getFullYear();
    var month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    var date = ("0" + date_ob.getDate()).slice(-2);
    var hours = ("0" + date_ob.getHours()).slice(-2);
    var minutes = ("0" + date_ob.getMinutes()).slice(-2);
    var seconds = ("0" + date_ob.getSeconds()).slice(-2);

    return year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds
}

export function checkActiveAdmin(adminType, systemLevelRole) {
    return adminType === "Admin" || adminType === GlobalMsgCons.roleTypeCommunityServiceAdmin || adminType === GlobalMsgCons.roleTypeHealthServiceAdmin || systemLevelRole === GlobalMsgCons.systemLevelRoleAdmin
}

export function equalCommunityService(ctype) {
    return ctype === GlobalMsgCons.orgTypeCommunityService
}

export function equalEducation(ctype) {
    return ctype === GlobalMsgCons.orgTypeEducation
}

export function notEqualEducation(ctype) {
    return ctype !== GlobalMsgCons.orgTypeEducation
}
export function equalMemberAdmin(ctype) {
    return ctype.toLowerCase() === "memberadmin" || ctype.toLowerCase() === "teacher"
}
export function notEqualMemberAdmin(ctype) {
    return ctype.toLowerCase() !== "memberadmin" || ctype.toLowerCase() !== "teacher"
}

export function memberTypeReturn(ctype) {
    let returnText = ""
    if (ctype.toLowerCase() === "memberadmin" || ctype.toLowerCase() === "teacher") {
        returnText = "Teacher"
    } else if (ctype.toLowerCase() === "member" || ctype.toLowerCase() === "student") {
        returnText = "Student"
    }
    return returnText
}
export function equalMemberType(ctype) {
    return ctype.toLowerCase() === "member" || ctype.toLowerCase() === "student"
}
export function notEqualMemberType(ctype) {
    return ctype.toLowerCase() !== "member" && ctype.toLowerCase() !== "student"
}


export function companyMemberType(ctype) {
    let returnText = ""
    if (ctype.toLowerCase() === "member") {
        returnText = "Member"
    } else if (ctype.toLowerCase() === "memberadmin") {
        returnText = "Manager"
    }
    return returnText
}

export function getUuidv4() {
    return uuidv4()
}

export function getFeebackNumber(id) {
    let number = id.replace(/\D/g, "")
    return number ? parseInt(number) : 0
}

export function searchDropdownOptionConvert(option) {
    option = JSON.parse(option);
    if (typeof option !== "string") {
        option.lastName = option.lastName ? option.lastName : " ";
    }
    return typeof option === "string" ? JSON.parse(option) : option
}

export function urlify(text) {
    text = text.split('<a').join('<a target="_blank"')
    return text ? Autolinker.link(text, {
        urls: { schemeMatches: true, tldMatches: false, ipV4Matches: true }
    }) : ""
}

export function capitalize(s) {
    return s !== "" ? s[0].toUpperCase() + s.slice(1) : "";
}

export function sortByKey(obj, orderKey) {
    let sortRes = {}
    Object.keys(obj).map((key, index) => {
        return sortRes[key] = obj[key][orderKey];
    });
    let keysSorted = Object.keys(sortRes).sort(function (a, b) { return sortRes[a] - sortRes[b] })

    return keysSorted;
}

export function getAppInfo() {

    let appInfo = {
        appPlatform: checkMobileView() ? "mobile web" : "web",
        appVersion: MyConstant.keyList.webReleaseVersion,
        buildNumber: "",
        model: "",
        bundleId: "",
        systemVersion: "",
        userAgent: navigator.userAgent
    };

    return JSON.stringify(appInfo);
}

export function highlighten(string, highlight) {
    //string = stripHtml(string);

    // add highlight
    // if (string.includes(highlight)) {
    // string = string.replaceAll(highlight, `<span class="highlight">${highlight}</span>`);
    // }
    string = string.replaceAll(highlight, `<span class="highlight">${highlight}</span>`);
    return string;
};


export function stripHtml(html) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}
export function getTimeFormate(date, activeCompanyDetails, formate) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hours = d.getHours(),
        minutes = d.getMinutes(),
        ampm = hours >= 12 ? 'PM' : 'AM';

    let returnStr = new Date(date).toLocaleString();
    if (activeCompanyDetails) {
        activeCompanyDetails = JSON.parse(activeCompanyDetails);
        let convertHoursFormate = ("0" + hours).slice(-2)
        if (formate && formate === 12) {
            if (convertHoursFormate.toString() > "12") {
                convertHoursFormate = (parseInt(convertHoursFormate) - 12).toString();
                convertHoursFormate = ("0" + convertHoursFormate).slice(-2)
            }
        }
        returnStr = " " + convertHoursFormate + ":" + ("0" + minutes).slice(-2);
    }
    return returnStr;
}

export function surveyTimingRun(totalSeconds) {
    // var d = new Date(date),
    //     month = '' + (d.getMonth() + 1),
    //     day = '' + d.getDate(),
    //     year = d.getFullYear(),
    //     hours = d.getHours(),
    //     minutes = d.getMinutes(),
    //     seconds = d.getSeconds(),
    //     ampm = hours >= 12 ? 'PM' : 'AM';

    // let returnStr = new Date(date).toLocaleString();
    // let convertHoursFormate = ("0" + hours).slice(-2)
    // if (convertHoursFormate.toString() > "12") {
    //     convertHoursFormate = (parseInt(convertHoursFormate) - 12).toString();
    //     convertHoursFormate = ("0" + convertHoursFormate).slice(-2)
    // }

    // returnStr = " " + convertHoursFormate + ":" + ("0" + minutes).slice(-2) + ":" + ("0" + seconds).slice(-2);
    let returnStr = "";
    var cMinutes = Math.floor(totalSeconds % 3600 / 60);
    var cHours = Math.floor(totalSeconds / 3600);
    var cSeconds = Math.floor(totalSeconds % 3600 % 60);
    returnStr = ("0" + cHours).slice(-2) + ":" + ("0" + cMinutes).slice(-2) + ":" + ("0" + cSeconds).slice(-2)

    return returnStr;
}

export function getOS(userAgent) {
    let os;
    if (userAgent.toLowerCase().indexOf("windows") >= 0) {
        os = "Windows";
    } else if (userAgent.toLowerCase().indexOf("iphone") >= 0) {
        os = "iPhone";
    } else if (userAgent.toLowerCase().indexOf("mac") >= 0) {
        os = "Mac";
    } else if (userAgent.toLowerCase().indexOf("x11") >= 0) {
        os = "Unix";
    } else if (userAgent.toLowerCase().indexOf("android") >= 0) {
        os = "Android";
    } else {
        os = "UnKnown, More-Info: " + userAgent;
    }

    return os;
}

export function getBrowser(userAgent) {
    let user = userAgent.toLowerCase();
    let browser;
    if (user.includes("msie")) {
        let substring = userAgent.substring(userAgent.indexOf("MSIE")).split(";")[0];
        browser = substring.split(" ")[0].replace("MSIE", "IE") + "-" + substring.split(" ")[1];
    } else if (user.includes("safari") && user.includes("version")) {
        browser = (userAgent.substring(userAgent.indexOf("Safari")).split(" ")[0]).split("/")[0] + "-" + (userAgent.substring(userAgent.indexOf("Version")).split(" ")[0]).split("/")[1];
    } else if (user.includes("opr") || user.includes("opera")) {
        if (user.includes("opera"))
            browser = (userAgent.substring(userAgent.indexOf("Opera")).split(" ")[0]).split("/")[0] + "-" + (userAgent.substring(userAgent.indexOf("Version")).split(" ")[0]).split("/")[1];
        else if (user.includes("opr"))
            browser = ((userAgent.substring(userAgent.indexOf("OPR")).split(" ")[0]).replace("/", "-")).replace("OPR", "Opera");
    } else if (user.includes("chrome")) {
        browser = (userAgent.substring(userAgent.indexOf("Chrome")).split(" ")[0]).replace("/", "-");
    } else if ((user.indexOf("mozilla/7.0") > -1) || (user.indexOf("netscape6") != -1) || (user.indexOf("mozilla/4.7") != -1) || (user.indexOf("mozilla/4.78") != -1) || (user.indexOf("mozilla/4.08") != -1) || (user.indexOf("mozilla/3") != -1)) {
        //browser=(userAgent.substring(userAgent.indexOf("MSIE")).split(" ")[0]).replace("/", "-");
        browser = "Netscape-?";

    } else if (user.includes("firefox")) {
        browser = (userAgent.substring(userAgent.indexOf("Firefox")).split(" ")[0]).replace("/", "-");
    } else if (user.includes("rv")) {
        browser = "IE-" + user.substring(user.indexOf("rv") + 3, user.indexOf(")"));
    } else {
        browser = "UnKnown, More-Info: " + userAgent;
    }

    return browser
}

export function notifyNewMessage(sound) {
    var audio = new Audio(sound);
    audio.play();
}

export function notifyNewWorkspaceMessage() {
    toast.dismiss();
    notifyNewMessage(MyConfig.messageSound[7].audio);
    toast(`New message has arrived in our other workspace!`, {
        position: "bottom-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
}

export function onlineAlert() {
    toast.dismiss();
    toast(`You're online.`, {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
}

export function offlineAlert() {
    toast.dismiss();
    toast(`You're offline. Check your connection.`, {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
}

export async function getMyIp() {
    return await fetch(MyConstant.keyList.ipURL + "myIP").then((res) => res.json()).then((json) => {
        var ipAddress = json.ip
        return ipAddress;
    });
}

export function startDayValidation(orgId, channelId, message) {
    message = message.toLowerCase();
    return orgId === "bda302d080624e19ae7108cd3a87f27c" && channelId === "370dea0a28244b189da4928bb5bf0a32" && message.includes("good morning all");
}

export function humanFileSize(size) {
    var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

export function aiChatAgentDetails(agent) {
    if (agent === "carePlanAgent") {
        return "CarePlanAgent :: Medical-Llama3-8B"
    }
    let agentTypeOptions = MyConfig.agentTypeOptions;
    let details = "";
    let index = agentTypeOptions.findIndex(x => x.value === agent);

    if (index > -1) {
        details = agentTypeOptions[index].details
    }
    return details
}
export function parseJsonBotReponse(jsonStr, returnJson) {

    if (isJson(jsonStr)) {
        returnJson = JSON.parse(jsonStr);

        if (isJson(returnJson.response)) {
            let modalResponse = JSON.parse(returnJson.response)
            if (modalResponse.choices && modalResponse.choices[0] && modalResponse.choices[0].text) {
                let modalResponseText = modalResponse.choices[0].text
                returnJson["response"] = modalResponseText.split("A:").pop();
            } else {
                returnJson["response"] = modalResponse;
            }
        }
    }

    return returnJson;
}

export function updateCsChannelName(companyType, activeUserType, channelName) {
    let name = (equalCommunityService(companyType) && (activeUserType === GlobalMsgCons.roleTypeCbo || activeUserType === GlobalMsgCons.roleTypeLNSC)) ? "Admin" : channelName
    return name ? name : "Admin"
}

export function checkMobileView() {
    return (window.outerWidth > 0 && window.outerWidth <= 768) || sessionStorage.getItem(MyConfig.project_LocalStorage + "designView") === "mobile";
}

function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function getEmojiIcon(icon) {
    try {
        return String.fromCodePoint("0x" + icon);
    } catch (e) {
        return String.fromCodePoint("0x003F");
    }
}
export function accumulation(pageName, eventName, orgId, userId, grpcClient) {
    // pageName: String, eventName: String, orgId: String, userId: String
    if (!pageName || !eventName || !orgId || !userId || !grpcClient) {
        return false
    }
    let postJson = { pageName, eventName, userId, orgId }
    const request = new ZiFetchRequest();
    request.setDomain(GlobalDomainCons.accumulationSetDomain);
    request.setEvent(GlobalEventCons.globalFrontendLogAccumulation);
    request.setMessage(JSON.stringify(postJson));
    request.setDatetime(new Date().toString());
    getGrpcClient(grpcClient).sayZiFetch(request, {}, (err, response) => {
        if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
            console.warn("Invalid Request. Please try again later")
        } else {
            let json = JSON.parse(response.getMessage());
        }
    });

}

export function getPageEventName(page, eventName) {
    let returnvalue = "";
    let json = GlobalAccumulation.pages[page]["events"];
    let keysValues = Object.keys(json);
    if (keysValues.includes(eventName)) {
        let filterName = keysValues.filter((e) => e === eventName)
        if (filterName.length > 0) {
            returnvalue = filterName[0]
        }
    }
    return returnvalue

}
export function commonEventList(comEventName) {
    let returnEvent = ""
    let comEventJson = GlobalAccumulation.commonEventList
    let keysEventValues = Object.keys(comEventJson);
    if (keysEventValues.includes(comEventName)) {
        let filterEventName = keysEventValues.filter((e) => e === comEventName)
        if (filterEventName.length > 0) {
            returnEvent = filterEventName[0]
        }
    }
    return returnEvent
}


export function coversionED(type, json) {
    var encryptedBase64Key = "MTIzdGVxYmFobiMhNDU2Nw==";
    var parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);
    var encryptedData = null;
    if (type === "encrypt") {
        var plaintText = JSON.stringify(json);
        encryptedData = CryptoJS.AES.encrypt(plaintText, parsedBase64Key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
    }
    else if (type === "decrypt") {
        var encryptedCipherText = json; // or encryptedData;
        var decryptedData = CryptoJS.AES.decrypt(encryptedCipherText, parsedBase64Key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });

        encryptedData = decryptedData.toString(CryptoJS.enc.Utf8);
    }
    // console.log("encryptedData = " + encryptedData);
    return encryptedData
}

export function checkNullAndReturnString(str) {
    if (str !== null && str !== undefined && str !== '') {
        return true;
    }
    return false;
}

export function esStatusdesign(status) {
    let statusbgdesign = ""

    switch (status) {
        case "CBO Accepted":
            statusbgdesign = "cbo-accepted"
            break;
        case "pending":
            statusbgdesign = "status-pending"
            break;
        case "declined":
            statusbgdesign = "status-declined"
            break;
        case "Shared with Ireti Resource":
            statusbgdesign = "status-ireti-resource"
            break;
        case "Received Request":
            statusbgdesign = "received-request"
            break;
        case "Admin Accepted":
            statusbgdesign = "admin-accepted"
            break;
        case "lnscAccepted":
            statusbgdesign = "lnsc-accepted"
            break;
        case "caseStage":
            statusbgdesign = "case-stage"
            break;
        case "intakeForm":
            statusbgdesign = "intake-form"
            break;
        case "iretiCaseNumber":
            statusbgdesign = "ireti-case-number"
            break;
        case "caseAccepted":
        case "psychosocialSupport/TherapyInProgress":
        case "mediationInProgress":
        case "caseUnderInvestigation":
        case "caseReferred":
        case "caseInCourt":
        case "caseClosed":
        case "notAuthenticProblem":
            statusbgdesign = "case-status-ireti"
            break;
    }
    return statusbgdesign
}


export function userFromTeqitMe(from) {
    return from === "teqit.me";
}

export function userFromTeqitMeDomain() {
    return window.location.host === "teqit.me" || window.location.host.includes("localhost");
}

export function resetObjectData(jsondata) {
    return JSON.parse(JSON.stringify(jsondata))
}

export function filesDocumentOnDrop(processType, newFiles, uuid, modifiedFileName) {
    var documentFiles = [];
    var document_Json = [];
    for (var i = 0; i < newFiles.length; i++) {
        let file = newFiles[i];
        let filename = file.name;
        let ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);

        modifiedFileName = modifiedFileName ? modifiedFileName : uuid;

        documentFiles.push(newFiles[i]);
        document_Json.push({
            name: "",
            path: newFiles[i],
            status: 'active',
            progress: '0',
            origFileName: filename,
            fileName: modifiedFileName + "." + ext,
            docsId: uuid,
            processType: processType,
            fileType: ext
        })
    }

    return { documentFiles, document_Json }
}



export async function filesUploadDocumentFiles(documentArray, i, arg_1, arg_2) {
    const postFileUpload = new FormData();
    postFileUpload.append('file' + (i + 1), documentArray.path);
    postFileUpload.append('fileName' + (i + 1), documentArray.fileName);
    postFileUpload.append('processType' + (i + 1), documentArray.processType);
    postFileUpload.append('docsId' + (i + 1), documentArray.docsId);

    let result = await fetch(MyConstant.keyList.apiURL + "uploads/" + documentArray.processType + "/" + arg_1 + "/" + arg_2, {
        method: "POST",
        mode: 'cors',
        redirect: 'follow',
        body: postFileUpload,
    }).then(
        function (response) {
            return response.json();
        }
    ).then(json => {
        return json.response;
    }).catch(error => console.warn(error));
    return result
}
export function checkvolunteersTypeOne(adminType) {
    return adminType === MyConfig.volunteersTypeOne
}
export function checkvolunteersTypeTwo(adminType) {
    return adminType === MyConfig.volunteersTypeTwo
}

export function getLocation(href) {
    var match = href.match(/^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
    return match && {
        href: href,
        protocol: match[1],
        host: match[2],
        hostname: match[3],
        port: match[4],
        pathname: match[5],
        search: match[6],
        hash: match[7]
    }
}

export function defaultRedirectRoute(organizationId, organizationType) {
    let redirectPath = "education/" + organizationId + "/calendar"

    if (checkMobileView()) {
        redirectPath = "education/" + organizationId
        switch (organizationType) {
            case GlobalMsgCons.orgTypeCompany:
                redirectPath = "work/" + organizationId
                break
            case GlobalMsgCons.orgTypeCommunityService:
                redirectPath = "community-service/" + organizationId;
                break
            case GlobalMsgCons.orgTypeHealthcareService:
                redirectPath = "health-service/" + organizationId;
                break
            case GlobalMsgCons.orgTypeCustomerSupport:
                redirectPath = "customer-support/" + organizationId
                break
            case GlobalMsgCons.orgTypeMultiplayerGaming:
                redirectPath = "gaming-platform/" + organizationId
                break;
        }
    } else {
        switch (organizationType) {
            case GlobalMsgCons.orgTypeCompany:
                redirectPath = "work/" + organizationId + "/calendar";
                break
            case GlobalMsgCons.orgTypeCommunityService:
                redirectPath = "community-service/" + organizationId;
                break
            case GlobalMsgCons.orgTypeHealthcareService:
                redirectPath = "health-service/" + organizationId;
                break
            case GlobalMsgCons.orgTypeCustomerSupport:
                redirectPath = "customer-support/" + organizationId + "/guest-feedback"
                break
            case GlobalMsgCons.orgTypeMultiplayerGaming:
                redirectPath = "gaming-platform/" + organizationId + "/calendar";
                break;

        }
    }


    return redirectPath;
}

export function getMimeTypeFromExtension(file) {
    const extension = file.split('.').pop().toLowerCase();
    const mimeTypes = {
        // **Text Files**
        'txt': 'text/plain',
        'text': 'text/plain',
        'md': 'text/markdown',
        'markdown': 'text/markdown',
        'csv': 'text/csv',
        'htm': 'text/html',
        'html': 'text/html',
        'css': 'text/css',
        'js': 'application/javascript',
        'json': 'application/json',
        'xml': 'application/xml',
        'yaml': 'application/x-yaml',
        'yml': 'application/x-yaml',
        'rtf': 'application/rtf',

        // **Images**
        'jpg': 'image/jpeg',
        'jpeg': 'image/jpeg',
        'jpe': 'image/jpeg',
        'png': 'image/png',
        'gif': 'image/gif',
        'bmp': 'image/bmp',
        'webp': 'image/webp',
        'tiff': 'image/tiff',
        'tif': 'image/tiff',
        'svg': 'image/svg+xml',
        'svgz': 'image/svg+xml',
        'ico': 'image/x-icon',
        'heic': 'image/heic',
        'heif': 'image/heif',
        'avif': 'image/avif',

        // **Audio**
        'mp3': 'audio/mpeg',
        'wav': 'audio/wav',
        'ogg': 'audio/ogg',
        'm4a': 'audio/mp4',
        'aac': 'audio/aac',
        'flac': 'audio/flac',
        'aiff': 'audio/aiff',
        'opus': 'audio/opus',
        'mid': 'audio/midi',
        'midi': 'audio/midi',
        'mka': 'audio/x-matroska',
        'ra': 'audio/x-realaudio',

        // **Video**
        'mp4': 'video/mp4',
        'avi': 'video/x-msvideo',
        'mov': 'video/quicktime',
        'wmv': 'video/x-ms-wmv',
        'flv': 'video/x-flv',
        'mkv': 'video/x-matroska',
        'webm': 'video/webm',
        'mpeg': 'video/mpeg',
        'mpg': 'video/mpeg',
        '3gp': 'video/3gpp',
        '3g2': 'video/3gpp2',
        'mts': 'video/mp2t',
        'm2ts': 'video/mp2t',
        'ts': 'video/mp2t',
        'ogv': 'video/ogg',

        // **Applications**
        'pdf': 'application/pdf',
        'doc': 'application/msword',
        'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'dotx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
        'docm': 'application/vnd.ms-word.document.macroEnabled.12',
        'dotm': 'application/vnd.ms-word.template.macroEnabled.12',
        'xls': 'application/vnd.ms-excel',
        'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'xltx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
        'xlsm': 'application/vnd.ms-excel.sheet.macroEnabled.12',
        'xltm': 'application/vnd.ms-excel.template.macroEnabled.12',
        'xlam': 'application/vnd.ms-excel.addin.macroEnabled.12',
        'ppt': 'application/vnd.ms-powerpoint',
        'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'ppsx': 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
        'pptm': 'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
        'ppsm': 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
        'potx': 'application/vnd.openxmlformats-officedocument.presentationml.template',
        'potm': 'application/vnd.ms-powerpoint.template.macroEnabled.12',
        'ppam': 'application/vnd.ms-powerpoint.addin.macroEnabled.12',
        'sldx': 'application/vnd.openxmlformats-officedocument.presentationml.slide',
        'sldm': 'application/vnd.ms-powerpoint.slide.macroEnabled.12',
        'odt': 'application/vnd.oasis.opendocument.text',
        'ods': 'application/vnd.oasis.opendocument.spreadsheet',
        'odp': 'application/vnd.oasis.opendocument.presentation',
        'odg': 'application/vnd.oasis.opendocument.graphics',
        'odc': 'application/vnd.oasis.opendocument.chart',
        'odb': 'application/vnd.oasis.opendocument.database',
        'odf': 'application/vnd.oasis.opendocument.formula',
        'odm': 'application/vnd.oasis.opendocument.text-master',
        'ott': 'application/vnd.oasis.opendocument.text-template',
        'ots': 'application/vnd.oasis.opendocument.spreadsheet-template',
        'otp': 'application/vnd.oasis.opendocument.presentation-template',
        'oth': 'application/vnd.oasis.opendocument.text-web',
        'epub': 'application/epub+zip',
        'mobi': 'application/x-mobipocket-ebook',
        'azw': 'application/vnd.amazon.ebook',

        // **Executables and Scripts**
        'exe': 'application/vnd.microsoft.portable-executable',
        'msi': 'application/x-msdownload',
        'bat': 'application/x-msdownload',
        'bin': 'application/octet-stream',
        'dll': 'application/vnd.microsoft.portable-executable',
        'deb': 'application/vnd.debian.binary-package',
        'rpm': 'application/x-rpm',
        'apk': 'application/vnd.android.package-archive',
        'sh': 'application/x-sh',
        'cgi': 'application/x-httpd-cgi',
        'php': 'application/x-httpd-php',
        'pl': 'application/x-perl',
        'py': 'text/x-python',
        'rb': 'application/x-ruby',
        'swift': 'text/x-swift',
        'go': 'text/x-go',
        'lua': 'text/x-lua',
        'jar': 'application/java-archive',
        'war': 'application/java-archive',
        'ear': 'application/java-archive',

        // **Archives and Compressed Files**
        'zip': 'application/zip',
        'rar': 'application/vnd.rar',
        '7z': 'application/x-7z-compressed',
        'tar': 'application/x-tar',
        'gz': 'application/gzip',
        'bz2': 'application/x-bzip2',
        'xz': 'application/x-xz',
        'iso': 'application/x-iso9660-image',
        'dmg': 'application/x-apple-diskimage',

        // **Fonts**
        'ttf': 'font/ttf',
        'otf': 'font/otf',
        'woff': 'font/woff',
        'woff2': 'font/woff2',
        'eot': 'application/vnd.ms-fontobject',
        'sfnt': 'font/sfnt',

        // **eBooks and Documents**
        'pdf': 'application/pdf',
        'djvu': 'image/vnd.djvu',
        'fb2': 'application/fb2+xml',

        // **CAD and 3D Models**
        'dwg': 'image/vnd.dwg',
        'dxf': 'image/vnd.dxf',
        'stl': 'application/sla',
        'obj': 'text/plain',
        'fbx': 'application/octet-stream',
        '3dm': 'x-world/x-3dmf',
        'max': 'application/x-max',
        'blend': 'application/x-blender',

        // **Data Formats**
        'jsonld': 'application/ld+json',
        'geojson': 'application/geo+json',
        'graphql': 'application/graphql',
        'graphqls': 'application/graphql',

        // **Miscellaneous**
        'torrent': 'application/x-bittorrent',
        'ics': 'text/calendar',
        'vcard': 'text/vcard',
        'srt': 'application/x-subrip',
        'ass': 'text/x-ass',
        'ssa': 'text/x-ssa',
        'sub': 'text/plain',

        // **Others**
        'wasm': 'application/wasm',
        'midi': 'audio/midi',
        'mid': 'audio/midi',

        // **Legacy and Less Common Types**
        'swf': 'application/x-shockwave-flash',
        'flac': 'audio/flac',
        'm4v': 'video/x-m4v',
        'vtt': 'text/vtt',

        // Add more extensions and MIME types as needed
    };

    return mimeTypes[extension] || 'application/octet-stream';
}

export function getAppsViewComponent(appsArgumentData) {
    let { appsData, path, grpcClient, history, languagePreference, pinTabInfo, setPinTabInfo, searchGrpcClient, docsGrpcPollingClient, companyType, simGrpcClient, docsGrpcClient } = appsArgumentData
    let { appsType, appsUrl } = appsData
    let comp;

    let id = "";
    let orgId = "";
    let fromOrgId = "";
    if (appsType === "docs" || appsType === "sheets" || appsType === "teqdraw") {
        let splitUrl = appsUrl.split('/');
        fromOrgId = splitUrl[splitUrl.length - 4]
        id = splitUrl[splitUrl.length - 2]
        orgId = splitUrl[splitUrl.length - 1]
    } else if (appsType === "updateFeedback") {
        let splitUrl = appsUrl.split('/');
        id = splitUrl[splitUrl.length - 1];
    }

    switch (appsType) {
        case "teqitTv":
            comp = <TeqitTv
                path={path}
                grpcClient={grpcClient}
                history={history}
                languagePreference={languagePreference}
                appsData={appsData}
                orgType={"work"}
            />
            break;
        case "simulationGraph":
            comp = <Graph
                path={path}
                grpcClient={grpcClient}
                simGrpcClient={simGrpcClient}
                history={history}
                languagePreference={languagePreference}
                appsData={appsData}
            />
            break;
        case "AppsBuilder":
            comp = <AppsBuilder
                path={path}
                grpcClient={grpcClient}
                history={history}
                languagePreference={languagePreference}
                appsData={appsData}
            />
            break;
        case "docs":
            comp = <DocsWrite
                pinTabInfo={pinTabInfo}
                setPinTabInfo={setPinTabInfo}
                searchGrpcClient={searchGrpcClient}
                path={path}
                docsGrpcPollingClient={docsGrpcPollingClient}
                docsGrpcClient={docsGrpcClient}
                grpcClient={grpcClient}
                history={history}
                companyType={companyType}
                id={id}
                orgId={orgId}
                fromOrgId={fromOrgId}
            />
            break;
        case "sheets":
            comp = <SheetsWrite
                pinTabInfo={pinTabInfo}
                setPinTabInfo={setPinTabInfo}
                searchGrpcClient={searchGrpcClient}
                path={path}
                docsGrpcPollingClient={docsGrpcPollingClient}
                docsGrpcClient={docsGrpcClient}
                grpcClient={grpcClient}
                history={history}
                companyType={companyType}
                id={id}
                orgId={orgId}
                fromOrgId={fromOrgId}
            />
            break;
        case "teqdraw":
            comp = <TeqDrawWrite
                pinTabInfo={pinTabInfo}
                setPinTabInfo={setPinTabInfo}
                searchGrpcClient={searchGrpcClient}
                path={path}
                docsGrpcClient={docsGrpcClient}
                docsGrpcPollingClient={docsGrpcPollingClient}
                grpcClient={grpcClient}
                history={history}
                languagePreference={languagePreference}
                id={id}
                orgId={orgId}
                fromOrgId={fromOrgId}
            />
            break;
        case "updateFeedback":
            comp = <FeedbackEdit
                grpcClient={grpcClient}
                history={history}
                path={path}
                languagePreference={languagePreference}
                id={id}
            />
            break;
        case "migrant":
            comp = <MigrantList
                path={path}
                grpcClient={grpcClient}
                searchGrpcClient={searchGrpcClient}
                history={history}
                languagePreference={languagePreference}
                appsData={appsData}
                orgType={"work"}
            />
            break;
    }
    return comp;

}

export function getControlFeature(controlFeatureDetails) {

    let controlFeature = {
        audioEnable: true,
        videoEnable: true,
        qatEnable: true,
        expensesEnable: true,
        orgFeedbackEnable: true,
        vsCodeEnable: true,
        teqreqEnable: true,
        dailyReportEnable: true,
        externalAccessPointEnable: false,
        videoTranscriberEnable: false,
        appsEnable: false,
        notesTeqitAiEnable: false,
        userLastAccessEnable: false,
        communityOrganization: false,
        openMrsEnable: false,
        appBuilderEnable: false,
        donationEnable: false,
        donationInventoryEnable: false,
        projectManagementEnable: true,
        simulationGraphEnable: false,
        volunteerRoleTypeEnable: false,
        directJoinFlowEnable: false,
        eirEnable: false,
        translateChatEnable: false,
        integrateWebAppEnable: false,
        experimentalEnable: false,
        migrantEnable: false,
    }
    if (controlFeatureDetails && Object.keys(controlFeatureDetails).length > 0) {
        let { audioEnable, videoEnable } = controlFeatureDetails;
        controlFeature.audioEnable = audioEnable;
        controlFeature.videoEnable = videoEnable;
        if (typeof (controlFeatureDetails.qat) !== "undefined") {
            controlFeature.qatEnable = controlFeatureDetails.qat;
        }
        if (typeof (controlFeatureDetails.expenses) !== "undefined") {
            controlFeature.expensesEnable = controlFeatureDetails.expenses;
        }
        if (typeof (controlFeatureDetails.orgFeedback) !== "undefined") {
            controlFeature.orgFeedbackEnable = controlFeatureDetails.orgFeedback
        }
        if (typeof (controlFeatureDetails.teqreq) !== "undefined") {
            controlFeature.teqreqEnable = controlFeatureDetails.teqreq
        }
        if (typeof (controlFeatureDetails.vsCode) !== "undefined") {
            controlFeature.vsCodeEnable = controlFeatureDetails.vsCode
        }
        if (typeof (controlFeatureDetails.dailyReport) !== "undefined") {
            controlFeature.dailyReportEnable = controlFeatureDetails.dailyReport
        }
        if (typeof (controlFeatureDetails.externalAccessPoint) !== "undefined") {
            controlFeature.externalAccessPointEnable = controlFeatureDetails.externalAccessPoint
        }
        if (typeof (controlFeatureDetails.migrant) !== "undefined") {
            controlFeature.migrantEnable = controlFeatureDetails.migrant
        }
        if (typeof (controlFeatureDetails.videoTranscriber) !== "undefined") {
            controlFeature.videoTranscriberEnable = controlFeatureDetails.videoTranscriber
        }
        if (typeof (controlFeatureDetails.apps) !== "undefined") {
            controlFeature.appsEnable = controlFeatureDetails.apps
        }
        if (typeof (controlFeatureDetails.notesTeqitAi) !== "undefined") {
            controlFeature.notesTeqitAiEnable = controlFeatureDetails.notesTeqitAi
        }
        if (typeof (controlFeatureDetails.aiFeedChannel) !== "undefined") {
            controlFeature.aiFeedChannelEnable = controlFeatureDetails.aiFeedChannel
        }
        if (typeof (controlFeatureDetails.userLastAccess) !== "undefined") {
            controlFeature.userLastAccessEnable = controlFeatureDetails.userLastAccess
        }
        if (typeof (controlFeatureDetails.communityOrganization) !== "undefined") {
            controlFeature.communityOrganization = controlFeatureDetails.communityOrganization
        }
        if (typeof (controlFeatureDetails.openMrs) !== "undefined") {
            controlFeature.openMrsEnable = controlFeatureDetails.openMrs
        }
        if (typeof (controlFeatureDetails.appBuilder) !== "undefined") {
            controlFeature.appBuilderEnable = controlFeatureDetails.appBuilder
        }
        if (typeof (controlFeatureDetails.donation) !== "undefined") {
            controlFeature.donationEnable = controlFeatureDetails.donation
        }
        if (typeof (controlFeatureDetails.donationInventory) !== "undefined") {
            controlFeature.donationInventoryEnable = controlFeatureDetails.donationInventory
        }
        if (typeof (controlFeatureDetails.projectManagement) !== "undefined") {
            controlFeature.projectManagementEnable = controlFeatureDetails.projectManagement
        }
        if (typeof (controlFeatureDetails.simulationGraph) !== "undefined") {
            controlFeature.simulationGraphEnable = controlFeatureDetails.simulationGraph
        }
        if (typeof (controlFeatureDetails.volunteerRoleType) !== "undefined") {
            controlFeature.volunteerRoleTypeEnable = controlFeatureDetails.volunteerRoleType
        }
        if (typeof (controlFeatureDetails.directJoinFlow) !== "undefined") {
            controlFeature.directJoinFlowEnable = controlFeatureDetails.directJoinFlow
        }
        if (typeof (controlFeatureDetails.eir) !== "undefined") {
            controlFeature.eirEnable = controlFeatureDetails.eir
        }
        if (typeof (controlFeatureDetails.translateChat) !== "undefined") {
            controlFeature.translateChatEnable = controlFeatureDetails.translateChat
        }
        if (typeof (controlFeatureDetails.experimental) !== "undefined") {
            controlFeature.experimentalEnable = controlFeatureDetails.experimental
        }
        if (typeof (controlFeatureDetails.integrateWebApp) !== "undefined") {
            controlFeature.integrateWebAppEnable = controlFeatureDetails.integrateWebApp
        }
    }
    return controlFeature
}

export function checkEmptyString(value) {
    if (value && value.trim()) {
        return true
    }
    return false;
}

export function generateId(str) {
    return str.toLowerCase().replace(/\s+/g, '_');
}

export function generateRoomId() {
    return "Teqit-" + generateUniqueText()
}

export function roomIdCreate() {
    var uniqueId = uuidv4();
    uniqueId = uniqueId.split(/([-])/g);
    let newval = ""
    uniqueId.map((val) => {
        if (val != "-") {
            newval += val
        }
    })
    newval = newval.toString().substring(1, 11);
    return newval
}

export function generateUniqueText() {
    const timestamp = new Date().getTime(); // Get current time in milliseconds
    const baseText = "abcdefghijklmnopqrstuvwxyz"; // Alphabet to generate text from

    // Convert timestamp to base 36 (which includes numbers) and remove numbers
    const uniquePart = timestamp.toString(36).replace(/\d+/g, '');

    // Generate random characters to make it more unique
    let randomPart = '';
    for (let i = 0; i < 5; i++) {
        randomPart += baseText.charAt(Math.floor(Math.random() * baseText.length));
    }

    return uniquePart + randomPart;
}
