import React, { Suspense, lazy, useEffect, useState, useRef } from 'react';

import {
  Switch,
  Route,
} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import MyConstant from './config/MyConstant';
import Loader from './components/Loader';
import { checkLangFileExistAndDownload, getLanguageData } from './config/MyLangManager';
import MyConfig from './config/MyConfig';
import SwitchLanguage from './pages/mobile/SwitchLanguage';
import CommonWorkspaceRoute from './pages/CommonWorkspaceRoute';
import AuthEmail from './pages/authLogin/AuthEmail';

const DeleteAccount = lazy(() => import('./pages/DeleteAccount'));
const Login = lazy(() => import('./pages/Login'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const AdminLogin = lazy(() => import('./pages/AdminLogin'));
const Work = lazy(() => import("./pages/Work"));
const Admin = lazy(() => import("./pages/Admin"));
const MemberJoin = lazy(() => import("./pages/MemberJoin"));
const NoMatch = lazy(() => import("./pages/NoMatch"));
const FeedbackUpdate = lazy(() => import("./pages/FeedbackUpdate"));
const Test = lazy(() => import("./Test"));
const Call = lazy(() => import("./pages/common/call/app/Call"));
const JoinCall = lazy(() => import("./pages/common/call/app/JoinCall"));
const JoinMeetingCall = lazy(() => import("./pages/common/call/app/JoinMeetingCall"));
const ManageLogin = lazy(() => import("./pages/ManageLogin"));
const ManageRoute = lazy(() => import("./pages/Manage/ManageRoute"));
const Education = lazy(() => import("./pages/Education"));
const VerifyAccount = lazy(() => import("./pages/VerifyAccount"));
const Maps = lazy(() => import("./pages/common/Maps"));
const CalendlyBook = lazy(() => import("./pages/common/calendly/CalendlyBook"));
const GuestRoute = lazy(() => import("./pages/GuestRoute"));
const CustomerSupportGuestRoute = lazy(() => import("./pages/CustomerSupportGuestRoute"));
const GuestThanks = lazy(() => import("./pages/common/calendly/GuestThanks"));
const LoginViaAuth = lazy(() => import("./pages/LoginViaAuth"));
const CompanyInvite = lazy(() => import("./pages/common/CompanyInvite"));
const AdminForgotPassword = lazy(() => import("./pages/Manage/AdminForgotPassword"));
const CommunityService = lazy(() => import("./pages/CommunityService"));
const HealthService = lazy(() => import("./pages/HealthService"));
const VerifyAccountEmail = lazy(() => import("./pages/VerifyAccountEmail"));
const ForgotPasswordPin = lazy(() => import("./pages/ForgotPasswordPin"));

const WebJoinCall = lazy(() => import('./pages/common/call/JoinCall'))
const WebCall = lazy(() => import('./pages/common/call/Call'))



function TeqitRoute(props) {
  const [langLoad, setLangLoad] = useState(false)
  const [langList, setLangList] = useState({})

  let [callWindowData, setCallWindowData] = useState("");
  let [pollCall, setPollCall] = useState({});
  let [callWindowStatus, setCallWindowStatus] = useState("");
  let [menuStatus, setMenuStatus] = useState(false);
  let [callExtApiLoad, setCallExtApiLoad] = useState(false);

  let path = ""
  let messageIdRef = useRef("");

  useEffect(() => {
    langInit()
    /*Add library*/
    addLibrary("https://meet.teqbahn.com:9443/external_api.js", "jitsi")
    addLibrary("/teqit-assets/tinymce/tinymce.min.js")
  }, [])

  function addLibrary(url, name) {
    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    script.onload = function () {
      if (name === "jitsi") {
        setCallExtApiLoad(true)
      }
    };
    document.body.appendChild(script);
  }

  async function langInit() {
    let langCode = localStorage.getItem(MyConfig.project_LocalStorage + "languagePreference");
    if (!langCode) {
      localStorage.setItem(MyConfig.project_LocalStorage + "languagePreference", 'eng')
    }
    langCode = langCode ? langCode : "eng"
    let langList = await getLanguageData()
    if (langCode !== "eng") {
      await checkLangFileExistAndDownload("eng", langList["eng"])
      setLangList(langList)
    }

    let response = await checkLangFileExistAndDownload(langCode, langList[langCode]);
    if (response) {
      setLangLoad(true)
    }
  }

  function setMessageId(id) {
    messageIdRef.current = id
  }

  return (
    <Suspense fallback={<Loader />}>
      {
        langLoad ? <Switch>
          <Route exact path={MyConstant.keyList.projectUrl + "/update-feedback-data/:editId"}>
            <FeedbackUpdate {...props} />
          </Route>
          <Route exact path={MyConstant.keyList.projectUrl + "/forgot-password"}>
            <ForgotPassword {...props} />
          </Route>
          <Route exact path={MyConstant.keyList.projectUrl + "/auth-email/:email"}>
            <AuthEmail {...props}/>
          </Route>
          <Route path={MyConstant.keyList.projectUrl + "/forgot-password/:userId/:otp/:email"}>
            <ForgotPasswordPin {...props} />
          </Route>

          <Route path={`${MyConstant.keyList.projectUrl}/app-call/:sessionId/:companyId/:activeChannelId/:roomId/:messageId/:userId/:name`}>
            {callExtApiLoad ? <Call {...props} /> : <Loader />}
          </Route>
          <Route path={`${MyConstant.keyList.projectUrl}/app-join-call/:sessionId/:companyId/:activeChannelId/:roomId/:messageId/:userId/:name`}>
            {callExtApiLoad ? <JoinCall  {...props} /> : <Loader />}
          </Route>

          <Route path={`${MyConstant.keyList.projectUrl}/app-join-meeting-call/:meetingId/:name`}>
            {callExtApiLoad ? <JoinMeetingCall {...props} /> : <Loader />}
          </Route>

          <Route exact path={MyConstant.keyList.projectUrl + "/member-join/:userId/:organizationId"}>
            <MemberJoin {...props} />
          </Route>
          <Route exact path={MyConstant.keyList.projectUrl + "/manage"} >
            <ManageLogin {...props} />
          </Route>
          <Route exact path={MyConstant.keyList.projectUrl + "/admin"} >
            <AdminLogin {...props} />
          </Route>
          <Route path={MyConstant.keyList.projectUrl + "/admin"} >
            <Admin {...props} />
          </Route>
          <Route path={MyConstant.keyList.projectUrl + "/test"} >
            <Test {...props} />
          </Route>
          <Route exact path={MyConstant.keyList.projectUrl + "/"} >
            <Login {...props} pageType={"work"} />
          </Route>
          <Route exact path={MyConstant.keyList.projectUrl + "/login-auth/:loginId/:sessionId/:companyType/:companyId/:type/:processId/:language?/:processType?"} >
            <LoginViaAuth langList={langList} {...props} pageType={"work"} />
          </Route>
          <Route exact path={MyConstant.keyList.projectUrl + "/verify-account/:userId/:otp/:email"} >
            <VerifyAccountEmail {...props} pageType={"work"} />
          </Route>

          <Route exact path={MyConstant.keyList.projectUrl + "/maps"}>
            <Maps />
          </Route>
          <Route path={MyConstant.keyList.projectUrl + "/manage"} >
            <ManageRoute {...props} />
          </Route>

          <Route path={MyConstant.keyList.projectUrl + "/work/:companyId"}>
            <Work
              {...props}
              callWindowData={callWindowData}
              setCallWindowData={setCallWindowData}
              callWindowStatus={callWindowStatus}
              setCallWindowStatus={setCallWindowStatus}
              setMessageId={setMessageId}
              messageIdRef={messageIdRef}
              setPollCall={setPollCall}
              pollCall={pollCall}
              setMenuStatus={setMenuStatus}
              subRoute={"work"} />
          </Route>
          <Route exact path={MyConstant.keyList.projectUrl + "/work"}>
            <Work
              {...props}
              callWindowData={callWindowData}
              setCallWindowData={setCallWindowData}
              callWindowStatus={callWindowStatus}
              setCallWindowStatus={setCallWindowStatus}
              setMessageId={setMessageId}
              messageIdRef={messageIdRef}
              setPollCall={setPollCall}
              pollCall={pollCall}
              setMenuStatus={setMenuStatus}
              subRoute={"work"} />
          </Route>

          <Route path={MyConstant.keyList.projectUrl + "/community-service/:companyId"}>
            <CommunityService {...props}
              callWindowData={callWindowData}
              setCallWindowData={setCallWindowData}
              callWindowStatus={callWindowStatus}
              setCallWindowStatus={setCallWindowStatus}
              setMessageId={setMessageId}
              messageIdRef={messageIdRef}
              setPollCall={setPollCall}
              pollCall={pollCall}
              setMenuStatus={setMenuStatus}
              subRoute={"work"} />
          </Route>
          <Route exact path={MyConstant.keyList.projectUrl + "/community-service"}>
            <CommunityService {...props}
              callWindowData={callWindowData}
              setCallWindowData={setCallWindowData}
              callWindowStatus={callWindowStatus}
              setCallWindowStatus={setCallWindowStatus}
              setMessageId={setMessageId}
              messageIdRef={messageIdRef}
              setPollCall={setPollCall}
              pollCall={pollCall}
              setMenuStatus={setMenuStatus}
              subRoute={"work"} />
          </Route>

          <Route path={MyConstant.keyList.projectUrl + "/health-service/:companyId"}>
            <HealthService {...props}
              callWindowData={callWindowData}
              setCallWindowData={setCallWindowData}
              callWindowStatus={callWindowStatus}
              setCallWindowStatus={setCallWindowStatus}
              setMessageId={setMessageId}
              messageIdRef={messageIdRef}
              setPollCall={setPollCall}
              pollCall={pollCall}
              setMenuStatus={setMenuStatus}
              subRoute={"work"} />
          </Route>
          <Route exact path={MyConstant.keyList.projectUrl + "/health-service"}>
            <HealthService {...props}
              callWindowData={callWindowData}
              setCallWindowData={setCallWindowData}
              callWindowStatus={callWindowStatus}
              setCallWindowStatus={setCallWindowStatus}
              setMessageId={setMessageId}
              messageIdRef={messageIdRef}
              setPollCall={setPollCall}
              pollCall={pollCall}
              setMenuStatus={setMenuStatus}
              subRoute={"work"} />
          </Route>

          <Route path={MyConstant.keyList.projectUrl + "/education/:companyId"}>
            <Education {...props}
              callWindowData={callWindowData}
              setCallWindowData={setCallWindowData}
              callWindowStatus={callWindowStatus}
              setCallWindowStatus={setCallWindowStatus}
              setMessageId={setMessageId}
              messageIdRef={messageIdRef}
              setPollCall={setPollCall}
              pollCall={pollCall}
              setMenuStatus={setMenuStatus}
              subRoute={"education"} />
          </Route>
          <Route exact path={MyConstant.keyList.projectUrl + "/education"}>
            <Education {...props}
              callWindowData={callWindowData}
              setCallWindowData={setCallWindowData}
              callWindowStatus={callWindowStatus}
              setCallWindowStatus={setCallWindowStatus}
              setMessageId={setMessageId}
              messageIdRef={messageIdRef}
              setPollCall={setPollCall}
              pollCall={pollCall}
              setMenuStatus={setMenuStatus}
              subRoute={"education"} />
          </Route>

          <Route exact path={MyConstant.keyList.projectUrl + "/verify-account"}>
            <VerifyAccount {...props} />
          </Route>
          <Route exact path={MyConstant.keyList.projectUrl + "/teqtock-book/:orgId/:eventId/:guestId"}>
            <CalendlyBook {...props} />
          </Route>

          <Route path={MyConstant.keyList.projectUrl + "/guest/:orgId/:eventId/:guestId"}>
            <GuestRoute
              {...props}
              subRoute={"guest"}
              callWindowData={callWindowData}
              setCallWindowData={setCallWindowData}
              callWindowStatus={callWindowStatus}
              setCallWindowStatus={setCallWindowStatus}
              setMessageId={setMessageId}
              messageIdRef={messageIdRef}
              setPollCall={setPollCall}
              pollCall={pollCall}
            />
          </Route>
          <Route path={MyConstant.keyList.projectUrl + "/guest-thanks"}>
            <GuestThanks {...props} subRoute={"guest"} />
          </Route>
          <Route path={MyConstant.keyList.projectUrl + "/company-invite/:userId/:orgId"}>
            <CompanyInvite {...props} />
          </Route>
          <Route exact path={MyConstant.keyList.projectUrl + "/manage-forget-password"} >
            <AdminForgotPassword {...props} />
          </Route>
          <Route exact path={MyConstant.keyList.projectUrl + "/switch-language"}>
            <SwitchLanguage {...props} />
          </Route>
          <Route exact path={MyConstant.keyList.projectUrl + "/delete-account"}>
            <DeleteAccount {...props} />
          </Route>

          <Route path={MyConstant.keyList.projectUrl + "/customer-support-guest/:orgId"}>
            <CustomerSupportGuestRoute
              {...props}
              subRoute={"guest"}
              callWindowData={callWindowData}
              setCallWindowData={setCallWindowData}
              callWindowStatus={callWindowStatus}
              setCallWindowStatus={setCallWindowStatus}
              setMessageId={setMessageId}
              messageIdRef={messageIdRef}
              setPollCall={setPollCall}
              pollCall={pollCall}
            />
          </Route>

          <Route path={MyConstant.keyList.projectUrl + "/:workspacetype/:companyId"}>
            <CommonWorkspaceRoute {...props}
              callWindowData={callWindowData}
              setCallWindowData={setCallWindowData}
              callWindowStatus={callWindowStatus}
              setCallWindowStatus={setCallWindowStatus}
              setMessageId={setMessageId}
              messageIdRef={messageIdRef}
              setPollCall={setPollCall}
              pollCall={pollCall}
              setMenuStatus={setMenuStatus}
              subRoute={""} />
          </Route>

          {/* <Route path="*">
              <NoMatch />
            </Route> */}
        </Switch> : <Loader />
      }

      <React.Fragment>
        {
          callWindowData && <div className={`call-window ${menuStatus ? "" : "menu-closed"} ${callWindowStatus} ${callWindowData.guestRole ? "guest" : ""}`}>
            <div className='menu-bar'>
              <div data-tip={`Close Window`} className='icon w-close' onClick={() => {
                setCallWindowData("")
                setMessageId("")
              }}><i className='fa fa-times'></i></div>
              <div data-tip="Resize Window" className='icon resize' onClick={() => {
                if (callWindowStatus === "resized") {
                  setCallWindowStatus("")
                } else {
                  setCallWindowStatus("resized")
                }
              }}><i className="fas fa-expand-alt"></i></div>
              <div data-tip={`${callWindowStatus === "minimized" ? "Maximize" : "Minimize"} Window`} className='icon' onClick={() => {
                if (callWindowStatus === "minimized") {
                  setCallWindowStatus("")
                } else {
                  setCallWindowStatus("minimized")
                }
              }}><i className='fa fa-minus'></i></div>
            </div>
            <div className="call-inner-window">
              {
                callWindowData.calltype === "join" ? <WebJoinCall setMessageId={setMessageId} pollCall={pollCall} setCallWindowData={setCallWindowData} callWindowData={callWindowData} grpcPollingClient={props.grpcPollingClient} grpcClient={props.grpcClient} history={props.history} path={path} /> : <WebCall setMessageId={setMessageId} pollCall={pollCall} setCallWindowData={setCallWindowData} callWindowData={callWindowData} grpcPollingClient={props.grpcPollingClient} grpcClient={props.grpcClient} history={props.history} path={path} />
              }
            </div>
          </div>
        }
      </React.Fragment>
    </Suspense>
  );
}

export default TeqitRoute;
