import {
  SET_ANALYTICS,
  SET_MULTIPLE_ANALYTICS,
  RESET_ANALYTICS
} from '../constants/actionTypes';

var d = new Date();
d.setDate(d.getDate() - 7);
let ds = new Date()
let yesterday = d;
var sTime = new Date();
var eTime = new Date();
sTime.setHours(9)
sTime.setMinutes(0)
eTime.setHours(18)
eTime.setMinutes(0)

const initialState = {
  userId: "",
  companyId: "",
  manage_mUserId: "",
  countListBox: {},
  companyOptions: [],
  companySelect: "",
  dayWiseCompanySelect: "",
  existDayWiseCompanySelect: "",
  languageData: [],
  companyCountListBox: {},
  companyLanguageData: [],
  sDate: yesterday,
  eDate: ds,
  sTime,
  eTime,
  filter: false,
  dayWiseReport: [],
  filterType: "date",
  errors: {},
  activeLayout: "horizontalTab",
  activeTab: 0,
  languageOptions: [],
  dayWiseCompanyAppSelect: "",
  dayWiseCompanyChannelSelect: "",
  dayWiseCompanyDirectChannelSelect: "",
  dayWiseCompanyTaskChannelSelect: "",
  dayWiseCompanyLanguageSelect: "",
  channelList: [],
  dmList: [],
  taskChannelList: [],
  appsList: [],
  dayWiseCompanyAppSelectData: [],
  dayWiseCompanyLanguageSelectData: [],
  dayWiseCompanyChannelMsgSelectData: [],
  dayWiseCompanyDirectChannelMsgSelectData: [],
  dayWiseCompanyTaskChannelMsgSelectData: [],
  companyToggleShow: false,
  dayWiseToggleShow: false,
  languageToggleShow: false,
};

const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ANALYTICS: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_ANALYTICS: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_ANALYTICS: {
      return {
        ...state,
        userId: "",
        companyId: "",
        manage_mUserId: "",
        countListBox: {},
        companyOptions: [],
        companySelect: "",
        dayWiseCompanySelect: "",
        existDayWiseCompanySelect: "",
        languageData: [],
        companyCountListBox: {},
        companyLanguageData: [],
        sDate: yesterday,
        eDate: ds,
        sTime,
        eTime,
        filter: false,
        dayWiseReport: [],
        filterType: "date",
        errors: {},
        activeLayout: "horizontalTab",
        activeTab: 0,
        languageOptions: [],
        dayWiseCompanyAppSelect: "",
        dayWiseCompanyChannelSelect: "",
        dayWiseCompanyDirectChannelSelect: "",
        dayWiseCompanyTaskChannelSelect: "",
        dayWiseCompanyLanguageSelect: "",
        channelList: [],
        dmList: [],
        taskChannelList: [],
        appsList: [],
        dayWiseCompanyAppSelectData: [],
        dayWiseCompanyLanguageSelectData: [],
        dayWiseCompanyChannelMsgSelectData: [],
        dayWiseCompanyDirectChannelMsgSelectData: [],
        dayWiseCompanyTaskChannelMsgSelectData: [],
        companyToggleShow: false,
        dayWiseToggleShow: false,
        languageToggleShow: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default analyticsReducer;
