import React from "react";
import ReactTooltip from 'react-tooltip';

export default class Card extends React.Component {
    render() {
        let { title, options, bodyClassName, themeColor, singleHeader } = this.props;
        return <div className="card">
            <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
            <div className={`card-header ${themeColor ? themeColor : 'theme-bg'}`}>
                {
                    singleHeader ? <div className="d-flex">
                        <div data-tip={title} style={{flex: 1, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', paddingRight: 10}}>{title}</div>
                        {
                            options && <div>
                                {options}
                            </div>
                        }
                    </div> : <React.Fragment>
                        {title}
                        {
                            options && <div className="float-right">
                                {options}
                            </div>
                        }
                    </React.Fragment>
                }
            </div>
            <div className={`card-body ${bodyClassName !== undefined ? bodyClassName : ""}`}>
                {this.props.children}
            </div>
        </div>
            ;
    }
}
