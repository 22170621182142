import MyConfig from '../../config/MyConfig';
import {
    SET_FEEDBACK_LIST,
    SET_MULTIPLE_FEEDBACK_LIST,
    RESET_FEEDBACK_LIST
} from '../constants/actionTypes';

const initialState = {
    issueList: [],
    menuActive: "",
    search: "",
    resultArray: [],
    noPagesLimit: 1,
    totalPagesResults: "",
    processing: true,
    pageLimit: 10,
    statusOption: MyConfig.status,
    selectedStatus: "",
    description: "",
    userId: "",
    viewModal: false,
    historyData: [],
    activeData: {},
    viewType: "",
    viewDetails: {},
    prev: false,
    next: false,
    pagination: [],
    editHistoryData: {},
    issueCountList: {},
    previewModal: false,
    previewIndex: "",
    previewTotalLength: 0,
    activeCompany_Id: "",
    issueId: "",
    updateStatusLoader: false,
};

const feedbackListReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FEEDBACK_LIST: {
            return {
                ...state,
                [action.key]: action.value,
            };
        }
        case SET_MULTIPLE_FEEDBACK_LIST: {
            return {
                ...state,
                ...action.data,
            };
        }
        case RESET_FEEDBACK_LIST: {
            return {
                ...state,
                issueList: [],
                menuActive: "",
                search: "",
                resultArray: [],
                noPagesLimit: 1,
                totalPagesResults: "",
                processing: true,
                pageLimit: 10,
                statusOption: MyConfig.status,
                selectedStatus: "",
                description: "",
                userId: "",
                viewModal: false,
                historyData: [],
                activeData: {},
                viewType: "",
                viewDetails: {},
                prev: false,
                next: false,
                pagination: [],
                editHistoryData: {},
                issueCountList: {},
                previewModal: false,
                previewIndex: "",
                previewTotalLength: 0,
                activeCompany_Id: "",
                issueId: "",
                updateStatusLoader: false,
            };
        }
        default: {
            return state;
        }
    }
};

export default feedbackListReducer;
