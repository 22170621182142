import React, { useEffect, useState } from "react";
import openmrsLogo from '../assets/logo/openmrsLogo.png'

const Modal = (props) => {
    let {
        visible,
        heading,
        body,
        footer,
        parentClass,
        size,
        bodyClassRemove,
        resizeEnable,
        closeModal, 
        isOpenMrs
    } = props;

    const [resizeWindow, setResizeWindow] = useState(false);

    useEffect(() => {
        document.body.classList.add('modal-open');
        return () => {
            if (typeof bodyClassRemove === "undefined") {
                document.body.classList.remove('modal-open');
            }
        }
    }, []);

    var isOpenMrsEnabled = false
    if(typeof (isOpenMrs) !== "undefined"){
        isOpenMrsEnabled = true
    }
    resizeEnable = resizeEnable ? resizeEnable : false


    function resizeModal() {
        setResizeWindow(!resizeWindow)
    }
    return (
        <React.Fragment>
            {visible ?
                <React.Fragment>
                    <div className={`modal ${parentClass ? parentClass : ""}`} style={{ display: 'block' }}>
                        <div className={`modal-dialog ${size !== undefined ? size : ""}`} style={{ maxWidth: resizeWindow ? "unset" : ""}}>
                            <div className="modal-content">
                                {
                                    heading && <div className="modal-header theme-bg" style={isOpenMrsEnabled ? {backgroundColor: "#007D79"} : {} }>
                                        <h5 className="modal-title">{heading}</h5>
                                        {
                                            isOpenMrsEnabled && <img src={openmrsLogo} className="img-fluid" style={{ maxWidth: 100, alignSelf: "center", marginLeft: 10 }} />
                                        }
                                        <div className="modal-close">
                                            {
                                                resizeEnable && <button type="button" className="resize" onClick={() => resizeModal()}>
                                                    <i className={`fas fa-${resizeWindow ? "compress-alt" : "expand-alt"}`}></i>
                                                </button>
                                            }
                                            {
                                                typeof (closeModal) !== "undefined" && <button type="button"  onClick={() => props.closeModal()}>
                                                    <i className="fas fa-times"></i>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                }

                                <div className="modal-body" style={isOpenMrsEnabled ? {backgroundColor: "#F4F4F4"} : {} }>
                                    {body}
                                </div>
                                {
                                    footer && <div className="modal-footer">
                                        {footer}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </React.Fragment> : null}
        </React.Fragment>
    );
};


export default Modal;