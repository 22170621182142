import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import GlobalDomainCons from "../config/GlobalDomainCons";
import GlobalEventCons from "../config/GlobalEventCons";
import PaginationDatatable from "./PaginationDatatable";
const GlobalMsgCons = require("../config/GlobalMsgCons");
const { getUuidv4, getGrpcClient } = require("../config/Common");
const { ZiFetchRequest } = require("../protobuf/generated/ZiFetchService_grpc_web_pb");

export default function CreateFeedbackWhiteboard(props) {
    let { feedbackId, fromAdmin, auth } = props;
    const [name, setName] = useState("");
    const [createModal, setCreateModal] = useState(false);
    const [view, setView] = useState("list");
    const [submitLoader, setSubmitLoader] = useState(false);
    const [memberList, setMemberList] = useState([]);

    const { loginId, activeCompany } = auth

    useEffect(() => {
        if (createModal) {
            getCustomerFeedbackDetails()
        }
    }, [createModal])

    function getCustomerFeedbackDetails() {
        let postJson = {
            reqId: getUuidv4(),
            feedbackId
        };

        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.customerFeedback);
        request.setEvent(GlobalEventCons.getCustomerFeedbackDetails);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());

                let memberList = []
                let { csManagerId, csMemberIdList, userId } = responseData.result
                if (csManagerId) {
                    memberList.push(userId)
                    memberList.push(csManagerId)

                    if (csMemberIdList) {
                        memberList = memberList.concat(csMemberIdList)
                    }

                    const index = memberList.indexOf(loginId);
                    if (index > -1) {
                        memberList.splice(index, 1);
                    }

                    setMemberList(memberList)
                }
            }
        });
    }

    function maptoCustomerFeedback(teqdrawId) {
        let postJson = {
            reqId: getUuidv4(),
            feedbackId,
            orgId: activeCompany,
            teqDrawId: teqdrawId
        };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.customerFeedback);
        request.setEvent(GlobalEventCons.teqDrawMaptoCustomerFeedback);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                // console.log("res", responseData)
            }
        });
    }

    function save(content, fileType) {
        let postJson = {
            reqId: getUuidv4(),
            orgId: activeCompany,
            userId: loginId,
            name,
            fileType,
            memberList,
            feedbackId
        };
        setSubmitLoader(true)
        const request = new ZiFetchRequest();
        request.setDomain("teqdraw");
        request.setEvent("create");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let responseData = JSON.parse(response.getMessage());
                if (responseData.response === GlobalMsgCons.success) {
                    let { teqDrawId } = responseData
                    setSubmitLoader(false)
                    maptoCustomerFeedback(teqDrawId)
                    props.history.push(props.path + "/teqdraw/" + teqDrawId + "/" + auth.activeCompany, {
                        content
                    })
                }
            }
        });
    }

    return <React.Fragment>
        <button className="btn btn-block btn-sm btn-success my-2" onClick={() => {
            setCreateModal(true)
            setView("list")
        }}>View Whiteboard</button>

        {
            createModal && <Modal
                visible={createModal}
                closeModal={() => {
                    setCreateModal(false)
                }}
                heading={view === "create" ? "Create Whitboard" : "Whiteboard"}
                body={<div>
                    {
                        view === "create" ? <React.Fragment>
                            <div className="form-group">
                                <label data-tip={"Name"}>{"Name"}</label>
                                <input spellCheck="false" type="text" className={`form-control`} placeholder={"Enter Name"} value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                        </React.Fragment> : <React.Fragment>
                            {fromAdmin && <div className="text-right">
                                <button className="btn btn-sm btn-primary" onClick={() => {
                                    setView("create")
                                }}>Add Whiteboard</button>
                            </div>
                            }
                            <PaginationDatatable
                                grpcClient={props.docsGrpcClient}
                                domain={"teqdraw"}
                                event={"getCustomerFeedbackTeqDrawList"}
                                data={[
                                    {
                                        column: "Name",
                                        cell: (row) => {
                                            return <div>{row.teqDraw.name}</div>
                                        }
                                    },
                                    {
                                        column: "View",
                                        cell: (row) => {
                                            return <button className="btn btn-sm btn-success" onClick={() => {
                                                props.history.push(props.path + "/teqDraw/" + row.teqDraw.id + "/" + auth.activeCompany)
                                            }}>
                                                View
                                            </button>
                                        }
                                    }
                                ]}
                                postJsonInfo={{ feedbackId, orgId: activeCompany }}
                                refresh={false}
                            />
                        </React.Fragment>
                    }

                </div>}
                footer={view === "create" && <React.Fragment>
                    <button type="button"
                        className={"btn btn-primary"}
                        onClick={() => {
                            save("", GlobalMsgCons.defaultDocType)
                        }}
                        disabled={name.trim() === ""}
                        data-tip={"Create"}
                    >{submitLoader && <i className="fa fa-spinner fa-spin"></i>} {"Create"}</button>

                    <button className="btn btn-secondary" onClick={() => {
                        setView("list")
                    }}>Cancel</button>
                </React.Fragment>}
            />
        }
    </React.Fragment>;
}
