import React, { useState } from "react";
// import ForceGraph from "./ForceGraph";
// import SimGraph from "./SimGraph";

export default function Graph(props) {
    const [activeTab, setActiveTab] = useState("current")

    function getActiveTabBody() {
        let comp;
        switch (activeTab) {
            // case "future":
            //     comp = <ForceGraph {...props} />
            //     break;
            // case "current":
            //     comp = <SimGraph {...props} />
            //     break;

        }
        return comp;
    }

    return <React.Fragment>
        <div className="tabs-container">
            <div className="tabs">
                <div className={`tab ${activeTab === "current" ? "active" : ""}`} onClick={() => setActiveTab("current")}>Current State</div>
                <div className={`tab ${activeTab === "future" ? "active" : ""}`} onClick={() => setActiveTab("future")}>Future State</div>
            </div>
            <div className="tabs-body">
                {getActiveTabBody()}
            </div>
        </div>
    </React.Fragment>;
}
