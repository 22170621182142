import React from "react";
import MyConfig from "../config/MyConfig";

export default class GifPicker extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            widthImg: 200,
            search: "",
            searchResult: [],
            category: [
                {
                    name: "Trending",
                    url: "https://media.tenor.com/6eKgeI8YZc4AAAAC/thank-you.gif"
                },
                {
                    name: "Good Luck",
                    url: "https://media.tenor.com/QGEgMBCFFowAAAPo/good-luck.mp4"
                },
                {
                    name: "High Five",
                    url: "https://media.tenor.com/EqX76RY5-IIAAAPo/high-five-apir.mp4"
                },
                {
                    name: "Nervous",
                    url: "https://media.tenor.com/rivLy0Q7-OAAAAPo/the-muppets-kermit-the-frog.mp4"
                },
                {
                    name: "Duh",
                    url: "https://media.tenor.com/MxOJ1quMwUoAAAPo/duh-ashley-olsen.mp4"
                },
                {
                    name: "Aww",
                    url: "https://media.tenor.com/Td_dBCzwFRQAAAPo/aww-cute.mp4"
                },
                {
                    name: "Scared",
                    url: "https://media.tenor.com/B25um8RTgfIAAAPo/nervous-sign-of-the-cross.mp4"
                },
                {
                    name: "Bored",
                    url: "https://media.tenor.com/TCr4VXmpJtUAAAPo/quarantine-got-me-like-bored.mp4"
                },
                {
                    name: "Chill out",
                    url: "https://media.tenor.com/Kd5nLbHikJkAAAPo/mcjuggernuggets-chillout.mp4"
                },
                {
                    name: "Happy",
                    url: "https://media.tenor.com/BnEKiDKJisEAAAPo/claire-dancing.mp4"
                },
                {
                    name: "Cry",
                    url: "https://media.tenor.com/p_f_x5NJzAsAAAPo/crying-baby.mp4"
                },
            ]
        }
        this.myInput = React.createRef()
    }

    componentDidMount() {
        let containerWidth = this.myInput.current.offsetWidth;
        let width = (containerWidth / 4) - 10;

        this.setState({
            widthImg: width > 150 ? width : 150
        })
    }

    setSearch(e) {
        this.setState({
            search: e.target.value
        }, () => {
            this.search()
        })
    }

    async search() {
        let { search } = this.state;
        // set the apikey and limit
        var apikey = MyConfig.gifPickerAPIkey;
        var lmt = 50;

        // using default locale of en_US
        var search_url = "https://g.tenor.com/v1/search?q=" + search + "&key=" + apikey + "&limit=" + lmt+"&contentfilter=medium";

        let response = await fetch(search_url,
            {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => response.json())
            .then((responseData) => {
                return responseData;
            })
            .catch(error => console.warn(error));

        this.setState({
            searchResult: response.results
        })
    }

    category(name) {
        this.setState({
            search: name
        }, () => {
            this.search()
        })
    }

    select(e, url) {
        this.props.select(e, url);
    }
    render() {
        let { category, widthImg, search, searchResult } = this.state;
        let gifs = ["gif"];
        let video = ["mp4"];
        return <div className="gif-picker" ref={this.myInput}>
            <div className="input-group" style={{ padding: 10 }}>
                <input type="text" className="form-control" placeholder="Search Tenor" value={search} onChange={(e) => this.setSearch(e)} />
                <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button" onClick={() => this.search()}>Search</button>
                </div>
            </div>
            <div style={{ height: 240, overflow: 'auto' }}>
                {
                    search.trim() === "" ? <div style={{display: 'flex', flexWrap: 'wrap', }}>
                        {
                            category.map((item, index) => {
                                let fileurl = item.url
                                let ext = fileurl.slice((fileurl.lastIndexOf(".") - 1 >>> 0) + 2);
                                return <div className="picker" key={index} style={{ height: 200, width: widthImg, padding: 5, position: "relative" }} onClick={() => this.category(item.name)}>
                                    {
                                        gifs.includes(ext.toLowerCase()) && <img src={fileurl} width="100%" height="100%" alt=""></img>
                                    }
                                    {
                                        video.includes(ext.toLowerCase()) && <video width="100%" height="100%" style={{ objectFit: 'fill' }} autoPlay={true} loop={true} preload="auto" src={fileurl}></video>
                                    }
                                    <div className="gif-category-name">{item.name}</div>
                                </div>
                            })
                        }
                    </div> : <div style={{display: 'flex', flexWrap: 'wrap', }}>
                        {
                            searchResult.map((item, index) => {
                                let fileurl = item.media[0].nanogif.url
                                return <div className="picker" key={index} style={{ height: 200, width: widthImg, padding: 5, position: "relative" }} onClick={(e) => this.select(e, fileurl)}>
                                        <img src={fileurl} width="100%" height="100%" alt=""></img>
                                    </div>
                            })
                        }
                    </div>
                }
            </div>
        </div>;
    }
}
