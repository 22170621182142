import React from 'react';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import Dragplaceholder from '../assets/Dragfile.png';
import { v4 as uuidv4 } from 'uuid';
import { getPageText, getValidLabels, getValidTooltip } from '../config/Common';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

class VoiceToText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            csvModalOpen: false,
            documentFiles: [],
            document_Json: [],
            errorCsv: {},
            pageText: null
        }
    }

    componentDidMount() {
        this.getJsonText()
        ReactTooltip.rebuild();
    }


    documentOnDrop(newFiles) {
        var documentFiles = [];
        var document_Json = [];
        let maxFileSize = 500 * 1024
        for (var i = 0; i < newFiles.length; i++) {
            let file = newFiles[i];
            if(file.size > maxFileSize) {
                toast.error("File too large, Maximum file size is 500kb.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            } else {
                let filename = file.name;
                let ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
                let uid = uuidv4();
    
                documentFiles.push(newFiles[i]);
                document_Json.push({
                    name: "",
                    path: newFiles[i],
                    status: 'active',
                    progress: '0',
                    origFileName: filename,
                    fileName: uid + "." + ext,
                    docsId: uid,
                    processType: "voicetotext",
                    fileType: ext
                })
            }
        }
        this.setState({
            documentFiles: documentFiles,
            document_Json: document_Json,
        });
    }

    documentOnRemove(loopIndex) {
        var document_Json = this.state.document_Json;
        var documentFiles = this.state.documentFiles;
        document_Json.splice(loopIndex, 1);
        documentFiles.splice(loopIndex, 1);
        this.setState({
            document_Json: document_Json, documentFiles: documentFiles,
        });

    }

    async submit() {
        let { document_Json } = this.state;
        for (var i = 0; i < document_Json.length; i++) {
            this.props.saveAudio("file", document_Json[i]);
        }
    }

    async getJsonText() {
        let pageText = await getPageText('csvModal');
        this.setState({ pageText: pageText })
    }
    render() {
        let { document_Json } = this.state;
        return <div className="form-group">
            <label>Audio File</label>

            <div className='dropback'>
                <Dropzone accept=".mp3, .wav"
                    onDrop={(acceptedFiles) => {
                        if (acceptedFiles.length === 1) {
                            this.documentOnDrop(acceptedFiles)
                        } else {
                            toast.error("Maximum file length is one.", {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true
                            });
                        }
                    }} onDropRejected={(reject) => {
                        toast.error("Please select only mp3/wav files.", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true
                        });

                    }}  >
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                <div className="row">
                                    <div className="col-3">
                                        <img src={Dragplaceholder} className='dragimgph' />
                                    </div>
                                    <div className="col-9">
                                        <p className='dragtext'>
                                            Try dropping some files here, or click to select files to upload
                                            <br />
                                            Support file formats (.mp3, .wav)
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </Dropzone>
            </div>

            {
                document_Json.length > 0 && <React.Fragment>
                    <div className='text-center mt-4'>
                        <div className='file-box' style={{ textAlign: "center", backgroundColor: "#ddd", }}>
                            <i className="fas fa-file-audio" style={{ color: "#3390cb", fontSize: 40 }}></i>
                            <i className="fa fa-trash delete-upload-file" style={{ top: "initial", right: 30, marginTop: 6 }} aria-hidden="true"
                                onClick={() => this.documentOnRemove(0)}
                            ></i>
                        </div>
                    </div>
                    <div className='my-2 text-right'>
                        <button className='btn btn-sm btn-primary' onClick={() => this.submit()}>Process</button>
                    </div>
                </React.Fragment>
            }
        </div>
    }
}


const mapStateToProps = (state) => {
    return {
        userId: state.authReducer.loginId,
        companyId: state.authReducer.activeCompany,
        sessionId: state.authReducer.sessionId,
        firstName: state.authReducer.firstName,
    }
}
export default withRouter(connect(mapStateToProps)(VoiceToText));