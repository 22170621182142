import {
  SET_EDUCATION_TASK,
  SET_EDUCATION_MULTIPLE_TASK,
  RESET_EDUCATION_TASK
} from '../constants/actionTypes';

const initialState = {
  userId: "",
  companyId: "",
  projectList: {},
  classDetails: {},
  classOptions: [],
  selectedClient: {},
  subjectName: "",
  notes: "",
  addProjectModal: false,
  editId: "",
  errors: {
    selectedClient: "",
    subjectName: "",
    notes: "",
  },
  pageText: null,
  clientExpanded: [],
  editTextChanges: false,
  editResponseData: {},
  userListOption: {},
  tableJson: [],
  viewModal: false,
  viewDetails: {},
  taskModal: false,
  taskDetails: {},
  teamDetails: {},
  addMembersToTeamModal: false,
  addTaskMembersModal: false,
  teamModal: false,
  viewType: {},
  filterClient: "",
  userDetails: {},
  studentListModal: false,
  studentDetails: {},
  noOfPage: 1,
  pageLimit: 10,
  loadMore: false,
  subjectResponseData: {},
  subPrev: false,
  subNext: false,
  subPagination: [],
};

const educationTaskReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EDUCATION_TASK: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_EDUCATION_MULTIPLE_TASK: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_EDUCATION_TASK: {
      return {
        ...state,
        userId: "",
        companyId: "",
        projectList: {},
        classDetails: {},
        classOptions: [],
        selectedClient: {},
        subjectName: "",
        notes: "",
        addProjectModal: false,
        editId: "",
        errors: {
          selectedClient: "",
          subjectName: "",
          notes: "",
        },
        pageText: null,
        clientExpanded: [],
        editTextChanges: false,
        editResponseData: {},
        userListOption: {},
        tableJson: [],
        viewModal: false,
        viewDetails: {},
        taskModal: false,
        taskDetails: {},
        teamDetails: {},
        addMembersToTeamModal: false,
        addTaskMembersModal: false,
        teamModal: false,
        viewType: {},
        filterClient: "",
        userDetails: {},
        studentListModal: false,
        studentDetails: {},
        noOfPage: 1,
        pageLimit: 10,
        loadMore: false,
        subjectResponseData: {},
        subPrev: false,
        subNext: false,
        subPagination: [],
      };
    }
    default: {
      return state;
    }
  }
};

export default educationTaskReducer;
