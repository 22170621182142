import {
  SET_TIMING_WINDOW,
  SET_MULTIPLE_TIMING_WINDOW,
  RESET_TIMING_WINDOW
} from '../constants/actionTypes';

let startTimeOption = [
  { value: "none", label: "None" },
  { value: "always", label: "Always" },
];
for (let hour = 0; hour < 24; hour++) {
  let ampm = hour >= 12 ? 'PM' : 'AM';
  let modHour = hour > 12 ? (hour - 12) : hour;
  let timeStr = ("0" + modHour).slice(-2) + ":00-" + ampm;

  startTimeOption.push({ value: timeStr, label: timeStr.replace("-", " ") })
  timeStr = ("0" + modHour).slice(-2) + ":30-" + ampm;
  startTimeOption.push({ value: timeStr, label: timeStr.replace("-", " ") })
}

const initialState = {
  setupType: "custom",
  days: [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ],
  userSelected: {
    "monday": { day: "monday", startTime: "always", endTime: "" },
    "tuesday": { day: "tuesday", startTime: "always", endTime: "" },
    "wednesday": { day: "wednesday", startTime: "always", endTime: "" },
    "thursday": { day: "thursday", startTime: "always", endTime: "" },
    "friday": { day: "friday", startTime: "always", endTime: "" },
    "saturday": { day: "saturday", startTime: "always", endTime: "" },
    "sunday": { day: "sunday", startTime: "always", endTime: "" }
  },
  startTimeOption,
  endTimeOption: {
    "monday": [],
    "tuesday": [],
    "wednesday": [],
    "thursday": [],
    "friday": [],
    "saturday": [],
    "sunday": [],
  },
  userId: "",
  sessionId: "",
  timezoneInfo: Intl.DateTimeFormat().resolvedOptions().timeZone
};

const timingWindowReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TIMING_WINDOW: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_TIMING_WINDOW: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_TIMING_WINDOW: {
      return {
        ...state,
        setupType: "custom",
        days: [
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
          "sunday",
        ],
        userSelected: {
          "monday": { day: "monday", startTime: "08:00-AM", endTime: "10:00-PM" },
          "tuesday": { day: "tuesday", startTime: "08:00-AM", endTime: "10:00-PM" },
          "wednesday": { day: "wednesday", startTime: "08:00-AM", endTime: "10:00-PM" },
          "thursday": { day: "thursday", startTime: "08:00-AM", endTime: "10:00-PM" },
          "friday": { day: "friday", startTime: "08:00-AM", endTime: "10:00-PM" },
          "saturday": { day: "saturday", startTime: "08:00-AM", endTime: "10:00-PM" },
          "sunday": { day: "sunday", startTime: "08:00-AM", endTime: "10:00-PM" }
        },
        startTimeOption,
        endTimeOption: {
          "monday": [],
          "tuesday": [],
          "wednesday": [],
          "thursday": [],
          "friday": [],
          "saturday": [],
          "sunday": [],
        },
        userId: "",
        sessionId: "",
        timezoneInfo: Intl.DateTimeFormat().resolvedOptions().timeZone
      };
    }
    default: {
      return state;
    }
  }
};

export default timingWindowReducer;
