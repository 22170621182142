import MyConfig from '../../config/MyConfig';
import {
  SET_CHAT,
  SET_MULTIPLE_CHAT,
  RESET_CHAT
} from '../constants/actionTypes';

const initialState = {}

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHAT: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_CHAT: {
      if(action.channelId) {
        return {
          ...state,
          [action.channelId] : {
            ...state[action.channelId],
            ...action.data
          }
        }
      }
      return state;
    }
    case RESET_CHAT: {
      return {};
    }
    default: {
      return state;
    }
  }
};

export default chatReducer;
