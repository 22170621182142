import MyConfig from '../../config/MyConfig';
import { RESET_QAT_LIST, SET_QAT_LIST, SET_MULTIPLE_QAT_LIST } from '../constants/actionTypes';

const initialState = {
    issueList: [],
    menuActive: "",
    search: "",
    resultArray: [],
    noPagesLimit: 1,
    totalPagesResults: "",
    processing: true,
    pageLimit: 10,
    statusOption: MyConfig.statusQas,
    selectedStatus: "",
    description: "",
    userId: "",
    viewModal: false,
    historyData: [],
    activeData: {},
    viewType: "",
    viewDetails: {},
    prev: false,
    next: false,
    pagination: [],
    editHistoryData: {},
    issueCountList: {},
    previewModal: false,
    previewIndex: "",
    previewTotalLength: 0,
    activeCompany_Id: "",
    pageText: null,
    projectOptions: [],
    feedbackListFrom: "company",
    userOptionList: [],
    userSelect: "",
    membersAllOptions: [],
    toggleShow: false,
    issueId: "",
    statusUpdateLoader:false,
};

const qatListReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_QAT_LIST: {
            return {
                ...state,
                [action.key]: action.value,
            };
        }
        case SET_MULTIPLE_QAT_LIST: {
            return {
                ...state,
                ...action.data,
            };
        }
        case RESET_QAT_LIST: {
            return {
                ...state,
                issueList: [],
                menuActive: "",
                search: "",
                resultArray: [],
                noPagesLimit: 1,
                totalPagesResults: "",
                processing: true,
                pageLimit: 10,
                statusOption: MyConfig.statusQas,
                selectedStatus: "",
                description: "",
                userId: "",
                viewModal: false,
                historyData: [],
                activeData: {},
                viewType: "",
                viewDetails: {},
                prev: false,
                next: false,
                pagination: [],
                editHistoryData: {},
                issueCountList: {},
                previewModal: false,
                previewIndex: "",
                previewTotalLength: 0,
                activeCompany_Id: "",
                pageText: null,
                projectOptions: [],
                feedbackListFrom: "company",
                userOptionList: [],
                userSelect: "",
                membersAllOptions: [],
                toggleShow: false,
                issueId: "",
                statusUpdateLoader:false,
            };
        }
        default: {
            return state;
        }
    }
};

export default qatListReducer;
