import React from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';


export default (props) => {
  const auth = useSelector(state => state.authReducer);

  function getThemeBg() {
    let { activeCompanyColor, theme } = auth;
    let returnTheme = "#fff";

    if (!window.location.pathname.includes("/manage/")) {
      if (theme === "dark") {
        returnTheme = "#1d2127"
      } 
    }
    return returnTheme;
  }

  function getThemeText() {
    let { activeCompanyColor, theme } = auth;
    let returnTheme = "#000";
    if (!window.location.pathname.includes("/manage/")) {
      if (theme === "dark") {
        returnTheme = "#fff"
      }
    }
    return returnTheme;
  }

  const customStyles = (hasError) => ({
    control: (styles) => ({
      ...styles,
      ...(hasError && { borderColor: 'red' }),
      background: getThemeBg(),
      color: getThemeText(),
    }),
    menu: (base) => ({
      ...base,
      background: getThemeBg(),
      color: getThemeText()
    }),
    input: (base) => ({
      ...base,
      '[type="text"]': {
        color: getThemeText() + ' !important'
      }
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#2196F3" : isFocused ? "#8ec5f2" : undefined
      };
    }
  });
  return (
    <Select
      styles={customStyles(props.error ? props.error : false)}
      value={props.value ? props.value : props.selectedOption}
      onChange={props.onChange}
      options={props.options}
      isDisabled={props.isDisabled ? true : false}
      isMulti={props.isMulti ? props.isMulti : false}
      placeholder={props.placeholder}
      menuPortalTarget={document.body}
      menuPlacement={"auto"}
      {...props}
    />
  )
}
