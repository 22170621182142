import React, { useEffect, useState } from "react";
import MyConfig from "../config/MyConfig";
import { getValidLabels, getPageText, accumulation, commonEventList, getPageEventName } from "../../src/config/Common";
import { useSelector } from "react-redux";
const pageNameAcc = "switchViewPage"
export default function UpdateViewSetup(props) {
    const [view, setView] = useState('classic');
    const [existView, setExistView] = useState('classic');
    const auth = useSelector(state => state.authReducer);
    let { activeCompany } = auth

    useEffect(() => {
        let view = sessionStorage.getItem(MyConfig.project_LocalStorage + "designView");
        if (view) {
            setView(view)
            setExistView(view)
        }
    }, [])
    return <React.Fragment>
        <div className="row view-update">
            <div className="col-6">
                <div className={`box ${view === "classic" ? "active" : ""}`} onClick={() => {
                    accumulation(pageNameAcc, getPageEventName("switchViewPage", "classicBtnClicked"), auth.activeCompany, auth.loginId, props.grpcClient)
                    setView("classic")
                }}>
                    <i className="fas fa-desktop"></i>
                    <div>{getValidLabels(props.pageText, 'classicIconTxt')}</div>
                </div>
            </div>
            <div className="col-6">
                <div className={`box ${view === "mobile" ? "active" : ""}`} onClick={() => {
                    accumulation(pageNameAcc, getPageEventName("switchViewPage", "mobileBtnClicked"), auth.activeCompany, auth.loginId, props.grpcClient)
                    setView("mobile")
                }}>
                    <i className="fas fa-mobile-alt"></i>
                    <div>{getValidLabels(props.pageText, 'mobileIconTxt')}</div>
                </div>
            </div>
        </div>
        <div className="mt-5 mb-2">
            {
                <button type="button" onClick={() => {
                    accumulation(pageNameAcc, getPageEventName("switchViewPage", "updateBtnClicked"), auth.activeCompany, auth.loginId, props.grpcClient)
                    sessionStorage.setItem(MyConfig.project_LocalStorage + "designView", view);
                    window.location.reload();
                }} disabled={existView === view} className={`btn btn-block btn-${existView === view ? "secondary" : "theme"}`}>{getValidLabels(props.pageText, 'updateBtnTxt')}</button>
            }
        </div>
    </React.Fragment>;
}
