import { Excalidraw, restoreElements, MainMenu } from "@excalidraw/excalidraw";
import { useEffect, useState } from "react";
import {
    fileOpen as _fileOpen,
    fileSave as _fileSave
} from "browser-fs-access";
function WhiteBoard(props) {
    const [excalidrawAPI, setExcalidrawAPI] = useState(null);

    useEffect(() => {
        if (excalidrawAPI) {
            const interval = setInterval(() => {
                saveServer()
            }, 5000);

            return () => {
                saveServer()
                clearInterval(interval);
            };
        }
    }, [excalidrawAPI])

    useEffect(() => {
        if (props.content) {
            updateScene(props.content)
        }
    }, [props.content])


    const onChange = (elements, state) => {

    }
    const updateScene = (content) => {
        if (content && excalidrawAPI) {
            const sceneData = {
                elements: restoreElements(
                    content.elements,
                    null
                ),
                appState: content.appState
            };
            excalidrawAPI.updateScene(sceneData);
        }
    }

    const isImageFileHandle = handle => {
        const type = getFileHandleType(handle)
        return type === "png" || type === "svg"
    }

    const getFileHandleType = handle => {
        if (!handle) {
            return null
        }

        return handle.name.match(/\.(json|excalidraw|png|svg)$/)?.[1] || null
    }

    function saveServer() {
        if (excalidrawAPI) {
            const elements = excalidrawAPI.getSceneElements();
            const appState = excalidrawAPI.getAppState()

            const serialized = serializeAsJSON(elements, appState, excalidrawAPI.getFiles(), "local");
            props.write(serialized)
        }
    }

    async function save() {
        const elements = excalidrawAPI.getSceneElements();
        if (!elements || !elements.length) {
            return
        }

        const appState = excalidrawAPI.getAppState()

        const serialized = serializeAsJSON(elements, appState, excalidrawAPI.getFiles(), "local");
        const blob = new Blob([serialized], {
            type: "application/vnd.excalidraw+json",
        });

        const fileHandle = await fileSave(blob, {
            name: appState.name,
            extension: "teqdraw",
            description: "Excalidraw file",
            fileHandle: isImageFileHandle(appState.fileHandle)
                ? null
                : appState.fileHandle,
        });
        return { fileHandle };
    }

    const fileSave = (blob, opts) => {
        return _fileSave(
            blob,
            {
                fileName: `${opts.name}.${opts.extension}`,
                description: opts.description,
                extensions: [`.${opts.extension}`]
            },
            opts.fileHandle
        )
    }

    function serializeAsJSON(elements, appState, files, type) {
        const data = {
            type: "excalidraw",
            version: 2,
            source: window.location.origin,
            elements: clearElementsForExport(elements),
            appState: cleanAppStateForExport(appState),
            files: filterOutDeletedFiles(elements, files),
        };

        return JSON.stringify(data, null, 2);
    }

    const cleanAppStateForExport = appState => {
        return clearAppStateForStorage(appState, "export")
    }

    const APP_STATE_STORAGE_CONF = (config => config)({
        showWelcomeScreen: { browser: true, export: false, server: false },
        theme: { browser: true, export: false, server: false },
        collaborators: { browser: false, export: false, server: false },
        currentChartType: { browser: true, export: false, server: false },
        currentItemBackgroundColor: { browser: true, export: false, server: false },
        currentItemEndArrowhead: { browser: true, export: false, server: false },
        currentItemFillStyle: { browser: true, export: false, server: false },
        currentItemFontFamily: { browser: true, export: false, server: false },
        currentItemFontSize: { browser: true, export: false, server: false },
        currentItemRoundness: {
            browser: true,
            export: false,
            server: false
        },
        currentItemOpacity: { browser: true, export: false, server: false },
        currentItemRoughness: { browser: true, export: false, server: false },
        currentItemStartArrowhead: { browser: true, export: false, server: false },
        currentItemStrokeColor: { browser: true, export: false, server: false },
        currentItemStrokeStyle: { browser: true, export: false, server: false },
        currentItemStrokeWidth: { browser: true, export: false, server: false },
        currentItemTextAlign: { browser: true, export: false, server: false },
        cursorButton: { browser: true, export: false, server: false },
        draggingElement: { browser: false, export: false, server: false },
        editingElement: { browser: false, export: false, server: false },
        editingGroupId: { browser: true, export: false, server: false },
        editingLinearElement: { browser: false, export: false, server: false },
        activeTool: { browser: true, export: false, server: false },
        penMode: { browser: true, export: false, server: false },
        penDetected: { browser: true, export: false, server: false },
        errorMessage: { browser: false, export: false, server: false },
        exportBackground: { browser: true, export: false, server: false },
        exportEmbedScene: { browser: true, export: false, server: false },
        exportScale: { browser: true, export: false, server: false },
        exportWithDarkMode: { browser: true, export: false, server: false },
        fileHandle: { browser: false, export: false, server: false },
        gridSize: { browser: true, export: true, server: true },
        height: { browser: false, export: false, server: false },
        isBindingEnabled: { browser: false, export: false, server: false },
        defaultSidebarDockedPreference: {
            browser: true,
            export: false,
            server: false
        },
        isLoading: { browser: false, export: false, server: false },
        isResizing: { browser: false, export: false, server: false },
        isRotating: { browser: false, export: false, server: false },
        lastPointerDownWith: { browser: true, export: false, server: false },
        multiElement: { browser: false, export: false, server: false },
        name: { browser: true, export: false, server: false },
        offsetLeft: { browser: false, export: false, server: false },
        offsetTop: { browser: false, export: false, server: false },
        contextMenu: { browser: false, export: false, server: false },
        openMenu: { browser: true, export: false, server: false },
        openPopup: { browser: false, export: false, server: false },
        openSidebar: { browser: true, export: false, server: false },
        openDialog: { browser: false, export: false, server: false },
        pasteDialog: { browser: false, export: false, server: false },
        previousSelectedElementIds: { browser: true, export: false, server: false },
        resizingElement: { browser: false, export: false, server: false },
        scrolledOutside: { browser: true, export: false, server: false },
        scrollX: { browser: true, export: false, server: false },
        scrollY: { browser: true, export: false, server: false },
        selectedElementIds: { browser: true, export: false, server: false },
        selectedGroupIds: { browser: true, export: false, server: false },
        selectionElement: { browser: false, export: false, server: false },
        shouldCacheIgnoreZoom: { browser: true, export: false, server: false },
        showStats: { browser: true, export: false, server: false },
        startBoundElement: { browser: false, export: false, server: false },
        suggestedBindings: { browser: false, export: false, server: false },
        toast: { browser: false, export: false, server: false },
        viewBackgroundColor: { browser: true, export: true, server: true },
        width: { browser: false, export: false, server: false },
        zenModeEnabled: { browser: true, export: false, server: false },
        zoom: { browser: true, export: false, server: false },
        viewModeEnabled: { browser: false, export: false, server: false },
        pendingImageElementId: { browser: false, export: false, server: false },
        showHyperlinkPopup: { browser: false, export: false, server: false },
        selectedLinearElement: { browser: true, export: false, server: false }
    })

    const clearAppStateForStorage = (appState, exportType) => {
        const stateForExport = {}
        for (const key of Object.keys(appState)) {
            const propConfig = APP_STATE_STORAGE_CONF[key]
            if (propConfig?.[exportType]) {
                const nextValue = appState[key]

                stateForExport[key] = nextValue
            }
        }
        return stateForExport
    }

    function clearElementsForExport(elements) {
        return getNonDeletedElements(elements).map((element) =>
            isLinearElementType(element.type)
                ? { ...element, lastCommittedPoint: null }
                : element,
        )
    }

    const filterOutDeletedFiles = (elements, files) => {
        const nextFiles = {}
        for (const element of elements) {
            if (
                !element.isDeleted &&
                "fileId" in element &&
                element.fileId &&
                files[element.fileId]
            ) {
                nextFiles[element.fileId] = files[element.fileId]
            }
        }
        return nextFiles
    }

    function isLinearElementType(elementType) {
        return elementType === "arrow" || elementType === "line";
    }

    function getNonDeletedElements(elements) {
        return elements.filter(
            (element) => !element.isDeleted,
        )
    }
    return <div className="whiteboard">
        <Excalidraw ref={(api) => setExcalidrawAPI(api)}
            initialData={{
                elements: restoreElements(
                    props.content.elements,
                    null
                ),
                appState: props.content.appState
            }}
        >
            <MainMenu>
                <MainMenu.DefaultItems.LoadScene />
                <MainMenu.Item onSelect={() => save()} icon={<svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 20 20" className="" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"><path strokeWidth="1.25" d="M3.333 14.167v1.666c0 .92.747 1.667 1.667 1.667h10c.92 0 1.667-.746 1.667-1.667v-1.666M5.833 9.167 10 13.333l4.167-4.166M10 3.333v10"></path></svg>}>
                    Save
                </MainMenu.Item>
                <MainMenu.Item onSelect={() => saveServer()} icon={<i className="fas fa-cloud"></i>}>
                    Upload
                </MainMenu.Item>
                <MainMenu.DefaultItems.SaveAsImage />
                <MainMenu.DefaultItems.ClearCanvas />
                <MainMenu.Separator />
                <MainMenu.DefaultItems.ToggleTheme />
            </MainMenu>
        </Excalidraw>
    </div>;
}

export default WhiteBoard;