import { useEffect, useState } from "react";
import Card from "../../components/Card";
import UpdateViewSetup from "../../components/UpdateViewSetup";
import { checkMobileView, getValidLabels, getPageText, getValidTooltip, accumulation, commonEventList, getPageEventName } from "../../config/Common";
import { useSelector } from "react-redux";
let pageNameAcc = "switchViewPage"

export default function SwitchView(props) {
    const [pageText, setPageText] = useState(null);
    const auth = useSelector(state => state.authReducer);
    useEffect(async () => {
        let pageText = await getPageText('switchView');
        setPageText(pageText);
    }, [props.languagePreference])

    useEffect(async () => {
        accumulation(pageNameAcc, commonEventList("pageInitiated"), auth.activeCompany, auth.loginId, props.grpcClient)
    }, [])

    return checkMobileView() ? <div className="mobile-container">
        <div className="header">
            <div style={{ display: "flex", flex: 1, padding: 5, flexDirection: "row", fontSize: 18, alignItems: "center" }}>
                <div style={{ padding: "0 10px" }} onClick={() => {
                    props.history.goBack()
                }}>
                    <i className='fas fa-chevron-left' />
                </div>
                <div style={{ flex: 1 }}>
                    <div>{getValidLabels(pageText, 'switchViewHeaderTxt')}</div>
                </div>
            </div>
        </div>
        <div className="body p-2">
            <UpdateViewSetup pageText={pageText} grpcClient={props.grpcClient} />
        </div>
    </div> : <div className="p-2 m-2">
        <Card title={<>{getValidLabels(pageText, 'switchViewHeaderTxt')}<button className='btn btn-primary btn-sm float-right' onClick={() => {
            accumulation(pageNameAcc, getPageEventName("switchViewPage", "switchViewHelpContextBtnClicked"), auth.activeCompany, auth.loginId, props.grpcClient)
            props.setContextHelp("switchView")
        }} data-tip={getValidTooltip(pageText, 'helpContextBtnTxt')}>
            <i className="fas fa-question"></i>
        </button></>}>
            <div className="row">
                <div className="offset-sm-3 col-sm-6">
                    <UpdateViewSetup pageText={pageText} grpcClient={props.grpcClient} />
                </div>
            </div>
        </Card>
    </div>
}
