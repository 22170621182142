import MyConfig from '../../config/MyConfig';
import {
  SET_MY_PROFILE,
  SET_MULTIPLE_MY_PROFILE,
  RESET_MY_PROFILE
} from '../constants/actionTypes';

const initialState = {
  userId: "",
  companyId: "",
  companyOwnerId: "",
  user_FirstName: "",
  user_LastName: "",
  user_profilePhoto: "",
  documentFiles: [],
  document_Json: [],
  errorFields: "",
  editable: false,
  pageText: null,
  workspaceColors: ["#1e325c", "#2f3136", "#eeeeee", "#9c27b0", "#2196f3", "#4caf50", "#ff9800", "#607d8b"],
  userBgColor: "#1e325c",
  languageList: [
    { label: "English", value: "eng" },
    { label: "Tamil", value: "tam" },
    { label: "Hindi", value: "hin" },
    { label: "Sinhala", value: "sin" },
  ],
  languagePreference: "eng",
  loaderBtn: false,
  vsCodeEdit: false,
  publicKey: "",
  ipAddress: "",
  errorIP: {},
  vsCodeLoader: false,
  modalWindowShow: false,
  checkPasswordResponse: false,
  askPassworModal: false,
  passwordText: "",
  passwordTextError: "",
  environmentData: MyConfig.environmentData,
  userSystemConfigDetails: {},
  toggleIcon: false,
  toggleIcon2: false,
  tutorialModal: false,
  activeTab: "windows",
  address: "",
  pincode: "",
  countryOptions: [],
  countrySelected: "",
  languageOptions: [],
  languageSelected: "",
  hoursSelected: 12,
  contactNumber:""
};

const myProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MY_PROFILE: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_MY_PROFILE: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_MY_PROFILE: {
      return {
        ...state,
        userId: "",
        companyId: "",
        companyOwnerId: "",
        user_FirstName: "",
        user_LastName: "",
        user_profilePhoto: "",
        documentFiles: [],
        document_Json: [],
        errorFields: "",
        editable: false,
        pageText: null,
        workspaceColors: ["#1e325c", "#2f3136", "#eeeeee", "#9c27b0", "#2196f3", "#4caf50", "#ff9800", "#607d8b"],
        userBgColor: "#1e325c",
        languageList: [
          { label: "English", value: "eng" },
          { label: "Tamil", value: "tam" },
          { label: "Hindi", value: "hin" },
          { label: "Sinhala", value: "sin" },
        ],
        languagePreference: "eng",
        loaderBtn: false,
        vsCodeEdit: false,
        publicKey: "",
        ipAddress: "",
        errorIP: {},
        vsCodeLoader: false,
        modalWindowShow: false,
        checkPasswordResponse: false,
        askPassworModal: false,
        passwordText: "",
        passwordTextError: "",
        environmentData: MyConfig.environmentData,
        userSystemConfigDetails: {},
        toggleIcon: false,
        toggleIcon2: false,
        tutorialModal: false,
        activeTab: "windows",
        address: "",
        pincode: "",
        countryOptions: [],
        countrySelected: "",
        languageOptions: [],
        languageSelected: "",
        hoursSelected: 12,
        contactNumber:""
      };
    }
    default: {
      return state;
    }
  }
};

export default myProfileReducer;
