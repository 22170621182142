import { SET_MULTIPLE_CALENDLY_GUEST, RESET_CALENDLY_GUEST } from '../constants/actionTypes';

const initialState = {
  inviteModal: false,
  inviteSubmitLoader: false,
  firstname: "",
  lastname: "",
  email: "",
};

const calendlyGuestReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MULTIPLE_CALENDLY_GUEST: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_CALENDLY_GUEST: {
      return {
        ...state,
        inviteModal: false,
        inviteSubmitLoader: false,
        firstname: "",
        lastname: "",
        email: "",
      };
    }
    default: {
      return state;
    }
  }
};

export default calendlyGuestReducer;
