import {
  SET_PROJECT,
  SET_MULTIPLE_PROJECT,
  RESET_PROJECT
} from '../constants/actionTypes';

let time = new Date()

const initialState = {
  userId: "",
  companyId: "",
  projectList: {},
  clientDetails: {},
  clientOptions: [],
  selectedClient: "",
  projectName: "",
  notes: "",
  addProjectModal: false,
  editId: "",
  errors: {
    selectedClient: "",
    projectName: "",
    notes: "",
  },
  pageText: null,
  clientExpanded: [],
  editTextChanges: false,
  editResponseData: {},
  userListOption: {},
  tableJson: [],
  viewModal: false,
  viewDetails: {},
  taskModal: false,
  taskDetails: {},
  teamDetails: {},
  addMembersToTeamModal: false,
  addTaskMembersModal: false,
  teamModal: false,
  csvModalOpen: false,
  documentFiles: [],
  document_Json: [],
  selectedCsvClient: "",
  errorCsv: {},
  viewType: {},
  mapTeacherModal: false,
  mapTeacherDetails: {},
  mapSubjectModal: false,
  mapSubjectDetails: {},
  submitProjectButtonDisable: false,
  filterClient: "",
  studentListModal: false,
  studentDetails: {},
  appsMappingModal: false,
  appsOptionsList: [],
  appsSelected: "",
  appsSubmitLoader: false,
  errorApps: {},
  appsData: {},
  appsMappedModal: false,
  appsMappedList: [],
  appsNoOfPage: 1,
  appsPageLimit: 10,
  appsNext: false,
  appsPrevious: false,
  appspagination: [],
  noOfPage: 1,
  pageLimit: 10,
  loadMore: false,
  projectResponseData: {},
  proPrev: false,
  proNext: false,
  proPagination: [],
  membersShowMorePaginate: { noOfPage: 1, pageLimit: 10, next: false, prev: false, pagination: [] },
  taskShowMore: {
    paginate: { noOfPage: 1, pageLimit: 10, next: false, prev: false, pagination: [], },
    project: { projectName: "", projectId: "", },
    clientDetails: { clientName: "", clientId: "", },
    teamData: { teamName: "", teamId: "" },
    isTaskPage: false, taskListData: {}, detailsInfo: {}
  },
  projectTeamShowMore: {
    paginate: { noOfPage: 1, pageLimit: 10, next: false, prev: false, pagination: [], },
    project: { projectName: "", projectId: "", },
    clientDetails: { clientName: "", clientId: "", },
    isTeamMore: false, tableJson: [], detailsInfo: {}
  },
  csvSubmitLoader: false,
  existMappedAppIds: [],
  projectTeamMappedData: [],
  projectTeamMappedDataPreview: false,
  projectTeamPaginate: { noOfPage: 1, pageLimit: 10, next: false, prev: false, pagination: [] },
  teamTaskMappedDataPreview: false,
  teamTaskMappedData: [],
  teamTaskPaginate: { noOfPage: 1, pageLimit: 10, next: false, prev: false, pagination: [] },
  projectTeamPageType: "",
  projectType: "Ongoing",
  toggleProjects: [],
  startTime: "",
  endTime: "",
  taskShowMoreEnable: {}
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROJECT: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_PROJECT: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_PROJECT: {
      return {
        ...state,
        userId: "",
        companyId: "",
        projectList: {},
        clientDetails: {},
        clientOptions: [],
        selectedClient: "",
        projectName: "",
        notes: "",
        addProjectModal: false,
        editId: "",
        errors: {
          selectedClient: "",
          projectName: "",
          notes: "",
        },
        pageText: null,
        clientExpanded: [],
        editTextChanges: false,
        editResponseData: {},
        userListOption: {},
        tableJson: [],
        viewModal: false,
        viewDetails: {},
        taskModal: false,
        taskDetails: {},
        teamDetails: {},
        addMembersToTeamModal: false,
        teamModal: false,
        csvModalOpen: false,
        documentFiles: [],
        document_Json: [],
        selectedCsvClient: "",
        errorCsv: {},
        viewType: {},
        mapTeacherModal: false,
        mapTeacherDetails: {},
        mapSubjectModal: false,
        mapSubjectDetails: {},
        submitProjectButtonDisable: false,
        filterClient: "",
        studentListModal: false,
        studentDetails: {},
        userDetails: {},
        appsMappingModal: false,
        appsOptionsList: [],
        appsSelected: "",
        appsSubmitLoader: false,
        errorApps: {},
        appsData: {},
        appsMappedModal: false,
        appsMappedList: [],
        appsNoOfPage: 1,
        appsPageLimit: 10,
        appsNext: false,
        appsPrevious: false,
        appspagination: [],
        noOfPage: 1,
        pageLimit: 10,
        loadMore: false,
        projectResponseData: {},
        proPrev: false,
        proNext: false,
        proPagination: [],
        membersShowMorePaginate: { noOfPage: 1, pageLimit: 10, next: false, prev: false, pagination: [] },
        taskShowMore: {
          paginate: { noOfPage: 1, pageLimit: 10, next: false, prev: false, pagination: [], },
          project: { projectName: "", projectId: "", },
          clientDetails: { clientName: "", clientId: "", },
          teamData: { teamName: "", teamId: "" },
          isTaskPage: false, taskListData: {}, detailsInfo: {}
        },
        projectTeamShowMore: {
          paginate: { noOfPage: 1, pageLimit: 10, next: false, prev: false, pagination: [], },
          project: { projectName: "", projectId: "", },
          clientDetails: { clientName: "", clientId: "", },
          isTeamMore: false, tableJson: [], detailsInfo: {}
        },
        csvSubmitLoader: false,
        existMappedAppIds: [],
        projectTeamMappedData: [],
        projectTeamMappedDataPreview: false,
        projectTeamPaginate: { noOfPage: 1, pageLimit: 10, next: false, prev: false, pagination: [] },
        teamTaskMappedDataPreview: false,
        teamTaskMappedData: [],
        teamTaskPaginate: { noOfPage: 1, pageLimit: 10, next: false, prev: false, pagination: [] },
        projectTeamPageType: "",
        projectType: "Ongoing",
        toggleProjects: [],
        startTime: "",
        endTime: "",
        taskShowMoreEnable: {}
      };
    }
    default: {
      return state;
    }
  }
};

export default projectReducer;
