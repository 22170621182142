import MyConfig from '../../config/MyConfig';
import {
    SET_FEEDBACK_EDIT,
    SET_MULTIPLE_FEEDBACK_EDIT,
    RESET_FEEDBACK_EDIT
} from '../constants/actionTypes';

const initialState = {
    userId: "",
    companyId: "",
    successMsg: false,
    viewMode: "upload",
    document_Json: [],
    deviceWidth: window.innerWidth,
    loader: false,
    documentFiles: [],
    title: "",
    description: "",
    deviceOs: "",
    deviceOsVersion: "",
    deviceModel: "",
    deviceOtherOsVersion: "",
    deviceOsVersionOption: [],
    completedStatus: "",
    reopenStatus: false,
    reOpenDescription: "",
    issueFrom: "Web",
    submitLoader: false,
    imagePreview: {},
    modalVisible: false,
    feedbackType: "bug",
    feedbackTypeOption: MyConfig.feedbackType,
};

const feedbackEditReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FEEDBACK_EDIT: {
            return {
                ...state,
                [action.key]: action.value,
            };
        }
        case SET_MULTIPLE_FEEDBACK_EDIT: {
            return {
                ...state,
                ...action.data,
            };
        }
        case RESET_FEEDBACK_EDIT: {
            return {
                ...state,
                userId: "",
                companyId: "",
                successMsg: false,
                viewMode: "upload",
                document_Json: [],
                deviceWidth: window.innerWidth,
                loader: false,
                documentFiles: [],
                title: "",
                description: "",
                deviceOs: "",
                deviceOsVersion: "",
                deviceModel: "",
                deviceOtherOsVersion: "",
                deviceOsVersionOption: [],
                completedStatus: "",
                reopenStatus: false,
                reOpenDescription: "",
                issueFrom: "Web",
                submitLoader: false,
                imagePreview: {},
                modalVisible: false,
                feedbackType: "bug",
                feedbackTypeOption: MyConfig.feedbackType,
            };
        }
        default: {
            return state;
        }
    }
};

export default feedbackEditReducer;
