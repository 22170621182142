import React, { useEffect, useState } from 'react';
import AsyncSelect from "react-select/async";
import GlobalMsgCons from '../config/GlobalMsgCons';
import { ZiFetchRequest } from '../protobuf/search/ZiFetchService_grpc_web_pb';
import { useSelector } from 'react-redux';
import { getGrpcClient, getUuidv4 } from '../config/Common';

export default (props) => {
    const auth = useSelector(state => state.authReducer);
    const [defaultOptions, setDefaultOptions] = useState(false);
    const [loading, setLoading] = useState(false);

    function getThemeBg() {
        let { theme } = auth;
        let returnTheme = "#fff";

        if (!window.location.pathname.includes("/manage/")) {
            if (theme === "dark") {
                returnTheme = "#1d2127"
            }
        }
        return returnTheme;
    }

    function getThemeText() {
        let { theme } = auth;
        let returnTheme = "#000";

        if (!window.location.pathname.includes("/manage/")) {
            if (theme === "dark") {
                returnTheme = "#fff"
            }
        }
        return returnTheme;
    }

    const customStyles = (hasError) => ({
        control: (styles) => ({
            ...styles,
            ...(hasError && { borderColor: 'red' }),
            background: getThemeBg(),
            color: getThemeText(),
        }),
        menu: (base) => ({
            ...base,
            background: getThemeBg(),
            color: getThemeText()
        }),
        input: (base) => ({
            ...base,
            '[type="text"]': {
                color: getThemeText()+' !important'
            }
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        option: (styles, { isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isSelected ? "#2196F3" : isFocused ? "#8ec5f2" : undefined
            };
        }
    });

    useEffect(() => {
        let { cacheDataType, parentId, parentOrgIdSwitch } = props;
        if (parentId) {
            setDefaultOptions([])
            setLoading(true)
            let postJson = { reqId: getUuidv4(), cacheDataType, searchText: "", orgId: auth.activeCompany, parentId, dataLimit: 5 };
            if (parentOrgIdSwitch) {
                postJson.orgId = parentOrgIdSwitch
            }
            const request = new ZiFetchRequest();
            request.setDomain("search");
            request.setEvent("preSearch");
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            getGrpcClient(props.searchGrpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    let option = props.buildOptions(responseData.idList);
                    setDefaultOptions(option)
                    setLoading(false)
                }
            })
        }
    }, [props.parentId, props.parentOrgIdSwitch])

    const loadOptions = async (inputValue) => {
        let { cacheDataType, parentId } = props;
        let postJson = { reqId: getUuidv4(), cacheDataType, searchText: inputValue, orgId: auth.activeCompany, parentId };
        const request = new ZiFetchRequest();
        request.setDomain("search");
        request.setEvent("get");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        let result = new Promise(resolve => {
            getGrpcClient(props.searchGrpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    resolve(props.buildOptions(responseData.idList))
                }
            });
        });

        return result;
    };

    const loadOptionsStatic = async (inputValue) => {
        return defaultOptions.filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    }

    return (
        <AsyncSelect
            cacheOptions
            styles={customStyles(props.error ? props.error : false)}
            value={props.value ? props.value : props.selectedOption}
            onChange={props.onChange}
            options={props.options}
            isDisabled={props.isDisabled ? true : false}
            isMulti={props.isMulti ? props.isMulti : false}
            placeholder={props.placeholder}
            menuPortalTarget={document.body}
            isLoading={loading}
            loadOptions={loadOptionsStatic}
            noOptionsMessage={() => "Type to Search"}
            loadingMessage={() => "Loading"}
            defaultOptions={defaultOptions}
            {...props}
        />
    )
}
