import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function AppsBuilder(props) {
    const [loader, setLoader] = useState(false);
    const auth = useSelector(state => state.authReducer);


    const [url, setUrl] = useState("");
    useEffect(() => {
        let { loginId, activeCompany } = auth;
        let showType = "all"
        if (auth.activeUserType.toLowerCase() === "Member") {
            showType = "separate"
        }
        setUrl("https://teqbahn.com/builder-web/auth/"+loginId+"/"+activeCompany+"/" + showType)
        // setUrl("http://localhost:3000/builder-web/auth/" + loginId + "/" + activeCompany + "/" + showType)
    }, [])


    return <React.Fragment>
        {
            (loader === true) && <div className='loader-apps'>
                <div className="loading text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        }
        <iframe allow="camera; microphone" onLoad={() => {
            setLoader(false)
        }} border={0} frameBorder={0} src={url} style={{ height: '100%', width: '100%', background: '#fff' }} />
    </React.Fragment>;
}
