import {
  SET_APP_MASTER,
  SET_MULTIPLE_APP_MASTER,
  RESET_APP_MASTER
} from '../constants/actionTypes';

const initialState = {
  appMasterModal: false,
  appsName: "",
  userId: "",
  companyId: "",
  editId: "",
  errors: {
    appsName: "",
    description: "",
    appsUrl: "",
    logoUrl: "",
    appBgColor: ""
  },
  pageText: null,
  sessionId: "",
  description: "",
  appsUrl: "",
  appBgColor: "#1e325c",
  logoUrl: "",
  documentFiles: [],
  document_Json: [],
  pageLimit: 1,
  noOfPage: 1,
  pagination: [],
  appManageList: {},
  toggleAppsSetup: false,
  editTextChanges: false,
  editResponseData: {},
  submitLoader: false,
  materialType: "other",
  sourceType: "all",
  accessType: "private",
  isAppBuilder: false,
};

const appMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_APP_MASTER: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_APP_MASTER: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_APP_MASTER: {
      return {
        ...state,
        appMasterModal: false,
        appsName: "",
        userId: "",
        companyId: "",
        editId: "",
        errors: {
          appsName: "",
          description: "",
          appsUrl: "",
          logoUrl: "",
          appBgColor: ""
        },
        pageText: null,
        sessionId: "",
        description: "",
        appsUrl: "",
        appBgColor: "#1e325c",
        logoUrl: "",
        documentFiles: [],
        document_Json: [],
        pageLimit: 1,
        noOfPage: 1,
        pagination: [],
        appManageList: {},
        toggleAppsSetup: false,
        editTextChanges: false,
        editResponseData: {},
        submitLoader: false,
        materialType: "other",
        sourceType: "all",
        accessType: "private",
        isAppBuilder: false
      };
    }
    default: {
      return state;
    }
  }
};

export default appMasterReducer;
