import React, { useEffect, useRef } from "react";
import { useReactMediaRecorder } from "react-media-recorder";

const RecordHook = (props) => {
    let { screen, audio, video, setRecordStarted, stopRecord, save, recordType, setStatus, setMediaBlobUrl, blobPropertyBag } = props;
    const { status, startRecording, stopRecording, mediaBlobUrl, previewStream, error } = useReactMediaRecorder({ 
        screen, 
        audio, 
        video, 
        blobPropertyBag
    });

    useEffect(() => {
        startRecording();
    }, [])

    useEffect(() => {
        if(error === "permission_denied") {
            setRecordStarted(false)
            setStatus("idle")
        }
    }, [error])


    useEffect(() => {
        if(stopRecord) {
            stopRecording();
        }
    }, [stopRecord])

    useEffect(() => {
        setStatus(status);
    }, [status])

    useEffect(() => {
        setMediaBlobUrl(mediaBlobUrl);
    }, [mediaBlobUrl])

    return <React.Fragment>
        {
            (!mediaBlobUrl && recordType === "video" && status !== "idle" && previewStream) && <div className="video-container">
                <VideoPreview stream={previewStream} />
                <div className="my-2">
                    <button className="btn btn-block btn-theme" onClick={() => {
                        stopRecording();
                    }}>Stop Recording</button>
                </div>
            </div>
        }
        {
            mediaBlobUrl && <div className="video-container">
                <video src={mediaBlobUrl} controls height={400} />
                <div className="text-center my-2">
                    <a className="btn btn-success mr-2" href={mediaBlobUrl} download={"Record"}>Download</a>
                    <button className="btn btn-primary" onClick={() => save(mediaBlobUrl)}>Save</button>
                </div>
            </div>
        }
    </React.Fragment>;
};


const VideoPreview = ({ stream }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current && stream) {
            videoRef.current.srcObject = stream;
        }
    }, [stream]);

    if (!stream) {
        return null;
    }
    return <video ref={videoRef} height={400} autoPlay />;
};

export default RecordHook;