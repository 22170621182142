import { useEffect, useState } from "react";
import Loader from "./Loader";
import { highlighten } from "../config/Common";

function TxtFileView(props) {
    let [loader, setLoader] = useState(true);
    let [data, setData] = useState("");
    let [originalData, setOriginalData] = useState("");
    let [search, setSearch] = useState("");
    let [searchCount, setSearchCount] = useState(0);

    useEffect(() => {
        getFileContent(props.url)
    }, [props.url])

    useEffect(() => {
        if(search) {
            let hightlightData = highlighten(originalData, search)
            let count = (hightlightData.match(/<span class="highlight">/g) || []).length;
            setData(hightlightData)
            setSearchCount(count)
        } else {
            setData(originalData)
            setSearchCount(0)
        }
    }, [search])

    function getFileContent(url) {
        fetch(url)
            .then((r) => {
                r.text().then((d) => {
                    setLoader(false)
                    setData(d)
                    setOriginalData(d)
                })
            })
    }

    return loader ? <Loader /> : <div>
        <div className="row">
            <div className="offset-9 col-3">
                <input className="form-control" placeholder="Search" onChange={(e) => {
                    setSearch(e.target.value)
                }}/>
                {
                    searchCount > 0 && <div className="text-left" style={{ color: "#000", fontWeight: "bold"}}>{searchCount+" result found."}</div>
                }
            </div>
        </div>
        <pre style={{ wordWrap: "break-word", whiteSpace: "pre-wrap", textAlign: "left", fontSize: "small" }} dangerouslySetInnerHTML={{ __html: data }}>
        </pre>
    </div>;
}

export default TxtFileView