import {
  SET_EDUCATION_BOARD_STATUS_TYPE,
  SET_MULTIPLE_EDUCATION_BOARD_STATUS_TYPE,
  RESET_EDUCATION_BOARD_STATUS_TYPE
} from '../constants/actionTypes';

const initialState = {
  userId: "",
  companyId: "",
  refresh: false,
  statusTypeModal: false,
  editGroupId: "",
  groupName: "",
  errors: {},
  colorOptions: [
    { label: "Default", value: "" },
    { label: "Gray", value: "#ededed" },
    { label: "Brown", value: "#f7ddc3" },
    { label: "Orange", value: "#f9d3c1" },
    { label: "Yellow", value: "#f7f0b6" },
    { label: "Green", value: "#c7eac3" },
    { label: "Blue", value: "#b1d1f6" },
    { label: "Purple", value: "#e6d0fc" },
    { label: "Pink", value: "#f4d1e4" },
  ],
  selectedColor: "",
  sortOrder: 0,
  editTextChanges: false,
  editId: "",
  changeOrderModal: false,
  sortList: [],
  editResponseData: {},
  statusTypeLoader: false


};

const educationBoardStatusTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EDUCATION_BOARD_STATUS_TYPE: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_EDUCATION_BOARD_STATUS_TYPE: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_EDUCATION_BOARD_STATUS_TYPE: {
      return {
        ...state,
        userId: "",
        companyId: "",
        refresh: false,
        statusTypeModal: false,
        editGroupId: "",
        groupName: "",
        errors: {},
        colorOptions: [
          { label: "Default", value: "" },
          { label: "Gray", value: "#ededed" },
          { label: "Brown", value: "#f7ddc3" },
          { label: "Orange", value: "#f9d3c1" },
          { label: "Yellow", value: "#f7f0b6" },
          { label: "Green", value: "#c7eac3" },
          { label: "Blue", value: "#b1d1f6" },
          { label: "Purple", value: "#e6d0fc" },
          { label: "Pink", value: "#f4d1e4" },
        ],
        selectedColor: "",
        sortOrder: 0,
        editTextChanges: false,
        editId: "",
        changeOrderModal: false,
        sortList: [],
        editResponseData: {},
        statusTypeLoader: false
      };
    }
    default: {
      return state;
    }
  }
};

export default educationBoardStatusTypeReducer;
