import React, { useEffect } from "react";

const IncomingCallModal = (props) => {
    const {
        visible,
        heading,
        body,
        footer,
        parentClass,
    } = props;

    useEffect(() => {
        document.body.classList.add('modal-open');
        return () => {
            document.body.classList.remove('modal-open');
        }
    }, []);

    return (
        <React.Fragment>
            {visible ?
                <React.Fragment>
                    <div className={`modal ${parentClass ? parentClass : ""}`} style={{ display: 'block' }}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body p-0">
                                    { body }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </React.Fragment> : null}
        </React.Fragment>
    );
};


export default IncomingCallModal;
