import React, { useEffect, useState } from "react";
import { analyticsUserTrack } from "../config/AnalyticsTrack";
import Cloud from '../assets/landing/version2/TeqitCloud.gif';
import TeqitWorkspaces from '../assets/landing/version2/TeqitWorkspaces5.gif';
import Modal from "../components/Modal";


export default function Landing(props) {

    const [modalView, setModalView] = useState(false);
    const [modalViewImage, setModalViewImage] = useState(null);

    useEffect(() => {
        analyticsUserTrack("Home", "Landing");
    }, [])

    useEffect(() => {
        if (modalViewImage) {
            setModalView(true)
        } else {
            setModalView(false)
        }

    }, [modalViewImage])

    return <div className="landing">
        <div className='container-fluid'>
            <div className='parent-box'>
                <div className='child-box d-flex column-reverse-mobile'>
                    <div className='image image1 pt-0 px-0'>
                        <img className='img-fluid' src={Cloud} />
                    </div>
                    {/* <div className='landing-content'>
                        <div>
                            Teqit Cloud
                        </div>
                        <div className="sub-content">
                            <br />
                            Scales from on-prem server resources to edge resources to public cloud resources.<br /><br />
                            Made up of 50+ managed infrastructure and application services.<br /><br />
                            Streaming system from the ground up.<br /><br />
                            Dynamic Graph of system of systems where its nodes and leafs are Actors and Agents.<br /><br />
                            Realtime continually learning is how the Data System behaves while facilitating Search, Reports, Tracing, Logging, Metrics besides assisting all its managed services.<br /><br />
                            Computationally Carbon Sensitive:
                            <ul>
                                <li>No AI training.</li>
                                <li>No BigData analytics.</li>
                                <li>No Post ingestion Data analytics.</li>
                            </ul>
                            <br />
                            All this in a zero trust environment.
                        </div>
                    </div> */}
                </div>
                <div className='child-box d-flex'>
                    <div className='landing-content'>
                        <div>
                        Internal Services:
                        </div>
                        <div className="sub-content">
                            <br />
                            Couple of those services are:
                            <ol>
                                <li><b>Workflow</b> service that is backed by tool calling AI. Workflow service can run in any temporal scale, from request response to long running.</li>
                                <li><b>AppBuilder</b> that builds on the Workflow service while assembling the UI backed by tool calling AI. </li>
                            </ol>
                            <br />
                        </div>
                    </div>
                    <div className='image'>
                        <div className="d-flex justify-content-center my-5" style={{ flexWrap: "wrap" }}>
                            <div className='m-2'>
                                <div className="services">
                                    Workflow Service
                                </div>
                            </div>
                            <div className='m-2'>
                                <div className="services">
                                    AppBuilder Service
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='child-box d-flex column-reverse-mobile'>
                    <div className='image'>
                        <img className='img-fluid' src={TeqitWorkspaces} />
                    </div>
                    <div className='landing-content'>
                        <div>
                            Teqit Workspaces - Meaningful Connectivity
                        </div>
                        <div className="sub-content">
                            <br />
                            The 50+ managed services compose in different ways to form different types of Workspaces.<br /><br />
                            Teqit Workspaces come in different flavors. Here is a list so far - Work, Education, Community Service, Healthcare Service, Customer support, Multiplayer Gaming and IoT.<br /><br />
                            Teqit Workspaces can be experienced from SMS, Voice, MobileWeb, Web, iOS, Android, Linux, Mac and Windows.<br /><br />
                            The next generation cloud that is lightweight, carbon sensitive and fluidly scalable.<br /><br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {
            modalView && <Modal
                size={"modal-xl"}
                visible={modalView}
                closeModal={() => {
                    setModalViewImage(null)
                }}
                heading={`View`}
                body={<div className="d-flex justify-content-center">
                    <img className="img-fluid" src={modalViewImage} />
                </div>
                }
            />
        }
    </div>;
}
