import {
  SET_SUBJECT,
  SET_MULTIPLE_SUBJECT,
  RESET_SUBJECT
} from '../constants/actionTypes';

const initialState = {
  userId: "",
  companyId: "",
  projectList: {},
  clientDetails: {},
  classOptions: [],
  selectedClass: "",
  subjectName: "",
  notes: "",
  addProjectModal: false,
  editId: "",
  errors: {
    selectedClass: "",
    subjectName: "",
    notes: "",
  },
  pageText: null,
  clientExpanded: [],
  editTextChanges: false,
  editResponseData: {},
  userListOption: {},
  tableJson: [],
  viewModal: false,
  viewDetails: {},
  taskModal: false,
  taskDetails: {},
  teamDetails: {},
  addMembersToTeamModal: false,
  addTaskMembersModal: false,
  teamModal: false,
  csvModalOpen: false,
  documentFiles: [],
  document_Json: [],
  selectedCsvClient: "",
  errorCsv: {},
  viewType: {},
  mapTeacherModal: false,
  mapTeacherDetails: {},
  mapSubjectModal: false,
  mapSubjectDetails: {},
  submitProjectButtonDisable: false,
  filterClient: "",
  studentListModal: false,
  studentDetails: {},
  appsMappingModal: false,
  appsOptionsList: [],
  appsSelected: "",
  appsSubmitLoader: false,
  errorApps: {},
  appsData: {},
  appsMappedModal: false,
  appsMappedList: [],
  appsNoOfPage: 1,
  appsPageLimit: 30,
  appsNext: false,
  appsPrevious: false,
  appspagination: [],
  noOfPage: 1,
  pageLimit: 10,
  loadMore: false,
  subjectResponseData: {},
  subPrev: false,
  subNext: false,
  subPagination: [],
  studentPaginate: { noOfPage: 1, pageLimit: 10, next: false, prev: false, pagination: [] },
  showMoreTaskModal: false,
  taskModalData: {},
  subjectCsvLoader: false,
  existMappedStudyMaterialIds: [],
  taskShowMoreEnable: {}
};

const subjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUBJECT: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_SUBJECT: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_SUBJECT: {
      return {
        ...state,
        userId: "",
        companyId: "",
        projectList: {},
        clientDetails: {},
        classOptions: [],
        selectedClass: "",
        subjectName: "",
        notes: "",
        addProjectModal: false,
        editId: "",
        errors: {
          selectedClass: "",
          subjectName: "",
          notes: "",
        },
        pageText: null,
        clientExpanded: [],
        editTextChanges: false,
        editResponseData: {},
        userListOption: {},
        tableJson: [],
        viewModal: false,
        viewDetails: {},
        taskModal: false,
        taskDetails: {},
        teamDetails: {},
        addMembersToTeamModal: false,
        teamModal: false,
        csvModalOpen: false,
        documentFiles: [],
        document_Json: [],
        selectedCsvClient: "",
        errorCsv: {},
        viewType: {},
        mapTeacherModal: false,
        mapTeacherDetails: {},
        mapSubjectModal: false,
        mapSubjectDetails: {},
        submitProjectButtonDisable: false,
        filterClient: "",
        studentListModal: false,
        studentDetails: {},
        userDetails: {},
        appsMappingModal: false,
        appsOptionsList: [],
        appsSelected: "",
        appsSubmitLoader: false,
        errorApps: {},
        appsData: {},
        appsMappedModal: false,
        appsMappedList: [],
        appsNoOfPage: 1,
        appsPageLimit: 10,
        appsNext: false,
        appsPrevious: false,
        appspagination: [],
        noOfPage: 1,
        pageLimit: 10,
        loadMore: false,
        subjectResponseData: {},
        subPrev: false,
        subNext: false,
        subPagination: [],
        studentPaginate: { noOfPage: 1, pageLimit: 10, next: false, prev: false, pagination: [] },
        showMoreTaskModal: false,
        taskModalData: {},
        subjectCsvLoader: false,
        existMappedStudyMaterialIds: [],
        taskShowMoreEnable: {}
      };
    }
    default: {
      return state;
    }
  }
};

export default subjectReducer;
