import {
    SET_USER_SESSION_REPORT,
    SET_MULTIPLE_USER_SESSION_REPORT,
    RESET_USER_SESSION_REPORT
  } from '../constants/actionTypes';
  
  const initialState = {
    sessionReport : [],
  };
  
  const userSessionReportReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_USER_SESSION_REPORT: {
        return {
          ...state,
          [action.key]: action.value,
        };
      }
      case SET_MULTIPLE_USER_SESSION_REPORT: {
        return {
          ...state,
          ...action.data,
        };
      }
      case RESET_USER_SESSION_REPORT: {
        return {
          ...state,
          sessionReport : [],
        };
      }
      default: {
        return state;
      }
    }
  };
  
  export default userSessionReportReducer;
  