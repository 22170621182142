import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import UpdateViewSetup from "./UpdateViewSetup";
import { getPageText } from "../config/Common";

export default function UpdateView(props) {
    let { className } = props;
    const [previewModal, setPreviewModal] = useState(false);
    const [pageText, setPageText] = useState(null);

    useEffect(async () => {
        let pageText = await getPageText('switchView');
        setPageText(pageText);
    }, [props.languagePreference])

    return <React.Fragment>
        {
            window.outerWidth > 768 && <div className={className}>
                <button type="button" className="btn btn-link" onClick={() => setPreviewModal(true)}>Switch View</button>
            </div>
        }
        {
            previewModal && <Modal
                visible={previewModal}
                closeModal={() => setPreviewModal(false)}
                heading={`Update View`}
                body={<React.Fragment>
                    <UpdateViewSetup
                        pageText={pageText}
                    />
                </React.Fragment>}
            />
        }
    </React.Fragment>;
}
