import MyConfig from '../../config/MyConfig';
import { RESET_QAT_CREATE, SET_QAT_CREATE, SET_MULTIPLE_QAT_CREATE } from '../constants/actionTypes';

const initialState = {
    successMsg: false,
    viewMode: "upload",
    document_Json: [],
    deviceWidth: window.innerWidth,
    loader: false,
    documentFiles: [],
    title: "",
    description: "",
    deviceOs: "android",
    deviceOsOption: MyConfig.deviceOs,
    deviceOsVersion: "",
    deviceModel: "",
    deviceOtherOsVersion: "",
    deviceOsVersionOption: MyConfig.andriodOsVersion,
    issueFrom: "Web",
    submitLoader: false,
    imagePreview: {},
    modalVisible: false,
    pageText: null,
    selectedClient: "",
    selectedProject: "",
    projectOptions: [],
    selectedStatus: "",
    testingOptions: [{ label: "Success", value: "success" }, { label: "Failure", value: "failure" }],
    testingId: "",
    testingValueSelect: ""
};

const qatCreateReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_QAT_CREATE: {
            return {
                ...state,
                [action.key]: action.value,
            };
        }
        case SET_MULTIPLE_QAT_CREATE: {
            return {
                ...state,
                ...action.data,
            };
        }
        case RESET_QAT_CREATE: {
            return {
                ...state,
                successMsg: false,
                viewMode: "upload",
                document_Json: [],
                deviceWidth: window.innerWidth,
                loader: false,
                documentFiles: [],
                title: "",
                description: "",
                deviceOs: "android",
                deviceOsOption: MyConfig.deviceOs,
                deviceOsVersion: "",
                deviceModel: "",
                deviceOtherOsVersion: "",
                deviceOsVersionOption: MyConfig.andriodOsVersion,
                issueFrom: "Web",
                imagePreview: {},
                modalVisible: false,
                pageText: null,
                selectedClient: "",
                selectedProject: "",
                projectOptions: [],
                selectedStatus: "",
                testingOptions: [{ label: "success", value: "succes" }, { label: "Failure", value: "failure" }],
                testingId: "",
                testingValueSelect: ""
            };
        }
        default: {
            return state;
        }
    }
};

export default qatCreateReducer;
