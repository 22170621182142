import {
    SET_APPS,
    SET_MULTIPLE_APPS,
    RESET_APPS
} from '../constants/actionTypes';

const initialState = {
    apps: [],
    pageLimit: 9,
    noOfPage: 1,
    noOfPagePublic: 1,
    appsMasterView: false,
    userId: "",
    companyId: "",
    ownerId: "",
    appsDetails: {},
    addAppsModal: false,
    loadMore: false,
    projectOptions: [],
    projectSelect: { projectId: "", value: "", select: "" },
    publicApps: [],
    totalPublicAppsResults: 0,
};

const appsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_APPS: {
            return {
                ...state,
                [action.key]: action.value,
            };
        }
        case SET_MULTIPLE_APPS: {
            return {
                ...state,
                ...action.data,
            };
        }
        case RESET_APPS: {
            return {
                ...state,
                apps: [],
                pageLimit: 9,
                noOfPage: 1,
                appsMasterView: false,
                userId: "",
                companyId: "",
                ownerId: "",
                appsDetails: {},
                addAppsModal: false,
                loadMore: false,
                projectOptions: [],
                projectSelect: { projectId: "", value: "", select: "" },
                publicApps: [],
                totalPublicAppsResults: 0,
            };
        }
        default: {
            return state;
        }
    }
};

export default appsReducer;
