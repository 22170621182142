/**
 * @fileoverview gRPC-Web generated client stub for teqdocs
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.teqdocs = require('./ZiFetchService_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiFetchService1Client =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiFetchService1PromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService1_SayZiFetch = new grpc.web.MethodDescriptor(
  '/teqdocs.ZiFetchService1/SayZiFetch',
  grpc.web.MethodType.UNARY,
  proto.teqdocs.ZiFetchRequest,
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodInfo_ZiFetchService1_SayZiFetch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teqdocs.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService1Client.prototype.sayZiFetch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teqdocs.ZiFetchService1/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService1_SayZiFetch,
      callback);
};


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teqdocs.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.teqdocs.ZiFetchService1PromiseClient.prototype.sayZiFetch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teqdocs.ZiFetchService1/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService1_SayZiFetch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService1_ItKeepsReplying = new grpc.web.MethodDescriptor(
  '/teqdocs.ZiFetchService1/ItKeepsReplying',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.teqdocs.ZiFetchRequest,
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodInfo_ZiFetchService1_ItKeepsReplying = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService1Client.prototype.itKeepsReplying =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/teqdocs.ZiFetchService1/ItKeepsReplying',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService1_ItKeepsReplying);
};


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService1PromiseClient.prototype.itKeepsReplying =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/teqdocs.ZiFetchService1/ItKeepsReplying',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService1_ItKeepsReplying);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiFetchService2Client =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiFetchService2PromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService2_SayZiFetch = new grpc.web.MethodDescriptor(
  '/teqdocs.ZiFetchService2/SayZiFetch',
  grpc.web.MethodType.UNARY,
  proto.teqdocs.ZiFetchRequest,
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodInfo_ZiFetchService2_SayZiFetch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teqdocs.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService2Client.prototype.sayZiFetch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teqdocs.ZiFetchService2/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService2_SayZiFetch,
      callback);
};


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teqdocs.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.teqdocs.ZiFetchService2PromiseClient.prototype.sayZiFetch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teqdocs.ZiFetchService2/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService2_SayZiFetch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService2_ItKeepsReplying = new grpc.web.MethodDescriptor(
  '/teqdocs.ZiFetchService2/ItKeepsReplying',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.teqdocs.ZiFetchRequest,
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodInfo_ZiFetchService2_ItKeepsReplying = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService2Client.prototype.itKeepsReplying =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/teqdocs.ZiFetchService2/ItKeepsReplying',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService2_ItKeepsReplying);
};


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService2PromiseClient.prototype.itKeepsReplying =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/teqdocs.ZiFetchService2/ItKeepsReplying',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService2_ItKeepsReplying);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiFetchService3Client =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiFetchService3PromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService3_SayZiFetch = new grpc.web.MethodDescriptor(
  '/teqdocs.ZiFetchService3/SayZiFetch',
  grpc.web.MethodType.UNARY,
  proto.teqdocs.ZiFetchRequest,
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodInfo_ZiFetchService3_SayZiFetch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teqdocs.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService3Client.prototype.sayZiFetch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teqdocs.ZiFetchService3/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService3_SayZiFetch,
      callback);
};


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teqdocs.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.teqdocs.ZiFetchService3PromiseClient.prototype.sayZiFetch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teqdocs.ZiFetchService3/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService3_SayZiFetch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService3_ItKeepsReplying = new grpc.web.MethodDescriptor(
  '/teqdocs.ZiFetchService3/ItKeepsReplying',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.teqdocs.ZiFetchRequest,
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodInfo_ZiFetchService3_ItKeepsReplying = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService3Client.prototype.itKeepsReplying =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/teqdocs.ZiFetchService3/ItKeepsReplying',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService3_ItKeepsReplying);
};


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService3PromiseClient.prototype.itKeepsReplying =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/teqdocs.ZiFetchService3/ItKeepsReplying',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService3_ItKeepsReplying);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiFetchService4Client =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiFetchService4PromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService4_SayZiFetch = new grpc.web.MethodDescriptor(
  '/teqdocs.ZiFetchService4/SayZiFetch',
  grpc.web.MethodType.UNARY,
  proto.teqdocs.ZiFetchRequest,
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodInfo_ZiFetchService4_SayZiFetch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teqdocs.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService4Client.prototype.sayZiFetch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teqdocs.ZiFetchService4/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService4_SayZiFetch,
      callback);
};


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teqdocs.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.teqdocs.ZiFetchService4PromiseClient.prototype.sayZiFetch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teqdocs.ZiFetchService4/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService4_SayZiFetch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService4_ItKeepsReplying = new grpc.web.MethodDescriptor(
  '/teqdocs.ZiFetchService4/ItKeepsReplying',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.teqdocs.ZiFetchRequest,
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodInfo_ZiFetchService4_ItKeepsReplying = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService4Client.prototype.itKeepsReplying =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/teqdocs.ZiFetchService4/ItKeepsReplying',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService4_ItKeepsReplying);
};


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService4PromiseClient.prototype.itKeepsReplying =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/teqdocs.ZiFetchService4/ItKeepsReplying',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService4_ItKeepsReplying);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiFetchService5Client =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiFetchService5PromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService5_SayZiFetch = new grpc.web.MethodDescriptor(
  '/teqdocs.ZiFetchService5/SayZiFetch',
  grpc.web.MethodType.UNARY,
  proto.teqdocs.ZiFetchRequest,
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodInfo_ZiFetchService5_SayZiFetch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teqdocs.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService5Client.prototype.sayZiFetch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teqdocs.ZiFetchService5/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService5_SayZiFetch,
      callback);
};


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teqdocs.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.teqdocs.ZiFetchService5PromiseClient.prototype.sayZiFetch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teqdocs.ZiFetchService5/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService5_SayZiFetch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService5_ItKeepsReplying = new grpc.web.MethodDescriptor(
  '/teqdocs.ZiFetchService5/ItKeepsReplying',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.teqdocs.ZiFetchRequest,
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodInfo_ZiFetchService5_ItKeepsReplying = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService5Client.prototype.itKeepsReplying =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/teqdocs.ZiFetchService5/ItKeepsReplying',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService5_ItKeepsReplying);
};


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService5PromiseClient.prototype.itKeepsReplying =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/teqdocs.ZiFetchService5/ItKeepsReplying',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService5_ItKeepsReplying);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiFetchService6Client =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiFetchService6PromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService6_SayZiFetch = new grpc.web.MethodDescriptor(
  '/teqdocs.ZiFetchService6/SayZiFetch',
  grpc.web.MethodType.UNARY,
  proto.teqdocs.ZiFetchRequest,
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodInfo_ZiFetchService6_SayZiFetch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teqdocs.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService6Client.prototype.sayZiFetch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teqdocs.ZiFetchService6/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService6_SayZiFetch,
      callback);
};


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teqdocs.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.teqdocs.ZiFetchService6PromiseClient.prototype.sayZiFetch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teqdocs.ZiFetchService6/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService6_SayZiFetch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService6_ItKeepsReplying = new grpc.web.MethodDescriptor(
  '/teqdocs.ZiFetchService6/ItKeepsReplying',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.teqdocs.ZiFetchRequest,
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodInfo_ZiFetchService6_ItKeepsReplying = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService6Client.prototype.itKeepsReplying =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/teqdocs.ZiFetchService6/ItKeepsReplying',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService6_ItKeepsReplying);
};


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService6PromiseClient.prototype.itKeepsReplying =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/teqdocs.ZiFetchService6/ItKeepsReplying',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService6_ItKeepsReplying);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiFetchService7Client =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiFetchService7PromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService7_SayZiFetch = new grpc.web.MethodDescriptor(
  '/teqdocs.ZiFetchService7/SayZiFetch',
  grpc.web.MethodType.UNARY,
  proto.teqdocs.ZiFetchRequest,
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodInfo_ZiFetchService7_SayZiFetch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teqdocs.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService7Client.prototype.sayZiFetch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teqdocs.ZiFetchService7/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService7_SayZiFetch,
      callback);
};


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teqdocs.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.teqdocs.ZiFetchService7PromiseClient.prototype.sayZiFetch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teqdocs.ZiFetchService7/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService7_SayZiFetch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService7_ItKeepsReplying = new grpc.web.MethodDescriptor(
  '/teqdocs.ZiFetchService7/ItKeepsReplying',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.teqdocs.ZiFetchRequest,
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodInfo_ZiFetchService7_ItKeepsReplying = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService7Client.prototype.itKeepsReplying =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/teqdocs.ZiFetchService7/ItKeepsReplying',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService7_ItKeepsReplying);
};


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService7PromiseClient.prototype.itKeepsReplying =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/teqdocs.ZiFetchService7/ItKeepsReplying',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService7_ItKeepsReplying);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiFetchService8Client =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiFetchService8PromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService8_SayZiFetch = new grpc.web.MethodDescriptor(
  '/teqdocs.ZiFetchService8/SayZiFetch',
  grpc.web.MethodType.UNARY,
  proto.teqdocs.ZiFetchRequest,
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodInfo_ZiFetchService8_SayZiFetch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teqdocs.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService8Client.prototype.sayZiFetch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teqdocs.ZiFetchService8/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService8_SayZiFetch,
      callback);
};


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teqdocs.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.teqdocs.ZiFetchService8PromiseClient.prototype.sayZiFetch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teqdocs.ZiFetchService8/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService8_SayZiFetch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService8_ItKeepsReplying = new grpc.web.MethodDescriptor(
  '/teqdocs.ZiFetchService8/ItKeepsReplying',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.teqdocs.ZiFetchRequest,
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodInfo_ZiFetchService8_ItKeepsReplying = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService8Client.prototype.itKeepsReplying =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/teqdocs.ZiFetchService8/ItKeepsReplying',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService8_ItKeepsReplying);
};


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService8PromiseClient.prototype.itKeepsReplying =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/teqdocs.ZiFetchService8/ItKeepsReplying',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService8_ItKeepsReplying);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiFetchService9Client =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiFetchService9PromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService9_SayZiFetch = new grpc.web.MethodDescriptor(
  '/teqdocs.ZiFetchService9/SayZiFetch',
  grpc.web.MethodType.UNARY,
  proto.teqdocs.ZiFetchRequest,
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodInfo_ZiFetchService9_SayZiFetch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teqdocs.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService9Client.prototype.sayZiFetch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teqdocs.ZiFetchService9/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService9_SayZiFetch,
      callback);
};


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teqdocs.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.teqdocs.ZiFetchService9PromiseClient.prototype.sayZiFetch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teqdocs.ZiFetchService9/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService9_SayZiFetch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService9_ItKeepsReplying = new grpc.web.MethodDescriptor(
  '/teqdocs.ZiFetchService9/ItKeepsReplying',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.teqdocs.ZiFetchRequest,
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodInfo_ZiFetchService9_ItKeepsReplying = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService9Client.prototype.itKeepsReplying =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/teqdocs.ZiFetchService9/ItKeepsReplying',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService9_ItKeepsReplying);
};


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService9PromiseClient.prototype.itKeepsReplying =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/teqdocs.ZiFetchService9/ItKeepsReplying',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService9_ItKeepsReplying);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiFetchService10Client =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiFetchService10PromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService10_SayZiFetch = new grpc.web.MethodDescriptor(
  '/teqdocs.ZiFetchService10/SayZiFetch',
  grpc.web.MethodType.UNARY,
  proto.teqdocs.ZiFetchRequest,
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodInfo_ZiFetchService10_SayZiFetch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teqdocs.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService10Client.prototype.sayZiFetch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teqdocs.ZiFetchService10/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService10_SayZiFetch,
      callback);
};


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teqdocs.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.teqdocs.ZiFetchService10PromiseClient.prototype.sayZiFetch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teqdocs.ZiFetchService10/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService10_SayZiFetch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService10_ItKeepsReplying = new grpc.web.MethodDescriptor(
  '/teqdocs.ZiFetchService10/ItKeepsReplying',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.teqdocs.ZiFetchRequest,
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodInfo_ZiFetchService10_ItKeepsReplying = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService10Client.prototype.itKeepsReplying =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/teqdocs.ZiFetchService10/ItKeepsReplying',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService10_ItKeepsReplying);
};


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiFetchService10PromiseClient.prototype.itKeepsReplying =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/teqdocs.ZiFetchService10/ItKeepsReplying',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService10_ItKeepsReplying);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiPollingServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiPollingServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodDescriptor_ZiPollingService_PollZiFetch = new grpc.web.MethodDescriptor(
  '/teqdocs.ZiPollingService/PollZiFetch',
  grpc.web.MethodType.UNARY,
  proto.teqdocs.ZiFetchRequest,
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodInfo_ZiPollingService_PollZiFetch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teqdocs.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiPollingServiceClient.prototype.pollZiFetch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teqdocs.ZiPollingService/PollZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiPollingService_PollZiFetch,
      callback);
};


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teqdocs.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.teqdocs.ZiPollingServicePromiseClient.prototype.pollZiFetch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teqdocs.ZiPollingService/PollZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiPollingService_PollZiFetch);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiPollingService1Client =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiPollingService1PromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodDescriptor_ZiPollingService1_PollZiFetch = new grpc.web.MethodDescriptor(
  '/teqdocs.ZiPollingService1/PollZiFetch',
  grpc.web.MethodType.UNARY,
  proto.teqdocs.ZiFetchRequest,
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodInfo_ZiPollingService1_PollZiFetch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teqdocs.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiPollingService1Client.prototype.pollZiFetch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teqdocs.ZiPollingService1/PollZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiPollingService1_PollZiFetch,
      callback);
};


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teqdocs.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.teqdocs.ZiPollingService1PromiseClient.prototype.pollZiFetch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teqdocs.ZiPollingService1/PollZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiPollingService1_PollZiFetch);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiPollingService2Client =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiPollingService2PromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodDescriptor_ZiPollingService2_PollZiFetch = new grpc.web.MethodDescriptor(
  '/teqdocs.ZiPollingService2/PollZiFetch',
  grpc.web.MethodType.UNARY,
  proto.teqdocs.ZiFetchRequest,
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodInfo_ZiPollingService2_PollZiFetch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teqdocs.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiPollingService2Client.prototype.pollZiFetch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teqdocs.ZiPollingService2/PollZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiPollingService2_PollZiFetch,
      callback);
};


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teqdocs.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.teqdocs.ZiPollingService2PromiseClient.prototype.pollZiFetch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teqdocs.ZiPollingService2/PollZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiPollingService2_PollZiFetch);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiPollingService3Client =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiPollingService3PromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodDescriptor_ZiPollingService3_PollZiFetch = new grpc.web.MethodDescriptor(
  '/teqdocs.ZiPollingService3/PollZiFetch',
  grpc.web.MethodType.UNARY,
  proto.teqdocs.ZiFetchRequest,
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodInfo_ZiPollingService3_PollZiFetch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teqdocs.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiPollingService3Client.prototype.pollZiFetch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teqdocs.ZiPollingService3/PollZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiPollingService3_PollZiFetch,
      callback);
};


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teqdocs.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.teqdocs.ZiPollingService3PromiseClient.prototype.pollZiFetch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teqdocs.ZiPollingService3/PollZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiPollingService3_PollZiFetch);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiPollingService4Client =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiPollingService4PromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodDescriptor_ZiPollingService4_PollZiFetch = new grpc.web.MethodDescriptor(
  '/teqdocs.ZiPollingService4/PollZiFetch',
  grpc.web.MethodType.UNARY,
  proto.teqdocs.ZiFetchRequest,
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodInfo_ZiPollingService4_PollZiFetch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teqdocs.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiPollingService4Client.prototype.pollZiFetch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teqdocs.ZiPollingService4/PollZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiPollingService4_PollZiFetch,
      callback);
};


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teqdocs.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.teqdocs.ZiPollingService4PromiseClient.prototype.pollZiFetch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teqdocs.ZiPollingService4/PollZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiPollingService4_PollZiFetch);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiPollingService5Client =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqdocs.ZiPollingService5PromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodDescriptor_ZiPollingService5_PollZiFetch = new grpc.web.MethodDescriptor(
  '/teqdocs.ZiPollingService5/PollZiFetch',
  grpc.web.MethodType.UNARY,
  proto.teqdocs.ZiFetchRequest,
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqdocs.ZiFetchRequest,
 *   !proto.teqdocs.ZiFetchReply>}
 */
const methodInfo_ZiPollingService5_PollZiFetch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqdocs.ZiFetchReply,
  /**
   * @param {!proto.teqdocs.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqdocs.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teqdocs.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teqdocs.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teqdocs.ZiPollingService5Client.prototype.pollZiFetch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teqdocs.ZiPollingService5/PollZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiPollingService5_PollZiFetch,
      callback);
};


/**
 * @param {!proto.teqdocs.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teqdocs.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.teqdocs.ZiPollingService5PromiseClient.prototype.pollZiFetch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teqdocs.ZiPollingService5/PollZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiPollingService5_PollZiFetch);
};


module.exports = proto.teqdocs;

