import React, { useState, useEffect, useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, Text, Stats } from '@react-three/drei'
import { MeshNormalMaterial, BoxBufferGeometry } from 'three'
import { useSelector } from 'react-redux';

let timer;
const ControlsWrapper = ({ id, sendMessage, firstName }) => {
    const controlsRef = useRef()
    const messageIdRef = useRef("");
    const [updateCallback, setUpdateCallback] = useState(null)

    // Register the update event and clean up
    useEffect(() => {
        const onControlsChange = (val) => {
            const { position, rotation } = val.target.object

            const posArray = []
            const rotArray = []

            position.toArray(posArray)
            rotation.toArray(rotArray)

            let data = {
                rotation: rotArray,
                position: posArray,
                firstName
            }
            messageIdRef.current = data
            // console.log("rece", data)
            clearTimeout(timer)
            timer = setTimeout(() => {
                sendMessage(messageIdRef.current)
            }, 1000)
        }

        if (controlsRef.current) {
            setUpdateCallback(
                controlsRef.current.addEventListener('change', onControlsChange)
            )
        }

        // Dispose
        return () => {
            if (updateCallback && controlsRef.current)
                controlsRef.current.removeEventListener(
                    'change',
                    onControlsChange
                )
        }
    }, [controlsRef])

    return <OrbitControls ref={controlsRef} />
}

const UserWrapper = ({ position, rotation, id }) => {
    return (
        <mesh
            position={position}
            rotation={rotation}
            geometry={new BoxBufferGeometry()}
            material={new MeshNormalMaterial()}
        >
            {/* Optionally show the ID above the user's mesh */}
            <Text
                position={[0, 1.0, 0]}
                color="black"
                anchorX="center"
                anchorY="middle"
            >
                {id}
            </Text>
        </mesh>
    )
}

export default function R3FGame(props) {
    const auth = useSelector(state => state.authReducer);
    const { firstName } = auth

    let { clients, loginId, addChannelChat } = props;
    function sendMessage(messageData) {
        if(messageData) {
            addChannelChat(JSON.stringify(messageData))
        }
    }
    return <Canvas camera={{ position: [0, 1, -5], near: 0.1, far: 1000 }}>
        {/* <Stats /> */}
        <ControlsWrapper id={loginId} sendMessage={sendMessage} firstName={firstName}/>
        <gridHelper rotation={[0, 0, 0]} />

        {/* Filter myself from the client list and create user boxes with IDs */}
        {Object.keys(clients)
            .map((client) => {
                const { position, rotation, firstName } = clients[client]
                return (
                    <UserWrapper
                        key={client}
                        id={firstName ? firstName : client}
                        position={position}
                        rotation={rotation}
                    />
                )
            })}
    </Canvas>
}