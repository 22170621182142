import { SET_LANG, RESET_LANG, SET_MULTIPLE_LANG } from '../constants/actionTypes';

const initialState = {
  languageOptions: []
};

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANG: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_LANG: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_LANG: {
      return {
        ...state,
        languageOptions: []
      };
    }
    default: {
      return state;
    }
  }
};

export default languageReducer;
