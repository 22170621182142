import {
  SET_SERVICE_TYPE,
  SET_MULTIPLE_SERVICE_TYPE,
  RESET_SERVICE_TYPE
} from '../constants/actionTypes';

const initialState = {
  userId: "",
  companyId: "",
  addModal: false,
  name: "",
  refresh: true,
  editId: "",
  errors: {
    name: ""
  },
  editTextChanges: false,
  editResponseData: {},
  loader: false,
  levelOfPriority: "low"
};

const serviceTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SERVICE_TYPE: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_SERVICE_TYPE: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_SERVICE_TYPE: {
      return {
        ...state,
        userId: "",
        companyId: "",
        addModal: false,
        name: "",
        refresh: true,
        editId: "",
        errors: {
          name: ""
        },
        editTextChanges: false,
        editResponseData: {},
        loader: false,
        levelOfPriority: "low"
      };
    }
    default: {
      return state;
    }
  }
};

export default serviceTypeReducer;
