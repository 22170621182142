import { useEffect, useState } from "react";
import GameChat from "./GameChat";
import GameTransfer from "./GameTransfer";
import { useParams } from "react-router-dom";

export default function GameSetup(props) {
    let { cIdListRef, channelResponseList } = props;
    let { channelId, gameChatChannelId } = useParams()

    let [gamePollChannel, setGamePollChannel] = useState({});
    let [pollChannel, setPollChannel] = useState({});
    useEffect(() => {
        if(channelResponseList[channelId]) {
            //Game channel
            setGamePollChannel(JSON.parse(channelResponseList[channelId]))
        }

        if(channelResponseList[gameChatChannelId]) {
            //Game chat channel
            setPollChannel(JSON.parse(channelResponseList[gameChatChannelId]))
        }
    }, [channelResponseList])
    
    useEffect(() => {
        if(channelId && gameChatChannelId) {
            let cIdList = [channelId, gameChatChannelId] 
            cIdListRef.current = cIdList
        }

        return () => {
            cIdListRef.current = []
        };
    }, [channelId, gameChatChannelId])

    
    return <div className="game-view">
        <div className="game">
            <GameTransfer
                pollChannel={gamePollChannel}
                channelId={channelId}
                {...props}
            />
        </div>
        <div className="chat">
            <GameChat
                pollChannel={pollChannel}
                channelId={gameChatChannelId}
                {...props}
            />
        </div>
    </div>
}
