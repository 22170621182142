import {
  SET_DONATION,
  SET_MULTIPLE_DONATION,
  RESET_DONATION
} from '../constants/actionTypes';

const initialState = {
  selectedLanguage: "esp",
  donationActiveId: "",
  donationInventoryActiveId: ""
};

const donationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DONATION: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_DONATION: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_DONATION: {
      return {
        ...state,
        selectedLanguage: "esp",
        donationActiveId: "",
        donationInventoryActiveId: ""
      };
    }
    default: {
      return state;
    }
  }
};

export default donationReducer;
