import {
  SET_EXPENSE_REPORT,
  SET_MULTIPLE_EXPENSE_REPORT,
  RESET_EXPENSE_REPORT
} from '../constants/actionTypes';


var d = new Date();
d.setDate(d.getDate() - 7);
let ds = new Date()
let yesterday = d;

const initialState = {
  userId: "",
  companyId: "",
  companyOwnerId: "",
  sDate: yesterday,
  eDate: ds,
  report: [],
  expenseDetails: {},
  expenseDetailsIndex: "",
  expenseDetailsIndex: false,
  userId: "",
  companyId: "",
  companyOwnerId: "",
  userListOption: [],
  selectedUser: "",
  expenseDetailsModal: false,
  searchLoader: false,
};

const expenseReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EXPENSE_REPORT: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_EXPENSE_REPORT: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_EXPENSE_REPORT: {
      return {
        ...state,
        userId: "",
        companyId: "",
        companyOwnerId: "",
        sDate: yesterday,
        eDate: ds,
        report: [],
        expenseDetails: {},
        expenseDetailsIndex: "",
        expenseDetailsIndex: false,
        userId: "",
        companyId: "",
        companyOwnerId: "",
        userListOption: [],
        selectedUser: "",
        expenseDetailsModal: false,
        searchLoader: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default expenseReportReducer;
