import DocsWrite from "./DocsWrite";
import Notes from "./Notes/Notes";
import ProjectDiagram from "./ProjectDiagram";
import SheetsWrite from "./sheets/SheetsWrite";
import TeqDrawWrite from "./teqdraw/TeqDrawWrite";

function PinnedTab(props) {

    function returnTab() {
        let tab;

        switch (props.pinTabInfo.type) {
            case "docs":
                tab = <DocsWrite pinTabInfo={props.pinTabInfo} setPinTabInfo={props.setPinTabInfo} setActiveDocsId={props.setActiveDocsId} searchGrpcClient={props.searchGrpcClient} path={props.path} docsGrpcPollingClient={props.docsGrpcPollingClient} docsGrpcClient={props.docsGrpcClient} grpcClient={props.grpcClient} history={props.history} />
                break;
            case "sheets":
                tab = <SheetsWrite pinTabInfo={props.pinTabInfo} setPinTabInfo={props.setPinTabInfo} setActiveSheetId={props.setActiveSheetId} searchGrpcClient={props.searchGrpcClient} path={props.path} docsGrpcPollingClient={props.docsGrpcPollingClient} docsGrpcClient={props.docsGrpcClient} grpcClient={props.grpcClient} history={props.history} />
                break;
            case "teqdraw":
                tab = <TeqDrawWrite pinTabInfo={props.pinTabInfo} setPinTabInfo={props.setPinTabInfo} setActiveDrawId={props.setActiveDrawId} searchGrpcClient={props.searchGrpcClient} path={props.path} docsGrpcPollingClient={props.docsGrpcPollingClient} docsGrpcClient={props.docsGrpcClient} grpcClient={props.grpcClient} history={props.history} />
                break;
            case "project-diagram":
                tab = <ProjectDiagram pinTabInfo={props.pinTabInfo} setPinTabInfo={props.setPinTabInfo} path={props.path} searchGrpcClient={props.searchGrpcClient} grpcClient={props.grpcClient} history={props.history} companyType={props.companyType} languagePreference={props.languagePreference} contextHelp={props.contextHelp} setContextHelp={props.setContextHelp} />
                break;
            case "notes":
                tab = <Notes
                    pinTabInfo={props.pinTabInfo}
                    setPinTabInfo={props.setPinTabInfo}
                    notesTeqitAiEnable={props.notesTeqitAiEnable}
                    notesChannelUpdated={props.notesChannelUpdated}
                    grpcClient={props.grpcClient}
                    history={props.history}
                    companyType={props.companyType}
                    languagePreference={props.languagePreference}
                    contextHelp={props.contextHelp}
                    setContextHelp={props.setContextHelp}
                    setShareMessage={props.setShareMessage}
                    path={props.path}
                    fromPinned={true}
                />
                break;
        }
        return tab;
    }

    return <div className="pinned-tab">
        {
            returnTab()
        }
    </div>;
}

export default PinnedTab