import React, { createRef } from "react";

class DoubleScrollbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: "auto"
    };

    this.boundCalculateWidth = this.calculateWidth.bind(this);
    this.outerDivRef = createRef(null);
    this.innerDivRef = createRef(null);
    this.childrenWrapperRef = createRef(null);
  }

  componentDidMount() {
    // Set initial width
    this.calculateWidth();

    // Update width when window size changes
    window.addEventListener("resize", this.boundCalculateWidth);

    let _this = this;
    // assoc the scrolls
    this.outerDivRef.current.onscroll = function () {
      _this.childrenWrapperRef.current.scrollLeft = _this.outerDivRef.current.scrollLeft;
    };

    this.childrenWrapperRef.current.onscroll = function () {
      _this.outerDivRef.current.scrollLeft = _this.childrenWrapperRef.current.scrollLeft;
    };
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.boundCalculateWidth);
  }

  componentDidUpdate() {
    this.calculateWidth();
  }

  calculateWidth() {

    let width = this.getChildWrapperWidth();

    if (width == null) {
      width = "auto";
    }

    // Set the width of the inner div to the first child's
    if (width !== this.state.width) {
      this.setState({
        width: width
      });
    }
  }

  getChildWrapperWidth() {
    let width = null;
    if (this.childrenWrapperRef && this.childrenWrapperRef.current.scrollWidth && this.childrenWrapperRef.current.scrollWidth > this.childrenWrapperRef.current.clientWidth) {
      width = this.childrenWrapperRef.current.scrollWidth + "px"
    }
    return width;
  }

  render() {

    let { width } = this.state
    let outerDivStyle = { overflowX: "auto", overflowY: "hidden" };
    let innerDivStyle = { paddingTop: "1px", width: this.state.width, height: 10 };
    let childDivStyle = { overflow: "auto" };

    return (
      <div>
        <div ref={this.outerDivRef} style={outerDivStyle}>
          <div ref={this.innerDivRef} style={innerDivStyle}>{width !== "auto" ? <React.Fragment>&nbsp;</React.Fragment> : ""}</div>
        </div>
        <div ref={this.childrenWrapperRef} className="childrenWrapper" style={childDivStyle} >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default DoubleScrollbar;