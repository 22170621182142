import { SET_GUEST_AUTH, RESET_GUEST_AUTH, SET_MULTIPLE_GUEST_AUTH } from '../constants/actionTypes';

const initialState = {
  isLoggedIn: '0',
  loginId: "",
  firstName: "",
  lastName: "",
  profilePhoto: "",
  sessionId: "",
  loggedEmail: "",
  userThemeColor: "",
  languagePreference: "",
  deviceToken: "",
  activeCompany: "",
  activeCompanyName: "",
  activeCompanyColor: "",
  activeCompanyDetails: "",
  activeCompanyLogo: "",
  activeMenuLayout: "",
  activeUserType: "",
  feedId: "",
  steppedAwayStatus: "available",
  systemLevelRole: "member",
  activeStatus: "",
  hoursSelected: 12,
  controlFeature: {
    audioEnable: true,
    videoEnable: true,
    qatEnable: true,
    expensesEnable: true,
    orgFeedbackEnable: true,
    vsCodeEnable: true,
    teqreqEnable: true,
    dailyReportEnable: true,
  },
};

const guestAuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GUEST_AUTH: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_GUEST_AUTH: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_GUEST_AUTH: {
      return {
        ...state,
        isLoggedIn: '0',
        loginId: "",
        firstName: "",
        lastName: "",
        profilePhoto: "",
        sessionId: "",
        loggedEmail: "",
        userThemeColor: "",
        languagePreference: "",
        deviceToken: "",
        activeCompany: "",
        activeCompanyName: "",
        activeCompanyColor: "",
        activeCompanyDetails: "",
        activeCompanyLogo: "",
        activeMenuLayout: "",
        activeUserType: "",
        feedId: "",
        steppedAwayStatus: "available",
        systemLevelRole: "member",
        activeStatus: "",
        hoursSelected: 12,
        controlFeature: {
          audioEnable: true,
          videoEnable: true,
          qatEnable: true,
          expensesEnable: true,
          orgFeedbackEnable: true,
          vsCodeEnable: true,
          teqreqEnable: true,
          dailyReportEnable: true,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default guestAuthReducer;
