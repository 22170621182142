import {
  SET_CHAT_CHANNEL,
  SET_MULTIPLE_CHAT_CHANNEL,
  RESET_CHAT_CHANNEL
} from '../constants/actionTypes';

const initialState = {
  channelList: [],
  dmList: [],
  taskChannelList: [],
  channelListFetched: false,
  dmListFetched: false,
  taskChannelListFetched: false
};
const chatChannelReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHAT_CHANNEL: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_CHAT_CHANNEL: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_CHAT_CHANNEL: {
      return {};
    }
    default: {
      return state;
    }
  }
};

export default chatChannelReducer;
