import {
  SET_ENTRY_REPORT,
  SET_MULTIPLE_ENTRY_REPORT,
  RESET_ENTRY_REPORT
} from '../constants/actionTypes';


var d = new Date();
d.setDate(d.getDate() - 7);
let ds = new Date()
let yesterday = d;

const initialState = {
  userId: "",
  companyId: "",
  companyOwnerId: "",
  sDate: yesterday,
  eDate: ds,
  report: [],
  entryDetails: {},
  entryDetailsIndex: "",
  entryDetailsModal: false,
  userListOption: [],
  selectedUser: "",
  placeholderSelect: "",
  projectOptions: [],
  selectedProject: "",
  projectListOptions: [],
  searchLoader: false,
};

const entryReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ENTRY_REPORT: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_ENTRY_REPORT: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_ENTRY_REPORT: {
      return {
        ...state,
        userId: "",
        companyId: "",
        companyOwnerId: "",
        sDate: new Date(),
        eDate: new Date(),
        report: [],
        entryDetails: {},
        entryDetailsIndex: "",
        entryDetailsModal: false,
        userListOption: [],
        selectedUser: "",
        placeholderSelect: "",
        projectOptions: [],
        selectedProject: "",
        projectListOptions: [],
        searchLoader: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default entryReportReducer;
