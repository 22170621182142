import { SET_LOGIN, RESET_LOGIN } from '../constants/actionTypes';

const initialState = {
  email: "",
  password: "",
  passwordVisible: false,
  errors: {
    email: "",
    password: ""
  },
  loginLoader: false
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case RESET_LOGIN: {
      return {
        ...state,
        email: "",
        password: "",
        passwordVisible: false,
        errors: {
          email: "",
          password: ""
        },
        loginLoader: false
      };
    }
    default: {
      return state;
    }
  }
};

export default loginReducer;
