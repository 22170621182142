import { REMOVE_DAILY_REPORT_ORG, REMOVE_DAILY_REPORT_ORG_DATA, RESET_DAILY_REPORT_ORG, SET_DAILY_REPORT_ORG, SET_MULTIPLE_DAILY_REPORT_ORG } from '../constants/actionTypes';


let date = new Date();
var getMonth = (date.getMonth() + 1).toString().padStart(2, "0");
var currentDate = date.getFullYear() + "-" + getMonth + "-" + date.getDate().toString().padStart(2, "0");
const initialState = {
    currentDate,
    data: {}
};

const dailyReportOrgReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DAILY_REPORT_ORG: {
            return {
                ...state,
                [action.key]: action.value,
            };
        }
        case SET_MULTIPLE_DAILY_REPORT_ORG: {
            if (action.date) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        [action.date] : {
                            ...state.data[action.date],
                            ...action.data
                          }
                    }
                }
            }
        }
        case REMOVE_DAILY_REPORT_ORG: {
            if (action.date) {
                const next = {...state}
                delete next.data[action.date]
                return next
            }
        }
        case REMOVE_DAILY_REPORT_ORG_DATA: {
            if (action.date && action.key) {
                const next = {...state}
                if(next.data[action.date] && next.data[action.date][action.key]) {
                    delete next.data[action.date][action.key]
                }
                return next
            }
        }
        case RESET_DAILY_REPORT_ORG: {
            return {
                ...state,
                currentDate,
                data: {}
            };
        }
        default: {
            return state;
        }
    }
};

export default dailyReportOrgReducer;
