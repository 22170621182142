import { RESET_WORKSPACE, SET_WORKSPACE, SET_MULTIPLE_WORKSPACE, REMOVE_WORKSPACE } from '../constants/actionTypes';

let date = new Date();
var getMonth = (date.getMonth() + 1).toString().padStart(2, "0");
var currentDate = date.getFullYear() + "-" + getMonth + "-" + date.getDate().toString().padStart(2, "0");

const initialState = {
    currentDate,
    data: {}
};

const workspaceReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_WORKSPACE: {
            return {
                ...state,
                [action.key]: action.value,
            };
        }
        case SET_MULTIPLE_WORKSPACE: {
            if (action.date) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        [action.date] : {
                            ...state.data[action.date],
                            ...action.data
                          }
                    }
                }
            }
        }
        case REMOVE_WORKSPACE: {
            if (action.date) {
                const next = {...state}
                delete next.data[action.date]
                return next
            }
        }
        case RESET_WORKSPACE: {
            return {
                ...state,
                currentDate,
                data: {}
            };
        }
        default: {
            return state;
        }
    }
};

export default workspaceReducer;
