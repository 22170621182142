import MyConfig from '../../config/MyConfig';
import {
  SET_CLOUD_PROVIDER_USER_MAP,
  SET_MULTIPLE_CLOUD_PROVIDER_USER_MAP,
  RESET_CLOUD_PROVIDER_USER_MAP
} from '../constants/actionTypes';

const initialState = {
  menuActive: "",
  action: "Add",
  cloud_Provider_Name: "",
  cloud_Provider__Validate: "",
  processing: false,
  prev: false,
  next: false,
  noPagesLimit: 1,
  pageLimit: 10,
  cloudMappingUserList_Json: {},
  pagination: [],
  visibleClass: "",
  totalResultsCount: 0,
  mUserId: "",
  session: "",
  cloudProviderOption: [],
  cloudProviderSelect: "",
  cloudProviderId: "",
  ipAdressArray: [{ ipAddress: "", publicKey: "" },],
  companyOptions: [],
  companySelect: "",
  companySelectId: "",
  userSelectOptions: [],
  userSelect: "",
  userSelectId: "",
  ipAddressOption: [],
  ipAddressSelect: "",
  ipAddressId: "",
  errors: {},
  overAllData: {},
  programLanguageData: MyConfig.environmentData,
  checkedValue: [],
  wireguardDetails: {},
  wgCardStatusInfo: {},
  wgCardModal: false,
  wgCheckBox: false,
  cloudMapIPList_Json: {},
  companyId: "",
  userId: "",
  checkPasswordResponse: false,
  askPassworModal: false,
  passwordText: "",
  vsCodeLoader: false,
  showButton: false,
};

const cloudProviderUserMapReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CLOUD_PROVIDER_USER_MAP: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_CLOUD_PROVIDER_USER_MAP: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_CLOUD_PROVIDER_USER_MAP: {
      return {
        ...state,
        menuActive: "",
        action: "Add",
        cloud_Provider_Name: "",
        cloud_Provider__Validate: "",
        processing: false,
        prev: false,
        next: false,
        noPagesLimit: 1,
        pageLimit: 10,
        cloudMappingUserList_Json: {},
        pagination: [],
        visibleClass: "",
        totalResultsCount: 0,
        mUserId: "",
        session: "",
        cloudProviderOption: [],
        cloudProviderSelect: "",
        cloudProviderId: "",
        ipAdressArray: [{ ipAddress: "", publicKey: "" },],
        companyOptions: [],
        companySelect: "",
        companySelectId: "",
        userSelectOptions: [],
        userSelect: "",
        userSelectId: "",
        ipAddressOption: [],
        ipAddressSelect: "",
        ipAddressId: "",
        errors: {},
        overAllData: {},
        programLanguageData: MyConfig.environmentData,
        checkedValue: [],
        wireguardDetails: {},
        wgCardStatusInfo: {},
        wgCardModal: false,
        wgCheckBox: false,
        cloudMapIPList_Json: {},
        companyId: "",
        userId: "",
        checkPasswordResponse: false,
        askPassworModal: false,
        passwordText: "",
        vsCodeLoader: false,
        showButton: false
      };
    }
    default: {
      return state;
    }
  }
};

export default cloudProviderUserMapReducer;
