import {
  SET_HIDDEN_CHANNELS,
  SET_MULTIPLE_HIDDEN_CHANNELS,
  RESET_HIDDEN_CHANNELS
} from '../constants/actionTypes';

const initialState = {
  hiddenChannelArr : [],
};

const hiddenChannelsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HIDDEN_CHANNELS: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_HIDDEN_CHANNELS: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_HIDDEN_CHANNELS: {
      return {
        ...state,
        hiddenChannelArr : [],
      };
    }
    default: {
      return state;
    }
  }
};

export default hiddenChannelsReducer;
