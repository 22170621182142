import React, { useState, useEffect } from "react";
import { getDateWithFormat, getGrpcClient, getUuidv4, searchDropdownOptionConvert } from "../../../config/Common";
import { useSelector } from "react-redux";
// import MyConstant from "../../../config/MyConstant";
// import { ZiFetchRequest } from "../../../protobuf/generated/ZiFetchService_grpc_web_pb";
// import GlobalMsgCons from "../../../config/GlobalMsgCons";
// import GlobalDomainCons from "../../../config/GlobalDomainCons";
// import GlobalEventCons from "../../../config/GlobalEventCons";
import DropDown from "../../../components/DropDown";
// import Loader from "../../../components/Loader";
import { ToastContainer, toast } from 'react-toastify';
import Modal from "../../../components/Modal";
import GlobalSearchDataTypes from "../../../config/GlobalSearchDataTypes";
import SearchDropdown from '../../../components/SearchDropdown';
import PaginationDatatable from "../../../components/PaginationDatatable";
import MigrantForm from "./MigrantForm";
import CustomDatePicker from "../../../components/CustomDatePicker";
import GlobalMsgCons from "../../../config/GlobalMsgCons";




const therapyList = [
    { label: "individual", value: "individual" },
    { label: "family", value: "family" },
    { label: "group", value: "group" },
    { label: "other", value: "other" }
]
const diseaseList = [
    { label: "symptoms of depression", value: "symptomsofdepression" },
    { label: "symptoms of anxiety", value: "symptomsofanxiety" },
    { label: "symptoms of postraumatic stress", value: "symptomsofpostraumaticstress" },
    { label: "psychosocial distress", value: "psychosocialdistress" },
    { label: "interpersonal challenges", value: "interpersonalchallenges" }
]

const clientPresented = [
    { label: "appearance WNL (within normal limits)", value: "appearanceWNLwithinnormallimits" },
    { label: "thoughts (content and processes) WNL", value: "thoughts(content and processes)WNL" },
    { label: "insight/judgment WNL", value: "insight/judgmentWNL" },
    { label: "attention/memory WNL", value: "attention/memoryWNL" },
    { label: "mood/affect WNL", value: "mood/affectWNL" }
]

const suicideRiskConfirmOptions = [
    { label: "was", value: "was" },
    { label: "was not", value: "wasnot" },
]
const suicideRiskOptions = [
    { label: "no intervention was indicated", value: "nointerventionwasindicated" },
    { label: "safety plan was created", value: "safetyplanwascreated" },
    { label: "safety plan was reviewed", value: "safetyplanwasreviewed" },
    { label: "resources were given", value: "resourcesweregiven" },
]

const interventionOptions = [
    { label: "supportive psychotherapy", value: "supportivepsychotherapy" },
    { label: "risk management", value: "riskmanagement" },
    { label: "coping skills training", value: "copingskillstraining" }
]
const clientdataWasGatheredOptions = [
    { label: "vitals", value: "vitals" },
    { label: "labs", value: "labs" },
    { label: "heart rate", value: "heartrate" },
    { label: "blood pressure", value: "bloodpressure" },
    { label: "temperature", value: "temperature" },
    { label: "breathing", value: "breathing" },
]

const clientparticipateOptions = [
    { label: "was", value: "was" },
    { label: "was not", value: "was not" },
]

function MigrantList(props) {
    const auth = useSelector(state => state.authReducer);
    let [therapySelected, setTherapySelected] = useState("");
    let [clientReportedExperiencingSelected, setClientReportedExperiencingSelected] = useState("");
    let [clientReportedBriefQuote, setClientReportedBriefQuote] = useState("");
    let [therapistName, setTherapistName] = useState("");
    let [therapistCredential, setTherapistCredential] = useState("");
    let [therapistAppointedDate, setTherapistAppointedDate] = useState("");
    let [therapistOrgName, setTherapistOrgName] = useState("");
    let [therapistOrgCity, setTherapistOrgCity] = useState("");
    let [therapistOrgCountry, setTherapistOrgCountry] = useState("");
    let [clientPresentMentalHealthSelected, setClientPresentMentalHealthSelected] = useState([]);
    let [therapistNotes, setTherapistNotes] = useState("");
    let [suicideRiskConfirmSelected, setSuicideRiskConfirmSelected] = useState("");
    let [suicideRiskSelected, setSuicideRiskSelected] = useState("");
    let [interventionSelected, setInterventionSelected] = useState("");
    let [therapistInterventionText, setTherapistInterventionText] = useState("");

    let [clientActivelyChecked, setClientActivelyChecked] = useState("");
    let [clientActivelyText, setClientActivelyText] = useState("");
    let [clientReportedChecked, setClientReportedChecked] = useState("");
    let [clientReportedText, setClientReportedText] = useState("");
    let [clientDemonstratedChecked, setClientDemonstratedChecked] = useState("");
    let [clientDemonstratedText, setClientDemonstratedText] = useState("");
    let [clientEngagedChecked, setClientEngagedChecked] = useState("");
    let [clientEngagedText, setClientEngaged] = useState("");
    let [clientProviderText, setClientProviderText] = useState("");
    let [clientProviderDate, setClientProviderDate] = useState("");
    let [recommendationsNextStepText, setRecommendationsNextStepText] = useState("");
    let [activeTab, setActiveTab] = useState("mentalhealth");
    let [doctorName, setDoctorName] = useState("");
    let [doctorCredential, setDoctorCredential] = useState("");
    let [doctorTreatmentText, setDoctorTreatmentText] = useState("");
    let [doctorOrgName, setDoctorOrgName] = useState("");
    let [doctorOrgCity, setDoctorOrgCity] = useState("");
    let [doctorCountry, setDoctorCountry] = useState("");
    let [doctorScheduleDate, setDoctorScheduleDate] = useState("");

    let [clientReportedSubjectText, setClientReportedSubjectText] = useState("");
    let [clientReportedSubjectBeganText, setClientReportedSubjectBeganText] = useState("");
    let [relevantMedicalText, setRelevantMedicalText] = useState("");
    let [clientIscurrentlyTakingMedications, setClientIscurrentlyTakingMedications] = useState("");

    let [clientExhibitedText, setClientExhibitedText] = useState("");
    let [physicianObservedText, setPhysicianObservedText] = useState("");
    let [clientparticipateSelected, setClientparticipateSelected] = useState("");
    let [clientDataWasGatheredChecked, setClientDataWasGatheredChecked] = useState([]);
    let [clientDiagnosisText, setClientDiagnosisText] = useState("");
    let [clientPrecipitatingFactorsText, setClientPrecipitatingFactorsText] = useState("");
    let [clientPerpetuatingFactorsText, setClientPerpetuatingFactorsText] = useState("");
    let [clientProtectiveFactorsText, setClientProtectiveFactorsText] = useState("");
    let [clientProblemsAddressedText, setClientProblemsAddressedText] = useState("");

    let [planPhysicianRecommendsText, setPlanPhysicianRecommendsText] = useState("");
    let [planPhysicianRequestsText, setPlanPhysicianRequestsText] = useState("");
    let [planNextStepsTreatmentText, setPlanNextStepsTreatmentText] = useState("");

    let [migrantUserSelected, setMigrantUserSelected] = useState("");
    let [getText, setGetText] = useState("");
    let [mentalHelathtableDataArry, setMentalHelathtableDataArry] = useState([]);
    let [physicaltableDataArray, setphysicaltableDataArray] = useState([]);
    let [tableRefresh, setTableRefresh] = useState(false);
    let [viewPageType, setViewPageType] = useState("list");
    let [previewModal, setPreviewModal] = useState(false);
    let [errors, setErrors] = useState({});
    let [rowData, setRowData] = useState({});



    //let paramsData = props.appsData;

    useEffect(() => {
        mentalHelathtableDataArry = [
            {
                column: <div className="text-center">Data</div>,
                cell: (row) => {
                    return <div>
                        {GenRowText(JSON.parse(row.data), true)}
                    </div>
                },
                tooltip: "Data",
                id: "Data"
            },
            {
                column: <div className="text-center">View</div>,
                cell: (row, key) => {
                    return <div className="text-center">
                        <button className="btn btn-primary" onClick={() => {
                            MentalHealthPrevieStateUpdate(JSON.parse(row.data))
                            setViewPageType("mentalHealthPreview")
                            setRowData(row)
                        }}>
                            View
                        </button>
                    </div>
                },
                tooltip: "View",
                id: "View"
            },
            {
                column: <div className="text-center">CreatedAt</div>,
                cell: (row) => {
                    return <div className="text-center">
                        {getDateWithFormat(row.createdAt, auth.activeCompanyDetails)}
                    </div>
                },
                tooltip: "CreatedAt",
                id: "createdAt"
            },
        ]


        physicaltableDataArray = [

            {
                column: <div className="text-center">View</div>,
                cell: (row, key) => {
                    return <div className="text-center">
                        <button className="btn btn-primary" onClick={() => {
                            physicalHealthPrevieStateUpdate(JSON.parse(row.data))
                            setViewPageType("physicalhealthPreview")
                            setRowData(row)
                        }}>
                            View
                        </button>
                    </div>
                },
                tooltip: "View",
                id: "View"
            },
            {
                column: <div className="text-center">CreatedAt</div>,
                cell: (row) => {
                    return <div className="text-center">
                        {getDateWithFormat(row.createdAt, auth.activeCompanyDetails)}
                    </div>
                },
                tooltip: "CreatedAt",
                id: "createdAt"
            },
        ]
        setMentalHelathtableDataArry(mentalHelathtableDataArry)
        setphysicaltableDataArray(physicaltableDataArray)
    }, [])


    useEffect(() => {
        setTableRefresh(!tableRefresh)
    }, [mentalHelathtableDataArry, migrantUserSelected])

    function MentalHealthPrevieStateUpdate(data) {

        setTherapySelected(data.data.therapyType)
        setTherapistName(data.data.therapistName)
        setTherapistCredential(data.data.therapistCredential)
        setTherapistOrgName(data.data.therapistOrgName)
        setTherapistOrgCity(data.data.therapistOrgCity)
        setTherapistOrgCountry(data.data.therapistOrgCountry)
        setTherapistAppointedDate(data.data.therapistAppointedDate)
        setClientReportedExperiencingSelected(data.data.clientReportedExperiencing)
        setClientReportedBriefQuote(data.data.clientReportedBriefQuote)
        setClientPresentMentalHealthSelected(data.data.clientPresentMentalHealthChecked)
        setTherapistNotes(data.data.clientPresentMentalHealthTherapistNotes)
        setSuicideRiskConfirmSelected(data.data.suicideRiskConfirm)
        setSuicideRiskSelected(data.data.suicideRisk)

        setInterventionSelected(data.intervention.TherapistProvided)
        setTherapistInterventionText(data.intervention.TherapistProvidedContent)

        setClientActivelyText(data.response.clientActively)
        if (data.response.clientActively) {
            setClientActivelyChecked(true)
        } else {
            setClientActivelyChecked(false)
        }
        setClientReportedText(data.response.clientReported)
        if (data.response.clientReported) {
            setClientReportedChecked(true)
        }
        else {
            setClientReportedChecked(false)
        }
        setClientDemonstratedText(data.response.clientDemonstrated)
        if (data.response.clientDemonstrated) {
            setClientDemonstratedChecked(true)
        }
        else {
            setClientDemonstratedChecked(false)
        }
        setClientEngaged(data.response.clientEngaged)
        if (data.response.clientEngaged) {
            setClientEngagedChecked(true)
        }
        else {
            setClientEngagedChecked(false)
        }
        setClientProviderText(data.plan.clientProviderText)
        setClientProviderDate(data.plan.clientProviderDate)

    }


    function physicalHealthPrevieStateUpdate(data) {

        setDoctorTreatmentText(data.patientReceived.doctorTreatment)
        setDoctorName(data.patientReceived.doctorName)
        setDoctorCredential(data.patientReceived.doctorCredential)
        setDoctorOrgName(data.patientReceived.doctorOrgName)
        setDoctorOrgCity(data.patientReceived.doctorOrgCity)
        setDoctorCountry(data.patientReceived.doctorCountry)
        setDoctorScheduleDate(data.patientReceived.doctorScheduleDate)

        setClientReportedSubjectText(data.subjective.clientReportedSubject)
        setClientReportedSubjectBeganText(data.subjective.clientReportedSubjectBegan)
        setRelevantMedicalText(data.subjective.relevantMedical)
        setClientIscurrentlyTakingMedications(data.subjective.clientIscurrentlyTakingMedications)

        setClientExhibitedText(data.objective.clientExhibited)
        setPhysicianObservedText(data.objective.physicianObserved)
        setClientparticipateSelected(data.objective.clientparticipate)
        setClientDataWasGatheredChecked(data.objective.clientDataWasGatheredChecked)

        setClientDiagnosisText(data.assessment.clientDiagnosis)
        setClientPrecipitatingFactorsText(data.assessment.clientPrecipitatingFactors)
        setClientPerpetuatingFactorsText(data.assessment.clientPerpetuatingFactors)
        setClientProtectiveFactorsText(data.assessment.clientProtectiveFactors)
        setClientProblemsAddressedText(data.assessment.clientProblemsAddressed)

        setPlanPhysicianRecommendsText(data.plan.planPhysicianRecommends)
        setPlanPhysicianRequestsText(data.plan.planPhysicianRequests)
        setPlanNextStepsTreatmentText(data.plan.planNextStepsTreatment)



    }


    function GenRowText(data, showMore) {
        let [suicideFilter] = suicideRiskConfirmOptions.filter(fe => fe.value == data.data.suicideRiskConfirm)
        let [suicideRiskFilter] = suicideRiskOptions.filter(fe => fe.value == data.data.suicideRisk)
        let [interventionFilter] = interventionOptions.filter(fe => fe.value == data.intervention.TherapistProvided)

        return <>
            <div className={`d-flex flex-wrap justify-content-start my-2 ${showMore ? "hideContent" : ""}`}>
                <div>
                    Client received {data.data.therapyType} from {data.data.therapistName}, {data.data.therapistCredential}. at {data.data.therapistOrgName} located in {data.data.therapistOrgCity}, {data.data.therapistOrgCountry} on {data.data.therapistAppointedDate}. Client reported that they are experiencing {data.data.clientReportedExperiencing} and are seeking care to {data.data.clientReportedBriefQuote}. Client presented as follows:
                </div>
                {Array.isArray(data.data.clientPresentMentalHealthChecked) && data.data.clientPresentMentalHealthChecked.map((map) => {
                    let [filterText] = clientPresented.filter(fe => fe.value == map)
                    return <div key={map}>
                        {filterText.label}
                    </div>
                })}
                <div>
                    Therapist noticed {data.data.clientPresentMentalHealthTherapistNotes}. Suicide risk  {suicideFilter ? suicideFilter.label : ""}assessed,
                </div>
                <div>
                    {suicideRiskFilter ? suicideRiskFilter.label : ""} . Therapist provided {interventionFilter ? interventionFilter.label : ""} focus on {data.intervention.TherapistProvidedContent}. Client actively participated in discussion on {clientActivelyText}. Client engaged in {data.response.clientEngaged} activity. Client will attend the next session on {data.plan.clientProviderText} if possible. Recommendations for next steps include {data.plan.clientProviderDate}.
                </div>
            </div>
            {showMore ? <div class="show-more">
                <a onClick={() => {
                    MentalHealthPrevieStateUpdate(data)
                    setPreviewModal(true)

                }} className="cursor-pointer">Show more</a>
            </div> : null}

        </>
    }



    function GenText() {
        let [suicideFilter] = suicideRiskConfirmOptions.filter(fe => fe.value == suicideRiskConfirmSelected)
        let [suicideRiskFilter] = suicideRiskOptions.filter(fe => fe.value == suicideRiskSelected)
        let [interventionFilter] = interventionOptions.filter(fe => fe.value == interventionSelected)

        return <>
            <div className={`d-flex flex-wrap justify-content-start my-2`}>
                <div>
                    Client received {therapySelected} from {therapistName}, {therapistCredential}. at {therapistOrgName} located in {therapistOrgCity}, {therapistOrgCountry} on {therapistAppointedDate}. Client reported that they are experiencing {clientReportedExperiencingSelected} and are seeking care to {clientReportedBriefQuote}. Client presented as follows:
                </div>
                {clientPresentMentalHealthSelected.map((map) => {
                    let [filterText] = clientPresented.filter(fe => fe.value == map)
                    return <div key={map}>
                        {filterText.label}
                    </div>
                })}
                <div>
                    Therapist noticed {therapistNotes}. Suicide risk  {suicideFilter ? suicideFilter.label : ""}assessed,
                </div>
                <div>
                    {suicideRiskFilter ? suicideRiskFilter.label : ""} . Therapist provided {interventionFilter ? interventionFilter.label : ""} focus on {therapistInterventionText}. Client actively participated in discussion on {clientActivelyText}. Client engaged in {clientEngagedText} activity. Client will attend the next session on {clientProviderDate} if possible. Recommendations for next steps include {recommendationsNextStepText}.
                </div>
            </div>


        </>
    }



    function getMigrantList() {
        return <>
            <div className=" container-fluid">
                <div className="d-flex justify-content-center my-3">
                    <div className="tabs-container w-100">
                        <div className="tabs">
                            <div className={`tab ${activeTab === "mentalhealth" ? "active" : "border-0"} `}
                                style={activeTab !== "mentalhealth" ? { backgroundColor: "#dcd7d7" } : {}}
                                onClick={() => {
                                    setActiveTab("mentalhealth")
                                }}>Mental Health Notes</div>
                            <div className={`tab ${activeTab === "physicalhealth" ? "active" : "border-0"} `}
                                style={activeTab !== "physicalhealth" ? { backgroundColor: "#dcd7d7" } : {}}
                                onClick={() => {
                                    setActiveTab("physicalhealth")
                                }}
                            >Physical Health Notes</div>
                        </div>
                        <div className="tabs-body my-2">
                            <div className="d-flex justify-content-end">
                                <button className="btn btn-primary mx-2" onClick={() => {
                                    setViewPageType("form")
                                }}>
                                    Add Form
                                </button>
                            </div>
                            {activeTab === "mentalhealth" ? getTableMentalContent() : getTablePhysicalContent()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    function getPhysicalContent() {
        return <div className="mx-2">
            <div className="row">
                <div className="col-12">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            Patient received
                        </div>
                        <div className="mx-1 my-2">
                            <input disabled className="form-control" placeholder="treatment type" value={doctorTreatmentText} onChange={(e) => {
                                setDoctorTreatmentText(e.target.value)
                            }} />
                        </div>
                        <div className="m-2">
                            from
                        </div>
                        <div className="mx-1 my-2">
                            <input disabled className="form-control" placeholder="doctor/nurse name" value={doctorName} onChange={(e) => {
                                setDoctorName(e.target.value)
                            }} />
                        </div>
                        <div className="mx-1 my-2">
                            <input disabled className="form-control" placeholder="credential" value={doctorCredential} onChange={(e) => {
                                setDoctorCredential(e.target.value)
                            }} />
                        </div>
                        <div className="m-2">
                            at
                        </div>
                        <div className="mx-1 my-2">
                            <input disabled className="form-control" placeholder="org name" value={doctorOrgName} onChange={(e) => {
                                setDoctorOrgName(e.target.value)
                            }} />
                        </div>
                        <div className="m-2">
                            located in
                        </div>
                        <div className="mx-1 my-2">
                            <input disabled className="form-control" placeholder="org city" value={doctorOrgCity} onChange={(e) => {
                                setDoctorOrgCity(e.target.value)
                            }} />
                        </div>
                        <div className="mx-1 my-2">
                            <input disabled className="form-control" placeholder="country" value={doctorCountry} onChange={(e) => {
                                setDoctorCountry(e.target.value)
                            }} />
                        </div>
                        <div className="m-2">
                            on
                        </div>
                        <div className="mx-1 my-2">
                            {typeof (doctorScheduleDate) === "number" ? <CustomDatePicker
                                className={`form-control`}
                                dateFormat="dd/MM/yyyy"
                                selected={doctorScheduleDate}
                                disabled={true}

                            /> : <input disabled className="form-control" placeholder="date" value={doctorScheduleDate} />}

                        </div>
                    </div>
                </div>
            </div>
            <div className="ml-1">
                1. Subjective
            </div>
            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            1. Client reported
                        </div>
                        <div className="mx-1 my-2">
                            <input disabled className="form-control" placeholder="" value={clientReportedSubjectText} onChange={(e) => {
                                setClientReportedSubjectText(e.target.value)
                            }} />
                        </div>
                        <div className="m-2">
                            which began
                        </div>
                        <div className="mx-1 my-2">
                            <input disabled className="form-control" placeholder="" value={clientReportedSubjectBeganText} onChange={(e) => {
                                setClientReportedSubjectBeganText(e.target.value)
                            }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            2. Relevant medical history includes
                        </div>
                        <div className="mx-1 my-2">
                            <input disabled className="form-control" placeholder="" value={relevantMedicalText} onChange={(e) => {
                                setRelevantMedicalText(e.target.value)
                            }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            3. Client is currently taking the following medications
                        </div>
                        <div className="mx-1 my-2">
                            <input disabled className="form-control" placeholder="" value={clientIscurrentlyTakingMedications} onChange={(e) => {
                                setClientIscurrentlyTakingMedications(e.target.value)
                            }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="ml-1">
                2. Objective
            </div>
            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            1. Client exhibited
                        </div>
                        <div className="mx-1 my-2">
                            <input disabled className="form-control" placeholder="" value={clientExhibitedText} onChange={(e) => {
                                setClientExhibitedText(e.target.value)
                            }} />
                        </div>
                        <div className="m-2">
                            symptoms
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            2. Physician observed the following
                        </div>
                        <div className="mx-1 my-2">
                            <input disabled className="form-control" placeholder="" value={physicianObservedText} onChange={(e) => {
                                setPhysicianObservedText(e.target.value)
                            }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            3. sleep, skin, fatigue, fever, headache, visual disturbances, hearing disturbaces, respiratory, cardiovascular, gastrointestinal, gen/touriary, musculoskeletal, extremities, neurological, psychiatric
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            4. Client
                        </div>
                        <div className="mx-1 my-2">
                            <DropDown
                                options={clientparticipateOptions}
                                isSearchable={true}
                                placeholder=""
                                onChange={(e) => {
                                    setClientparticipateSelected(e.value)
                                }}
                                value={clientparticipateOptions.filter(option => option.value === clientparticipateSelected)}
                                isDisabled={true}
                            />
                        </div>
                        <div className="m-2">
                            able to participate fully in their care
                        </div>
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            5. The following data was gathered
                        </div>
                        {
                            clientdataWasGatheredOptions.map((data) => {
                                return <div className="mx-1 my-2" key={data.value}>
                                    <div className="form-check">
                                        <input disabled className="form-check-input" type="checkbox" id={data.value} checked={clientDataWasGatheredChecked.includes(data.value)} onChange={() => {
                                            if (clientDataWasGatheredChecked.includes(data.value)) {
                                                clientDataWasGatheredChecked = clientDataWasGatheredChecked.filter((fe) => {
                                                    return fe !== data.value
                                                })
                                            } else {
                                                clientDataWasGatheredChecked.push(data.value)
                                            }
                                            setClientDataWasGatheredChecked([...clientDataWasGatheredChecked])
                                        }} />
                                        <label className="form-check-label" for={data.value}>
                                            {data.label}
                                        </label>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            Assessment
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 ml-4">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            1. Client has
                        </div>
                        <div className="mx-1 my-2">
                            <input disabled className="form-control" placeholder="diagnosis" value={clientDiagnosisText} onChange={(e) => {
                                setClientDiagnosisText(e.target.value)
                            }} />
                        </div>
                        <div className="m-2">
                            caused by
                        </div>
                        <div className="m-2">
                            <input disabled className="form-control" placeholder="precipitating factors" value={clientPrecipitatingFactorsText} onChange={(e) => {
                                setClientPrecipitatingFactorsText(e.target.value)
                            }} />
                        </div>
                        <div className="m-2">
                            and exacerbated by
                        </div>
                        <div className="m-2">
                            <input disabled className="form-control" placeholder="perpetuating factors" value={clientPerpetuatingFactorsText} onChange={(e) => {
                                setClientPerpetuatingFactorsText(e.target.value)
                            }} />
                        </div>
                        <div className="m-2">
                            <input disabled className="form-control" placeholder="strengths/protective factors" value={clientProtectiveFactorsText} onChange={(e) => {
                                setClientProtectiveFactorsText(e.target.value)
                            }} />
                        </div>
                        <div className="m-2">
                            may mitigate these symptoms
                        </div>
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            2. Problems to be addressed include
                        </div>
                        <div className="mx-1 my-2">
                            <input disabled className="form-control" placeholder="" value={clientProblemsAddressedText} onChange={(e) => {
                                setClientProblemsAddressedText(e.target.value)
                            }} />
                        </div>
                    </div>
                </div>
            </div>


            <div className="ml-1">
                3. Plan
            </div>

            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            1. Physician recommends that client receive information on
                        </div>
                        <div className="mx-1 my-2">
                            <input disabled className="form-control" placeholder="" value={planPhysicianRecommendsText} onChange={(e) => {
                                setPlanPhysicianRecommendsText(e.target.value)
                            }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            2. Physician requests that client complete
                        </div>
                        <div className="mx-1 my-2">
                            <input disabled className="form-control" placeholder="" value={planPhysicianRequestsText} onChange={(e) => {
                                setPlanPhysicianRequestsText(e.target.value)
                            }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            3. Next steps for treatment include
                        </div>
                        <div className="mx-1 my-2">
                            <input disabled className="form-control" placeholder="" value={planNextStepsTreatmentText} onChange={(e) => {
                                setPlanNextStepsTreatmentText(e.target.value)
                            }} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    }

    function getMentalContent() {
        return <div className="mx-2">
            <div className="ml-1">
                1. Data
            </div>
            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            1.Client received
                        </div>
                        <div className="mx-1 my-2">
                            <DropDown
                                options={therapyList}
                                isSearchable={true}
                                placeholder="Select therapy"
                                onChange={(e) => {
                                    setTherapySelected(e.value)
                                }}
                                value={therapyList.filter(option => option.value === therapySelected)}
                                isDisabled={true}
                            />
                        </div>
                        <div className="m-2">
                            from
                        </div>
                        <div className="mx-1 my-2">
                            <input disabled className="form-control" placeholder="Therapist name" value={therapistName} onChange={(e) => {
                                setTherapistName(e.target.value)
                            }} />
                        </div>
                        <div className="mx-1 my-2">
                            <input disabled className="form-control" placeholder="Therapist credential" value={therapistCredential} onChange={(e) => {
                                setTherapistCredential(e.target.value)
                            }} />
                        </div>
                        <div className="m-2">
                            at
                        </div>
                        <div className="mx-1 my-2">
                            <input disabled className="form-control" placeholder="Org name" value={therapistOrgName} onChange={(e) => {
                                setTherapistOrgName(e.target.value)
                            }} />
                        </div>
                        <div className="m-2"> located in </div>
                        <div className="mx-1 my-2">
                            <input disabled className="form-control" placeholder="Org City" value={therapistOrgCity} onChange={(e) => {
                                setTherapistOrgCity(e.target.value)
                            }} />
                        </div>
                        <div className="mx-1 my-2">
                            <input disabled className="form-control" placeholder="Country" value={therapistOrgCountry} onChange={(e) => {
                                setTherapistOrgCountry(e.target.value)
                            }} />
                        </div>
                        <div className="m-2"> on</div>
                        <div className="mx-1 my-2">
                            {typeof (doctorScheduleDate) === "number" ? <CustomDatePicker
                                className={`form-control`}
                                dateFormat="dd/MM/yyyy"
                                dateFormatType="custom"
                                selected={therapistAppointedDate}
                                onChange={(date) => {
                                    setTherapistAppointedDate(date)
                                }}
                                disabled={true}
                            /> : <input disabled className="form-control" placeholder="date" value={therapistAppointedDate} />}
                        </div>
                    </div>
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            2.Client reported that they are experiencing
                        </div>
                        <div className="mx-1 my-2">
                            <DropDown
                                options={diseaseList}
                                isSearchable={true}
                                placeholder="Client reported that they are experiencing"
                                onChange={(e) => {
                                    setClientReportedExperiencingSelected(e.value)
                                }}
                                value={diseaseList.filter(option => option.value === clientReportedExperiencingSelected)}
                                isDisabled={true}
                            />
                        </div>
                        <div className="m-2">
                            and are seeking care to
                        </div>
                        <div className="mx-1 my-2">
                            <textarea type="text" rows="2" cols="20" className={`form-control `} placeholder={"Brief goals quote"} value={clientReportedBriefQuote} onChange={(e) => {
                                setClientReportedBriefQuote(e.target.value)
                            }} />
                        </div>
                    </div>
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            3.Client presented as follows
                        </div>
                        <div className="mx-1 my-2">
                            {
                                clientPresented.map((data) => {
                                    return <div className="form-check" key={data.value}>
                                        <input disabled className="form-check-input" type="checkbox" id={data.value} checked={clientPresentMentalHealthSelected.includes(data.value)} onChange={() => {
                                            if (clientPresentMentalHealthSelected.includes(data.value)) {
                                                clientPresentMentalHealthSelected = clientPresentMentalHealthSelected.filter((fe) => {
                                                    return fe !== data.value
                                                })
                                            } else {
                                                clientPresentMentalHealthSelected.push(data.value)
                                            }
                                            setClientPresentMentalHealthSelected([...clientPresentMentalHealthSelected])
                                        }} />
                                        <label className="form-check-label" for={data.value}>
                                            {data.label}
                                        </label>
                                    </div>
                                })
                            }
                        </div>
                        <div className="mx-1 my-2">
                            Therapist noticed
                        </div>
                        <div className="mx-1 my-2">
                            <textarea type="text" rows="2" cols="20" className={`form-control `} placeholder={"observations if client is not WNL for any of the above]"} value={therapistNotes} onChange={(e) => {
                                setTherapistNotes(e.target.value)
                            }} />
                        </div>
                    </div>
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            4.Suicide risk
                        </div>
                        <div className="mx-1 my-2">
                            <DropDown
                                options={suicideRiskConfirmOptions}
                                isSearchable={true}
                                placeholder="Suicide risk"
                                onChange={(e) => {
                                    setSuicideRiskConfirmSelected(e.value)
                                }}
                                value={suicideRiskConfirmOptions.filter(option => option.value === suicideRiskConfirmSelected)}
                                isDisabled={true}
                            />
                        </div>
                        <div className="m-2">
                            assessed and
                        </div>
                        <div className="mx-1 my-2">
                            <DropDown
                                options={suicideRiskOptions}
                                isSearchable={true}
                                placeholder="assessed"
                                onChange={(e) => {
                                    setSuicideRiskSelected(e.value)
                                }}
                                value={suicideRiskOptions.filter(option => option.value === suicideRiskSelected)}
                                isDisabled={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="ml-1">
                2. Intervention
            </div>
            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            1.Therapist provided
                        </div>
                        <div className="mx-1 my-2">
                            <DropDown
                                options={interventionOptions}
                                isSearchable={true}
                                placeholder="intervention type"
                                onChange={(e) => {
                                    setInterventionSelected(e.value)
                                }}
                                value={interventionOptions.filter(option => option.value === interventionSelected)}
                                isDisabled={true}
                            />
                        </div>
                        <div className="m-2">
                            with a focus on
                        </div>
                        <div className="m-2">
                            <input disabled className="form-control" placeholder="content" value={therapistInterventionText} onChange={(e) => {
                                setTherapistInterventionText(e.target.value)
                            }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="ml-1">
                3. Response
            </div>
            <div className="row">
                <div className="col-12 ml-3">

                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            <div className="form-check">
                                <input disabled className="form-check-input" type="checkbox"
                                    id="flexClientActively" checked={clientActivelyChecked} onChange={(e) => {
                                        setClientActivelyChecked(e.target.checked)
                                    }} />
                                <div className="form-check-label" for="flexClientActively">
                                </div>
                            </div>
                        </div>
                        <div className="m-2">
                            1. Client actively participated in discussion on
                        </div>
                        <div className="mx-1 my-2">
                            {clientActivelyChecked ? <>
                                <input disabled className="form-control" placeholder=" " value={clientActivelyText} onChange={(e) => {
                                    setClientActivelyText(e.target.value)
                                }} />
                            </> : "***"}
                        </div>
                    </div>

                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            <div className="form-check">
                                <input disabled className="form-check-input" type="checkbox"
                                    id="flexClientReported" checked={clientReportedChecked} onChange={(e) => {
                                        setClientReportedChecked(e.target.checked)
                                    }} />
                                <div className="form-check-label" for="flexClientReported">
                                </div>
                            </div>
                        </div>
                        <div className="m-2">
                            2. Client reported
                        </div>
                        <div className="mx-1 my-2">
                            {clientReportedChecked ? <>
                                <input disabled className="form-control" placeholder=" " value={clientReportedText} onChange={(e) => {
                                    setClientReportedText(e.target.value)
                                }} />
                            </> : "***"}
                        </div>
                        <div className="m-2">
                            was helpful
                        </div>
                    </div>

                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            <div className="form-check">
                                <input disabled className="form-check-input" type="checkbox"
                                    id="flexClientDemonstrated" checked={clientDemonstratedChecked} onChange={(e) => {
                                        setClientDemonstratedChecked(e.target.checked)
                                    }} />
                                <div className="form-check-label" for="flexClientDemonstrated">
                                </div>
                            </div>
                        </div>
                        <div className="m-2">
                            3. Client demonstrated insight related to
                        </div>
                        <div className="mx-1 my-2">
                            {clientDemonstratedChecked ? <>
                                <input disabled className="form-control" placeholder=" " value={clientDemonstratedText} onChange={(e) => {
                                    setClientDemonstratedText(e.target.value)
                                }} />
                            </> : "***"}
                        </div>
                    </div>


                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            <div className="form-check">
                                <input disabled className="form-check-input" type="checkbox"
                                    id="flexClientEngaged" checked={clientEngagedChecked} onChange={(e) => {
                                        setClientEngagedChecked(e.target.checked)
                                    }} />
                                <div className="form-check-label" for="flexClientEngaged">
                                </div>
                            </div>
                        </div>
                        <div className="m-2">
                            4. Client engaged in
                        </div>
                        <div className="mx-1 my-2">
                            {clientEngagedChecked ? <>
                                <input disabled className="form-control" placeholder=" " value={clientEngagedText} onChange={(e) => {
                                    setClientEngaged(e.target.value)
                                }} />
                            </> : "***"}
                        </div>
                        <div className="m-2">
                            activity
                        </div>
                    </div>

                </div>
            </div>

            <div className="ml-1 mt-3">
                4. Plan
            </div>
            <div className="row">
                <div className="col-12 ml-3">
                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            1. Client will attend the next session with
                        </div>
                        <div className="mx-1 my-2">
                            <input disabled className="form-control" placeholder="provider" value={clientProviderText} onChange={(e) => {
                                setClientProviderText(e.target.value)
                            }} />
                        </div>
                        <div className="mx-1 my-2">
                            on
                        </div>
                        <div className="m-2">
                            <input disabled className="form-control" placeholder="date" value={clientProviderDate} onChange={(e) => {
                                setClientProviderDate(e.target.value)
                            }} />
                        </div>
                    </div>

                    <div className="d-flex flex-wrap justify-content-start mt-3">
                        <div className="m-2">
                            2. Recommendations for next steps include
                        </div>
                        <div className="mx-1 my-2">
                            <input disabled className="form-control" placeholder="Recommendations" value={recommendationsNextStepText} onChange={(e) => {
                                setRecommendationsNextStepText(e.target.value)
                            }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    function getTableMentalContent() {
        return <>
            <PaginationDatatable
                refresh={tableRefresh}
                postJsonInfo={{ reqId: getUuidv4(), userId: migrantUserSelected && migrantUserSelected.value ? migrantUserSelected.value : "", }}
                grpcClient={props.grpcClient}
                domain={"user"}
                event={"getMentalHealthDataList"}
                data={mentalHelathtableDataArry}
            />
        </>

    }

    function getTablePhysicalContent() {
        return <>
            <PaginationDatatable
                refresh={tableRefresh}
                postJsonInfo={{ reqId: getUuidv4(), userId: migrantUserSelected && migrantUserSelected.value ? migrantUserSelected.value : "", }}
                grpcClient={props.grpcClient}
                domain={"user"}
                event={"getPhysicalHealthDataList"}
                data={physicaltableDataArray}
            />
        </>

    }

    function validationSelectBox(value) {
        let errors = {}
        if (!value || !value.value) {
            errors = {
                memberselected: true
            }
        }
        setErrors(errors)
    }


    function getContent() {
        let returnPage = getMigrantList();
        switch (viewPageType) {
            case "form":
                returnPage = <MigrantForm
                    path={props.path}
                    grpcClient={props.grpcClient}
                    searchGrpcClient={props.searchGrpcClient}
                    history={props.history}
                    languagePreference={props.languagePreference}
                    appsData={props.appsData}
                    orgType={"work"}
                    selectBoxMember={migrantUserSelected}
                    activeTab={activeTab}
                    goBack={() => {
                        setViewPageType("list")
                    }}
                    validationSelectBox={() => {
                        validationSelectBox(migrantUserSelected)
                    }}
                />
                break;
            case "mentalHealthPreview":
                returnPage = getMentalContent()
                break;
            case "physicalhealthPreview":
                returnPage = getPhysicalContent()
                break;

            default:
            // code block
        }

        return <div className="migrant-container">
            <div className="row mx-2">
                {["mentalHealthPreview", "physicalhealthPreview"].includes(viewPageType) ? <div className="col-12">
                    <div className="d-flex flex-wrap justify-content-start mt-2">
                        <div className="mr-2">
                            <button className="btn btn-primary" onClick={() => {
                                setViewPageType("list")
                            }}>
                                Back
                            </button>
                        </div>
                        <div className="justify-content align-items-center flex-1 text-center">
                            {viewPageType === "mentalHealthPreview" ? "Mental Health Notes" : "Physical Health Notes"} preview
                        </div>
                        <div className="mx-2">
                            <label className="mb-0">Member</label>
                            <div>
                                {migrantUserSelected && migrantUserSelected.label}
                            </div>
                        </div>
                        <div className="mx-2">
                            <div className="form-group">
                                <label className="mb-0">Created At</label>
                                <div>
                                    {getDateWithFormat(rowData.createdAt, auth.activeCompanyDetails)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : <div className="col-3">
                    <div className="form-group">
                        <label>{"Members"}</label>
                        <SearchDropdown
                            selectedOption={migrantUserSelected}
                            onChange={(e) => {
                                setMigrantUserSelected(e)
                                validationSelectBox(e)
                            }}
                            isDisabled={false}
                            isMulti={false}
                            placeholder={"Search user"}
                            error={(errors && errors.memberselected) ? true : false}
                            buildOptions={(e) => {
                                let options = [];
                                e.forEach((option) => {
                                    option = searchDropdownOptionConvert(option)
                                    if (option.userId !== auth.loginId) {
                                        let lastName = option.lastName ? option.lastName[0].toUpperCase() + option.lastName.slice(1) : ""
                                        options.push({ value: option.userId, label: option.firstName[0].toUpperCase() + option.firstName.slice(1) + " " + lastName })
                                    }
                                })
                                return options
                            }}
                            grpcClient={props.grpcClient}
                            searchGrpcClient={props.searchGrpcClient}
                            parentId={GlobalMsgCons.roleTypeMigrant}
                            cacheDataType={GlobalSearchDataTypes.User}
                        />
                    </div>
                </div>}
            </div>
            {["mentalHealthPreview", "physicalhealthPreview"].includes(viewPageType) ? <hr /> : null}
            {returnPage}
        </div>
    }

    return <React.Fragment>
        <ToastContainer />
        {
            props.fromView === "mobile" ? <div className='mobile-container'>
                <div className="header">
                    <div style={{ display: "flex", flex: 1, padding: 5, flexDirection: "row", fontSize: 18 }}>
                        <div style={{ padding: "0 10px" }} onClick={() => {
                            props.history.push(props.path + "/home/more")
                        }}>
                            <i className='fas fa-chevron-left' />
                        </div>
                        <div style={{ flex: 1 }}>
                            <div>Migrant Workspace</div>
                        </div>
                    </div>
                </div>
                <div className="body">
                    {getContent()}
                </div>
            </div> : <React.Fragment>
                {getContent()}
            </React.Fragment>
        }

        {previewModal && <Modal
            visible={previewModal}
            size={"modal-lg"}
            closeModal={() => {
                setPreviewModal(false)
            }}
            heading={"Preview"}
            body={<React.Fragment>
                {GenText()}
            </React.Fragment>}
        >
        </Modal>}

    </React.Fragment>
}

export default MigrantList