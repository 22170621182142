/**
 * @fileoverview gRPC-Web generated client stub for teqversereporting
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

// const proto = {};
// proto.teqversereporting = require('./ZiFetchService_pb.js');

// source: ZiFetchService.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.teqversereporting.ZiFetchReply', null, global);
goog.exportSymbol('proto.teqversereporting.ZiFetchRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teqversereporting.ZiFetchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teqversereporting.ZiFetchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teqversereporting.ZiFetchRequest.displayName = 'proto.teqversereporting.ZiFetchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teqversereporting.ZiFetchReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teqversereporting.ZiFetchReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teqversereporting.ZiFetchReply.displayName = 'proto.teqversereporting.ZiFetchReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teqversereporting.ZiFetchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.teqversereporting.ZiFetchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teqversereporting.ZiFetchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teqversereporting.ZiFetchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    domain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    event: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    datetime: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teqversereporting.ZiFetchRequest}
 */
proto.teqversereporting.ZiFetchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teqversereporting.ZiFetchRequest;
  return proto.teqversereporting.ZiFetchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teqversereporting.ZiFetchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teqversereporting.ZiFetchRequest}
 */
proto.teqversereporting.ZiFetchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEvent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatetime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teqversereporting.ZiFetchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teqversereporting.ZiFetchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teqversereporting.ZiFetchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teqversereporting.ZiFetchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEvent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDatetime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string domain = 1;
 * @return {string}
 */
proto.teqversereporting.ZiFetchRequest.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teqversereporting.ZiFetchRequest} returns this
 */
proto.teqversereporting.ZiFetchRequest.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string event = 2;
 * @return {string}
 */
proto.teqversereporting.ZiFetchRequest.prototype.getEvent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.teqversereporting.ZiFetchRequest} returns this
 */
proto.teqversereporting.ZiFetchRequest.prototype.setEvent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.teqversereporting.ZiFetchRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.teqversereporting.ZiFetchRequest} returns this
 */
proto.teqversereporting.ZiFetchRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string dateTime = 4;
 * @return {string}
 */
proto.teqversereporting.ZiFetchRequest.prototype.getDatetime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.teqversereporting.ZiFetchRequest} returns this
 */
proto.teqversereporting.ZiFetchRequest.prototype.setDatetime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teqversereporting.ZiFetchReply.prototype.toObject = function(opt_includeInstance) {
  return proto.teqversereporting.ZiFetchReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teqversereporting.ZiFetchReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teqversereporting.ZiFetchReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teqversereporting.ZiFetchReply}
 */
proto.teqversereporting.ZiFetchReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teqversereporting.ZiFetchReply;
  return proto.teqversereporting.ZiFetchReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teqversereporting.ZiFetchReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teqversereporting.ZiFetchReply}
 */
proto.teqversereporting.ZiFetchReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teqversereporting.ZiFetchReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teqversereporting.ZiFetchReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teqversereporting.ZiFetchReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teqversereporting.ZiFetchReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.teqversereporting.ZiFetchReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teqversereporting.ZiFetchReply} returns this
 */
proto.teqversereporting.ZiFetchReply.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.teqversereporting);


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqversereporting.ZiFetchServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqversereporting.ZiFetchServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqversereporting.ZiFetchRequest,
 *   !proto.teqversereporting.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService_SayZiFetch = new grpc.web.MethodDescriptor(
  '/teqversereporting.ZiFetchService/SayZiFetch',
  grpc.web.MethodType.UNARY,
  proto.teqversereporting.ZiFetchRequest,
  proto.teqversereporting.ZiFetchReply,
  /**
   * @param {!proto.teqversereporting.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqversereporting.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqversereporting.ZiFetchRequest,
 *   !proto.teqversereporting.ZiFetchReply>}
 */
const methodInfo_ZiFetchService_SayZiFetch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqversereporting.ZiFetchReply,
  /**
   * @param {!proto.teqversereporting.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqversereporting.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqversereporting.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teqversereporting.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teqversereporting.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teqversereporting.ZiFetchServiceClient.prototype.sayZiFetch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teqversereporting.ZiFetchService/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService_SayZiFetch,
      callback);
};


/**
 * @param {!proto.teqversereporting.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teqversereporting.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.teqversereporting.ZiFetchServicePromiseClient.prototype.sayZiFetch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teqversereporting.ZiFetchService/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService_SayZiFetch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqversereporting.ZiFetchRequest,
 *   !proto.teqversereporting.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService_ItKeepsReplying = new grpc.web.MethodDescriptor(
  '/teqversereporting.ZiFetchService/ItKeepsReplying',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.teqversereporting.ZiFetchRequest,
  proto.teqversereporting.ZiFetchReply,
  /**
   * @param {!proto.teqversereporting.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqversereporting.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqversereporting.ZiFetchRequest,
 *   !proto.teqversereporting.ZiFetchReply>}
 */
const methodInfo_ZiFetchService_ItKeepsReplying = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqversereporting.ZiFetchReply,
  /**
   * @param {!proto.teqversereporting.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqversereporting.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqversereporting.ZiFetchRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.teqversereporting.ZiFetchReply>}
 *     The XHR Node Readable Stream
 */
proto.teqversereporting.ZiFetchServiceClient.prototype.itKeepsReplying =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/teqversereporting.ZiFetchService/ItKeepsReplying',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService_ItKeepsReplying);
};


/**
 * @param {!proto.teqversereporting.ZiFetchRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.teqversereporting.ZiFetchReply>}
 *     The XHR Node Readable Stream
 */
proto.teqversereporting.ZiFetchServicePromiseClient.prototype.itKeepsReplying =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/teqversereporting.ZiFetchService/ItKeepsReplying',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService_ItKeepsReplying);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqversereporting.ZiFetchService1Client =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqversereporting.ZiFetchService1PromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqversereporting.ZiFetchRequest,
 *   !proto.teqversereporting.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService1_SayZiFetch = new grpc.web.MethodDescriptor(
  '/teqversereporting.ZiFetchService1/SayZiFetch',
  grpc.web.MethodType.UNARY,
  proto.teqversereporting.ZiFetchRequest,
  proto.teqversereporting.ZiFetchReply,
  /**
   * @param {!proto.teqversereporting.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqversereporting.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqversereporting.ZiFetchRequest,
 *   !proto.teqversereporting.ZiFetchReply>}
 */
const methodInfo_ZiFetchService1_SayZiFetch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqversereporting.ZiFetchReply,
  /**
   * @param {!proto.teqversereporting.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqversereporting.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqversereporting.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teqversereporting.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teqversereporting.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teqversereporting.ZiFetchService1Client.prototype.sayZiFetch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teqversereporting.ZiFetchService1/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService1_SayZiFetch,
      callback);
};


/**
 * @param {!proto.teqversereporting.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teqversereporting.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.teqversereporting.ZiFetchService1PromiseClient.prototype.sayZiFetch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teqversereporting.ZiFetchService1/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService1_SayZiFetch);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqversereporting.ZiFetchService2Client =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqversereporting.ZiFetchService2PromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqversereporting.ZiFetchRequest,
 *   !proto.teqversereporting.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService2_SayZiFetch = new grpc.web.MethodDescriptor(
  '/teqversereporting.ZiFetchService2/SayZiFetch',
  grpc.web.MethodType.UNARY,
  proto.teqversereporting.ZiFetchRequest,
  proto.teqversereporting.ZiFetchReply,
  /**
   * @param {!proto.teqversereporting.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqversereporting.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqversereporting.ZiFetchRequest,
 *   !proto.teqversereporting.ZiFetchReply>}
 */
const methodInfo_ZiFetchService2_SayZiFetch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqversereporting.ZiFetchReply,
  /**
   * @param {!proto.teqversereporting.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqversereporting.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqversereporting.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teqversereporting.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teqversereporting.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teqversereporting.ZiFetchService2Client.prototype.sayZiFetch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teqversereporting.ZiFetchService2/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService2_SayZiFetch,
      callback);
};


/**
 * @param {!proto.teqversereporting.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teqversereporting.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.teqversereporting.ZiFetchService2PromiseClient.prototype.sayZiFetch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teqversereporting.ZiFetchService2/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService2_SayZiFetch);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqversereporting.ZiFetchService3Client =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqversereporting.ZiFetchService3PromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqversereporting.ZiFetchRequest,
 *   !proto.teqversereporting.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService3_SayZiFetch = new grpc.web.MethodDescriptor(
  '/teqversereporting.ZiFetchService3/SayZiFetch',
  grpc.web.MethodType.UNARY,
  proto.teqversereporting.ZiFetchRequest,
  proto.teqversereporting.ZiFetchReply,
  /**
   * @param {!proto.teqversereporting.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqversereporting.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqversereporting.ZiFetchRequest,
 *   !proto.teqversereporting.ZiFetchReply>}
 */
const methodInfo_ZiFetchService3_SayZiFetch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqversereporting.ZiFetchReply,
  /**
   * @param {!proto.teqversereporting.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqversereporting.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqversereporting.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teqversereporting.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teqversereporting.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teqversereporting.ZiFetchService3Client.prototype.sayZiFetch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teqversereporting.ZiFetchService3/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService3_SayZiFetch,
      callback);
};


/**
 * @param {!proto.teqversereporting.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teqversereporting.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.teqversereporting.ZiFetchService3PromiseClient.prototype.sayZiFetch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teqversereporting.ZiFetchService3/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService3_SayZiFetch);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqversereporting.ZiFetchService4Client =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqversereporting.ZiFetchService4PromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqversereporting.ZiFetchRequest,
 *   !proto.teqversereporting.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService4_SayZiFetch = new grpc.web.MethodDescriptor(
  '/teqversereporting.ZiFetchService4/SayZiFetch',
  grpc.web.MethodType.UNARY,
  proto.teqversereporting.ZiFetchRequest,
  proto.teqversereporting.ZiFetchReply,
  /**
   * @param {!proto.teqversereporting.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqversereporting.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqversereporting.ZiFetchRequest,
 *   !proto.teqversereporting.ZiFetchReply>}
 */
const methodInfo_ZiFetchService4_SayZiFetch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqversereporting.ZiFetchReply,
  /**
   * @param {!proto.teqversereporting.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqversereporting.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqversereporting.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teqversereporting.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teqversereporting.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teqversereporting.ZiFetchService4Client.prototype.sayZiFetch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teqversereporting.ZiFetchService4/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService4_SayZiFetch,
      callback);
};


/**
 * @param {!proto.teqversereporting.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teqversereporting.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.teqversereporting.ZiFetchService4PromiseClient.prototype.sayZiFetch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teqversereporting.ZiFetchService4/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService4_SayZiFetch);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqversereporting.ZiFetchService5Client =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqversereporting.ZiFetchService5PromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqversereporting.ZiFetchRequest,
 *   !proto.teqversereporting.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService5_SayZiFetch = new grpc.web.MethodDescriptor(
  '/teqversereporting.ZiFetchService5/SayZiFetch',
  grpc.web.MethodType.UNARY,
  proto.teqversereporting.ZiFetchRequest,
  proto.teqversereporting.ZiFetchReply,
  /**
   * @param {!proto.teqversereporting.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqversereporting.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqversereporting.ZiFetchRequest,
 *   !proto.teqversereporting.ZiFetchReply>}
 */
const methodInfo_ZiFetchService5_SayZiFetch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqversereporting.ZiFetchReply,
  /**
   * @param {!proto.teqversereporting.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqversereporting.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqversereporting.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teqversereporting.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teqversereporting.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teqversereporting.ZiFetchService5Client.prototype.sayZiFetch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teqversereporting.ZiFetchService5/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService5_SayZiFetch,
      callback);
};


/**
 * @param {!proto.teqversereporting.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teqversereporting.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.teqversereporting.ZiFetchService5PromiseClient.prototype.sayZiFetch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teqversereporting.ZiFetchService5/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService5_SayZiFetch);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqversereporting.ZiFetchService6Client =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqversereporting.ZiFetchService6PromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqversereporting.ZiFetchRequest,
 *   !proto.teqversereporting.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService6_SayZiFetch = new grpc.web.MethodDescriptor(
  '/teqversereporting.ZiFetchService6/SayZiFetch',
  grpc.web.MethodType.UNARY,
  proto.teqversereporting.ZiFetchRequest,
  proto.teqversereporting.ZiFetchReply,
  /**
   * @param {!proto.teqversereporting.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqversereporting.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqversereporting.ZiFetchRequest,
 *   !proto.teqversereporting.ZiFetchReply>}
 */
const methodInfo_ZiFetchService6_SayZiFetch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqversereporting.ZiFetchReply,
  /**
   * @param {!proto.teqversereporting.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqversereporting.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqversereporting.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teqversereporting.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teqversereporting.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teqversereporting.ZiFetchService6Client.prototype.sayZiFetch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teqversereporting.ZiFetchService6/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService6_SayZiFetch,
      callback);
};


/**
 * @param {!proto.teqversereporting.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teqversereporting.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.teqversereporting.ZiFetchService6PromiseClient.prototype.sayZiFetch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teqversereporting.ZiFetchService6/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService6_SayZiFetch);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqversereporting.ZiFetchService7Client =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqversereporting.ZiFetchService7PromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqversereporting.ZiFetchRequest,
 *   !proto.teqversereporting.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService7_SayZiFetch = new grpc.web.MethodDescriptor(
  '/teqversereporting.ZiFetchService7/SayZiFetch',
  grpc.web.MethodType.UNARY,
  proto.teqversereporting.ZiFetchRequest,
  proto.teqversereporting.ZiFetchReply,
  /**
   * @param {!proto.teqversereporting.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqversereporting.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqversereporting.ZiFetchRequest,
 *   !proto.teqversereporting.ZiFetchReply>}
 */
const methodInfo_ZiFetchService7_SayZiFetch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqversereporting.ZiFetchReply,
  /**
   * @param {!proto.teqversereporting.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqversereporting.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqversereporting.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teqversereporting.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teqversereporting.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teqversereporting.ZiFetchService7Client.prototype.sayZiFetch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teqversereporting.ZiFetchService7/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService7_SayZiFetch,
      callback);
};


/**
 * @param {!proto.teqversereporting.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teqversereporting.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.teqversereporting.ZiFetchService7PromiseClient.prototype.sayZiFetch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teqversereporting.ZiFetchService7/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService7_SayZiFetch);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqversereporting.ZiFetchService8Client =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqversereporting.ZiFetchService8PromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqversereporting.ZiFetchRequest,
 *   !proto.teqversereporting.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService8_SayZiFetch = new grpc.web.MethodDescriptor(
  '/teqversereporting.ZiFetchService8/SayZiFetch',
  grpc.web.MethodType.UNARY,
  proto.teqversereporting.ZiFetchRequest,
  proto.teqversereporting.ZiFetchReply,
  /**
   * @param {!proto.teqversereporting.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqversereporting.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqversereporting.ZiFetchRequest,
 *   !proto.teqversereporting.ZiFetchReply>}
 */
const methodInfo_ZiFetchService8_SayZiFetch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqversereporting.ZiFetchReply,
  /**
   * @param {!proto.teqversereporting.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqversereporting.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqversereporting.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teqversereporting.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teqversereporting.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teqversereporting.ZiFetchService8Client.prototype.sayZiFetch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teqversereporting.ZiFetchService8/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService8_SayZiFetch,
      callback);
};


/**
 * @param {!proto.teqversereporting.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teqversereporting.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.teqversereporting.ZiFetchService8PromiseClient.prototype.sayZiFetch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teqversereporting.ZiFetchService8/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService8_SayZiFetch);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqversereporting.ZiFetchService9Client =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqversereporting.ZiFetchService9PromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqversereporting.ZiFetchRequest,
 *   !proto.teqversereporting.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService9_SayZiFetch = new grpc.web.MethodDescriptor(
  '/teqversereporting.ZiFetchService9/SayZiFetch',
  grpc.web.MethodType.UNARY,
  proto.teqversereporting.ZiFetchRequest,
  proto.teqversereporting.ZiFetchReply,
  /**
   * @param {!proto.teqversereporting.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqversereporting.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqversereporting.ZiFetchRequest,
 *   !proto.teqversereporting.ZiFetchReply>}
 */
const methodInfo_ZiFetchService9_SayZiFetch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqversereporting.ZiFetchReply,
  /**
   * @param {!proto.teqversereporting.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqversereporting.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqversereporting.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teqversereporting.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teqversereporting.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teqversereporting.ZiFetchService9Client.prototype.sayZiFetch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teqversereporting.ZiFetchService9/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService9_SayZiFetch,
      callback);
};


/**
 * @param {!proto.teqversereporting.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teqversereporting.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.teqversereporting.ZiFetchService9PromiseClient.prototype.sayZiFetch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teqversereporting.ZiFetchService9/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService9_SayZiFetch);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqversereporting.ZiFetchService10Client =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.teqversereporting.ZiFetchService10PromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.teqversereporting.ZiFetchRequest,
 *   !proto.teqversereporting.ZiFetchReply>}
 */
const methodDescriptor_ZiFetchService10_SayZiFetch = new grpc.web.MethodDescriptor(
  '/teqversereporting.ZiFetchService10/SayZiFetch',
  grpc.web.MethodType.UNARY,
  proto.teqversereporting.ZiFetchRequest,
  proto.teqversereporting.ZiFetchReply,
  /**
   * @param {!proto.teqversereporting.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqversereporting.ZiFetchReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.teqversereporting.ZiFetchRequest,
 *   !proto.teqversereporting.ZiFetchReply>}
 */
const methodInfo_ZiFetchService10_SayZiFetch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.teqversereporting.ZiFetchReply,
  /**
   * @param {!proto.teqversereporting.ZiFetchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.teqversereporting.ZiFetchReply.deserializeBinary
);


/**
 * @param {!proto.teqversereporting.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.teqversereporting.ZiFetchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.teqversereporting.ZiFetchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.teqversereporting.ZiFetchService10Client.prototype.sayZiFetch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/teqversereporting.ZiFetchService10/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService10_SayZiFetch,
      callback);
};


/**
 * @param {!proto.teqversereporting.ZiFetchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.teqversereporting.ZiFetchReply>}
 *     Promise that resolves to the response
 */
proto.teqversereporting.ZiFetchService10PromiseClient.prototype.sayZiFetch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/teqversereporting.ZiFetchService10/SayZiFetch',
      request,
      metadata || {},
      methodDescriptor_ZiFetchService10_SayZiFetch);
};


module.exports = proto.teqversereporting;

