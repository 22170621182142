import { checkNullAndReturnString } from "./Common";
import { readBinaryFromDB, writeBinaryToDB } from "./MyDb";

export function writeBinary(fileName, contents) {
  if (checkNullAndReturnString(fileName)) {
    writeBinaryToDB(fileName, contents)
  }
}

export function writeFile(fileName, contents) {
  if (checkNullAndReturnString(fileName)) {
    writeBinaryToDB(fileName, contents)
  }
}

export async function readFile(obj) {
  let { filePath } = obj;
  let str = ""
  if (checkNullAndReturnString(filePath)) {
    str = await readBinaryFromDB(filePath)
  }

  return str;
}

export async function readBinary(obj) {
  let { key } = obj;
  let data = await readBinaryFromDB(key)
  return data
}