import {
  SET_AUTH,
  RESET_AUTH,
  SET_MULTIPLE_AUTH,
  SET_GUEST_AUTH,
  RESET_GUEST_AUTH,
  SET_MULTIPLE_GUEST_AUTH,
  SET_LOGIN,
  RESET_LOGIN,
  SET_REGISTER,
  SET_MULTIPLE_REGISTER,
  RESET_REGISTER,
  SET_FORGOT_PASSWORD,
  SET_MULTIPLE_FORGOT_PASSWORD,
  RESET_FORGOT_PASSWORD,
  SET_SWITCH_ACCOUNT,
  SET_MULTIPLE_SWITCH_ACCOUNT,
  RESET_SWITCH_ACCOUNT,
  SET_WORKSPACE,
  SET_MULTIPLE_WORKSPACE,
  RESET_WORKSPACE,
  SET_DAILY_UPDATE,
  SET_MULTIPLE_DAILY_UPDATE,
  RESET_DAILY_UPDATE,
  SET_DAILY_REPORT_ORG,
  SET_MULTIPLE_DAILY_REPORT_ORG,
  RESET_DAILY_REPORT_ORG,
  SET_EXPENSES,
  SET_MULTIPLE_EXPENSES,
  RESET_EXPENSES,
  SET_QAT_LIST,
  SET_MULTIPLE_QAT_LIST,
  RESET_QAT_LIST,
  SET_QAT_CREATE,
  SET_MULTIPLE_QAT_CREATE,
  RESET_QAT_CREATE,
  SET_QAT_EDIT,
  SET_MULTIPLE_QAT_EDIT,
  RESET_QAT_EDIT,
  SET_APPS,
  SET_MULTIPLE_APPS,
  RESET_APPS,
  SET_PROJECT_DIAGRAM,
  SET_MULTIPLE_PROJECT_DIAGRAM,
  RESET_PROJECT_DIAGRAM,
  SET_FEEDBACK_LIST,
  SET_MULTIPLE_FEEDBACK_LIST,
  RESET_FEEDBACK_LIST,
  SET_FEEDBACK_ADD,
  SET_MULTIPLE_FEEDBACK_ADD,
  RESET_FEEDBACK_ADD,
  SET_FEEDBACK_EDIT,
  SET_MULTIPLE_FEEDBACK_EDIT,
  RESET_FEEDBACK_EDIT,
  SET_USER_FEEDBACK_LIST,
  SET_MULTIPLE_USER_FEEDBACK_LIST,
  RESET_USER_FEEDBACK_LIST,
  SET_REPORT,
  SET_MULTIPLE_REPORT,
  RESET_REPORT,
  SET_ENTRY_REPORT,
  SET_MULTIPLE_ENTRY_REPORT,
  RESET_ENTRY_REPORT,
  SET_EXPENSE_REPORT,
  SET_MULTIPLE_EXPENSE_REPORT,
  RESET_EXPENSE_REPORT,
  SET_CALENDAR,
  SET_MULTIPLE_CALENDAR,
  RESET_CALENDAR,
  SET_MEMBERS,
  SET_MULTIPLE_MEMBERS,
  RESET_MEMBERS,
  SET_CLIENT,
  SET_MULTIPLE_CLIENT,
  RESET_CLIENT,
  SET_EXPENSES_CATEGORY,
  SET_MULTIPLE_EXPENSES_CATEGORY,
  RESET_EXPENSES_CATEGORY,
  SET_SERVICE_TYPE,
  SET_MULTIPLE_SERVICE_TYPE,
  RESET_SERVICE_TYPE,
  SET_DESIGNATION,
  SET_MULTIPLE_DESIGNATION,
  RESET_DESIGNATION,
  SET_BOARD_STATUS_TYPE,
  SET_MULTIPLE_BOARD_STATUS_TYPE,
  RESET_BOARD_STATUS_TYPE,
  SET_CLIENT_MASTER,
  SET_MULTIPLE_CLIENT_MASTER,
  RESET_CLIENT_MASTER,
  SET_PROJECT,
  SET_MULTIPLE_PROJECT,
  RESET_PROJECT,
  SET_TEAM,
  SET_MULTIPLE_TEAM,
  RESET_TEAM,
  SET_TASK,
  SET_MULTIPLE_TASK,
  RESET_TASK,
  SET_CLOUD_PROVIDER_USER_MAP,
  SET_MULTIPLE_CLOUD_PROVIDER_USER_MAP,
  RESET_CLOUD_PROVIDER_USER_MAP,
  SET_BOARD,
  SET_MULTIPLE_BOARD,
  RESET_BOARD,
  SET_MY_PROFILE,
  SET_MULTIPLE_MY_PROFILE,
  RESET_MY_PROFILE,
  SET_PREFERENCES,
  SET_MULTIPLE_PREFERENCES,
  RESET_PREFERENCES,
  SET_NOTIFICATION,
  SET_MULTIPLE_NOTIFICATION,
  RESET_NOTIFICATION,
  SET_TIMEZONE,
  SET_MULTIPLE_TIMEZONE,
  RESET_TIMEZONE,
  SET_CHAT,
  SET_MULTIPLE_CHAT,
  RESET_CHAT,
  SET_CHAT_CHANNEL,
  SET_MULTIPLE_CHAT_CHANNEL,
  RESET_CHAT_CHANNEL,
  SET_THREAD_CHAT,
  SET_MULTIPLE_THREAD_CHAT,
  RESET_THREAD_CHAT,
  SET_ANALYTICS,
  SET_MULTIPLE_ANALYTICS,
  RESET_ANALYTICS,
  SET_EXPENSE_MANAGE,
  RESET_EXPENSE_MANAGE,
  SET_MULTIPLE_EXPENSE_MANAGE,
  SET_APP_MASTER, SET_MULTIPLE_APP_MASTER, RESET_APP_MASTER,
  SET_SUBJECT, SET_MULTIPLE_SUBJECT, RESET_SUBJECT,
  SET_CLASSROOM, SET_MULTIPLE_CLASSROOM, RESET_CLASSROOM,
  SET_EDUCATION_TASK, SET_EDUCATION_MULTIPLE_TASK, RESET_EDUCATION_TASK,
  SET_EDUCATION_WORKSPACE, SET_EDUCATION_MULTIPLE_WORKSPACE, RESET_EDUCATION_WORKSPACE,
  SET_EDUCATION_ENTRY_REPORT, SET_EDUCATION_MULTIPLE_ENTRY_REPORT, RESET_EDUCATION_ENTRY_REPORT,
  SET_EDUCATION_BOARD, SET_EDUCATION_MULTIPLE_BOARD, RESET_EDUCATION_BOARD,
  SET_EDUCATION_BOARD_STATUS_TYPE, SET_MULTIPLE_EDUCATION_BOARD_STATUS_TYPE, RESET_EDUCATION_BOARD_STATUS_TYPE,
  SET_EDUCATION_EXPENSES, SET_MULTIPLE_EDUCATION_EXPENSES, RESET_EDUCATION_EXPENSES,
  SET_EDUCATION_EXPENSE_MANAGE, SET_MULTIPLE_EDUCATION_EXPENSE_MANAGE, RESET_EDUCATION_EXPENSE_MANAGE,
  SET_EDUCATION_EXPENSES_CATEGORY, SET_MULTIPLE_EDUCATION_EXPENSES_CATEGORY, RESET_EDUCATION_EXPENSES_CATEGORY,
  SET_EDUCATION_CSV_FILE_HISTORY, SET_MULTIPLE_EDUCATION_CSV_FILE_HISTORY, RESET_EDUCATION_CSV_FILE_HISTORY,
  SET_EDUCATION_EXPENSE_REPORTS, SET_MULTIPLE_EDUCATION_EXPENSE_REPORTS, RESET_EDUCATION_EXPENSE_REPORTS,
  SET_EDUCATION_REPORT, SET_MULTIPLE_EDUCATION_REPORT, RESET_EDUCATION_REPORT,
  SET_FAQ, SET_MULTIPLE_FAQ, RESET_FAQ,
  SET_EDUCATION_APPS, SET_MULTIPLE_EDUCATION_APPS, RESET_EDUCATION_APPS,
  SET_NOTES, SET_MULTIPLE_NOTES, RESET_NOTES,
  SET_VERIFY_ACCCOUNT, SET_MULTIPLE_VERIFY_ACCCOUNT, RESET_VERIFY_ACCCOUNT,
  SET_HIDDEN_CHANNELS, SET_MULTIPLE_HIDDEN_CHANNELS, RESET_HIDDEN_CHANNELS,
  SET_USER_SESSION_REPORT, SET_MULTIPLE_USER_SESSION_REPORT, RESET_USER_SESSION_REPORT, REMOVE_DAILY_REPORT_ORG, REMOVE_DAILY_REPORT, REMOVE_WORKSPACE, REMOVE_EXPENSES, SET_MULTIPLE_CALENDLY_GUEST, RESET_CALENDLY_GUEST, SET_MULTIPLE_CALENDLY_EVENT, RESET_CALENDLY_EVENT, REMOVE_DAILY_REPORT_ORG_DATA, SET_TIMING_WINDOW, SET_MULTIPLE_TIMING_WINDOW, RESET_TIMING_WINDOW, SET_USER_LEAVE_REQUEST, SET_MULTIPLE_USER_LEAVE_REQUEST, RESET_USER_LEAVE_REQUEST, SET_USER_IP_HISTORY, SET_MULTIPLE_USER_IP_HISTORY,
  RESET_USER_IP_HISTORY, REMOVE_USER_IP_HISTORY,
  SET_SUB_SERVICE_TYPE, SET_SUB_MULTIPLE_SERVICE_TYPE, RESET_SUB_SERVICE_TYPE,
  SET_DONATION, SET_MULTIPLE_DONATION, RESET_DONATION,
  SET_LANG, SET_MULTIPLE_LANG, RESET_LANG
} from '../constants/actionTypes';

export const setAuthData = (key, value) => ({
  type: SET_AUTH,
  key: key,
  value: value,
});

export const setMultipleAuthData = (data) => ({
  type: SET_MULTIPLE_AUTH,
  data: data,
});

export const reSetAuthData = () => ({
  type: RESET_AUTH,
});

export const setGuestAuthData = (key, value) => ({
  type: SET_GUEST_AUTH,
  key: key,
  value: value,
});

export const setMultipleGuestAuthData = (data) => ({
  type: SET_MULTIPLE_GUEST_AUTH,
  data: data,
});

export const reSetGuestAuthData = () => ({
  type: RESET_GUEST_AUTH,
});

export const setLoginData = (key, value) => ({
  type: SET_LOGIN,
  key: key,
  value: value,
});

export const reSetLoginData = () => ({
  type: RESET_LOGIN,
});

export const setRegisterData = (key, value) => ({
  type: SET_REGISTER,
  key: key,
  value: value,
});

export const setMultipleRegisterData = (data) => ({
  type: SET_MULTIPLE_REGISTER,
  data: data,
});

export const reSetRegisterData = () => ({
  type: RESET_REGISTER,
});

export const setForgotPasswordData = (key, value) => ({
  type: SET_FORGOT_PASSWORD,
  key: key,
  value: value,
});

export const setMultipleForgotPasswordData = (data) => ({
  type: SET_MULTIPLE_FORGOT_PASSWORD,
  data: data,
});

export const reSetForgotPasswordData = () => ({
  type: RESET_FORGOT_PASSWORD,
});

export const setSwitchAccountData = (key, value) => ({
  type: SET_SWITCH_ACCOUNT,
  key: key,
  value: value,
});

export const setMultipleSwitchAccountData = (data) => ({
  type: SET_MULTIPLE_SWITCH_ACCOUNT,
  data: data,
});

export const reSetSwitchAccountData = () => ({
  type: RESET_SWITCH_ACCOUNT,
});

export const setWorkspaceData = (key, value) => ({
  type: SET_WORKSPACE,
  key: key,
  value: value,
});

export const setMultipleWorkspaceData = (date, data) => ({
  type: SET_MULTIPLE_WORKSPACE,
  date,
  data,
});

export const removeWorkpaceData = (date) => ({
  type: REMOVE_WORKSPACE,
  date
});

export const reSetWorkpaceData = () => ({
  type: RESET_WORKSPACE,
});

export const setDailyUpdateData = (key, value) => ({
  type: SET_DAILY_UPDATE,
  key: key,
  value: value,
});

export const setMultipleDailyUpdateData = (date, data) => ({
  type: SET_MULTIPLE_DAILY_UPDATE,
  date,
  data
});

export const removeDailyReport = (date) => ({
  type: REMOVE_DAILY_REPORT,
  date
});

export const reSetDailyUpdateData = () => ({
  type: RESET_DAILY_UPDATE,
});

export const setDailyReportOrgData = (key, value) => ({
  type: SET_DAILY_REPORT_ORG,
  key: key,
  value: value,
});

export const setMultipleDailyReportOrgData = (date, data) => ({
  type: SET_MULTIPLE_DAILY_REPORT_ORG,
  date,
  data,
});

export const removeDailyReportOrg = (date) => ({
  type: REMOVE_DAILY_REPORT_ORG,
  date
});

export const removeDailyReportOrgData = (date, key) => ({
  type: REMOVE_DAILY_REPORT_ORG_DATA,
  date,
  key
});

export const reSetDailyReportOrgData = () => ({
  type: RESET_DAILY_REPORT_ORG,
});

export const setExpensesData = (key, value) => ({
  type: SET_EXPENSES,
  key: key,
  value: value,
});

export const setMultipleExpensesData = (date, data) => ({
  type: SET_MULTIPLE_EXPENSES,
  date, data
});

export const removeExpensesData = (date) => ({
  type: REMOVE_EXPENSES,
  date
});

export const reSetExpensesData = () => ({
  type: RESET_EXPENSES,
});

export const setQatListData = (key, value) => ({
  type: SET_QAT_LIST,
  key: key,
  value: value,
});

export const setMultipleQatListData = (data) => ({
  type: SET_MULTIPLE_QAT_LIST,
  data: data,
});

export const reSetQatListData = () => ({
  type: RESET_QAT_LIST,
});

export const setQatCreateData = (key, value) => ({
  type: SET_QAT_CREATE,
  key: key,
  value: value,
});

export const setMultipleQatCreateData = (data) => ({
  type: SET_MULTIPLE_QAT_CREATE,
  data: data,
});

export const reSetQatCreateData = () => ({
  type: RESET_QAT_CREATE,
});

export const setQatEditData = (key, value) => ({
  type: SET_QAT_EDIT,
  key: key,
  value: value,
});

export const setMultipleQatEditData = (data) => ({
  type: SET_MULTIPLE_QAT_EDIT,
  data: data,
});

export const reSetQatEditData = () => ({
  type: RESET_QAT_EDIT,
});

export const setAppsData = (key, value) => ({
  type: SET_APPS,
  key: key,
  value: value,
});

export const setMultipleAppsData = (data) => ({
  type: SET_MULTIPLE_APPS,
  data: data,
});

export const reSetAppsData = () => ({
  type: RESET_APPS,
});

export const setProjectDiagramData = (key, value) => ({
  type: SET_PROJECT_DIAGRAM,
  key: key,
  value: value,
});

export const setMultipleProjectDiagramData = (data) => ({
  type: SET_MULTIPLE_PROJECT_DIAGRAM,
  data: data,
});

export const reSetProjectDiagramData = () => ({
  type: RESET_PROJECT_DIAGRAM,
});

export const setOrgFeedbackListData = (key, value) => ({
  type: SET_FEEDBACK_LIST,
  key: key,
  value: value,
});

export const setMultipleOrgFeedbackListData = (data) => ({
  type: SET_MULTIPLE_FEEDBACK_LIST,
  data: data,
});

export const reSetOrgFeedbackListData = () => ({
  type: RESET_FEEDBACK_LIST,
});

export const setOrgFeedbackAddData = (key, value) => ({
  type: SET_FEEDBACK_ADD,
  key: key,
  value: value,
});

export const setMultipleOrgFeedbackAddData = (data) => ({
  type: SET_MULTIPLE_FEEDBACK_ADD,
  data: data,
});

export const reSetOrgFeedbackAddData = () => ({
  type: RESET_FEEDBACK_ADD,
});

export const setOrgFeedbackEditData = (key, value) => ({
  type: SET_FEEDBACK_EDIT,
  key: key,
  value: value,
});

export const setMultipleOrgFeedbackEditData = (data) => ({
  type: SET_MULTIPLE_FEEDBACK_EDIT,
  data: data,
});

export const reSetOrgFeedbackEditData = () => ({
  type: RESET_FEEDBACK_EDIT,
});

export const setUserOrgFeedbackListData = (key, value) => ({
  type: SET_USER_FEEDBACK_LIST,
  key: key,
  value: value,
});

export const setMultipleUserOrgFeedbackListData = (data) => ({
  type: SET_MULTIPLE_USER_FEEDBACK_LIST,
  data: data,
});

export const reSetUserOrgFeedbackListData = () => ({
  type: RESET_USER_FEEDBACK_LIST,
});

export const setReportData = (key, value) => ({
  type: SET_REPORT,
  key: key,
  value: value,
});

export const setMultipleReportData = (data) => ({
  type: SET_MULTIPLE_REPORT,
  data: data,
});

export const reSetReportData = () => ({
  type: RESET_REPORT,
});

export const setEntryReportData = (key, value) => ({
  type: SET_ENTRY_REPORT,
  key: key,
  value: value,
});

export const setMultipleEntryReportData = (data) => ({
  type: SET_MULTIPLE_ENTRY_REPORT,
  data: data,
});

export const reSetEntryReportData = () => ({
  type: RESET_ENTRY_REPORT,
});

export const setExpenseReportData = (key, value) => ({
  type: SET_EXPENSE_REPORT,
  key: key,
  value: value,
});

export const setMultipleExpenseReportData = (data) => ({
  type: SET_MULTIPLE_EXPENSE_REPORT,
  data: data,
});

export const reSetExpenseReportData = () => ({
  type: RESET_EXPENSE_REPORT,
});

export const setCalendarData = (key, value) => ({
  type: SET_CALENDAR,
  key: key,
  value: value,
});

export const setMultipleCalendarData = (data) => ({
  type: SET_MULTIPLE_CALENDAR,
  data: data,
});

export const reSetCalendarData = () => ({
  type: RESET_CALENDAR,
});

export const setMembersData = (key, value) => ({
  type: SET_MEMBERS,
  key: key,
  value: value,
});

export const setMultipleMembersData = (data) => ({
  type: SET_MULTIPLE_MEMBERS,
  data: data,
});

export const reSetMembersData = () => ({
  type: RESET_MEMBERS,
});

export const setClientData = (key, value) => ({
  type: SET_CLIENT,
  key: key,
  value: value,
});

export const setMultipleClientData = (data) => ({
  type: SET_MULTIPLE_CLIENT,
  data: data,
});

export const reSetClientData = () => ({
  type: RESET_CLIENT,
});

export const setExpensesCategoryData = (key, value) => ({
  type: SET_EXPENSES_CATEGORY,
  key: key,
  value: value,
});

export const setMultipleExpensesCategoryData = (data) => ({
  type: SET_MULTIPLE_EXPENSES_CATEGORY,
  data: data,
});

export const reSetExpensesCategoryData = () => ({
  type: RESET_EXPENSES_CATEGORY,
});

export const setServiceTypeData = (key, value) => ({
  type: SET_SERVICE_TYPE,
  key: key,
  value: value,
});

export const setMultipleServiceTypeData = (data) => ({
  type: SET_MULTIPLE_SERVICE_TYPE,
  data: data,
});

export const reSetServiceTypeData = () => ({
  type: RESET_SERVICE_TYPE,
});

export const setDesignationData = (key, value) => ({
  type: SET_DESIGNATION,
  key: key,
  value: value,
});

export const setMultipleDesignationData = (data) => ({
  type: SET_MULTIPLE_DESIGNATION,
  data: data,
});

export const reSetDesignationData = () => ({
  type: RESET_DESIGNATION,
});

export const setBoardStatusTypeData = (key, value) => ({
  type: SET_BOARD_STATUS_TYPE,
  key: key,
  value: value,
});

export const setMultipleBoardStatusTypeData = (data) => ({
  type: SET_MULTIPLE_BOARD_STATUS_TYPE,
  data: data,
});

export const reSetBoardStatusTypeData = () => ({
  type: RESET_BOARD_STATUS_TYPE,
});

export const setClientMasterData = (key, value) => ({
  type: SET_CLIENT_MASTER,
  key: key,
  value: value,
});

export const setMultipleClientMasterData = (data) => ({
  type: SET_MULTIPLE_CLIENT_MASTER,
  data: data,
});

export const reSetClientMasterData = () => ({
  type: RESET_CLIENT_MASTER,
});

export const setProjectData = (key, value) => ({
  type: SET_PROJECT,
  key: key,
  value: value,
});

export const setMultipleProjectData = (data) => ({
  type: SET_MULTIPLE_PROJECT,
  data: data,
});

export const reSetProjectData = () => ({
  type: RESET_PROJECT,
});

export const setTeamData = (key, value) => ({
  type: SET_TEAM,
  key: key,
  value: value,
});

export const setMultipleTeamData = (data) => ({
  type: SET_MULTIPLE_TEAM,
  data: data,
});

export const reSetTeamData = () => ({
  type: RESET_TEAM,
});

export const setTaskData = (key, value) => ({
  type: SET_TASK,
  key: key,
  value: value,
});

export const setMultipleTaskData = (data) => ({
  type: SET_MULTIPLE_TASK,
  data: data,
});

export const reSetTaskData = () => ({
  type: RESET_TASK,
});

export const setCloudProviderUserMapData = (key, value) => ({
  type: SET_CLOUD_PROVIDER_USER_MAP,
  key: key,
  value: value,
});

export const setMultipleCloudProviderUserMapData = (data) => ({
  type: SET_MULTIPLE_CLOUD_PROVIDER_USER_MAP,
  data: data,
});

export const reSetCloudProviderUserMapData = () => ({
  type: RESET_CLOUD_PROVIDER_USER_MAP,
});

export const setBoardData = (key, value) => ({
  type: SET_BOARD,
  key: key,
  value: value,
});

export const setMultipleBoardData = (data) => ({
  type: SET_MULTIPLE_BOARD,
  data: data,
});

export const reSetBoardData = () => ({
  type: RESET_BOARD,
});

export const setMyProfileData = (key, value) => ({
  type: SET_MY_PROFILE,
  key: key,
  value: value,
});

export const setMultipleMyProfileData = (data) => ({
  type: SET_MULTIPLE_MY_PROFILE,
  data: data,
});

export const reSetMyProfileData = () => ({
  type: RESET_MY_PROFILE,
});

export const setPreferencesData = (key, value) => ({
  type: SET_PREFERENCES,
  key: key,
  value: value,
});

export const setMultiplePreferencesData = (data) => ({
  type: SET_MULTIPLE_PREFERENCES,
  data: data,
});

export const reSetPreferencesData = () => ({
  type: RESET_PREFERENCES,
});

export const setNotificationData = (key, value) => ({
  type: SET_NOTIFICATION,
  key: key,
  value: value,
});

export const setMultipleNotificationData = (data) => ({
  type: SET_MULTIPLE_NOTIFICATION,
  data: data,
});

export const reSetNotificationData = () => ({
  type: RESET_NOTIFICATION,
});

export const setTimingWindowData = (key, value) => ({
  type: SET_TIMING_WINDOW,
  key: key,
  value: value,
});

export const setMultipleTimingWindowData = (data) => ({
  type: SET_MULTIPLE_TIMING_WINDOW,
  data: data,
});

export const reSetTimingWindowData = () => ({
  type: RESET_TIMING_WINDOW,
});

export const setTimezoneData = (key, value) => ({
  type: SET_TIMEZONE,
  key: key,
  value: value,
});

export const setMultipleTimezoneData = (data) => ({
  type: SET_MULTIPLE_TIMEZONE,
  data: data,
});

export const reSetTimezoneData = () => ({
  type: RESET_TIMEZONE,
});

export const setChatData = (key, value) => ({
  type: SET_CHAT,
  key: key,
  value: value,
});

export const setMultipleChatData = (channelId, data) => ({
  type: SET_MULTIPLE_CHAT,
  channelId,
  data,
});

export const reSetChatData = () => ({
  type: RESET_CHAT,
});

export const setChatChannelData = (key, value) => ({
  type: SET_CHAT_CHANNEL,
  key: key,
  value: value,
});

export const setMultipleChatChannelData = (data) => ({
  type: SET_MULTIPLE_CHAT_CHANNEL,
  data,
});

export const reSetChatChannelData = () => ({
  type: RESET_CHAT_CHANNEL,
});

export const setThreadChatData = (key, value) => ({
  type: SET_THREAD_CHAT,
  key: key,
  value: value,
});

export const setMultipleThreadChatData = (data) => ({
  type: SET_MULTIPLE_THREAD_CHAT,
  data: data,
});

export const reSetThreadChatData = () => ({
  type: RESET_THREAD_CHAT,
});

export const setAnalyticsData = (key, value) => ({
  type: SET_ANALYTICS,
  key: key,
  value: value,
});

export const setMultipleAnalyticsData = (data) => ({
  type: SET_MULTIPLE_ANALYTICS,
  data: data,
});

export const reSetAnalyticsData = () => ({
  type: RESET_ANALYTICS,
});

export const setExpenseManageData = (key, value) => ({
  type: SET_EXPENSE_MANAGE,
  key: key,
  value: value,
});

export const setMultipleExpenseManageData = (data) => ({
  type: SET_MULTIPLE_EXPENSE_MANAGE,
  data: data,
});

export const reSetExpenseManageData = () => ({
  type: RESET_EXPENSE_MANAGE,
});

export const setAppMaster = (data) => ({
  type: SET_APP_MASTER,
  data: data,
});

export const setMultipleAppMaster = (data) => ({
  type: SET_MULTIPLE_APP_MASTER,
  data: data,
});

export const reSetAppMaster = () => ({
  type: RESET_APP_MASTER,
});

export const setSubjectData = (key, value) => ({
  type: SET_SUBJECT,
  key: key,
  value: value,
});

export const setMultipleSubjectData = (data) => ({
  type: SET_MULTIPLE_SUBJECT,
  data: data,
});

export const reSetSubjectData = () => ({
  type: RESET_SUBJECT,
});
export const setClassRoomData = (key, value) => ({
  type: SET_CLASSROOM,
  key: key,
  value: value,
});

export const setMultipleClassRoomData = (data) => ({
  type: SET_MULTIPLE_CLASSROOM,
  data: data,
});

export const reSetClassRoomData = () => ({
  type: RESET_CLASSROOM,
});
export const setEducationTaskData = (key, value) => ({
  type: SET_EDUCATION_TASK,
  key: key,
  value: value,
});

export const setMultipleEducationTaskData = (data) => ({
  type: SET_EDUCATION_MULTIPLE_TASK,
  data: data,
});

export const reSetEducationTaskData = () => ({
  type: RESET_EDUCATION_TASK,
});


export const setEducationWorkspaceData = (key, value) => ({
  type: SET_EDUCATION_WORKSPACE,
  key: key,
  value: value,
});

export const setMultipleEducationWorkspaceData = (data) => ({
  type: SET_EDUCATION_MULTIPLE_WORKSPACE,
  data: data,
});

export const reSetEducationWorkpaceData = () => ({
  type: RESET_EDUCATION_WORKSPACE,
});


export const setEducationEntryReportData = (key, value) => ({
  type: SET_EDUCATION_ENTRY_REPORT,
  key: key,
  value: value,
});

export const setMultipleEducationEntryReportData = (data) => ({
  type: SET_EDUCATION_MULTIPLE_ENTRY_REPORT,
  data: data,
});

export const reSetEducationEntryReportData = () => ({
  type: RESET_EDUCATION_ENTRY_REPORT,
});

export const setEducationdBoardData = (key, value) => ({
  type: SET_EDUCATION_BOARD,
  key: key,
  value: value,
});

export const setMultipleEducationBoardData = (data) => ({
  type: SET_EDUCATION_MULTIPLE_BOARD,
  data: data,
});

export const reSetEducationBoardData = () => ({
  type: RESET_EDUCATION_BOARD,
});

export const setEducationdBoardStatustTypeData = (key, value) => ({
  type: SET_EDUCATION_BOARD_STATUS_TYPE,
  key: key,
  value: value,
});

export const setMultipleEducationBoardStatustTypeData = (data) => ({
  type: SET_MULTIPLE_EDUCATION_BOARD_STATUS_TYPE,
  data: data,
});

export const reSetEducationBoardStatustTypeBoardData = () => ({
  type: RESET_EDUCATION_BOARD_STATUS_TYPE,
});

export const setEducationExpensesData = (key, value) => ({
  type: SET_EDUCATION_EXPENSES,
  key: key,
  value: value,
});

export const setMultipleEducationExpensesData = (data) => ({
  type: SET_MULTIPLE_EDUCATION_EXPENSES,
  data: data,
});

export const reSetEducationExpensesData = () => ({
  type: RESET_EDUCATION_EXPENSES,
});

export const setEducationExpenseManageData = (key, value) => ({
  type: SET_EDUCATION_EXPENSE_MANAGE,
  key: key,
  value: value,
});

export const setMultipleEducationExpenseManageData = (data) => ({
  type: SET_MULTIPLE_EDUCATION_EXPENSE_MANAGE,
  data: data,
});

export const reSetEducationExpenseManageData = () => ({
  type: RESET_EDUCATION_EXPENSE_MANAGE,
});

export const setEducationExpensesCategoryData = (key, value) => ({
  type: SET_EDUCATION_EXPENSES_CATEGORY,
  key: key,
  value: value,
});

export const setMultipleEducationExpensesCategoryData = (data) => ({
  type: SET_MULTIPLE_EDUCATION_EXPENSES_CATEGORY,
  data: data,
});

export const reSetEducationExpensesCategoryData = () => ({
  type: RESET_EDUCATION_EXPENSES_CATEGORY,
});

export const setEducationCsvFileHistoryData = (key, value) => ({
  type: SET_EDUCATION_CSV_FILE_HISTORY,
  key: key,
  value: value,
});

export const setMultipleEducationCsvFileHistoryData = (data) => ({
  type: SET_MULTIPLE_EDUCATION_CSV_FILE_HISTORY,
  data: data,
});

export const reSetEducationCsvFileHistoryData = () => ({
  type: RESET_EDUCATION_CSV_FILE_HISTORY,
});

export const setEducationExpensesReportsData = (key, value) => ({
  type: SET_EDUCATION_EXPENSE_REPORTS,
  key: key,
  value: value,
});

export const setMultipleEducationExpensesReportsData = (data) => ({
  type: SET_MULTIPLE_EDUCATION_EXPENSE_REPORTS,
  data: data,
});

export const reSetEducationExpensesReportData = () => ({
  type: RESET_EDUCATION_EXPENSE_REPORTS,
});

export const setEducationReportsData = (key, value) => ({
  type: SET_EDUCATION_REPORT,
  key: key,
  value: value,
});

export const setMultipleEducationReportsData = (data) => ({
  type: SET_MULTIPLE_EDUCATION_REPORT,
  data: data,
});

export const reSetEducationReportsData = () => ({
  type: RESET_EDUCATION_REPORT,
});

export const setFaqData = (key, value) => ({
  type: SET_FAQ,
  key: key,
  value: value,
});

export const setMultipleFaqData = (data) => ({
  type: SET_MULTIPLE_FAQ,
  data: data,
});

export const reSetFaqData = () => ({
  type: RESET_FAQ,
});


export const setEducationAppsData = (key, value) => ({
  type: SET_EDUCATION_APPS,
  key: key,
  value: value,
});

export const setMultipleEducationAppsData = (data) => ({
  type: SET_MULTIPLE_EDUCATION_APPS,
  data: data,
});

export const reSetEducationAppsData = () => ({
  type: RESET_EDUCATION_APPS,
});

export const setNotesData = (key, value) => ({
  type: SET_NOTES,
  key: key,
  value: value,
});

export const setMultipleNotesData = (data) => ({
  type: SET_MULTIPLE_NOTES,
  data: data,
});

export const reSetNotesData = () => ({
  type: RESET_NOTES,
});


export const setVerifyAccoutData = (key, value) => ({
  type: SET_VERIFY_ACCCOUNT,
  key: key,
  value: value,
});

export const setMultipleVerifyAccoutData = (data) => ({
  type: SET_MULTIPLE_VERIFY_ACCCOUNT,
  data: data,
});

export const reSetVerifyAccoutData = () => ({
  type: RESET_VERIFY_ACCCOUNT,
});

export const setHiddenChannels = (key, value) => ({
  type: SET_HIDDEN_CHANNELS,
  key: key,
  value: value,
});

export const setMultipleHiddenChannels = (data) => ({
  type: SET_MULTIPLE_HIDDEN_CHANNELS,
  data: data,
});

export const reSetHiddenChannels = () => ({
  type: RESET_HIDDEN_CHANNELS,
});

export const setUserSessionReport = (key, value) => ({
  type: SET_USER_SESSION_REPORT,
  key: key,
  value: value,
});

export const setMultipleUserSessionReport = (data) => ({
  type: SET_MULTIPLE_USER_SESSION_REPORT,
  data: data,
});

export const reSetUserSessionReport = () => ({
  type: RESET_USER_SESSION_REPORT,
});

export const setMultipleCalendlyGuest = (data) => ({
  type: SET_MULTIPLE_CALENDLY_GUEST,
  data: data,
});

export const reSetCalendlyGuest = () => ({
  type: RESET_CALENDLY_GUEST,
});

export const setMultipleCalendlyEvent = (data) => ({
  type: SET_MULTIPLE_CALENDLY_EVENT,
  data: data,
});

export const reSetCalendlyEvent = () => ({
  type: RESET_CALENDLY_EVENT,
});

export const setUserLeaveRequest = (key, value) => ({
  type: SET_USER_LEAVE_REQUEST,
  key: key,
  value: value,
});

export const setMultipleUserLeaveRequest = (data) => ({
  type: SET_MULTIPLE_USER_LEAVE_REQUEST,
  data: data,
});

export const reSetUserLeaveRequest = () => ({
  type: RESET_USER_LEAVE_REQUEST,
});
export const setUserIpHistory = (key, value) => ({
  type: SET_USER_IP_HISTORY,
  key: key,
  value: value,
});

export const setMultipleUserIpHistory = (date, data) => ({
  type: SET_MULTIPLE_USER_IP_HISTORY,
  date,
  data
});

export const removeUserIpHistory = (date) => ({
  type: REMOVE_USER_IP_HISTORY,
  date
});

export const reSetUserIpHistory = () => ({
  type: RESET_USER_IP_HISTORY,
});

export const setSubServiceTypeData = (key, value) => ({
  type: SET_SUB_SERVICE_TYPE,
  key: key,
  value: value,
});

export const setSubMultipleServiceTypeData = (data) => ({
  type: SET_SUB_MULTIPLE_SERVICE_TYPE,
  data: data,
});

export const reSetSubServiceTypeData = () => ({
  type: RESET_SUB_SERVICE_TYPE,
});
export const setDonationData = (key, value) => ({
  type: SET_DONATION,
  key: key,
  value: value,
});

export const setMultipleDonationData = (data) => ({
  type: SET_MULTIPLE_DONATION,
  data: data,
});

export const reSetDonationData = () => ({
  type: RESET_DONATION,
});


export const setLangugeData = (key, value) => ({
  type: SET_LANG,
  key: key,
  value: value,
});

export const setMultipleLangugeData = (data) => ({
  type: SET_MULTIPLE_LANG,
  data: data,
});

export const reSetLangugeData = () => ({
  type: RESET_LANG,
});
