import React, { useEffect, useState } from "react";
import { getGrpcClient, getUuidv4 } from "../config/Common";
import GlobalDomainCons from "../config/GlobalDomainCons";
import GlobalEventCons from "../config/GlobalEventCons";
import GlobalMsgCons from "../config/GlobalMsgCons";
import { useSelector } from "react-redux";
import { ZiFetchRequest } from "../protobuf/generated/ZiFetchService_grpc_web_pb";
import Modal from "./Modal";

export default function InboxFeedbackNotification(props) {
    const auth = useSelector(state => state.authReducer);
    let { loginId } = auth;
    let [data, setData] = useState([]);
    let [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        getUnreadFeedBackInboxMessageList()
    }, [])

    function getUnreadFeedBackInboxMessageList() {
        let postJson = { reqId: getUuidv4(), memberId: loginId, orgId: "", pageLimit: 10, noOfPage: 1 };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.userSetDomain);
        request.setEvent(GlobalEventCons.getUnreadFeedBackInboxMessageList);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                setData(responseData.result)
            }
        });

    }

    function markAsRead(messageId) {
        let postJson = { messageId, memberId: loginId, status: "read", reqId: getUuidv4(), }
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.userSetDomain);
        request.setEvent(GlobalEventCons.updateInboxMessageReadStatus);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, async (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request");
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                console.log("Success")
            }
        });
    }

    // Function to extract the table HTML
    const extractTable = (htmlString) => {
        // Create a temporary DOM element to parse the HTML string
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;

        // Find the first table element
        const table = tempDiv.querySelector('table');

        // Return the outer HTML of the table if it exists, otherwise return null
        return table ? table.outerHTML : null;
    };

    function getData() {
        let { message } = data[activeIndex];
        const tableHTML = extractTable(message);

        return <React.Fragment>
            <div id="inbox-message" className={`inbox-message-view ${message.includes("<meta ") ? "font-black" : ""}`}>
                <div dangerouslySetInnerHTML={{ __html: tableHTML }}></div>
            </div>
        </React.Fragment>
    }
    return <React.Fragment>
        {
            data.length > 0 && <Modal
                visible={data.length > 0}
                heading={"Feedback Fixed"}
                closeModal={() => {
                    markAsRead(data[activeIndex].id)
                    setData([])
                }}
                body={<React.Fragment>
                    {getData()}

                    <div className="text-right my-2">
                        <button className="btn btn-primary" onClick={() => {
                            markAsRead(data[activeIndex].id)
                            if (data.length > (activeIndex + 1)) {
                                setActiveIndex(activeIndex + 1)
                            } else {
                                setData([])
                            }
                        }}>
                            {data.length === (activeIndex + 1) ? "Okay" : "Next"}
                        </button>
                    </div>
                </React.Fragment>}
            />
        }

    </React.Fragment>;
}
