import MyConfig from '../../config/MyConfig';
import {
  SET_PREFERENCES,
  SET_MULTIPLE_PREFERENCES,
  RESET_PREFERENCES
} from '../constants/actionTypes';

const initialState = {
  viewType: "notification",
};

const preferencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PREFERENCES: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_PREFERENCES: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_PREFERENCES: {
      return {
        ...state,
        viewType: "notification",
      };
    }
    default: {
      return state;
    }
  }
};

export default preferencesReducer;
