import {
  SET_EXPENSE_MANAGE,
  SET_MULTIPLE_EXPENSE_MANAGE,
  RESET_EXPENSE_MANAGE
} from '../constants/actionTypes';
const initialState = {
  userId: "",
  companyId: "",
  expenseDetails: {},
  expenseDetailsIndex: "",
  expenseDetailsIndex: false,
  expenseDetailsModal: false,
  selectedIds: {},
  pageLimit: 10,
  noOfPage: 1,
  pagination: [],
  prev: false,
  next: false,
  approveModal: false,
  result: {},
  counterList: {},
  statusOptions: [
    { value: "Approved", label: "Approved" },
    { value: "Rejected", label: "Rejected" },
  ],
  selectedStatusType: "all"
};

const expenseManageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EXPENSE_MANAGE: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_EXPENSE_MANAGE: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_EXPENSE_MANAGE: {
      return {
        ...state,
        userId: "",
        companyId: "",
        expenseDetails: {},
        expenseDetailsIndex: "",
        expenseDetailsIndex: false,
        expenseDetailsModal: false,
        selectedIds: {},
        pageLimit: 10,
        noOfPage: 1,
        pagination: [],
        prev: false,
        next: false,
        approveModal: false,
        result: {},
        counterList: {},
        statusOptions: [
          { value: "Pending", label: "Pending" },
          { value: "Approved", label: "Approved" },
          { value: "Rejected", label: "Rejected" },
        ],
        selectedStatusType: "all"
      };
    }
    default: {
      return state;
    }
  }
};

export default expenseManageReducer;
