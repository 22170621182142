import { Excalidraw, restoreElements, MainMenu } from "@excalidraw/excalidraw";
import { useEffect, useState } from "react";
import {
    fileOpen as _fileOpen,
    fileSave as _fileSave
} from "browser-fs-access";
function WhiteBoardRead(props) {
    const [excalidrawAPI, setExcalidrawAPI] = useState(null);
    const [viewMode, setViewMode] = useState(props.viewMode);
    let [dummyState, setDummyState] = useState(false);

    useEffect(() => {
        console.log("-->", props.content)
        setDummyState(true)
    }, [])

    useEffect(() => {
        if (props.content) {
            updateScene(props.content)
        }
    }, [props.content])

    const updateScene = (content) => {
        if (content && excalidrawAPI) {
            const sceneData = {
                elements: restoreElements(
                    content.elements,
                    null
                ),
                appState: content.appState
            };
            excalidrawAPI.updateScene(sceneData);
        }
    }

    return <div className="whiteboard">
        <Excalidraw viewModeEnabled={viewMode}
            initialData={{
                elements: restoreElements(
                    props.content.elements,
                    null
                ),
                appState: props.content.appState
            }}
            ref={(api) => setExcalidrawAPI(api)}
        >
            <MainMenu>
                <MainMenu.DefaultItems.LoadScene />
                <MainMenu.Item icon={<svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 20 20" className="" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"><path strokeWidth="1.25" d="M3.333 14.167v1.666c0 .92.747 1.667 1.667 1.667h10c.92 0 1.667-.746 1.667-1.667v-1.666M5.833 9.167 10 13.333l4.167-4.166M10 3.333v10"></path></svg>}>
                    Save
                </MainMenu.Item>
                <MainMenu.Item icon={<i className="fas fa-cloud"></i>}>
                    Upload
                </MainMenu.Item>
                <MainMenu.DefaultItems.SaveAsImage />
                <MainMenu.DefaultItems.ClearCanvas />
                <MainMenu.Separator />
                <MainMenu.DefaultItems.ToggleTheme />
            </MainMenu>
        </Excalidraw>
    </div>;
}

export default WhiteBoardRead;