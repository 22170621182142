import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import { getGrpcClient, getUuidv4 } from './Common';
import MyConfig from './MyConfig';
import MyConstant from './MyConstant';
import { readBinary, writeBinary } from './MyFileSystem';

var jsZip = require('jszip')

export function downloadLangFromUrl(langCode) {
    return new Promise(resolve => {
        let FILE_URL = MyConstant.keyList.apiURL + `downloadLang/${langCode}.zip?dataType=${langCode}`;

        fetch(FILE_URL, {
            headers: {
                'Cache-Control': 'no-cache'
            }
        })       // 1) fetch the url
            .then(function (response) {                       // 2) filter on 200 OK
                if (response.status === 200 || response.status === 0) {
                    return Promise.resolve(response.blob());
                } else {
                    return Promise.reject(new Error(response.statusText));
                }
            })
            .then(jsZip.loadAsync)                            // 3) chain with the zip promise
            .then(function (zip) {
                const promises = [];
                Object.keys(zip.files).forEach(function (filename) {
                    promises.push({ filename, value: zip.files[filename].async('string') });
                })

                resolve(promises); // 4) chain with the text content promise
            })
    });
}

export async function checkLangFileExistAndDownload(langCode, releasedDate) {
    let languageDownloadedListInfo = localStorage.getItem("languageDownloadedListInfo");
    let savedLangReleaseDate = ""
    if(languageDownloadedListInfo){
        languageDownloadedListInfo = JSON.parse(languageDownloadedListInfo)
        if(languageDownloadedListInfo[langCode]){
            savedLangReleaseDate = languageDownloadedListInfo[langCode]
        }
    } else {
        languageDownloadedListInfo = {}
    }
    
    let getsDownload = true
    if(releasedDate && savedLangReleaseDate){
        if(releasedDate == savedLangReleaseDate){
            getsDownload = false
        }
    }

    let data = await readBinary({ key: MyConfig.languageSetup+"_"+langCode })
    if(getsDownload || data === ""){
        await downloadLang(langCode)
    }
    languageDownloadedListInfo[langCode] = releasedDate
    localStorage.setItem("languageDownloadedListInfo", JSON.stringify(languageDownloadedListInfo))

    return "success";
}

export async function downloadLang(langCode) {
    let response = await downloadLangFromUrl(langCode);
    let files = []
    for (let i = 0; i < response.length; i++) {
        let { filename, value } = response[i]
        let json = await new Promise(function (resolve, reject) {
            value.then(function (json) {
                resolve(json)
            })
        })
        files.push({ filename, json })
    }
    writeBinary(MyConfig.languageSetup + "_" + langCode, files);
    return "success";
}

export async function getLanguageData() {
    let postJson = { platform: "" };
    let result = await fetch(MyConstant.keyList.apiURL + "getAppForceUpdateInfraData", {
        method: "POST",
        mode: 'cors',
        redirect: 'follow',
        body: JSON.stringify(postJson),
    }).then(
        function (response) {
            // console.log(response)
            return response.json();
        }
    ).then(json => {
        // console.log(json.langMap)
        // console.log(json.data)
        return json;
    }).catch(error => console.warn(error));

    let langaugeOption = {}
    if (Object.keys(result.langMap).length > 0) {
        Object.keys(result.langMap).map((key => {
            let lan = result.langMap[key]
            var obj = JSON.parse(lan)
            var releasedDate = ""
            var releasedStatus = obj.releasedStatus
            if (releasedStatus == "Yes") {
                releasedDate = obj.releasedDate
                langaugeOption[key] = releasedDate
            }
        }))
    }
    return langaugeOption
}
