import {
  SET_EDUCATION_BOARD,
  SET_EDUCATION_MULTIPLE_BOARD,
  RESET_EDUCATION_BOARD
} from '../constants/actionTypes';

const initialState = {
  columns: [],
  viewGroupId: "",
  userId: "",
  boardId: "",
  boardDetails: "",
  groupName: "",
  groupModal: false,
  errors: {},
  taskModal: false,
  projectOptions: [],
  selectedProject: "",
  selectedTask: "",
  taskOptions: [],
  dueDate: new Date(),
  selectedPriority: "",
  priorityOptions: [
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
  ],
  documentFiles: [],
  document_Json: [],
  taskIcon: [],
  memberIds: [],
  selectedGroup: "",
  viewTaskModal: false,
  viewTaskData: "",
  projectDetails: {},
  taskDetails: {},
  clientDetails: {},
  pageText: null,
  title: "",
  description: "",
  selectedColor: "",
  colorOptions: [
    { label: "Default", value: "" },
    { label: "Gray", value: "#ededed" },
    { label: "Brown", value: "#f7ddc3" },
    { label: "Orange", value: "#f9d3c1" },
    { label: "Yellow", value: "#f7f0b6" },
    { label: "Green", value: "#c7eac3" },
    { label: "Blue", value: "#b1d1f6" },
    { label: "Purple", value: "#e6d0fc" },
    { label: "Pink", value: "#f4d1e4" },
  ],
  userListOption: [],
  addUserListOption: [],
  addedUserListOption: [],
  editId: "",
  editGroupId: "",
  teamOptions: [],
  selectedTeam: "",
  companyId: "",
  selectedProjectId: "",
  addMembersModal: "",
  editTextChanges: false,
  editResponseData: {},
  updateStatusModal: [],
  toStatusId: "",
  taskStatusOptions: [],
  taskStatus: {}
};

const educationBoardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EDUCATION_BOARD: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_EDUCATION_MULTIPLE_BOARD: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_EDUCATION_BOARD: {
      return {
        ...state,
        columns: [],
        viewGroupId: "",
        userId: "",
        boardId: "",
        boardDetails: "",
        groupName: "",
        groupModal: false,
        errors: {},
        taskModal: false,
        projectOptions: [],
        selectedProject: "",
        selectedTask: "",
        taskOptions: [],
        dueDate: new Date(),
        selectedPriority: "",
        priorityOptions: [
          { label: "Low", value: "Low" },
          { label: "Medium", value: "Medium" },
          { label: "High", value: "High" },
        ],
        documentFiles: [],
        document_Json: [],
        taskIcon: [],
        memberIds: [],
        selectedGroup: "",
        viewTaskModal: false,
        viewTaskData: "",
        projectDetails: {},
        taskDetails: {},
        clientDetails: {},
        pageText: null,
        title: "",
        description: "",
        selectedColor: "",
        colorOptions: [
          { label: "Default", value: "" },
          { label: "Gray", value: "#ededed" },
          { label: "Brown", value: "#f7ddc3" },
          { label: "Orange", value: "#f9d3c1" },
          { label: "Yellow", value: "#f7f0b6" },
          { label: "Green", value: "#c7eac3" },
          { label: "Blue", value: "#b1d1f6" },
          { label: "Purple", value: "#e6d0fc" },
          { label: "Pink", value: "#f4d1e4" },
        ],
        userListOption: [],
        addUserListOption: [],
        addedUserListOption: [],
        editId: "",
        editGroupId: "",
        teamOptions: [],
        selectedTeam: "",
        companyId: "",
        selectedProjectId: "",
        addMembersModal: "",
        editTextChanges: false,
        editResponseData: {},
        updateStatusModal: [],
        toStatusId: "",
        taskStatusOptions: [],
        taskStatus: {}
      };
    }
    default: {
      return state;
    }
  }
};

export default educationBoardReducer;
