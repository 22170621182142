import { RESET_FORGOT_PASSWORD, SET_FORGOT_PASSWORD, SET_MULTIPLE_FORGOT_PASSWORD } from '../constants/actionTypes';

const initialState = {
  email: "",
  userId: "",
  errors: {
    email: ""
  },
  otp: "",
  password: "",
  errorsReset: {
    otp: "",
    password: "",
  },
  passwordVisible: false,
  confirmPassword: "",
};

const forgotPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FORGOT_PASSWORD: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_FORGOT_PASSWORD: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_FORGOT_PASSWORD: {
      return {
        ...state,
        email: "",
        userId: "",
        errors: {
          email: ""
        },
        otp: "",
        password: "",
        errorsReset: {
          otp: "",
          password: "",
        },
        pageText: null,
        passwordVisible: false,
        confirmPassword:""
      };
    }
    default: {
      return state;
    }
  }
};

export default forgotPasswordReducer;
