import React, { useEffect, useRef, useState } from "react";
import RecordHook from "./RecordHook";
import RecordScreenWithVideo from "./RecordScreenWithVideo";
import { getGrpcClient, getPageText, getUuidv4, getValidLabels } from "../../../config/Common";
import GlobalDomainCons from "../../../config/GlobalDomainCons";
import GlobalEventCons from "../../../config/GlobalEventCons";
import GlobalMsgCons from "../../../config/GlobalMsgCons";
import { ZiFetchRequest } from "../../../protobuf/generated/ZiFetchService_grpc_web_pb";
import TvAdd from "../tvManage/TvAdd";

let fileTypeOptions = [
    {label: "mp4", value: "mp4", video: true, audio: true},
    {label: "mp3", value: "mp3", video: true, audio: true},
    {label: "wav", value: "wav", video: true, audio: true},
]
export default function ScreenRecord(props) {
    let { setScreenRecord } = props;
    const [recordType, setRecordType] = useState("screen");
    const [recordStarted, setRecordStarted] = useState(false);
    const [stopRecord, setStopRecord] = useState(false);
    const [audio, setAudio] = useState(false);
    const [status, setStatus] = useState("idle");
    const [mediaBlobUrl, setMediaBlobUrl] = useState("");

    const [formModal, setFormModal] = useState(false);
    const [videoFileObj, setVideoFileObj] = useState({});
    const [pageText, setPageText] = useState({});
    const [languageList, setLanguageList] = useState([]);
    const [pageTextNew, setPageTextNew] = useState({});
    const [fileType, setFileType] = useState("mp4");


    const videoRef = useRef(null);


    useEffect(() => {
        if (status === "idle") {
            setRecordStarted(false)
        }
        getLanguageList()
    }, [])

    useEffect(async () => {
        let pageText = await getPageText('TVManagement');
        let pageTextNew = await getPageText('screenRecord');
        setPageText(pageText);
        setPageTextNew(pageTextNew)
    }, [props.languagePreference])


    function getLanguageList() {
        let postJson = { reqId: getUuidv4(), };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.uiSetDomain);
        request.setEvent(GlobalEventCons.languagesDataSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                let languageOptions = []
                let selectedVal = ""
                if (json && json.uiMap && Object.keys(json.uiMap).length !== 0) {
                    Object.keys(json.uiMap).map((key) => {
                        let lan = json.uiMap[key]
                        var obj = JSON.parse(lan)
                        languageOptions.push({ label: obj["name"], value: key })
                    })
                }

                setLanguageList(languageOptions)
            }
        });
    }

    async function createFile(url) {
        let ext = recordType === "video" ? "mp4" : "wav";
        let response = await fetch(url);
        let data = await response.blob();
        let file = new File([data], "Record." + ext);

        return file;
    }

    async function save(mediaBlobUrl) {
        let file = await createFile(mediaBlobUrl)
        setVideoFileObj(file)
        setFormModal(true)
    }

    function getBlobPropertyBag() {
        let type = 'video/mp4'

        if(recordType === "video" || recordType === "screen&video") {
            type = 'video/'+fileType
        } else {
            type = 'audio/'+fileType
        }

        return {
            type,
        };
    }

    return <React.Fragment>
        <div className={`screen-record ${(status === "recording" && (recordType === "screen" || recordType === "screen&video")) ? "start-record" : ""}`}>
            <div className="screen-record-container">
                <div className="screen-record-header d-flex">
                    <div className="title">
                        {getValidLabels(pageTextNew, 'screenRecordTxt')}                    </div>
                    <div className="screen-record-close" onClick={() => setScreenRecord(false)}>
                        <i className="fa fa-times" />
                    </div>
                </div>
                <div>
                    <div className="screen-record-body">
                        {
                            ((status === "idle" || status === "stopped") && !mediaBlobUrl) && <React.Fragment>
                                <div className="form-group">
                                    <label>{getValidLabels(pageTextNew, 'videoSettingsTxt')}</label>
                                    <div className="d-flex">
                                        <div className="flex-1 px-1 mx-2">
                                            <div className={`screen-record-box ${recordType === "screen" ? "active" : ""}`} onClick={() => setRecordType("screen")}>
                                                <i className="fas fa-desktop"></i>
                                                <div>{getValidLabels(pageTextNew, 'screenTxt')}</div>
                                            </div>
                                        </div>
                                        <div className="flex-1 px-1 mx-2">
                                            <div className={`screen-record-box ${recordType === "video" ? "active" : ""}`} onClick={() => setRecordType("video")}>
                                                <i className="fas fa-camera"></i>
                                                <div>{getValidLabels(pageTextNew, 'videoTxt')}</div>
                                            </div>
                                        </div>
                                        <div className="flex-1 px-1 mx-2">
                                            <div className={`screen-record-box ${recordType === "screen&video" ? "active" : ""}`} onClick={() => setRecordType("screen&video")}>
                                                <span className='beta'>{getValidLabels(pageTextNew, 'betaTxt')}</span>
                                                <i className="fas fa-photo-video"></i>
                                                <div>{getValidLabels(pageTextNew, 'screenVideoTxt')}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    recordType !== "screen&video" && <div className="form-group mt-2">
                                        <label>{getValidLabels(pageTextNew, 'audioSettingsTxt')}</label>
                                        <div>
                                            <div className="form-check form-check-inline">
                                                <input name="audio" className="form-check-input" type="radio" id="audioMute" value="mute" checked={!audio} onChange={() => setAudio(false)} />
                                                <label className="form-check-label" htmlFor="audioMute">{getValidLabels(pageTextNew, 'muteTxt')}</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input name="audio" className="form-check-input" type="radio" id="audioUnmute" value="unmute" checked={audio} onChange={() => setAudio(true)} />
                                                <label className="form-check-label" htmlFor="audioUnmute">{getValidLabels(pageTextNew, 'unMuteTxt')}</label>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className="form-group mt-2">
                                        <label>{"File Type"}</label>
                                        <div>
                                            {
                                                fileTypeOptions.map((item, index) => {
                                                    let { label, value } = item
                                                    return <div className="form-check form-check-inline">
                                                        <input name="fileType" className="form-check-input" type="radio" id={"fileType"+index} checked={fileType === value} onChange={() => setFileType(value)} />
                                                        <label className="form-check-label" htmlFor={"fileType"+index}>{label}</label>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>

                                <button className="btn btn-block btn-theme mt-5" onClick={() => {
                                    // startRecording();
                                    setRecordStarted(true)
                                }}>{getValidLabels(pageTextNew, 'startRecordingTxt')}</button>
                            </React.Fragment>
                        }
                        {
                            recordStarted && <React.Fragment>
                                {
                                    recordType === "screen&video" ? <RecordScreenWithVideo
                                        audio={audio}
                                        setStatus={setStatus}
                                        setRecordStarted={setRecordStarted}
                                        setMediaBlobUrl={setMediaBlobUrl}
                                        recordType={recordType}
                                        save={(mediaBlobUrl) => save(mediaBlobUrl)}
                                        stopRecord={stopRecord}
                                        videoRef={videoRef}
                                        mediaBlobUrl={mediaBlobUrl}
                                        blobPropertyBag={getBlobPropertyBag()}
                                    /> :
                                        <RecordHook
                                            screen={recordType === "screen"}
                                            audio={audio}
                                            video={recordType === "video"}
                                            setStatus={setStatus}
                                            setRecordStarted={setRecordStarted}
                                            setMediaBlobUrl={setMediaBlobUrl}
                                            recordType={recordType}
                                            save={(mediaBlobUrl) => save(mediaBlobUrl)}
                                            stopRecord={stopRecord}
                                            blobPropertyBag={getBlobPropertyBag()}
                                        />
                                }
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
            {
                <TvAdd
                    pageText={pageText}
                    formModal={formModal}
                    setFormModal={setFormModal}
                    workspaceType={props.workspaceType}
                    refreshData={() => { }}
                    editId={""}
                    languageList={languageList}
                    grpcClient={props.grpcClient}
                    videoFileObj={videoFileObj}
                />
            }
        </div>
        {
            (status === "recording" && (recordType === "screen" || recordType === "screen&video")) && <div className="stop-record-circle" onClick={() => {
                setStopRecord(true)
            }}>
                <i className="far fa-stop-circle"></i>
            </div>
        }

        {
            (recordStarted && status !== "stopped" && recordType === "screen&video") && <div className="video-record-circle">
                <video ref={videoRef} autoPlay />
            </div>
        }

    </React.Fragment>
};

