import React from 'react';
import { getPageText, getValidLabels } from '../config/Common';


export default class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageText: null,
            pre: "",
            nxt: "",
            pathUrl:""
        }
    }
    
    componentDidMount() {
        const pathUrl = window.location.href.split('/')[3] + "/" +window.location.href.split('/')[4]
        this.setState({pathUrl},()=>{
            this.getPageText()
        })
        
    }
    async getPageText() {
        let {pathUrl} = this.state
        let pageText = await getPageText('manage');
        let pre = getValidLabels(pageText, 'previousBtnTxt')
        let nxt = getValidLabels(pageText, 'nextBtnTxt')
        if(pathUrl === "teqit/manage"){
         pre = "Previous"
         nxt = "Next"
        }
        this.setState({ pageText: pageText, pre: pre, nxt: nxt })
    }
    render() {
        let { pagination, prev, next, movePage, currentPage, pageText, totalResultCount, totalCountNumberShow } = this.props;
        let { pre, nxt } = this.state;
        let lastItem = pagination[pagination.length - 1];
        let paginationArr = Array(lastItem).fill(1).map((n, i) => n + i)

        return (
            <React.Fragment>
                <nav>
                    <div className='d-flex my-2'>
                        {totalCountNumberShow && totalResultCount ? <div className='w-30'>
                            <div className='owner-details p-3'>Total Count - {totalResultCount}</div>
                        </div> : null}

                        <div className='flex-1'>
                            <ul className="pagination justify-content-end ">
                                <li className={`page-item ${prev ? "" : "disabled"}`}>
                                    <a className="page-link" onClick={() => movePage('prev')}>{pre}</a>
                                </li>
                                {
                                    pagination.map((page, index) => {
                                        return <li key={index} className={`page-item ${currentPage === page ? "active" : ((page === 0 || page === "...") ? "disabled" : "")}`}><a className="page-link" onClick={() => movePage(page)}>{(page === 0 || page === "...") ? "..." : page}</a></li>
                                    })
                                }
                                <li className={`page-item ${next ? "" : "disabled"}`}>
                                    <a className="page-link" onClick={() => movePage('next')}>{nxt}</a>
                                </li>
                                <li className={`page-item ml-2`}>
                                    {
                                        lastItem > 10 && <select className='form-control' value={currentPage} onChange={(e) => {
                                            movePage(parseInt(e.target.value))
                                        }}>
                                            {
                                                paginationArr.map(function (x, i) {
                                                    return <option value={x} key={i}>{x}</option>;
                                                })
                                            }
                                        </select>
                                    }
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </React.Fragment>
        );
    }
}