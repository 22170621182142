import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import { getGrpcClient, getUuidv4, getDateWithFormat } from "../config/Common";
import { ZiFetchRequest } from "../protobuf/generated/ZiFetchService_grpc_web_pb";
import GlobalMsgCons from "../config/GlobalMsgCons";
import { useSelector } from "react-redux";
import ReactTooltip from 'react-tooltip';


function ViewedByModal(props) {
    const auth = useSelector(state => state.authReducer);


    let [viewedByModal, setViewedByModal] = useState(false)
    let [existUsers, setExistUsers] = useState([])
    let postJsonInfo = props.postJsonInfo !== undefined ? props.postJsonInfo : {}

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [])

    function getSharedUsersDetails(switchComId) {
        let postJson = { reqId: getUuidv4(), orgId: auth.activeCompany, userId: auth.loginId };
        postJson = { ...postJson, ...postJsonInfo }
        if (switchComId) {
            postJson.orgId = switchComId
        }
        const request = new ZiFetchRequest();
        request.setDomain(props.domain);
        request.setEvent(props.getSharedUserDetailsEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.log("Invalid Request. Please try again later")
            } else {
                let responseData = JSON.parse(response.getMessage());
                setExistUsers(responseData.result)
                setViewedByModal(true)
            }
        });
    }

    return <React.Fragment>
        <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
        <button className="btn btn-sm btn-success share-button mx-2" onClick={() => {
            getSharedUsersDetails()
        }} data-tip={"Viewed by"}>Viewed by</button>
        {
            viewedByModal && <Modal
                visible={viewedByModal}
                closeModal={() => {
                    setViewedByModal(false)
                }}
                size={`modal-md`}
                heading={"Viewed by"}
                body={<div>
                    {
                        Object.keys(existUsers).length > 0 && <div className="">
                            <label>
                                Users
                            </label>
                            {
                                Object.keys(existUsers).map((user, index) => {
                                    let { lastViewedAt } = existUsers[user]
                                    return <div className="mb-2" key={user}>
                                        <i className="fas fa-user mr-2"></i> {existUsers[user].emailId + " (" + existUsers[user].orgName + ")"} - <span className={`p-1 pl-2 pr-2 badge badge-pill badge-${lastViewedAt ? "primary" : "secondary"}`}>
                                            {lastViewedAt ? getDateWithFormat(lastViewedAt, auth.activeCompanyDetails) : "Not viewed"}
                                        </span>
                                    </div>
                                })
                            }
                        </div>
                    }

                </div>}
            />
        }
    </React.Fragment>

}

export default ViewedByModal
