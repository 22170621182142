import {
    SET_EDUCATION_APPS,
    SET_MULTIPLE_EDUCATION_APPS,
    RESET_EDUCATION_APPS
} from '../constants/actionTypes';

const initialState = {
    apps: [],
    pageLimit: 9,
    noOfPage: 1,
    appsMasterView: false,
    userId: "",
    companyId: "",
    ownerId: "",
    appsDetails: {},
    addAppsModal: false,
    loadMore: false,
    subjectOptions: [],
    subjectListOptions: [],
    subjectSelect: { subjectId: "", value: "", select: "" },
    publicApps: [],
    totalPublicAppsResults: 0,
    noOfPagePublic: 1,
};

const educationAppsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_EDUCATION_APPS: {
            return {
                ...state,
                [action.key]: action.value,
            };
        }
        case SET_MULTIPLE_EDUCATION_APPS: {
            return {
                ...state,
                ...action.data,
            };
        }
        case RESET_EDUCATION_APPS: {
            return {
                ...state,
                apps: [],
                pageLimit: 10,
                noOfPage: 1,
                appsMasterView: false,
                userId: "",
                companyId: "",
                ownerId: "",
                appsDetails: {},
                addAppsModal: false,
                loadMore: false,
                subjectOptions: [],
                subjectListOptions: [],
                subjectSelect: { projectId: "", value: "", select: "" },
                publicApps: [],
                totalPublicAppsResults: 0,
                noOfPagePublic: 1,
            };
        }
        default: {
            return state;
        }
    }
};

export default educationAppsReducer;
