import React from "react";
import Pagination from "./Pagination";
import { getGrpcClient, pageNumbers, getUuidv4 } from "../config/Common";
import { ZiFetchRequest } from "../protobuf/generated/ZiFetchService_grpc_web_pb";
import { getPageText, getValidLabels } from '../config/Common';
import GlobalMsgCons from "../config/GlobalMsgCons";
import ReactTooltip from 'react-tooltip';
import DoubleScrollbar from "./DoubleScrollbar";

export default class PaginationDatatable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: [],
            pageLimit: 10,
            noOfPage: 1,
            pagination: [],
            prev: false,
            next: false,
            pageText: null,
            processing: true,
            objectResult: {},
            totalResultCount: 0
        }
    }

    componentDidMount() {
        this.getList()
        this.getPageText()
        ReactTooltip.rebuild();
    }

    componentDidUpdate(prevProps) {
        if (this.props.domain !== prevProps.domain || this.props.event !== prevProps.event || this.props.refresh !== prevProps.refresh) {
            this.setState({
                processing: true
            }, () => {
                this.getList()
            })
            this.getPageText()
        }

        if (this.props.dataRefresh !== prevProps.dataRefresh) {
            this.currentDataRefresh()

        }
    }

    sortByKey(obj, orderKey) {
        let sortRes = {}
        Object.keys(obj).map((key, index) => {
            let value = obj[key];
            if (orderKey.includes(".")) {
                orderKey.split('.').forEach(function (item) {
                    value = value[item]
                })
            } else {
                value = obj[orderKey]
            }
            return sortRes[key] = value;
        });
        let keysSorted = Object.keys(sortRes).sort(function (a, b) { return sortRes[b] - sortRes[a] })

        return keysSorted;
    }

    getList() {
        let { domain, event, data, postJsonInfo, sortBy, getResultData } = this.props;
        if (typeof domain !== "undefined" && typeof event !== "undefined") {
            postJsonInfo = postJsonInfo !== undefined ? postJsonInfo : {}
            let { pageLimit, noOfPage } = this.state;
            let postJson = { sessionId: '123', pageLimit, noOfPage, reqId: getUuidv4(), };
            postJson = { ...postJson, ...postJsonInfo }
            const request = new ZiFetchRequest();
            request.setDomain(domain);
            request.setEvent(event);
            request.setMessage(JSON.stringify(postJson));
            getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    let value = [];
                    let result = responseData.result ? responseData.result : responseData.response;
                    let keysSorted = Object.keys(result)
                    if (sortBy !== undefined) {
                        keysSorted = this.sortByKey(result, sortBy);
                    }
                    if (getResultData) {
                        getResultData(result,noOfPage)
                    }
                    let tableNoRows = (pageLimit * noOfPage) - (pageLimit - 1)

                    keysSorted.map((key, index) => {
                        let keyData = result[key];
                        if (typeof keyData.tableNoRows === "undefined") {
                            keyData.tableNoRows = tableNoRows
                            tableNoRows = tableNoRows + 1
                        }
                        value[index] = [];
                        data.map((column, columnIndex) => {
                            let input = "";
                            if (typeof column.selector !== "undefined") {
                                let value = keyData;
                                if (column.selector.includes(".")) {
                                    column.selector.split('.').forEach(function (item) {
                                        value = value[item]
                                    })
                                } else {
                                    value = keyData[column.selector]
                                }
                                input = value;
                            } else if (typeof column.cell !== "undefined") {
                                input = column.cell(keyData)
                            }
                            value[index][columnIndex] = input;
                        })
                    })



                    let next = false;
                    let prev = false;

                    let totalResult = responseData.totalResult
                    let currentResult = noOfPage * pageLimit;
                    if (currentResult < totalResult) {
                        next = true;
                    }
                    if (noOfPage > 1) {
                        prev = true;
                    }

                    if (this.props.setTotalResult !== undefined) {
                        this.props.setTotalResult(totalResult)
                    }
                    let pageCount = Math.ceil(totalResult / pageLimit);
                    let pagination = pageNumbers(pageCount, noOfPage, 5);

                    this.setState({
                        value,
                        pagination,
                        next,
                        prev,
                        processing: false,
                        objectResult: result,
                        totalResultCount: totalResult
                    })
                }
            });
        }
    }

    movePage(page) {
        let pageNo = page
        if (page === "next") {
            if (this.state.next) {
                pageNo = this.state.noOfPage + 1;
            }
        } else if (page === "prev") {
            if (this.state.prev) {
                pageNo = this.state.noOfPage - 1;
            }
        }

        if (pageNo !== "next" && pageNo !== "prev") {
            let scrollView = document.getElementById('scrolltop')
            this.setState({
                noOfPage: pageNo,
                processing: true
            }, () => {
                if (scrollView) {
                    scrollView.scrollIntoView({
                        behavior: 'smooth'
                    });
                }

                this.getList();
            })
        }
    }
    async getPageText() {
        let pageText = await getPageText('team');
        this.setState({ pageText: pageText })
    }

    currentDataRefresh() {
        let { objectResult, noOfPage, pageLimit } = this.state;
        let { data, sortBy } = this.props;
        if (Object.keys(objectResult).length) {
            let value = [];
            let result = objectResult;
            let keysSorted = Object.keys(result)
            if (sortBy !== undefined) {
                keysSorted = this.sortByKey(result, sortBy);
            }

            keysSorted.map((key, index) => {
                let keyData = result[key];
                let tableNoRows = (pageLimit * noOfPage) - (pageLimit - 1)
                if (typeof keyData.tableNoRows === "undefined") {
                    keyData.tableNoRows = tableNoRows
                    tableNoRows = tableNoRows + 1
                }
                value[index] = [];
                data.map((column, columnIndex) => {
                    let input = "";
                    if (typeof column.selector !== "undefined") {
                        let value = keyData;
                        if (column.selector.includes(".")) {
                            column.selector.split('.').forEach(function (item) {
                                value = value[item]
                            })
                        } else {
                            value = keyData[column.selector]
                        }
                        input = value;
                    } else if (typeof column.cell !== "undefined") {
                        input = column.cell(keyData)
                    }
                    value[index][columnIndex] = input;
                })
            })


            this.setState({
                value,
            })


        }

    }

    render() {
        let { data, renderMobileView, headerHide, parentClassName } = this.props;
        let { value, pagination, next, prev, noOfPage, pageText, processing, totalResultCount } = this.state;
        return <div>
            <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
            {renderMobileView ? <div>
                <div className={`${parentClassName ? parentClassName : ""} ${processing && "table-processing"}`} id="scrolltop">
                    {processing && <div className="processing"><span>Processing...</span></div>}
                    {
                        value.length > 0 ? value.map((row, index) => {
                            return <div key={index}>{this.props.render(row)}</div>
                        }) : <div className="text-center"> {getValidLabels(pageText, 'noRecordsFoundTxt')}</div>
                    }
                </div>
            </div> :
                <div className={`${parentClassName ? parentClassName : ""} ${processing && "table-processing"}`} id="scrolltop">
                    {processing && <div className="processing"><span>Processing...</span></div>}
                    <DoubleScrollbar>

                        <table className="table table-bordered ">
                            {!headerHide ? <thead>
                                <tr >
                                    {
                                        data.map((row, index) => {
                                            return <th key={index} data-tip={row.tooltip}>{row.column}</th>
                                        })
                                    }
                                </tr>
                            </thead> : null}

                            <tbody>
                                {
                                    value.length > 0 ? value.map((row, index) => {
                                        return <tr key={index}>
                                            {
                                                data.map((column, columnIndex) => {
                                                    return <td key={columnIndex}>{value[index][columnIndex]}</td>
                                                })
                                            }
                                        </tr>
                                    }) : <tr>
                                        <td className="text-center" colSpan={data.length}>{processing ? "Processing" : getValidLabels(pageText, 'noRecordsFoundTxt')}</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </DoubleScrollbar>
                </div>
            }
            <Pagination
                pagination={pagination}
                currentPage={noOfPage}
                prev={prev}
                next={next}
                movePage={(type) => {
                    this.movePage(type)
                }}
                totalCountNumberShow={this.props.totalCountNumberShow}
                totalResultCount={totalResultCount}
            />
        </div>;
    }
}
