import React from "react";
import { ZiFetchRequest } from "../../protobuf/generated/ZiFetchService_grpc_web_pb";
import { v4 as uuidv4 } from 'uuid';
import MyConstant from "../../config/MyConstant";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Picker from 'emoji-picker-react';
import { Link, withRouter } from 'react-router-dom';
import CustomEditor from "../../components/CustomEditor";
import Modal from "../../components/Modal";
import { PDFViewer } from 'react-view-pdf';
import Recorder from "../../components/Recorder";
import GifPicker from "../../components/GifPicker";
import { getDateWithFormat, getGrpcClient, getPageText, getValidLabels, getUuidv4, getGrpcPollingClient, urlify, capitalize, getAppInfo, coversionED, getOnlyDateWithFormat } from "../../config/Common";
import { setChatData, setMultipleThreadChatData } from '../../redux/actions';
import { connect } from 'react-redux';
import GlobalDomainCons from '../../config/GlobalDomainCons';
import GlobalEventCons from '../../config/GlobalEventCons';
import GlobalMsgCons from "../../config/GlobalMsgCons";
import ProcessAudioToText from "./component/ProcessAudioToText";
import VoiceToText from "../../components/VoiceToText";
import ReactTooltip from 'react-tooltip';

let intervalId;
let intervalChannelId;
let lastMessage;


let color = {
    "A": "#673ab7",
    "B": "#3f51b5",
    "C": "#2196f3",
    "D": "#00bcd4",
    "E": "#009688",
    "F": "#4caf50",
    "G": "#8bc34a",
    "H": "#cddc39",
    "I": "#ffeb3b",
    "J": "#ffc107",
    "K": "#ff9800",
    "L": "#795548",
    "M": "#673ab7",
    "N": "#3f51b5",
    "O": "#2196f3",
    "P": "#00bcd4",
    "Q": "#009688",
    "R": "#4caf50",
    "S": "#8bc34a",
    "T": "#cddc39",
    "U": "#ffeb3b",
    "V": "#ffc107",
    "W": "#ff9800",
    "X": "#795548",
    "Y": "#9e9e9e",
    "Z": "#9e9e9e",
}
class ChatThread extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageText: null,
            typingUserList: {},
            messageEmojiPicker: false,
            messageIdEmojiPicker: "",
            messageEmojiPickerPosition: {},
            emojiHoverView: "",
            processAudioToText: "",
            resultAudioToText: {},
            chatMessageSeenModal: false,
            chatMessageSeenList: [],
        }
        this.saveAudio = this.saveAudio.bind(this);
        this.processAudioToText = this.processAudioToText.bind(this);
        this.messageEnd = React.createRef();
    }

    componentDidMount() {
        this.getPageText()
        let { channelId, feed } = this.props.match.params;
        this.props.setMultipleThreadChatData({
            channelId,
            userChatHistory: {},
            currentResult: 0,
            message: "",
            emojiPicker: false,
            fileList: [],
            isRender: true,
            isFeedPage: feed ? true : false,
            noOfPage: 1,
            tempMessageList: {},
            chatMessageSeenMessage: ""
        })

        if (channelId && channelId !== "nill") {
            this.init(channelId);

        }

    }
    componentDidUpdate(prevProps) {

        if (prevProps.threadData !== this.props.threadData || prevProps.refreshThreadData !== this.props.refreshThreadData) {
            let { channelId, threadData, userChatHistory, currentResult } = this.props;
            console.log("thread refresh**", threadData.msgType)
            // let replyThreadId = ""
            if (threadData.msgType === "ThreadMsgArrived" || threadData.msgType === "ThreadMsgEdited" || threadData.msgType === "init") {
                if (threadData.msgType === "ThreadMsgArrived" || threadData.msgType === "ThreadMsgEdited") {
                    this.props.setMultipleThreadChatData({
                        emojiPicker: false,
                        fileList: [],
                        isRender: true,
                    })
                } else if (threadData.msgType === "init") {
                    this.props.setMultipleThreadChatData({
                        userChatHistory: {},
                        currentResult: 0,
                        message: "",
                        emojiPicker: false,
                        fileList: [],
                        isRender: true,
                        noOfPage: 1,
                        tempMessageList: {}
                    })
                }

                this.init(channelId);

            } else if (threadData.msgType === "ThreadMsgDeleted" && threadData.replyThreadId) {
                let filterData = Object.keys(userChatHistory).filter((msgkey) => {
                    if (userChatHistory[msgkey].chatThreadMessage.id && userChatHistory[msgkey].chatThreadMessage.id === threadData.replyThreadId) {
                        return msgkey
                    }
                    return false
                })

                if (Array.isArray(filterData) && filterData.length > 0) {
                    let deletedIndex = filterData[0];
                    delete userChatHistory[deletedIndex]
                    console.log("-->***",)
                    this.props.setMultipleThreadChatData({
                        userChatHistory,
                        currentResult: currentResult - 1
                    })

                }

            }


        }

        if (prevProps.languagePreference !== this.props.languagePreference) {
            this.getPageText()
        }
    }



    saveAudio(type, blob) {
        let uid = uuidv4();
        let audioFile = new File([blob], "record_" + uid + ".mp3")
        let fileUrl = URL.createObjectURL(audioFile);
        let file = audioFile;
        let filename = audioFile.name;
        let ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
        let fileObj = {
            file: file,
            origFileName: filename,
            fileUrl: fileUrl,
            fileName: uid + "." + ext,
            docsId: uid,
            processType: "chat-record",
            fileType: ext,
        };

        let fileList = [];
        let inc = 0;
        fileList[inc] = fileObj;
        fileList[inc].uploadProcess = "pending";

        this.props.setMultipleThreadChatData({
            fileList,
            recordingModal: false
        })
        inc++;
    }


    init(channelId,) {
        let { threadData } = this.props;
        let { message } = threadData
        message.chatThreadMessage = message.chatMessage
        message.parent = true
        this.getChannelChats(channelId, 1, true, {
            [message.chatThreadMessage.createdAt]: message
        },);
    }

    getChatTypingInfo(channelId) {
        let { userId } = this.props;
        let postJson = { reqId: getUuidv4(), channelId };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.getChatTypingInfo);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)

                let result = responseData.result;
                let list = [];
                if (Object.keys(result).length > 0) {
                    Object.keys(result).map((id) => {
                        if (userId !== id) {
                            list.push(capitalize(result[id]))
                        }
                    })
                }
                this.setState({
                    typingUserList: list
                })
            }
        });
    }

    getChannelChatsNew(channelId, noOfPage, scrollBottom) {
        let { loginId, sessionId } = this.props;

        let postJson = { reqId: getUuidv4(), sessionId: sessionId, channelId, userId: loginId, pageLimit: 25, noOfPage: noOfPage };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.channelChatViaListenerSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let { userChatHistory, deleteMessageId } = this.props;
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                if (responseData.channelId === this.props.channelId) {
                    let totalMsgCount = responseData.totalResult
                    let result = userChatHistory;
                    let deletedMsg = false

                    let { deletedIdList, newMsgs, editedMsgs } = responseData
                    if (deleteMessageId.length > 0) {
                        let chat_his = {}
                        Object.keys(result).map((key) => {
                            if (!deleteMessageId.includes(result[key].id)) {
                                chat_his[key] = { ...result[key] }
                            }
                        })
                        result = chat_his
                    }

                    if (deletedIdList.length > 0) {
                        let chatHistory = {}
                        deletedIdList.map((msgId) => {
                            Object.keys(result).map((key) => {
                                if (result[key].chatThreadMessage.id === msgId) {
                                    deleteMessageId.push(msgId)
                                    deletedMsg = true
                                }
                                else {
                                    chatHistory[key] = { ...result[key] }
                                }
                            })
                        })
                        result = chatHistory
                        totalMsgCount = Object.keys(result).length
                    }

                    if (newMsgs.length > 0 && !deletedMsg) {
                        newMsgs.map((ival, i) => {
                            result[ival.chatThreadMessage.createdAt] = ival;
                        });
                    }


                    if (editedMsgs.length > 0 && !deletedMsg) {
                        responseData.editedMsgs.map((ival, i) => {
                            result[ival.chatThreadMessage.createdAt] = ival;
                        });
                    }
                    this.props.setMultipleThreadChatData({
                        userChatHistory: result,
                        currentResult: totalMsgCount,
                        scrollBottom: false,
                        deleteMessageId,
                        tempMessageList: {}
                    })

                    if (newMsgs.length > 0) {
                        this.scrollBottom();
                    }
                }
            }

        });
    }

    getChannelChats(channelId, noOfPage, scrollBottom, userChatHistory,) {
        let { threadData, tempMessageList, loginId } = this.props;
        let postJson = { reqId: getUuidv4(), memberId: loginId, channelId, pageLimit: 25, noOfPage: noOfPage, messageId: threadData.message.chatThreadMessage.id };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.getChatThread);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                let result = userChatHistory;
                responseData.result.map((ival, i) => {
                    result[ival.chatThreadMessage.createdAt] = ival;
                });

                tempMessageList[channelId] = []
                this.props.setMultipleThreadChatData({
                    userChatHistory: result,
                    currentResult: responseData.totalResult,
                    tempMessageList
                })
                if (scrollBottom) {
                    this.scrollBottom();
                } else if (Object.keys(result)[((noOfPage - 1) * 25)]) {
                    //focus div
                    document.getElementById(Object.keys(result)[((noOfPage - 1) * 25)]).scrollIntoView();
                }
                let filterNotReadUsers = Object.values(result).filter((e) => {
                    return e.readStatus === false
                })
                let filterMsdIds = []
                if (Array.isArray(filterNotReadUsers)) {
                    filterMsdIds = filterNotReadUsers.map((e) => { return e.chatThreadMessage.id })
                }
                this.updateThreadMessage(filterMsdIds)
            }
        });
    }

    async editChannelSubmit(event, gifURL) {
        event.preventDefault();
        let { threadData, channelId, fileList, loginId, editMessageText, editMessageId, userChatHistory, editMessageDetails, setEmptyText } = this.props;

        if ((editMessageText.trim() !== "") || fileList.length > 0 || gifURL !== "") {
            for (let i = 0; i < fileList.length; i++) {
                await this.fileUpload(i, fileList[i]);
            }

            if (gifURL !== "") {
                editMessageText = "";
                fileList = [];
            }
            let postJson = {
                reqId: getUuidv4(),
                messageId: threadData.message.chatThreadMessage.id,
                channelId,
                threadMsgId: editMessageId,
                senderId: loginId,
                message: editMessageText,
                editType: "chat",
                callData: "",
                fileList: fileList,
                objUrl: gifURL,
                deviceInfo: getAppInfo()
            };
            postJson = coversionED("encrypt", postJson).toString()
            let request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.chatSetDomain);
            request.setEvent(GlobalEventCons.editChatThread);
            request.setMessage(postJson);
            request.setDatetime(new Date().toString());
            getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = response.getMessage();
                    responseData = coversionED("decrypt", responseData)
                    responseData = JSON.parse(responseData)
                    if (responseData.response === GlobalMsgCons.success) {
                        editMessageDetails.message = editMessageText;
                        editMessageDetails.fileList = fileList;
                        userChatHistory[editMessageDetails.createdAt].chatThreadMessage = editMessageDetails
                        this.props.setMultipleThreadChatData({
                            editMessageId: "",
                            editMessageText: "",
                            editMessageDetails: {},
                            userChatHistory,
                            setEmptyText: !setEmptyText,
                            emojiPicker: false,
                            fileList: [],
                            gifURL: ""
                        })
                    }
                }
            });
        }
    }

    scrollBottom() {
        this.messageEnd.scrollTop = this.messageEnd.scrollHeight;
    }

    handleClickOutside(e) {
        let { messageEmojiPicker } = this.state;
        if (messageEmojiPicker && !e.target.closest(".emoji-message") && !(e.target.className.includes("emoji-from-picker") || e.target.closest(".emoji-from-picker"))) {
            this.setState({
                messageEmojiPicker: false
            })
        }
    }

    componentWillUnmount() {
        clearInterval(intervalId);
        clearInterval(intervalChannelId);
    }

    setChatMessage(e) {
        this.props.setMultipleThreadChatData({
            message: e
        });
    }

    edit(details) {
        this.props.setMultipleThreadChatData({
            editMessageId: details.id,
            editMessageDetails: details,
            editMessageText: details.message,
            changeText: details.message,
            fileList: details.fileList,
            editBlink: true
        });

        setTimeout(() => {
            this.props.setMultipleThreadChatData({
                editBlink: false
            })
        }, 3000)
    }


    async addChannelChat(event, gifURL) {
        event.preventDefault();
        let { userChatHistory, threadData, loginId, channelId, message, fileList, setEmptyText, companyId, tempMessageList, firstName, profilePhoto, currentResult } = this.props;
        let msg = message.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '')
        if ((msg.trim() !== "") || fileList.length > 0 || gifURL !== "") {
            let createdAt = new Date();

            let tempMessage = {
                chatThreadMessage: {
                    id: "",
                    userId: loginId,
                    message,
                    sendAt: createdAt.getTime(),
                    createdAt: createdAt.getTime(),
                    messageType: GlobalMsgCons.messageTypeChat,
                    fileList: fileList,
                    objUrl: gifURL
                },
                name: firstName,
                userPhoto: profilePhoto
            }
            if (tempMessageList[channelId] === undefined) {
                tempMessageList[channelId] = [tempMessage]
            } else {
                tempMessageList[channelId].push(tempMessage)
            }

            await this.props.setMultipleThreadChatData({
                tempMessageList,
                message: "",
                fileList: [],
                emojiPicker: false,
                setEmptyText: !setEmptyText,
                scrollBottom: true,
                gifURL: "",
            })

            for (let i = 0; i < fileList.length; i++) {
                await this.fileUpload(i, fileList[i]);
            }


            let postJson = {
                reqId: getUuidv4(),
                channelId,
                messageId: threadData.message.chatThreadMessage.id,
                messageOwnerId: threadData.message.chatThreadMessage.userId,
                orgId: companyId,
                senderId: loginId,
                message,
                fileList: fileList,
                sendAt: createdAt.getTime(),
                objUrl: gifURL,
                messageType: GlobalMsgCons.messageTypeChat,
                deviceInfo: getAppInfo()
            };
            postJson = coversionED("encrypt", postJson).toString()
            let request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.chatSetDomain);
            request.setEvent(GlobalEventCons.addChatThread);
            request.setMessage(postJson);
            request.setDatetime(createdAt.toString());
            getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = response.getMessage();
                    responseData = coversionED("decrypt", responseData)
                    responseData = JSON.parse(responseData)
                    if (responseData.response === GlobalMsgCons.success) {
                        //this.getChannelChats(channelId, 1, true, userChatHistory)
                    }
                }
            });
        }
    }

    async fileChange(event) {
        let { pageText } = this.state
        let files = event.target.files;
        let length = files.length;
        let validation = true;
        let fileList = [];
        if (length > 0) {
            let inc = fileList.length;
            for (var i = 0; i < length; i++) {
                let fileUrl = URL.createObjectURL(files[i]);
                let file = files[i];
                let filename = file.name;
                let valid = this.isValidFileExt(filename);
                if (!valid) {
                    validation = false;
                }
                let ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
                let uid = uuidv4();
                let fileObj = {
                    file: file,
                    origFileName: filename,
                    fileUrl: fileUrl,
                    fileName: uid + "." + ext,
                    docsId: uid,
                    processType: "chat",
                    fileType: ext
                };
                if (validation) {
                    fileList[inc] = fileObj;
                    fileList[inc].uploadProcess = "pending";

                    this.props.setMultipleThreadChatData({
                        fileList
                    })
                    inc++;
                } else {
                    toast.error(getValidLabels(pageText, 'fileNotValidErr_msg'), {
                        position: "top-center",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        }
    }

    fileUpload(i, dataJson) {
        let { loginId } = this.props;
        const postFileUpload = new FormData();
        postFileUpload.append('file' + (i + 1), dataJson.file);
        postFileUpload.append('fileName' + (i + 1), dataJson.fileName);
        postFileUpload.append('processType' + (i + 1), dataJson.processType);
        postFileUpload.append('docsId' + (i + 1), dataJson.docsId);
        fetch(MyConstant.keyList.apiURL + "uploads/" + dataJson.processType + "/" + dataJson.docsId + "/" + loginId, {
            method: "POST",
            mode: 'cors',
            redirect: 'follow',
            body: postFileUpload,
        }).then(
            function (response) {
                return response;
            }
        ).then(json => {
            return json.response;
        }).catch(error => console.warn(error));
    }
    isValidFileExt(name) {
        const ext = ['.jpg', '.jpeg', '.bmp', '.gif', '.png', '.svg', '.csv', '.pdf', '.xls', '.xlsx', '.mp3', '.mp4', '.3gp', '.ogg', '.mov'];
        return ext.some(el => name.endsWith(el));
    }

    onEmojiClick(event, emojiObject) {
        let { insertTextToggle } = this.props
        this.props.setMultipleThreadChatData({
            insertTextToggle: !insertTextToggle,
            insertText: emojiObject.emoji
        })
    }

    humanDateFormat(createdAt) {
        let { activeCompanyDetails, hoursSelected } = this.props;
        return getDateWithFormat(createdAt, activeCompanyDetails, hoursSelected)
    }


    loadMore() {
        let { channelId, noOfPage, userChatHistory } = this.props;
        this.props.setMultipleThreadChatData({
            noOfPage: noOfPage + 1
        })
        this.getChannelChats(channelId, (noOfPage + 1), false, userChatHistory);
    }


    previewContent(buildUrl) {
        let content;
        if (buildUrl !== undefined) {
            var re = /(?:\.([^.]+))?$/;
            let url = new URL(buildUrl)
            let params = new URLSearchParams(url.search);
            let fileType = re.exec(params.get('key'))[1];

            let images = ["jpg", "png", "jpeg", "gif"];
            let videos = ["mp4", "3gp", "ogg", "mov"]
            let pdf = ["pdf"]
            let txt = ["txt"]
            let audio = ["mp3"]
            let fileContent = "";
            if (images.includes(fileType.toLowerCase())) {
                fileContent = <img src={buildUrl} className="img-fluid" />
            } else if (videos.includes(fileType.toLowerCase())) {
                fileContent = <video width="100%" controls>
                    <source src={buildUrl} />
                </video>
            } else if (pdf.includes(fileType.toLowerCase())) {
                fileContent = <PDFViewer url={buildUrl} />
            } else if (txt.includes(fileType.toLowerCase())) {
                fileContent = <iframe frameborder="0" style={{ height: "75vh" }} src={buildUrl} height="75vh" width="100%" />
            } else if (audio.includes(fileType.toLowerCase())) {
                fileContent = <audio width="100%" controls>
                    <source src={buildUrl} />
                </audio>
            } else {
                fileContent = <div>
                    <div className="text-right">
                        <button className="btn btn-secondary" onClick={() => this.downloadUrl(buildUrl)} >
                            <i className="fa fa-download"></i>
                        </button>
                    </div>
                    Not able to preview.
                </div>
            }

            content = <React.Fragment>
                {/* <div className="text-right">
                    <button className="btn btn-secondary" onClick={() => this.downloadUrl(buildUrl)} >
                        <i className="fa fa-download"></i>
                    </button>
                </div> */}
                <div style={{ textAlign: "center" }} >
                    {fileContent}
                </div>
            </React.Fragment>
        }

        return content;
    }

    downloadUrl(buildUrl) {
        var link = document.createElement("a");
        link.download = "feedback.xls";
        link.href = buildUrl;
        link.click();
    }


    fileIcon(buildUrl, fileType) {
        let images = ["jpg", "png"];
        let videos = ["mp4", "3gp", "ogg", "mov"]
        let pdf = ["pdf"]
        let txt = ["txt"]
        let fileContent = "";
        if (images.includes(fileType.toLowerCase())) {
            fileContent = <img src={buildUrl} className="img-fluid" />
        } else if (videos.includes(fileType.toLowerCase())) {
            fileContent = <i className="fa fa-video" />
        } else if (pdf.includes(fileType.toLowerCase())) {
            fileContent = <i className="fa fa-file-pdf" />
        } else if (txt.includes(fileType.toLowerCase())) {
            fileContent = <i className="fa fa-file-alt" />
        } else {
            fileContent = <i className="fa fa-file" />
        }

        return fileContent;
    }

    previewBlobContent() {
        let { previewBlob } = this.props;
        let { filePath, fileType } = previewBlob;

        let images = ["jpg", "png", "jpeg", "gif"];
        let videos = ["mp4", "3gp", "ogg", "mov"]
        let pdf = ["pdf"]
        let txt = ["txt"]
        let audio = ["mp3"]
        let excel = ["csv", "xls", "xlsx"]
        let fileContent = "";
        if (images.includes(fileType.toLowerCase())) {
            fileContent = <img src={filePath} className="img-fluid" />
        } else if (videos.includes(fileType.toLowerCase())) {
            fileContent = <video width="100%" controls>
                <source src={filePath} />
            </video>
        } else if (pdf.includes(fileType.toLowerCase())) {
            fileContent = <PDFViewer url={filePath} />
        } else if (txt.includes(fileType.toLowerCase())) {
            fileContent = <iframe frameborder="0" style={{ height: "75vh" }} src={filePath} height="75vh" width="100%" />
        } else if (audio.includes(fileType.toLowerCase())) {
            fileContent = <audio width="100%" controls>
                <source src={filePath} />
            </audio>
        }

        let content = <div style={{ textAlign: "center" }} >
            {fileContent}
        </div>

        return content;
    }

    fileDelete(e, i) {
        e.stopPropagation();
        let { fileList } = this.props;
        fileList.splice(i, 1);

        this.props.setMultipleThreadChatData({
            fileList
        })
    }

    toggleInfo() {
        let { infoShow } = this.props;
        this.props.setMultipleThreadChatData({
            infoShow: !infoShow
        })
    }

    chatFileView(ival) {
        let { userChatHistory } = this.props;
        let message = userChatHistory[ival].chatThreadMessage;
        let downlodFileName = message.fileList[0].origFileName;
        let buildUrl = MyConstant.keyList.apiURL + "vp?action=" + message.fileList[0].processType + "&key=" + message.fileList[0].fileName + "&id=" + message.userId;
        let downLoadUrl = MyConstant.keyList.apiURL + `download/${downlodFileName}?action=` + message.fileList[0].processType + "&key=" + message.fileList[0].fileName + "&id=" + message.userId;
        let fileType = message.fileList[0].fileType;
        let content = <div className="message-file">
            <div className="file" onClick={() => {
                this.props.setMultipleThreadChatData({
                    buildUrl,
                    previewModal: true
                })
            }}>
                <i className="fa fa-file"></i>
                {message.fileList[0].processType === "chat-record" ? "" : message.fileList[0].origFileName}
            </div>
        </div>;

        let images = ["jpg", "png", "jpeg", "gif"];
        let videos = ["mp4", "3gp", "ogg", "mov"]
        let pdf = ["pdf"]
        let txt = ["txt"]
        let audio = ["mp3"]
        if (images.includes(fileType.toLowerCase())) {
            content = <div className="d-flex chat-file-box-wrapper">
                <div className="chat-file-box" >
                    <img src={buildUrl} className="img-fluid" onClick={() => {
                        this.props.setMultipleThreadChatData({
                            buildUrl,
                            previewModal: true
                        })
                    }} />
                </div>
                <div className="download-url">
                    <a href={downLoadUrl} download className="files-down">
                        <i className="fa fa-download "></i>
                    </a>
                </div>
            </div>
        } else if (videos.includes(fileType.toLowerCase())) {
            content = <div className="d-flex chat-file-box-wrapper">
                <div className="chat-file-box" onClick={() => {
                    this.props.setMultipleThreadChatData({
                        buildUrl,
                        previewModal: true
                    })
                }}>
                    <video width="100%" controls style={{ width: "80%" }}>
                        <source src={buildUrl} />
                    </video>
                </div>
                <div className="download-url">
                    <a href={downLoadUrl} download className="files-down">
                        <i className="fa fa-download "></i>
                    </a>
                </div>
            </div>
        } else if (audio.includes(fileType.toLowerCase())) {
            content = <div className="d-flex chat-file-box-wrapper">
                <div>
                    <audio width="100%" controls onClick={() => {
                        this.props.setMultipleThreadChatData({
                            buildUrl,
                            previewModal: true
                        })
                    }}>
                        <source src={buildUrl} />
                    </audio>
                </div>
                <div className="download-url">
                    <a href={downLoadUrl} download className="files-down">
                        <i className="fa fa-download "></i>
                    </a>
                </div>
            </div>
        } else if (pdf.includes(fileType.toLowerCase())) {
            content = <div className="d-flex chat-file-box-wrapper">
                <div className="chat-file-box" >
                    <div className="file-info d-flex" onClick={() => {
                        this.props.setMultipleThreadChatData({
                            buildUrl,
                            previewModal: true
                        })
                    }} >
                        <div className="icon" >
                            <i className="fa fa-file-pdf"></i>
                        </div>
                        <div className="text">
                            {message.fileList[0].origFileName}
                        </div>
                    </div>
                </div>
                <div className="download-url">
                    <a href={downLoadUrl} download className="files-down">
                        <i className="fa fa-download "></i>
                    </a>
                </div>
            </div>
        }
        return content;
    }

    tempChatFileView(ival) {
        let message = ival.chatThreadMessage;
        let fileType = message.fileList[0].fileType;
        let buildUrl = message.fileList[0].fileUrl;

        let content = <div className="message-file">
            <div className="file" onClick={() => {
                this.props.setMultipleThreadChatData({
                    buildUrl,
                    previewModal: true
                })
            }}>
                <i className="fa fa-file"></i>
                {message.fileList[0].processType === "chat-record" ? "" : message.fileList[0].origFileName}
            </div>
        </div>;

        let images = ["jpg", "png", "jpeg", "gif"];
        let videos = ["mp4", "3gp", "ogg", "mov"]
        let pdf = ["pdf"]
        let txt = ["txt"]
        let audio = ["mp3"]
        if (images.includes(fileType.toLowerCase())) {
            content = <div className="chat-file-box" onClick={() => {
                this.props.setMultipleThreadChatData({
                    buildUrl,
                    previewModal: true
                })
            }}>
                <img src={buildUrl} className="img-fluid" />
            </div>
        } else if (videos.includes(fileType.toLowerCase())) {
            content = <div className="chat-file-box" onClick={() => {
                this.props.setMultipleThreadChatData({
                    buildUrl,
                    previewModal: true
                })
            }}>
                <video width="100%" controls style={{ width: "80%" }}>
                    <source src={buildUrl} />
                </video>
            </div>
        } else if (audio.includes(fileType.toLowerCase())) {
            content = <div className="chat-file-box" onClick={() => {
                this.props.setMultipleThreadChatData({
                    buildUrl,
                    previewModal: true
                })
            }}>
                <audio width="100%" controls>
                    <source src={buildUrl} />
                </audio>
            </div>
        } else if (pdf.includes(fileType.toLowerCase())) {
            content = <div className="chat-file-box" onClick={() => {
                this.props.setMultipleThreadChatData({
                    buildUrl,
                    previewModal: true
                })
            }}>
                <div className="file-info d-flex">
                    <div className="icon">
                        <i className="fa fa-file-pdf"></i>
                    </div>
                    <div className="text">
                        {message.fileList[0].origFileName}
                    </div>
                </div>
            </div>
        }
        return content;
    }

    chatEditFileView(message) {
        let { loginId } = this.props;
        let buildUrl = MyConstant.keyList.apiURL + "vp?action=" + message.fileList[0].processType + "&key=" + message.fileList[0].fileName + "&id=" + loginId;
        let fileType = message.fileList[0].fileType;
        return this.fileIcon(buildUrl, fileType);
    }

    deleteChatMessage(threadMsgId, key) {
        let { loginId, channelId, threadData, userChatHistory, currentResult } = this.props
        let postJson = { reqId: getUuidv4(), messageId: threadData.message.chatThreadMessage.id, messageOwnerId: threadData.message.chatThreadMessage.userId, threadMsgId, channelId: channelId, senderId: loginId }
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.deleteThreadChat);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {

                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                if (responseData.response === GlobalMsgCons.success) {
                    delete userChatHistory[key]
                    this.props.setMultipleThreadChatData({
                        userChatHistory,
                        currentResult: currentResult - 1
                    })
                }
            }
        });

    }

    updateEmoJiReactionForMsg(msgId, emojiId) {
        let { loginId, channelId, companyId, threadData, userChatHistory } = this.props;
        let postJson = { reqId: getUuidv4(), channelId, msgId: threadData.message.chatThreadMessage.id, threadMsgId: msgId, userId: loginId, orgId: companyId, emojiId };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.updateEmoJiReactionForThreadMsg);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                this.getChannelChats(channelId, 1, true, userChatHistory)
            }
        });
    }

    async getPageText() {
        let pageText = await getPageText('chat');
        this.setState({ pageText: pageText })
    }

    setUserSelected(event) {
        this.props.setMultipleThreadChatData({
            channelUser: event
        })
    }

    timeStampToDate = (d) => {
        let { activeCompanyDetails } = this.props;
        return getOnlyDateWithFormat(d, activeCompanyDetails)
    }

    updateChatTypingInfo(event) {
        let { userId, companyId, firstName, channelId } = this.props;
        let postJson = { reqId: getUuidv4(), channelId, userId, orgId: companyId, userName: firstName, actionType: event };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.updateChatTypingInfo);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, async (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {

            }
        });
    }

    checkUserDetailsDisplay(chatMessage, index, type, parent) {
        let nameDisplay = false
        if (lastMessage !== undefined && (type !== "live" || index > 0)) {
            let diff = chatMessage.createdAt - lastMessage.createdAt
            if (lastMessage.userId === chatMessage.userId && diff < 60000) {
                nameDisplay = false
            } else {
                nameDisplay = true
            }
        } else {
            nameDisplay = true
        }

        if (parent) {
            lastMessage = ""
        } else {
            lastMessage = chatMessage
        }

        return nameDisplay;
    }

    processAudioToText(type, blob) {
        let uid = uuidv4();
        let file = "",
            ext = "";
        if (type === "record") {
            let audioFile = new File([blob], "record_" + uid + ".mp3")
            file = audioFile;
            let filename = audioFile.name;
            ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
        } else {
            file = blob.path;
            ext = blob.fileType
        }
        let fileObj = {
            file: file,
            fileName: uid + "." + ext,
            docsId: uid,
            processType: "chat-record",
        };

        this.audioToTextUpload(fileObj)
    }

    audioToTextUpload(dataJson) {
        let { userId } = this.props;
        this.setState({
            processAudioToText: "process"
        }, () => {
            const postFileUpload = new FormData();
            postFileUpload.append('file', dataJson.file);
            postFileUpload.append('fileName', dataJson.fileName);
            postFileUpload.append('processType', dataJson.processType);
            postFileUpload.append('docsId', dataJson.docsId);
            postFileUpload.append('entryId', dataJson.docsId);
            postFileUpload.append('userId', userId)
            fetch(MyConstant.keyList.aiUrl + "voice-to-text/file", {
                method: "POST",
                mode: 'cors',
                redirect: 'follow',
                body: postFileUpload,
            })
                .then((response) => response.json())
                .then((result) => {
                    this.setState({
                        processAudioToText: "completed",
                        resultAudioToText: result
                    })
                    console.log('Success:', result);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        })
    }


    updateThreadMessage(msgIdList) {
        let { userId, threadData } = this.props;
        let { channelId } = threadData
        let postJson = { reqId: getUuidv4(), channelId, messageId: threadData.message.chatThreadMessage.id, threadMsgIdList: msgIdList, memberId: userId };
        //reqId: String, messageId: String,threadMsgIdList: List[String], channelId: String, memberId: String
        console.log("-->", postJson)
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.updateChatThreadReadStatusSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)

            }
        });
    }
    getReplyChatReadedMemeberDataAPI(msgId) {
        let { userId, channelId, threadData } = this.props;
        let postJson = { reqId: getUuidv4(), messageId: threadData.message.chatThreadMessage.id, threadMsgId: msgId, channelId: channelId, memberId: userId };
        //reqId: String, messageId: String, threadMsgId: String,channelId: String, memberId: String
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.getChatThreadReadedMemeberDataSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                let responseMsg = []
                if (responseData && Array.isArray(responseData.result)) {
                    responseMsg = responseData.result;
                    responseMsg = responseMsg.filter((e => { return e.memberId !== userId })).sort(function (a, b) { return b.readAt - a.readAt; })
                }
                this.setState({ chatMessageSeenList: responseMsg, chatMessageSeenLoader: false })
            }
        });
    }


    render() {
        let { voiceToTextFileType, voiceToTextModal, message, editBlink, editMessageDetails, gifURL, loginId, userChatHistory, fileList, emojiPicker, gifPicker, currentResult, previewModal, buildUrl, insertText, insertTextToggle, recording, previewType, recordingModal, selectedMessage, editMessageId, editMessageText, setEmptyText, isRender, changeText, channelId, path, deactivatedMembersIds, tempMessageList } = this.props;
        let { processAudioToText, resultAudioToText, pageText, typingUserList, messageEmojiPicker, messageEmojiPickerPosition, messageIdEmojiPicker, emojiHoverView, chatMessageSeenList, chatMessageSeenModal, chatMessageSeenMessage, chatMessageSeenLoader } = this.state;
        let _this = this;
        let currentTempMessageList = tempMessageList[channelId] !== undefined ? tempMessageList[channelId] : []

        return <div className="thread-content">
            <ToastContainer />
            <div className="chat-header">
                <div className="chat-header-title" style={{ flex: 1 }}>
                    # Thread
                </div>
                <div className="chat-header-extra">
                    <button className='btn btn-sm btn-danger' onClick={() => this.props.close()} >
                        <i className="fas fa-times"></i>
                    </button>
                </div>
            </div>
            <div className="thread-chat-window">
                {channelId && channelId !== "nill" && <div className="chat-body">
                    <div className="chat-body-message" ref={(el) => { this.messageEnd = el; }}>
                        {
                            userChatHistory && Object.keys(userChatHistory).sort().map(function (ival, i) {
                                let message;

                                let parent = userChatHistory[ival].parent;
                                let chatMessage = userChatHistory[ival].chatThreadMessage;
                                let userName = userChatHistory[ival].name
                                let profilePhoto = userChatHistory[ival].userPhoto
                                let emojiReactionWithMemberNameData = userChatHistory[ival].emojiReactionWithMemberNameData
                                let userProfilePhoto = ""

                                if (profilePhoto) {
                                    let split = profilePhoto.split(".")
                                    userProfilePhoto = MyConstant.keyList.apiURL + "vp?action=profile&key=" + split[0] + "." + split[1] + "&id=" + chatMessage.userId
                                }

                                let { editedAt, messageType } = chatMessage

                                if (typeof chatMessage.fileList != "undefined" && chatMessage.fileList != null && chatMessage.fileList.length > 0) {
                                    message = <div id={ival}>
                                        <div className="d-flex">
                                            <div dangerouslySetInnerHTML={{ __html: urlify(chatMessage.message) }} />
                                            {
                                                editedAt && <div className="edited">
                                                    (edited)
                                                    <div className="time">{_this.humanDateFormat(editedAt)}</div>
                                                </div>
                                            }
                                        </div>
                                        {_this.chatFileView(ival)}
                                    </div>;
                                } else if (chatMessage.objUrl !== undefined && chatMessage.objUrl) {
                                    message = <div id={ival}>
                                        <div className="d-flex">
                                            <div dangerouslySetInnerHTML={{ __html: urlify(chatMessage.message) }} />
                                            {
                                                editedAt && <div className="edited">
                                                    (edited)
                                                    <div className="time">{_this.humanDateFormat(editedAt)}</div>
                                                </div>
                                            }
                                        </div>
                                        <div style={{ cursor: "pointer" }} onClick={() => {
                                            _this.props.setMultipleThreadChatData({
                                                buildUrl: chatMessage.objUrl,
                                                previewModal: true
                                            })
                                        }}>
                                            <img src={chatMessage.objUrl} />
                                        </div>
                                    </div>
                                } else {
                                    message = <div id={ival} className="d-flex">
                                        <div dangerouslySetInnerHTML={{ __html: urlify(chatMessage.message) }} />
                                        {
                                            editedAt && <div className="edited">
                                                (edited)
                                                <div className="time">{_this.humanDateFormat(editedAt)}</div>
                                            </div>
                                        }
                                    </div>
                                }

                                let emojiList = [];
                                if (emojiReactionWithMemberNameData) {
                                    Object.keys(emojiReactionWithMemberNameData).map((emoji) => {
                                        if (emojiReactionWithMemberNameData[emoji].length > 0) {
                                            emojiList.push({
                                                icon: emoji,
                                                count: emojiReactionWithMemberNameData[emoji].length,
                                                name: emojiReactionWithMemberNameData[emoji]
                                            })
                                        }
                                    })
                                }

                                let checkUserDetailsDisplay = _this.checkUserDetailsDisplay(chatMessage, i, "live", parent)

                                return <div key={i} id={ival}>
                                    <div key={ival} className={`message-container p-1 ${chatMessage.userId === loginId ? "receiver" : "sender"}`}
                                        onMouseEnter={() => {
                                            if (typeof messageType === "undefined" || (messageType !== "call" && messageType !== "calender" && messageType !== "meeting" && messageType !== "meeting-notification")) {
                                                _this.props.setMultipleThreadChatData({
                                                    selectedMessage: chatMessage.id,
                                                })
                                            }

                                        }
                                        }
                                        onMouseLeave={() => {
                                            if (!messageEmojiPicker) {
                                                _this.props.setMultipleThreadChatData({
                                                    selectedMessage: "",
                                                })
                                            }
                                            ReactTooltip.hide()
                                        }
                                        }>
                                        {
                                            checkUserDetailsDisplay ? <div className="user-icon">
                                                {
                                                    (userProfilePhoto && userProfilePhoto != "") ? <img className="name-bg" src={userProfilePhoto} alt="no img" /> : <div className="name-bg" style={{ backgroundColor: color[userName !== "" ? userName[0].toUpperCase() : "A"] }}>{userName !== "" ? userName[0].toUpperCase() : ""}</div>
                                                }
                                            </div> : <div className="empty-user-icon">
                                            </div>
                                        }

                                        <div className="message mx-2">
                                            <div className="user-details">
                                                <div className="user">
                                                    {
                                                        checkUserDetailsDisplay && <React.Fragment>
                                                            <b className="mr-2 uppercase">{userName}</b>
                                                            <span className="time-info">{_this.humanDateFormat(chatMessage.createdAt)}</span>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                                <div className="options">
                                                    {
                                                        selectedMessage === chatMessage.id && <div className="options-container">
                                                            {
                                                                !parent && <React.Fragment>
                                                                    <div className="option" onClick={() => _this.updateEmoJiReactionForMsg(chatMessage.id, "2705")} data-tip={"Completed"}>
                                                                        &#x2705;
                                                                    </div>
                                                                    <div className="option" onClick={() => _this.updateEmoJiReactionForMsg(chatMessage.id, "1f64f")} data-tip={"Thanks"}>
                                                                        &#x1f64f;
                                                                    </div>
                                                                    <div className="option" onClick={() => _this.updateEmoJiReactionForMsg(chatMessage.id, "1f64c")} data-tip={"Nicely Done"}>
                                                                        &#x1f64c;
                                                                    </div>
                                                                    <div className="option" onClick={() => _this.updateEmoJiReactionForMsg(chatMessage.id, "1f440")} data-tip={"Taking a look"}>
                                                                        &#x1f440;
                                                                    </div>
                                                                    <div className="option emoji-from-picker" onClick={(e) => {
                                                                        let { clientX, clientY } = e;
                                                                        _this.setState({
                                                                            messageEmojiPicker: true,
                                                                            messageIdEmojiPicker: chatMessage.id,
                                                                            messageEmojiPickerPosition: {
                                                                                clientX,
                                                                                clientY
                                                                            }
                                                                        })
                                                                    }}>
                                                                        <i className="far fa-smile"></i>
                                                                    </div>
                                                                </React.Fragment>
                                                            }
                                                            {
                                                                chatMessage.message && <div className="option" onClick={() => {
                                                                    let textMsg = chatMessage.message.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '')
                                                                    navigator.clipboard.writeText(textMsg);

                                                                    toast(`Copied Successfully.`, {
                                                                        position: "bottom-center",
                                                                        autoClose: 1000,
                                                                        hideProgressBar: true,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                        theme: "dark",
                                                                    });
                                                                }} data-tip={"Copy"}>
                                                                    <i className="fas fa-copy"></i>
                                                                </div>
                                                            }
                                                            {chatMessage.userId === loginId ?
                                                                <div className="option" onClick={() => {

                                                                    _this.setState({
                                                                        chatMessageSeenLoader: true,
                                                                        chatMessageSeenModal: true,
                                                                        chatMessageSeenMessage: chatMessage.message
                                                                    })
                                                                    _this.getReplyChatReadedMemeberDataAPI(chatMessage.id)

                                                                }} data-tip={"Info"}> <i className="fas fa-user-check">
                                                                    </i>
                                                                </div>
                                                                : null}
                                                            {
                                                                (chatMessage.userId === loginId && editMessageId !== chatMessage.id && !parent) && <React.Fragment>
                                                                    <div className="option" onClick={() => {
                                                                        _this.edit(chatMessage)
                                                                    }}>{getValidLabels(pageText, 'editBtnTxt')}</div>
                                                                    <div className="option" onClick={() => {
                                                                        let responseChoose = window.confirm(getValidLabels(pageText, 'deletethisAlert_Msg'))
                                                                        if (responseChoose) {
                                                                            _this.deleteChatMessage(chatMessage.id, ival)
                                                                        }
                                                                    }}>{getValidLabels(pageText, 'deleteBtnTxt')}</div>

                                                                </React.Fragment>
                                                            }

                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="message-box">
                                                {
                                                    message
                                                }
                                                {
                                                    emojiList.length > 0 && <div className="emoji-reaction">
                                                        {
                                                            emojiList.map((item, index) => {
                                                                return <div key={index} className="emoji-reaction-item" onClick={() => _this.updateEmoJiReactionForMsg(chatMessage.id, item.icon)}
                                                                    onMouseEnter={(e) => {
                                                                        let rect = e.currentTarget.getBoundingClientRect()
                                                                        let clientX = rect.left + rect.width / 2;
                                                                        let clientY = rect.top + rect.height + 5;
                                                                        _this.setState({
                                                                            emojiHoverView: { id: chatMessage.id, icon: item.icon, clientX, clientY }
                                                                        })
                                                                    }}
                                                                    onMouseLeave={() => {
                                                                        _this.setState({
                                                                            emojiHoverView: ""
                                                                        })
                                                                    }}>
                                                                    <div className="icon">{String.fromCodePoint("0x" + item.icon)}</div>
                                                                    <div className="count">{item.count}</div>
                                                                    {
                                                                        (chatMessage.id === emojiHoverView.id && item.icon === emojiHoverView.icon) && <div className="names" style={{ position: "fixed", top: emojiHoverView.clientY, left: emojiHoverView.clientX }}>{item.name.join(", ")}</div>
                                                                    }

                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        parent && <React.Fragment>
                                            <div className="parent">
                                                <div className="text">{`${(currentResult + currentTempMessageList.length)} ${(currentResult + currentTempMessageList.length) > 1 ? "replies" : "reply"}`}</div>
                                                <div className="line"></div>
                                            </div>
                                            {
                                                (currentResult > 0 && (currentResult !== Object.keys(userChatHistory).length - 1)) ? <div className="load-more-message" onClick={() => _this.loadMore()}><span>{getValidLabels(pageText, 'loadMoreMessagesBtnTxt')}</span></div> : null
                                            }
                                        </React.Fragment>
                                    }
                                </div>
                            })
                        }
                        {
                            currentTempMessageList.map(function (ival, i) {
                                let chatMessage = ival.chatThreadMessage;

                                let message;

                                let userName = ival.name
                                let profilePhoto = ival.userPhoto
                                let userProfilePhoto = ""

                                if (profilePhoto) {
                                    let split = profilePhoto.split(".")
                                    userProfilePhoto = MyConstant.keyList.apiURL + "vp?action=profile&key=" + split[0] + "." + split[1] + "&id=" + chatMessage.userId
                                }

                                let checkUserDetailsDisplay = _this.checkUserDetailsDisplay(chatMessage, i, "temp", false)
                                if (typeof chatMessage.fileList != "undefined" && chatMessage.fileList != null && chatMessage.fileList.length > 0) {
                                    message = <React.Fragment>
                                        <div dangerouslySetInnerHTML={{ __html: urlify(chatMessage.message) }} />
                                        {_this.tempChatFileView(ival)}
                                    </React.Fragment>;
                                } else if (chatMessage.objUrl !== undefined && chatMessage.objUrl) {
                                    message = <div>
                                        <div dangerouslySetInnerHTML={{ __html: urlify(chatMessage.message) }} />
                                        <div style={{ cursor: "pointer" }} onClick={() => {
                                            _this.props.setMultipleThreadChatData({
                                                buildUrl: chatMessage.objUrl,
                                                previewModal: true
                                            })
                                        }}>
                                            <img src={chatMessage.objUrl} />
                                        </div>
                                    </div>
                                } else {
                                    message = <>
                                        <div dangerouslySetInnerHTML={{ __html: urlify(chatMessage.message) }} />
                                    </>
                                }


                                return <div key={i}>

                                    <div key={i} className={`message-container p-1 ${chatMessage.userId === loginId ? "receiver" : "sender"}`}>
                                        {
                                            checkUserDetailsDisplay ? <div className="user-icon">
                                                {
                                                    (userProfilePhoto && userProfilePhoto != "") ? <img className="name-bg" src={userProfilePhoto} alt="no img" /> : <div className="name-bg" style={{ backgroundColor: color[userName !== "" ? userName[0].toUpperCase() : "A"] }}>{userName !== "" ? userName[0].toUpperCase() : ""}</div>
                                                }
                                            </div> : <div className="empty-user-icon">
                                            </div>
                                        }
                                        <div className="message mx-2">
                                            <div className="user-details">
                                                <div className="user">
                                                    {
                                                        checkUserDetailsDisplay && <React.Fragment>
                                                            <b className="mr-2 uppercase">{userName}</b>
                                                            <span className="time-info">{_this.humanDateFormat(chatMessage.createdAt)}</span>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </div>
                                            <div className="message-box">
                                                {
                                                    message
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>}

                {channelId && channelId !== "nill" && !deactivatedMembersIds.includes(this.props.userId) && <div className="chat-input-container">
                    <div className="chat-input-container-element">

                        {
                            emojiPicker && <div className="chat-smiley">
                                <Picker
                                    pickerStyle={{ width: '100%', height: 300 }}
                                    disableSkinTonePicker={true}
                                    onEmojiClick={(event, emojiObject) => this.onEmojiClick(event, emojiObject)}
                                />
                            </div>
                        }
                        {
                            gifPicker && <div className="chat-gif">
                                <GifPicker select={(e, url) => {
                                    this.props.setMultipleThreadChatData({
                                        gifPicker: false,
                                        gifURL: url
                                    })
                                    if (editMessageId) {
                                        this.editChannelSubmit(e, url)
                                    } else {
                                        this.addChannelChat(e, url)
                                    }
                                }} />
                            </div>
                        }
                        <div className="">
                            {
                                isRender && <CustomEditor
                                    editorClassName={editBlink ? "editor-blink" : ""}
                                    pagename={"chat"}
                                    updateChatTypingInfo={(event) => this.updateChatTypingInfo(event)}
                                    insertText={insertText}
                                    insertTextToggle={insertTextToggle}
                                    setEmptyText={setEmptyText}
                                    text={editMessageId ? editMessageText : message}
                                    changeText={changeText}
                                    textOnchange={(value) => {
                                        {
                                            if (editMessageId) {
                                                this.props.setMultipleThreadChatData({
                                                    editMessageText: value
                                                })
                                            } else {
                                                this.setChatMessage(value)
                                            }
                                        }
                                    }}
                                    submit={(e) => {
                                        if (editMessageId) {
                                            this.editChannelSubmit(e, gifURL)
                                        } else {
                                            this.addChannelChat(e, gifURL)
                                        }
                                    }}
                                    channelId={channelId}
                                />
                            }
                        </div>
                        {
                            fileList.length > 0 && <div className="chat-files">
                                <div className="row mx-0">
                                    {
                                        fileList.map((file, i) => {
                                            return file.file instanceof File ? <div className="col-2" key={i}>
                                                <div className="chat-file-holder" title={file.origFileName} onClick={() => {
                                                    let preview = { filePath: file.fileUrl, fileType: file.fileType }
                                                    _this.props.setMultipleThreadChatData({
                                                        previewBlob: preview,
                                                        previewType: "blob",
                                                        previewModal: true
                                                    })
                                                }}>
                                                    {file.uploadProcess === "pending" && <div className="spin-delete" onClick={(e) => {
                                                        this.fileDelete(e, i)
                                                    }}><i className="fa fa-times" /></div>}
                                                    {file.uploadProcess === "processing" && <div className="spin-loader"></div>}
                                                    {
                                                        this.fileIcon(file.fileUrl, file.fileType)
                                                    }
                                                    {
                                                        <span>{file.processType === "chat-record" ? "Record" : file.origFileName}</span>
                                                    }
                                                </div>
                                            </div> : <div className="col-2" key={i}>
                                                <div className="chat-file-holder">
                                                    <div className="spin-delete" onClick={(e) => {
                                                        this.fileDelete(e, i)
                                                    }}><i className="fa fa-times" /></div>
                                                    {_this.chatEditFileView(editMessageDetails)}
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        }
                        <div className="chat-input-extra">
                            <div style={{ display: 'flex', flex: 1 }}>
                                <div className="icon">
                                    <input className="file-input-hidden" type="file" accept="image/*,video/*,.csv,application/pdf,application/vnd.ms-excel," onChange={(e) => this.fileChange(e)} />
                                    <i className="fa fa-plus"></i>
                                </div>
                                <div className={`icon ${recording ? "record" : ""}`} onClick={() => {
                                    this.props.setMultipleThreadChatData({
                                        recordingModal: true
                                    })
                                }}>
                                    <i className="fa fa-microphone"></i>
                                </div>
                                <div className={`icon ${emojiPicker ? "active" : ""}`} onClick={() => {
                                    this.props.setMultipleThreadChatData({
                                        gifPicker: false,
                                        emojiPicker: !emojiPicker
                                    })
                                }}>
                                    <i className="fa fa-smile"></i>
                                </div>
                                <div className={`icon ${gifPicker ? "active" : ""}`} style={{ fontWeight: 'bold' }} onClick={() => {
                                    this.props.setMultipleThreadChatData({
                                        emojiPicker: false,
                                        gifPicker: !gifPicker
                                    })
                                }}>
                                    {getValidLabels(pageText, 'gIFLabelTxt')}
                                </div>
                                <div className={`icon ${voiceToTextModal ? "active" : ""}`} onClick={() => {
                                    this.setState({
                                        processAudioToText: ""
                                    }, () => {
                                        this.props.setMultipleThreadChatData({
                                            voiceToTextModal: true,
                                            gifPicker: false,
                                            emojiPicker: false,
                                        })
                                    })
                                }} data-tip={"Audio To Text"}>
                                    <i className="fas fa-file-audio"></i>
                                </div>
                            </div>
                            <div className="d-flex">
                                <button className="message-submit-button reset" type="button" onClick={() => {
                                    this.props.setMultipleThreadChatData({
                                        editMessageId: "",
                                        editMessageText: "",
                                        editMessageDetails: {},
                                        userChatHistory,
                                        setEmptyText: !setEmptyText,
                                        emojiPicker: false,
                                        fileList: [],
                                        gifURL: ""
                                    })
                                }}><i className="fa fa-times"></i></button>
                                <button className="message-submit-button" type="submit" onClick={(e) => {
                                    if (editMessageId) {
                                        this.editChannelSubmit(e, gifURL)
                                    } else {
                                        this.addChannelChat(e, gifURL)
                                    }
                                }}><i className="fa fa-paper-plane"></i></button>
                            </div>
                        </div>
                    </div>
                </div>}
                <div className="typing">
                    {
                        typingUserList.length > 0 && <div>{typingUserList.join(" and ")} is typing...</div>
                    }
                </div>
            </div>

            {
                messageEmojiPicker && <div className="emoji-message" style={{ top: messageEmojiPickerPosition.clientY, left: messageEmojiPickerPosition.clientX }}>
                    <Picker
                        pickerStyle={{ width: 300, height: 300 }}
                        disableSkinTonePicker={true}
                        onEmojiClick={(event, emojiObject) => {
                            _this.setState({
                                messageEmojiPicker: false
                            }, () => {
                                _this.updateEmoJiReactionForMsg(messageIdEmojiPicker, emojiObject.unified)
                            })
                        }}
                    />
                </div>
            }


            {
                previewModal && <Modal
                    visible={previewModal}
                    size={"modal-xl"}
                    closeModal={() => this.props.setMultipleThreadChatData({
                        previewModal: false,
                        viewModal: true,
                        previewBlob: {},
                        previewType: "",
                    })}
                    heading={getValidLabels(pageText, 'previewHeaderTxt')}
                    body={<div style={{ minHeight: "75vh" }}>
                        <div className="row">
                            <div className="col-1">
                            </div>
                            <div className="col-10">
                                {
                                    previewType === "blob" ? this.previewBlobContent() : this.previewContent(buildUrl)
                                }
                            </div>
                            <div className="col-1">
                            </div>
                        </div>

                    </div>
                    }
                />
            }

            {
                recordingModal && <Modal
                    visible={recordingModal}
                    closeModal={() => this.props.setMultipleThreadChatData({
                        recordingModal: false
                    })}
                    heading={getValidLabels(pageText, 'recordHeaderTxt')}
                    body={
                        <Recorder
                            saveAudio={this.saveAudio} />
                    }
                />
            }

            {
                voiceToTextModal && <Modal
                    visible={voiceToTextModal}
                    closeModal={() => {
                        this.props.setMultipleThreadChatData({
                            voiceToTextModal: false
                        })
                    }}
                    heading={"Audio to Text"}
                    body={<div>
                        {
                            processAudioToText ? <ProcessAudioToText
                                processAudioToText={processAudioToText}
                                resultAudioToText={resultAudioToText}
                                addTextToChat={(message) => {
                                    this.props.setMultipleThreadChatData({
                                        changeText: message,
                                        voiceToTextModal: false
                                    })
                                }} /> : <React.Fragment>
                                <div className="tabs">
                                    <div className={`tab ${voiceToTextFileType === "record" ? "active" : ""}`} onClick={() => this.props.setMultipleThreadChatData({
                                        voiceToTextFileType: "record"
                                    })
                                    }>
                                        Record
                                    </div>
                                    <div className={`tab ${voiceToTextFileType === "file" ? "active" : ""}`} onClick={() => this.props.setMultipleThreadChatData({
                                        voiceToTextFileType: "file"
                                    })
                                    }>
                                        File
                                    </div>
                                </div>
                                {
                                    voiceToTextFileType === "record" ? <Recorder autoStart={true} maxTime={10000} saveAudio={this.processAudioToText} /> : <VoiceToText saveAudio={this.processAudioToText} />
                                }
                            </React.Fragment>
                        }
                    </div>
                    }
                />
            }


            {
                chatMessageSeenModal && <Modal
                    visible={chatMessageSeenModal}
                    size={"modal-md"}
                    closeModal={() => this.setState({
                        chatMessageSeenModal: false,
                        chatMessageSeenList: [],
                    })}
                    heading={"Info"}
                    body={<div className="seen-msg" style={{ minHeight: "50vh" }}>
                        {!chatMessageSeenLoader ? <>
                            <div className="seen-msg-view" >
                                <div dangerouslySetInnerHTML={{ __html: chatMessageSeenMessage }}></div>
                            </div>
                            <div style={{ backgroundColor: "#ddd", padding: 5 }}>
                                {
                                    chatMessageSeenList.map((e) => {
                                        return (<div style={{ display: "flex", marginBottom: 5, borderBottom: "1px solid #fff" }}>
                                            <div className="name-bg" style={{ backgroundColor: color[e.memberName[0].toUpperCase()], margin: 5, }}>
                                                {e.memberName[0].toUpperCase()}
                                            </div>
                                            <div style={{ flex: 1, margin: "auto", paddingLeft: 5 }}>
                                                <div style={{ textTransform: "capitalize" }} className="read-user-name"> {e.memberName}</div>
                                                <div>
                                                    <span className="read-type" style={{ textTransform: "capitalize" }}>{e.status}</span> {" - "}
                                                    <span className="read-message-readat">{this.humanDateFormat(e.readAt)}</span>
                                                </div>
                                            </div>

                                        </div>)
                                    })
                                }
                                {chatMessageSeenList.length === 0 && <div className="text-center"> Not yet seen </div>}
                            </div></> : <>
                            <i className="fa fa-spinner fa-spin" style={{ fontSize: 48, color: "red", display: "flex", justifyContent: "center", alignItems: "center" }}></i>
                        </>}


                    </div>
                    }
                />
            }
        </div>
    }
}


const mapStateToProps = (state) => {
    return {
        userId: state.authReducer.loginId,
        loginId: state.authReducer.loginId,
        profilePhoto: state.authReducer.profilePhoto,
        sessionId: state.authReducer.sessionId,
        companyId: state.authReducer.activeCompany,
        activeCompanyDetails: state.authReducer.activeCompanyDetails,
        firstName: state.authReducer.firstName,
        currentChannelName: state.chatThreadReducer.currentChannelName,
        channelType: state.chatThreadReducer.channelType,
        channelId: state.chatThreadReducer.channelId,
        channelMembers: state.chatThreadReducer.channelMembers,
        userChatHistory: state.chatThreadReducer.userChatHistory,
        currentResult: state.chatThreadReducer.currentResult,
        message: state.chatThreadReducer.message,
        fileList: state.chatThreadReducer.fileList,
        noOfPage: state.chatThreadReducer.noOfPage,
        emojiPicker: state.chatThreadReducer.emojiPicker,
        gifPicker: state.chatThreadReducer.gifPicker,
        voiceToTextModal: state.chatThreadReducer.voiceToTextModal,
        buildUrl: state.chatThreadReducer.buildUrl,
        insertTextToggle: state.chatThreadReducer.insertTextToggle,
        setEmptyText: state.chatThreadReducer.setEmptyText,
        insertText: state.chatThreadReducer.insertText,
        recording: state.chatThreadReducer.recording,
        recordingModal: state.chatThreadReducer.recordingModal,
        previewBlob: state.chatThreadReducer.previewBlob,
        previewType: state.chatThreadReducer.previewType,
        selectedMessage: state.chatThreadReducer.selectedMessage,
        editMessageId: state.chatThreadReducer.editMessageId,
        editMessageDetails: state.chatThreadReducer.editMessageDetails,
        editMessageText: state.chatThreadReducer.editMessageText,
        infoShow: state.chatThreadReducer.infoShow,
        isRender: state.chatThreadReducer.isRender,
        scrollBottom: state.chatThreadReducer.scrollBottom,
        gifURL: state.chatThreadReducer.gifURL,
        deleteMessageId: state.chatThreadReducer.deleteMessageId,
        channelMembersDetails: state.chatThreadReducer.channelMembersDetails,
        isFeedPage: state.chatThreadReducer.isFeedPage,
        pageText: state.chatThreadReducer.pageText,
        addPeopleModal: state.chatThreadReducer.addPeopleModal,
        channelUser: state.chatThreadReducer.channelUser,
        userListOption: state.chatThreadReducer.userListOption,
        errors: state.chatThreadReducer.errors,
        excludeMembers: state.chatThreadReducer.excludeMembers,
        channelMuteStatus: state.chatThreadReducer.channelMuteStatus,
        messageSound: state.chatThreadReducer.messageSound,
        selectedMessageSound: state.chatThreadReducer.selectedMessageSound,
        deactivatedMembersIds: state.chatThreadReducer.deactivatedMembersIds,
        previewModal: state.chatThreadReducer.previewModal,
        changeText: state.chatThreadReducer.changeText,
        editBlink: state.chatThreadReducer.editBlink,
        showMorePeopleModal: state.chatThreadReducer.showMorePeopleModal,
        existChannelMemberIds: state.chatThreadReducer.existChannelMemberIds,
        paginateData: state.chatThreadReducer.paginateData,
        tempMessageList: state.chatThreadReducer.tempMessageList,
        voiceToTextFileType: state.chatThreadReducer.voiceToTextFileType,
        hoursSelected: state.authReducer.hoursSelected,
        controlFeature: state.authReducer.controlFeature,
        state,
    };
};

const mapDispatchToProps = {
    setChatData, setMultipleThreadChatData
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatThread));