import { RESET_EDUCATION_WORKSPACE, SET_EDUCATION_WORKSPACE, SET_EDUCATION_MULTIPLE_WORKSPACE } from '../constants/actionTypes';

const initialState = {
    currentDate: "",
    userId: "",
    companyId: "",
    companyOwnerId: "",
    subjectList: {},
    classDetails: {},
    timeEntryModal: false,
    subjectOptions: [],
    selectedClassRoom: "",
    selectedSubject: "",
    selectedTask: "",
    taskOptions: [],
    todayTotalHours: "00:00",
    hours: "00:00",
    notes: "",
    selectedDate: new Date(),
    editId: "",
    selectedDateDetails: [],
    errors: {
        selectedClassRoom: "",
        selectedSubject: "",
        selectedTask: "",
        notes: "",
        hours: "",
    },
    artifactType: "url",
    artifactURL: [""],
    artifactURLText: [],
    documentFiles: [],
    document_Json: [],
    entryDetails: {},
    entryDetailsIndex: "",
    entryDetailsModal: false,
    hoursOption: [],
    submitLoader: false,
    imagePreview: {},
    modalVisible: false,
    pageText: null,
    teamOptions: [],
    selectedTeam: "",
    editTextChanges: false,
    editResponseData: {},
    subjectListOptions: [],
};

const educationWorkspaceReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_EDUCATION_WORKSPACE: {
            return {
                ...state,
                [action.key]: action.value,
            };
        }
        case SET_EDUCATION_MULTIPLE_WORKSPACE: {
            return {
                ...state,
                ...action.data,
            };
        }
        case RESET_EDUCATION_WORKSPACE: {
            return {
                ...state,
                currentDate: "",
                userId: "",
                companyId: "",
                companyOwnerId: "",
                subjectList: {},
                classDetails: {},
                timeEntryModal: false,
                subjectOptions: [],
                selectedClassRoom: "",
                selectedSubject: "",
                selectedTask: "",
                taskOptions: [],
                todayTotalHours: "00:00",
                hours: "00:00",
                notes: "",
                selectedDate: new Date(),
                editId: "",
                selectedDateDetails: [],
                errors: {
                    selectedClassRoom: "",
                    selectedSubject: "",
                    selectedTask: "",
                    notes: "",
                    hours: "",
                },
                artifactType: "url",
                artifactURL: [""],
                artifactURLText: [],
                documentFiles: [],
                document_Json: [],
                entryDetails: {},
                entryDetailsIndex: "",
                entryDetailsModal: false,
                hoursOption: [],
                submitLoader: false,
                imagePreview: {},
                modalVisible: false,
                pageText: null,
                teamOptions: [],
                selectedTeam: "",
                editTextChanges: false,
                editResponseData: {},
                subjectListOptions: [],
            };
        }
        default: {
            return state;
        }
    }
};

export default educationWorkspaceReducer;
