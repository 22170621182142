import { RESET_EDUCATION_EXPENSES, SET_EDUCATION_EXPENSES, SET_MULTIPLE_EDUCATION_EXPENSES } from '../constants/actionTypes';

const initialState = {
    sDate: new Date(),
    currentDate: "",
    userId: "",
    companyId: "",
    expensesModal: false,
    selectedSubject: "",
    selectedClassRoom: "",
    submitLoader: false,
    selectedCategory: "",
    categoryOptions: [],
    subjectOptions: [],
    documentFiles: [],
    document_Json: [],
    notes: "",
    amount: 0,
    errors: {
        selectedClassRoom: "",
        selectedSubject: "",
        selectedCategory: "",
        notes: ""
    },
    isBillable: true,
    selectedDate: new Date(),
    selectedDateDetails: [],
    expenseDetails: {},
    expenseDetailsIndex: "",
    expenseDetailsModal: false,
    previewModal: false,
    pageText: null,
    editTextChanges: false,
    editResponseData: {},
    editId: "",
    subjectOptionsList:[]
};

const educationExpensesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_EDUCATION_EXPENSES: {
            return {
                ...state,
                [action.key]: action.value,
            };
        }
        case SET_MULTIPLE_EDUCATION_EXPENSES: {
            return {
                ...state,
                ...action.data,
            };
        }
        case RESET_EDUCATION_EXPENSES: {
            return {
                ...state,
                sDate: new Date(),
                currentDate: "",
                userId: "",
                companyId: "",
                expensesModal: false,
                selectedClassRoom: "",
                selectedSubject: "",
                submitLoader: false,
                selectedCategory: "",
                categoryOptions: [],
                subjectOptions: [],
                documentFiles: [],
                document_Json: [],
                notes: "",
                amount: 0,
                errors: {
                    selectedClassRoom: "",
                    selectedSubject: "",
                    selectedCategory: "",
                    notes: ""
                },
                isBillable: true,
                selectedDate: new Date(),
                selectedDateDetails: {},
                expenseDetails: {},
                expenseDetailsIndex: "",
                expenseDetailsModal: false,
                previewModal: false,
                pageText: null,
                editTextChanges: false,
                editResponseData: {},
                editId: "",
                subjectOptionsList:[]
            };
        }
        default: {
            return state;
        }
    }
};

export default educationExpensesReducer;
