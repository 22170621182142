import Loader from "../../../components/Loader";

function ProcessAudioToText(props) {
    let { processAudioToText, resultAudioToText } = props;
    return processAudioToText === "process" ? <div style={{ height: 250 }}>
        <Loader />
    </div> : <div>
        {
            resultAudioToText.text.trim() ? <div>
                <div className="voice-to-text-result">{resultAudioToText.text}</div>
                <div className="text-right">
                    <button className="btn btn-sm btn-success" onClick={() => props.addTextToChat(resultAudioToText.text)}>Add to Chat</button>
                </div>
            </div> : "No result found."
        }
    </div>;
}

export default ProcessAudioToText