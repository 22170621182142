import React, { useEffect, useRef } from "react";


let localCamStream;
let stream;
let chunks = []
export default function RecordScreenWithVideo(props) {
    let { setStatus, stopRecord, setMediaBlobUrl, videoRef, setRecordStarted, mediaBlobUrl, save, blobPropertyBag } = props;

    useEffect(() => {
        if (videoRef) {
            init()
        }
    }, [videoRef])

    useEffect(() => {
        if (stopRecord) {
            stop()
        }
    }, [stopRecord])

    async function init() {
        try {
            localCamStream = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: { deviceId: { ideal: "communications" } }
            })

            videoRef.current.srcObject = localCamStream;
            videoRef.current.volume = 0;

            stream = await navigator.mediaDevices.getDisplayMedia({
                video: true,
                audio: true,
            })
            setStatus("recording")

            let combine = new MediaStream([...stream.getTracks(), ...localCamStream.getAudioTracks()])
            let mediaRecorder = new MediaRecorder(stream)


            mediaRecorder.addEventListener('dataavailable', function (e) {
                chunks.push(e.data)
            })

            mediaRecorder.addEventListener('stop', function () {
                stop()
            })

            //we have to start the recorder manually
            mediaRecorder.start()
        } catch (err) {
            /* handle the error */
            console.log("err", err)
            stopAllStreamsFn()
            setRecordStarted(false)
            setStatus("idle")
        }
    }



    function stop() {
        setStatus("stopped")
        // setRecordStarted(false)
        let blob = new Blob(chunks, blobPropertyBag)
        let url = URL.createObjectURL(blob)
        setMediaBlobUrl(url)

        stopAllStreamsFn()
    }

    function stopAllStreamsFn() {
        [
            ...(localCamStream ? localCamStream.getTracks() : []),
            ...(stream ? stream.getTracks() : []),
        ].map((track) => track.stop());
        localCamStream = null;
    }

    return <React.Fragment>
        {
            mediaBlobUrl && <div className="video-container">
                <video src={mediaBlobUrl} controls height={400} />
                <div className="text-center my-2">
                    <a className="btn btn-success mr-2" href={mediaBlobUrl} download={"Record"}>Download</a>
                    <button className="btn btn-primary" onClick={() => save(mediaBlobUrl)}>Save</button>
                </div>
            </div>
        }
    </React.Fragment>;
}

