import {
  SET_FAQ,
  SET_MULTIPLE_FAQ,
  RESET_FAQ
} from '../constants/actionTypes';

const initialState = {
  toggleQuestion: []
};

const faqReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FAQ: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_FAQ: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_FAQ: {
      return {
        ...state,
        toggleQuestion: []
      };
    }
    default: {
      return state;
    }
  }
};

export default faqReducer;
