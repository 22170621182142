import React, { useEffect, useState, useRef, Suspense, lazy } from 'react';
import {
    Switch,
    Route,
    Link,
    useRouteMatch,
    useLocation,
    withRouter,
    useParams
} from "react-router-dom";

import { useSelector } from 'react-redux';


// GHT
const GHTHome = lazy(() => import('./ght/GHTHome'));




function GHTRoute(props) {
    // The `path` lets us build <Route> paths that are
    // relative to the parent route, while the `url` lets
    // us build relative links.
    let { path, openApps, activeCompanyOwner, languagePreference, setContextHelp, contextHelp, companyType } = props;
    const previousValue = useRef(null);
    const auth = useSelector(state => state.authReducer);
    let { loginId } = auth;

    return (
        <>
            <Switch>
                <Route exact path={`${path}/home`}>
                    <GHTHome history={props.history} grpcClient={props.grpcClient}
                    />
                </Route>

            </Switch>
        </>
    );
}
export default withRouter(GHTRoute);