import MyConfig from '../../config/MyConfig';
import {
  SET_NOTES,
  SET_MULTIPLE_NOTES,
  RESET_NOTES
} from '../constants/actionTypes';

const initialState = {
  currentChannelName: "",
  channelType: "",
  loginId: "",
  channelId: "",
  channelMembers: [],
  userChatHistory: {},
  currentResult: 0,
  message: "",
  fileList: [],
  noOfPage: 1,
  emojiPicker: false,
  gifPicker: false,
  buildUrl: "",
  insertTextToggle: false,
  setEmptyText: false,
  insertText: "",
  recording: false,
  recordingModal: false,
  previewBlob: {},
  previewType: "",
  selectedMessage: "",
  editMessageId: "",
  editMessageDetails: {},
  editMessageText: "",
  infoShow: true,
  isRender: false,
  scrollBottom: true,
  gifURL: "",
  deleteMessageId: [],
  companyId: "",
  channelMembersDetails: {},
  channelMembersInfo: {},
  isFeedPage: false,
  pageText: null,
  addPeopleModal: false,
  channelUser: [],
  userListOption: [],
  errors: {},
  excludeMembers: [],
  channelMuteStatus: "unmute",
  messageSound: MyConfig.messageSound,
  selectedMessageSound: "sound1",
  deactivatedMembersIds: [],
  previewModal: false,
  changeText: "",
  editBlink: false,
  showMorePeopleModal: false,
  existChannelMemberIds: [],
  onlineUserData: {},
  copyChannelMembersResult: [],
  paginateData: {
    pagination: [],
    noOfPage: 1,
    prev: false,
    next: false,
  },
  tempMessageList: {},
  dataFetched: false,
  selectedAgent: { label: "Select Bot", value: "" },
  agentDropdown: false,
  shareMessage: "",  
};

const notesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTES: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_NOTES: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_NOTES: {
      return {
        ...state,
        currentChannelName: "",
        channelType: "",
        loginId: "",
        channelId: "",
        channelMembers: [],
        userChatHistory: {},
        currentResult: 0,
        message: "",
        fileList: [],
        noOfPage: 1,
        emojiPicker: false,
        gifPicker: false,
        buildUrl: "",
        insertTextToggle: false,
        setEmptyText: false,
        insertText: "",
        recording: false,
        recordingModal: false,
        previewBlob: {},
        previewType: "",
        selectedMessage: "",
        editMessageId: "",
        editMessageDetails: {},
        editMessageText: "",
        infoShow: true,
        isRender: false,
        scrollBottom: true,
        gifURL: "",
        deleteMessageId: [],
        companyId: "",
        channelMembersDetails: {},
        isFeedPage: false,
        pageText: null,
        addPeopleModal: false,
        channelUser: [],
        userListOption: [],
        errors: {},
        excludeMembers: [],
        channelMuteStatus: "unmute",
        messageSound: MyConfig.messageSound,
        selectedMessageSound: "sound1",
        deactivatedMembersIds: [],
        previewModal: false,
        changeText: "",
        editBlink: false,
        showMorePeopleModal: false,
        existChannelMemberIds: [],
        copyChannelMembersResult: [],
        paginateData: {
          pagination: [],
          noOfPage: 1,
          prev: false,
          next: false,
          processing: false,
          tableData: {},
          initialStatusList: {},
          paginateStatusList: {},
          channelOnlineUserId: [],
        },
        tempMessageList: {},
        dataFetched: false,
        selectedAgent: { label: "Select Bot", value: "" },
        agentDropdown: false,
        shareMessage: "",
  
      };
    }
    default: {
      return state;
    }
  }
};

export default notesReducer;
