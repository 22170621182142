import {
  SET_CLIENT,
  SET_MULTIPLE_CLIENT,
  RESET_CLIENT
} from '../constants/actionTypes';

const initialState = {
  addClientModal: false,
  clientName: "",
  refresh: true,
  userId: "",
  companyId: "",
  editId: "",
  errors: {
    clientName: ""
  },
  tableTxtData: [],
  pageText: null,
  editTextChanges: false,
  editResponseData: {},
  csvModalOpen: false,
  documentFiles: [],
  document_Json: [],
  clientButtonDisable: false,
  languageOptions: [],
  languageSelect: "",
  languageResponse: {},
  clientCsvLoader: false,
  clientBtnLoader: false,
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CLIENT: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_CLIENT: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_CLIENT: {
      return {
        ...state,
        addClientModal: false,
        clientName: "",
        refresh: true,
        userId: "",
        companyId: "",
        editId: "",
        errors: {
          clientName: ""
        },
        tableTxtData: [],
        pageText: null,
        editTextChanges: false,
        editResponseData: {},
        csvModalOpen: false,
        documentFiles: [],
        document_Json: [],
        clientButtonDisable: false,
        languageOptions: [],
        languageSelect: "",
        languageResponse: {},
        clientCsvLoader: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default clientReducer;
