import React from 'react';
import { withRouter, } from 'react-router-dom';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import Modal from './Modal';
import { getGrpcClient, getAppInfo, getValidLabels } from '../config/Common';
import { getUuidv4, coversionED } from '../config/Common';
import GlobalDomainCons from '../config/GlobalDomainCons';
import GlobalEventCons from '../config/GlobalEventCons';
import GlobalMsgCons from '../config/GlobalMsgCons';
import DropDown from './DropDown';
import { connect } from 'react-redux';
import MyConfig from '../config/MyConfig';




class SharedMessageModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            groupedChannelOptions: this.props.groupedChannelOptions,
            selectedGroup: "",
            shareSubmitLoader: false,
            errorSharedMsgId: "",
            refresh: false,
            pageText: this.props.pageText
        }
    }

    componentDidMount() {
    }


    shareSubmit() {
        let { selectedGroup, groupedChannelOptions } = this.state;
        let { userId, companyId, shareMessage, path, channelId } = this.props;
        console.log("shareMessage", shareMessage)
        let deviceInfo = getAppInfo();
        let messageId = getUuidv4();
        let createdAt = new Date();
        let { message, fileList, objUrl, id, sharedChatData } = shareMessage.chatMessage

        let originUserId = userId;
        if(sharedChatData) {
            originUserId = sharedChatData.originUserId
        }

        let postJson = {
            reqId: getUuidv4(),
            messageId,
            channelId: selectedGroup.value,
            orgId: companyId,
            senderId: userId,
            message,
            fileList,
            sendAt: createdAt.getTime(),
            objUrl,
            messageType: GlobalMsgCons.messageTypeSharedChat,
            deviceInfo,
            sharedChatData: { channelId, msgId: id, originUserId  },
            userType: GlobalMsgCons.type_user
        };

        // console.log("postJson", postJson)
        if (selectedGroup.msgType) {
            console.log("selectedGroup", selectedGroup.msgType)
            localStorage.setItem(MyConfig.project_LocalStorage + "channelSharedType", selectedGroup.msgType);
        }



        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.sendMsgASyncSetEvent);
        request.setMessage(postJson);
        request.setDatetime(createdAt.toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = responseData
                if (responseData === GlobalMsgCons.success) {
                    let url = path + "/chat/" + selectedGroup.value;
                    console.log("url", url)
                    this.setState({ selectedGroup: "", shareSubmitLoader: false, errorSharedMsgId: "" }, () => {
                        this.props.closeModal("");
                        this.props.history.push(url)
                    })
                }
            }
        });
    }


    render() {
        let { shareMessage, heading, } = this.props
        let { selectedGroup, groupedChannelOptions, shareSubmitLoader, errorSharedMsgId, pageText } = this.state
        return (<>

            {
                shareMessage && <Modal
                    visible={shareMessage}
                    closeModal={() => {
                        this.setState({ selectedGroup: "" })
                        this.props.closeModal("")
                    }}
                    heading={heading}
                    body={<React.Fragment>
                        <DropDown
                            options={groupedChannelOptions}
                            isSearchable={true}
                            placeholder={getValidLabels(pageText, 'selectChannelsTxt')}
                            onChange={(e) => {
                                this.setState({
                                    selectedGroup: e,
                                    errorSharedMsgId: ""
                                })
                            }}
                            selectedOption={selectedGroup}
                            isDisabled={false}
                            isMulti={false}
                            error={(errorSharedMsgId)}
                        />
                    </React.Fragment>}
                    footer={<React.Fragment>
                        <button type="button" className="btn theme-bg" disabled={shareSubmitLoader} onClick={() => {
                            this.setState({ shareSubmitLoader: selectedGroup, errorSharedMsgId: !selectedGroup }, () => {
                                if (!selectedGroup) {
                                    return false
                                }
                                this.shareSubmit()
                            })
                        }}>
                            {shareSubmitLoader && <i className="fa fa-spinner fa-spin"></i>}
                            <span className={`${shareSubmitLoader && ("pl-1")}`}>
                                {getValidLabels(pageText, 'shareBtnTxt')}
                            </span>
                        </button>
                    </React.Fragment>}
                />
            }

        </>)
    }

}

const mapStateToProps = (state) => {
    return {
        userId: state.authReducer.loginId,
        companyId: state.authReducer.activeCompany,
        sessionId: state.authReducer.sessionId,
        firstName: state.authReducer.firstName,
        openMrsChannelId: state.authReducer.openMrsChannelId,
    }
}
export default withRouter(connect(mapStateToProps)(SharedMessageModal));

