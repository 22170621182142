const GlobalMsgCons = require("./GlobalMsgCons");

module.exports = {
    GuestUser: "guestUser",
    User: "user",
    Project: "project",
    Client: "client",
    Manager: "manager",
    Teacher: "teacher",
    Apps: "Apps",
    Class: "class",
    Subject: "subject",
    StudyMaterial: "StudyMaterial",
    CommunityServiceAdmin: GlobalMsgCons.roleTypeCommunityServiceAdmin
}