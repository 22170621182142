import { withRouter, Link } from "react-router-dom";
import LabsWhite from "../assets/logo/LabsWhite.png"
import logoImg from "../assets/logo/logo.png"
import GoesOnLightBackground from "../assets/logo/LightBg2.gif"
import { analyticsUserTrack } from "../config/AnalyticsTrack";
import Menu from "./Menu";

function Header(props) {
    return <div className='version-2' >
        <div className='block1'>
            <div className={"home-sticky-crop d-flex"}>
                <div className="logo d-flex align-items-center">
                    <a href={"https://teqbahn.com/"} onClick={() => {
                        analyticsUserTrack("Home", "Teqbahn Home Click")
                    }}>
                        <img className='img-fluid' src={LabsWhite} />
                    </a>
                </div>
                <div className="teqit-logo-container d-flex flex-1 justify-content-center align-items-center">
                    <div className="teqit-logo">
                        <Link to={"/"}>
                            <img className='img-fluid' src={logoImg} />
                        </Link>
                    </div>
                </div>
                <div className="teqit-logo-container d-flex flex-1 justify-content-center align-items-center">
                    <div className="teqbahn-logo">
                        <a href={"https://teqbahn.com/mid-year-update-2024/"}>
                            <img className='img-fluid' src={GoesOnLightBackground} />
                        </a>
                    </div>
                </div>
                <div className="teqit-menu d-flex justify-content-center align-items-center">
                    <Menu />
                </div>
            </div>
        </div>
    </div>;
}

export default withRouter(Header)
