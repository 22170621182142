import {
  SET_TASK,
  SET_MULTIPLE_TASK,
  RESET_TASK
} from '../constants/actionTypes';

const initialState = {
  userId: "",
  companyId: "",
  projectList: {},
  clientDetails: {},
  clientOptions: [],
  selectedClient: {},
  projectName: "",
  notes: "",
  addProjectModal: false,
  editId: "",
  errors: {
    selectedClient: "",
    projectName: "",
    notes: "",
  },
  pageText: null,
  clientExpanded: [],
  editTextChanges: false,
  editResponseData: {},
  userListOption: {},
  tableJson: [],
  viewModal: false,
  viewDetails: {},
  taskModal: false,
  taskDetails: {},
  teamDetails: {},
  addMembersToTeamModal: false,
  addTaskMembersModal: false,
  teamModal: false,
  viewType: {},
  filterClient: "",
  userDetails: {},
  studentListModal: false,
  studentDetails: {},
  noOfPage: 1,
  pageLimit: 10,
  loadMore: false,
  projectResponseData: {},
  proPagination: [],
  proPrev: false,
  proNext: false,
  membersShowMorePaginate: { noOfPage: 1, pageLimit: 10, next: false, prev: false, pagination: [] },
  taskShowMore: {
    paginate: { noOfPage: 1, pageLimit: 10, next: false, prev: false, pagination: [], },
    project: { projectName: "", projectId: "", },
    clientDetails: { clientName: "", clientId: "", },
    teamData: { teamName: "", teamId: "" },
    isTaskPage: false, taskListData: {}, detailsInfo: {}
  },
  projectTeamShowMore: {
    paginate: { noOfPage: 1, pageLimit: 10, next: false, prev: false, pagination: [], },
    project: { projectName: "", projectId: "", },
    clientDetails: { clientName: "", clientId: "", },
    isTeamMore: false, tableJson: [], detailsInfo: {}
  },
};

const taskReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TASK: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_TASK: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_TASK: {
      return {
        ...state,
        userId: "",
        companyId: "",
        projectList: {},
        clientDetails: {},
        clientOptions: [],
        selectedClient: {},
        projectName: "",
        notes: "",
        addProjectModal: false,
        editId: "",
        errors: {
          selectedClient: "",
          projectName: "",
          notes: "",
        },
        pageText: null,
        clientExpanded: [],
        editTextChanges: false,
        editResponseData: {},
        userListOption: {},
        tableJson: [],
        viewModal: false,
        viewDetails: {},
        taskModal: false,
        taskDetails: {},
        teamDetails: {},
        addMembersToTeamModal: false,
        addTaskMembersModal: false,
        teamModal: false,
        viewType: {},
        filterClient: "",
        userDetails: {},
        studentListModal: false,
        studentDetails: {},
        noOfPage: 1,
        pageLimit: 10,
        loadMore: false,
        projectResponseData: {},
        proPagination: [],
        proPrev: false,
        proNext: false,
        membersShowMorePaginate: { noOfPage: 1, pageLimit: 10, next: false, prev: false, pagination: [] },
        taskShowMore: {
          paginate: { noOfPage: 1, pageLimit: 10, next: false, prev: false, pagination: [], },
          project: { projectName: "", projectId: "", },
          clientDetails: { clientName: "", clientId: "", },
          teamData: { teamName: "", teamId: "" },
          isTaskPage: false, taskListData: {}
        },
        projectTeamShowMore: {
          paginate: { noOfPage: 1, pageLimit: 10, next: false, prev: false, pagination: [], },
          project: { projectName: "", projectId: "", },
          clientDetails: { clientName: "", clientId: "", },
          isTeamMore: false, tableJson: [], detailsInfo: {}
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default taskReducer;
