import {
  SET_MEMBERS,
  SET_MULTIPLE_MEMBERS,
  RESET_MEMBERS
} from '../constants/actionTypes';

let time = new Date()

const initialState = {
  inviteModal: false,
  emailIds: [""],
  refresh: true,
  userId: "",
  companyId: "",
  tableDataArry: "",
  errorEmailId: {},
  inviteEmail: "",
  inviteResendModal: false,
  csvModalOpen: false,
  documentFiles: [],
  document_Json: [],
  memberRoleTypeModal: false,
  memberRoleTypeData: {},
  companyRoleType: [{ label: "Member", value: "member" }, { label: "Manager", value: "memberAdmin" }],
  selectedRoleType: "",
  educationRoleType: [{ label: "Student", value: "student" }, { label: "Teacher", value: "teacher" }],
  memberCsvLoader: false,
  inviteSubmitLoader: false,
  roleTypeSubmitLoader: false,
  mailSendType: true,
  sidemenuControl: false,
  memberId: "",
  readNotesModal: "",
  readNotes: "",  
  ownerInfo: {}
};

const membersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MEMBERS: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_MEMBERS: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_MEMBERS: {
      return {
        ...state,
        inviteModal: false,
        emailIds: [""],
        refresh: true,
        userId: "",
        companyId: "",
        tableDataArry: "",
        errorEmailId: {},
        inviteEmail: "",
        inviteResendModal: false,
        csvModalOpen: false,
        documentFiles: [],
        document_Json: [],
        memberRoleTypeModal: false,
        memberRoleTypeData: {},
        companyRoleType: [{ label: "Member", value: "member" }, { label: "Manager", value: "memberAdmin" }],
        selectedRoleType: "",
        educationRoleType: [{ label: "Student", value: "student" }, { label: "Teacher", value: "teacher" }],
        memberCsvLoader: false,
        inviteSubmitLoader: false,
        roleTypeSubmitLoader: false,
        mailSendType: true,
        sidemenuControl: false,
        memberId: "",
        readNotesModal: "",
        readNotes: "",
        ownerInfo: {}
      };
    }
    default: {
      return state;
    }
  }
};

export default membersReducer;
