import {
  SET_REPORT,
  SET_MULTIPLE_REPORT,
  RESET_REPORT
} from '../constants/actionTypes';


var d = new Date();
d.setDate(d.getDate() - 7);
let ds = new Date()
let yesterday = d;

const initialState = {
  userId: "",
  companyId: "",
  isLoggedIn: '0',
  sDate: yesterday,
  eDate: ds,
  activeTab: "client",
  reportData: {
    clientReport: {},
    projectReport: {},
    taskReport: {},
    teamReport: {},
  },
  commonReportData: [],
  searchLoading: false,
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REPORT: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_REPORT: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_REPORT: {
      return {
        ...state,
        userId: "",
        companyId: "",
        isLoggedIn: '0',
        sDate: new Date(),
        eDate: new Date(),
        activeTab: "client",
        reportData: {
          clientReport: {},
          projectReport: {},
          taskReport: {},
          teamReport: {},
        },
        commonReportData: [],
        searchLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default reportReducer;
