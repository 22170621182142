import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { useEffect, useState } from "react";
import 'react-datepicker/dist/react-datepicker.css'

function CustomDatePicker(props) {
    let { className, dateFormat, selected, onChange, disabled, dateFormatType } = props;
    const auth = useSelector(state => state.authReducer);

    let [customDateFormat, setCustomDateFormat] = useState(dateFormat)
    useEffect(() => {
        let { activeCompanyDetails } = auth;
        activeCompanyDetails = JSON.parse(activeCompanyDetails);
        let activeCompanyDateFormat = activeCompanyDetails.dateFormat;
        let format = "dd/MM/yyyy";
        if (activeCompanyDateFormat === "MM/DD/YYYY") {
            format = "MM/dd/yyyy";
        } else if (activeCompanyDateFormat === "YYYY/MM/DD") {
            format = "yyyy/MM/dd";
        }
        if (dateFormatType === "custom") {
            format = dateFormat
        }
        setCustomDateFormat(format)
    }, [])

    return <div>
        <DatePicker
            className={className}
            dateFormat={customDateFormat}
            selected={selected}
            onChange={onChange}
            onKeyDown={(e) => {
                e.preventDefault();
            }}
            disabled={disabled ? disabled : false}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
        />
    </div>
}

export default CustomDatePicker;