import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyC6xFB7uHBTcpi4ApEJKdEkecZZQ6ZFY-g",
    authDomain: "teqit-e04a8.firebaseapp.com",
    projectId: "teqit-e04a8",
    storageBucket: "teqit-e04a8.appspot.com",
    messagingSenderId: "750362348852",
    appId: "1:750362348852:web:f27a1afa4669128961e961",
    measurementId: "G-9F101XDFLV"
};

firebase.initializeApp(firebaseConfig);
// const messaging = firebase.messaging();
let messaging;
try {
    messaging = firebase.messaging();
} catch (err) {
    console.error("Failed to initialize Firebase Messaging", err);
}


export const getToken = () => {
    return messaging.getToken({ vapidKey: 'BIz74Vy04QilMRHncxhUWYCnHGQSygHDk9vxrY9fERw2XA7qL3kLXfVnvdKWZUpBIFU9PA7caciNXelSZ3hMsFk' }).then((currentToken) => {
        if (currentToken) {
            // console.log('current token for client: ', currentToken);
            // setTokenFound(currentToken);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured

            return currentToken;
        } else {
            console.log('No registration token available. Request permission to generate one.');
            // shows on the UI that permission is required 
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        messaging.onMessage((payload) => {
            resolve(payload);
        });
    });
