import React from "react";
import { useSelector } from "react-redux";
import MyConstant from "../config/MyConstant";
import { Link, withRouter } from "react-router-dom";

function SwitchAccountAlert(props) {
    let { workspaceType } = props;
    const auth = useSelector(state => state.authReducer);
    let { activeCompany } = auth
    let pathname = props.location.pathname;

    return <React.Fragment>
        {
            (activeCompany === "" && !pathname.includes("switch-account"))  && <div className="workspace-message text-center">Please note that you haven't selected any workspace at the moment. For Switch Account please <Link to={MyConstant.keyList.projectUrl + "/" + workspaceType + "/login/switch-account"}>Click here.</Link></div>
        }
    </React.Fragment >;
}

export default withRouter(SwitchAccountAlert)