import { useEffect, useRef, } from "react"
import Spreadsheet from "x-data-spreadsheet";
import { xtos } from "../../../config/xlsxspread";
import { writeFile } from 'xlsx';
import MyConfig from "../../../config/MyConfig"

let xSpreadSheet;
let sheetIndex = 0;
let fullData = []
function SheetsSetup(props) {
    const ref = useRef(null)
    let accessType = props.accessType === "edit" ? "" : "read"
    let restricationType = props.accessType === "edit" ? true : false
    let mobileView = false
    let checkSrcType = localStorage.getItem(MyConfig.project_LocalStorage + "_srcType");
    if (checkSrcType === "app") {
        mobileView = true
    }

    useEffect(() => {
        if (ref && props.accessType) {
            fullData = props.content ? props.content : [];
            xSpreadSheet = new Spreadsheet("#teqit-spreadsheet" + accessType, {
                mode: props.accessType, // edit | read,
                showToolbar: restricationType,
                showBottomBar: restricationType,
                view: {
                    height: () => (mobileView) ? window.innerHeight - 30 : ref.current ? ref.current.clientHeight : "",
                    width: () => (mobileView) ? window.innerWidth : ref.current ? ref.current.clientWidth : "",
                },
                style: { bgcolor: props.accessType === "edit" ? "#fff" : "#d7d7d7", },
            })
                .loadData(props.content) // load data
                .change(data => {
                    fullData[sheetIndex] = data;
                    // save data to db
                    props.write(JSON.stringify(fullData))
                });
            xSpreadSheet.validate()
        }

    }, [ref,])
    useEffect(() => {
        if (!restricationType) {
            xSpreadSheet.loadData(props.content)
        }

    }, [props.content])

    useEffect(() => {
        if (props.downloadInc > 0) {
            var new_wb = xtos(xSpreadSheet.getData());
            /* generate download */
            try {
                writeFile(new_wb, props.filename + ".xlsx");
            } catch (e) {
                console.log(e);
            }
        }
    }, [props.downloadInc])

    const handleOnClick = (e) => {
        if (e.target.closest('.x-spreadsheet-menu')) {
            let nodes = e.target.closest('.x-spreadsheet-menu').querySelectorAll('li');
            for (let i = 0; i < nodes.length; i++) {
                if (nodes[i].classList.contains("active")) {
                    sheetIndex = i - 1
                }
            }
        }
    };
    return <div onClick={handleOnClick} id={"teqit-spreadsheet" + accessType} ref={ref}></div>
}

export default SheetsSetup