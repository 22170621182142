import {
  SET_EXPENSES_CATEGORY,
  SET_MULTIPLE_EXPENSES_CATEGORY,
  RESET_EXPENSES_CATEGORY
} from '../constants/actionTypes';

let time = new Date()

const initialState = {
  userId: "",
  companyId: "",
  addCategoryModal: false,
  categoryName: "",
  refresh: true,
  editId: "",
  errors: {
    categoryName: ""
  },
  editTextChanges: false,
  editResponseData: {},
  expenseCatSubmitLoader: false,
};

const expensesCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EXPENSES_CATEGORY: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_EXPENSES_CATEGORY: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_EXPENSES_CATEGORY: {
      return {
        ...state,
        userId: "",
        companyId: "",
        addCategoryModal: false,
        categoryName: "",
        refresh: true,
        editId: "",
        errors: {
          categoryName: ""
        },
        editTextChanges: false,
        editResponseData: {},
        expenseCatSubmitLoader: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default expensesCategoryReducer;
