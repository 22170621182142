import {
    SET_EDUCATION_EXPENSE_REPORTS,
    SET_MULTIPLE_EDUCATION_EXPENSE_REPORTS,
    RESET_EDUCATION_EXPENSE_REPORTS
  } from '../constants/actionTypes';
  
  
  var d = new Date();
  d.setDate(d.getDate() - 7);
  let ds = new Date()
  let yesterday = d;
  
  const initialState = {
    userId: "",
    companyId: "",
    companyOwnerId: "",
    sDate: yesterday,
    eDate: ds,
    report: [],
    expenseDetails: {},
    expenseDetailsIndex: "",
    expenseDetailsIndex: false,
    userId: "",
    companyId: "",
    companyOwnerId: "",
    userListOption: [],
    selectedUser: "",
    expenseDetailsModal: false,
    searchLoader: false

  };
  
  const educationExpenseReportReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_EDUCATION_EXPENSE_REPORTS: {
        return {
          ...state,
          [action.key]: action.value,
        };
      }
      case SET_MULTIPLE_EDUCATION_EXPENSE_REPORTS: {
        return {
          ...state,
          ...action.data,
        };
      }
      case RESET_EDUCATION_EXPENSE_REPORTS: {
        return {
          ...state,
          userId: "",
          companyId: "",
          companyOwnerId: "",
          sDate: yesterday,
          eDate: ds,
          report: [],
          expenseDetails: {},
          expenseDetailsIndex: "",
          expenseDetailsIndex: false,
          userId: "",
          companyId: "",
          companyOwnerId: "",
          userListOption: [],
          selectedUser: "",
          expenseDetailsModal: false,
          searchLoader: false

        };
      }
      default: {
        return state;
      }
    }
  };
  
  export default educationExpenseReportReducer;
  