import GlobalMsgCons from '../../config/GlobalMsgCons';
import MyConfig from '../../config/MyConfig';
import { RESET_REGISTER, SET_REGISTER, SET_MULTIPLE_REGISTER } from '../constants/actionTypes';

const initialState = {
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  passwordVisible: false,
  errors: {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    country: "",
    pincode: ""
  },
  countryOptions: [],
  countrySelected: "",
  pincode: "",
  address: "",
  languageOptions: [],
  languageSelected: "",
  genderOptions: MyConfig.gender,
  genderSelected: "Male",
  loadingRegister: false,
  roleType: GlobalMsgCons.roleTypeCommunityMember
};

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REGISTER: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_REGISTER: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_REGISTER: {
      return {
        ...state,
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        passwordVisible: false,
        errors: {
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          country: "",
          pincode: ""
        },
        countryOptions: [],
        countrySelected: "",
        pincode: "",
        address: "",
        languageOptions: [],
        languageSelected: "",
        loadingRegister: false,
        roleType: GlobalMsgCons.roleTypeCommunityMember
      };
    }
    default: {
      return state;
    }
  }
};

export default registerReducer;
