import {
  SET_EDUCATION_CSV_FILE_HISTORY,
  SET_MULTIPLE_EDUCATION_CSV_FILE_HISTORY,
  RESET_EDUCATION_CSV_FILE_HISTORY
} from '../constants/actionTypes';


const initialState = {
  noPagesLimit: 1,
  totalPagesResults: "",
  processing: false,
  pageLimit: 10,
  prev: false,
  next: false,
  pagination: [],
  csvHistoryData: [],
  viewModal: false,
  companyList: {}
};

const educationCsvFileHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EDUCATION_CSV_FILE_HISTORY: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_EDUCATION_CSV_FILE_HISTORY: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_EDUCATION_CSV_FILE_HISTORY: {
      return {
        ...state,
        noPagesLimit: 1,
        totalPagesResults: "",
        processing: false,
        pageLimit: 10,
        prev: false,
        next: false,
        pagination: [],
        csvHistoryData: [],
        viewModal: false,
        companyList: {}
      };
    }
    default: {
      return state;
    }
  }
};

export default educationCsvFileHistoryReducer;
