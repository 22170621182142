import GlobalMsgCons from '../../config/GlobalMsgCons';
import {
  SET_EDUCATION_EXPENSE_MANAGE,
  SET_MULTIPLE_EDUCATION_EXPENSE_MANAGE,
  RESET_EDUCATION_EXPENSE_MANAGE
} from '../constants/actionTypes';
const initialState = {
  userId: "",
  companyId: "",
  expenseDetails: {},
  expenseDetailsIndex: "",
  expenseDetailsIndex: false,
  expenseDetailsModal: false,
  selectedIds: {},
  pageLimit: 10,
  noOfPage: 1,
  pagination: [],
  prev: false,
  next: false,
  approveModal: false,
  result: [],
  counterList: {},
  statusOptions: [
    { value: GlobalMsgCons.pending, label: "Pending" },
    { value: GlobalMsgCons.approved, label: "Approved" },
    { value: GlobalMsgCons.rejected, label: "Rejected" },
  ],
  selectedStatusType: "all"
};

const educationExpenseManageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EDUCATION_EXPENSE_MANAGE: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_EDUCATION_EXPENSE_MANAGE: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_EDUCATION_EXPENSE_MANAGE: {
      return {
        ...state,
        userId: "",
        companyId: "",
        expenseDetails: {},
        expenseDetailsIndex: "",
        expenseDetailsIndex: false,
        expenseDetailsModal: false,
        selectedIds: {},
        pageLimit: 10,
        noOfPage: 1,
        pagination: [],
        prev: false,
        next: false,
        approveModal: false,
        result: {},
        counterList: {},
        statusOptions: [
          { value: GlobalMsgCons.pending, label: "Pending" },
          { value: GlobalMsgCons.approved, label: "Approved" },
          { value: GlobalMsgCons.rejected, label: "Rejected" },
        ],
        selectedStatusType: "all"
      };
    }
    default: {
      return state;
    }
  }
};

export default educationExpenseManageReducer;
